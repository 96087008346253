import React from 'react';

// IMAGES
import PlaceholderBanner from '../../img/placeholder-banner.jpg';

// ----------------------------------------------------------------------------------
// ---------------------------------- PLAYLIST CARD ---------------------------------
// ----------------------------------------------------------------------------------

export const PlaylistCard = ({ data }) => {
  const {
    type,
    img,
    description,
  } = data;

  return (
    <div
      key={type}
      className={
        'hover:bg-mcgreen max-w-359 hover:bg-opacity-25 p-2 rounded-lg  z-50'
      }
    >
      <div className={`overflow-hidden`}>
        {/* TOP IMG */}
        <img
          src={img ? img : PlaceholderBanner}
          alt='playlist icon'
          className='object-fill w-full h-full min-h-full max-h-40 rounded-t-md'
        />
        {/* color bar */}
        <div className={`relative  w-full  rounded-b-lg bg-collcards`}>
          <div className='flex items-center justify-between px-4 py-2 text-sm text-white'>
            <span>{type}</span>
          </div>
        </div>
        {/* BODY */}
        <div className='px-3 pt-1 text-sm font-medium text-publisherbio truncate-3-lines'>
          <span className='mr-2'>{description}</span>
        </div>
      </div>
      <div className='flex items-start pt-2'>
        <div className='flex-shrink-0 pt-1'>
          <div className='flex-1 w-0 ml-5'>
            <dl>
              <dd>
                <div className='text-xs font-medium text-teal-400 truncate-2-lines'></div>
              </dd>
            </dl>
          </div>
        </div>
      </div>
    </div>
  );
};

export const MemoizedPlaylistCard = React.memo(PlaylistCard);