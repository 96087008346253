import React from 'react';

// ROUTING
import { Route, Link } from 'react-router-dom';

// COMPONENTS
import { MemoizedCollectionCard } from './CollectionCard';

// ----------------------------------------------------------------------------------
// ------------------------------ GROUP COLLECTION PAGE -----------------------------
// ----------------------------------------------------------------------------------

export const GroupCollectionPage = ({ cognitoUser, group, groupCollections, match }) => {
  return (
    <div className='mt-3'>

      {/* All COLLECTIONS SECTION */}
      {groupCollections?.length === 0 ? (
        <div className='text-white w-full pt-2 text-2xl font-medium leading-6 text-white'>
          <p>This ensemble has no collections</p>
        </div>
      ) : (
        <div className='mt-2 border-t-4 border-green-600 border-solid'>
          <h1 className='w-full pt-2 text-2xl font-medium leading-6 text-white'>
            ALL COLLECTIONS
          </h1>
        </div>
      )}

      {/* RENDERS ALL OF A GROUP'S COLLECTIONS */}
      <div className='grid justify-center gap-10 mt-2 sm:grid-cols-2 md:grid-cols-2 xl:grid-cols-4 2xl:grid-cols-6 2xl:px-15'>
        {groupCollections?.map((data) => (
          <Link
            key={data.id}
            to={`${match.url}/${data?.id}`}
          >
            <Route
              render={(props) => (
                <MemoizedCollectionCard
                  key={data.id}
                  cognitoUser={cognitoUser}
                  data={data}
                  {...props}
                />
              )}
            />
          </Link>
        ))}
      </div>
    </div>
  );
};

export const MemoizedGroupCollectionPage = React.memo(GroupCollectionPage);