import React from 'react';

// ----------------------------------------------------------------------------------
// --------------------------------- TOS PUBLISHERS ---------------------------------
// ----------------------------------------------------------------------------------

export const TOSPublishers = () => {
  return (
    <div className='relative py-8 bg-hero-pattern bg-fixed bg-cover overflow-hidden'>
      <div className='max-w-lg mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl '>
        <a
          href='/'
          class='inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-mcgreen bg-teal-600 hover:bg-teal-500 focus:outline-none focus:border-mcdarkgreen focus:shadow-outline-teal active:bg-mcdarkgreen transition ease-in-out duration-150'
        >
          {/* <!-- Heroicon name: back --> */}
          <svg
            stroke='currentColor'
            fill='currentColor'
            className='mr-2'
            stroke-width='0'
            viewBox='0 0 512 512'
            height='1em'
            width='1em'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path d='M401.4 224h-214l83-79.4c11.9-12.5 11.9-32.7 0-45.2s-31.2-12.5-43.2 0L89 233.4c-6 5.8-9 13.7-9 22.4v.4c0 8.7 3 16.6 9 22.4l138.1 134c12 12.5 31.3 12.5 43.2 0 11.9-12.5 11.9-32.7 0-45.2l-83-79.4h214c16.9 0 30.6-14.3 30.6-32 .1-18-13.6-32-30.5-32z'></path>
          </svg>
          Go back
        </a>
      </div>

      <div className='relative px-4 sm:px-6 lg:px-8'>
        <div className='text-lg max-w-prose mx-auto mb-6'>
          <a href='/'>
            <p className='text-base text-center leading-6 text-mcgreen font-semibold tracking-wide uppercase'>
              Music Chunks
            </p>
            <h1 className='mt-2 mb-8 text-3xl text-center leading-8 font-extrabold tracking-tight text-teal-500 sm:text-4xl sm:leading-10'>
              Publisher Terms and Conditions
            </h1>
          </a>
        </div>

        <div className='prose prose-lg text-gray-50 mx-auto'>
          <p className='text-base text-gray-50 leading-8 italic'>
            NOTE: The below terms are the operative licensing terms for inclusion
            in the Musicchunks.com Terms of Service:
          </p>

          {/* GRANT OF RIGHTS */}
          <p className='text-teal-300 text-lg font-black'>1. Grant of Rights</p>
          <p className='text-base text-gray-50 leading-8'>
            a) In consideration of any license fee or royalty, as applicable,
            and SUP’s other agreements set forth herein, you hereby grant to SUP,
            in perpetuity, the royalty-free, non-exclusive right and license to reproduce,
            distribute, display, publicly perform, exhibit, modify, edit, translate,
            or include in collective works, and otherwise exploit each of your uploaded
            audiovisual clips (each, a “Licensed Clip”) as incorporated in the Site.
          </p>
          <p className='text-base text-gray-50 leading-8'>
            b) You further hereby grants to SUP a non-exclusive right and license to use,
            reproduce and publicly display your trademarks, service marks, trade dress,
            trade names, logos, corporate names, and domain names, and other similar
            designations of source or origin, together with the goodwill symbolized
            by any of the foregoing (the “Trademarks”) in the form provided by you
            to SUP or as contained in any Licensed Clips solely on or in connection
            with the use, promotion, advertising and resale of the Licensed Clips
            in accordance with these Terms. All uses of your Trademarks, including
            any good will, shall inure to your benefit. SUP agrees to comply with
            your quality control standards for use of the Trademarks, as provided
            to SUP from time to time.
          </p>
          <p className='text-base text-gray-50 leading-8'>
            c) SUP does not have any obligation to include any of the Licensed Clips on
            the Authorized Website or to produce, distribute, or otherwise exploit the
            Authorized Website. As between SUP and You, SUP owns all copyrights and
            other rights in the Authorized Website, provided that such ownership does
            not include ownership of the copyright in the Licensed Clips.
          </p>

          {/* RESERVATION OF RIGHTS */}
          <p className='text-teal-300 text-lg font-black'>2. Reservation of Rights</p>
          <p className='text-base text-gray-50 leading-8'>
            You reserve all rights not expressly granted to SUP under this Agreement. You
            own and retains all right, title, and interest in and to the Licensed Clip[s],
            subject to the license granted herein.
          </p>

          {/* LICENSE FEE */}
          <p className='text-teal-300 text-lg font-black'>3. License Fee</p>
          <p className='text-base text-gray-50 leading-8'>
            In full consideration of the grant of rights and license hereunder, and for other
            good and valuable consideration, the receipt and sufficiency of which are hereby
            acknowledged, you acknowledge and agree that the Licensed Clips are being licensed
            to SUP for no licensing fee and royalty-free.
          </p>

          {/* PERMISSIONS, REPRESENTATIONS, AND WARRANTIES */}
          <p className='text-teal-300 text-lg font-black'>4. Permissions, Representations, and Warranties</p>
          <p className='text-base text-gray-50 leading-8'>
            a) You represent, warrant, and covenant that you have obtained from all persons
            and entities who are, or whose trademark or other property is, identified, depicted,
            or otherwise referred to in the Licensed Clip, such written and signed licenses,
            permissions, waivers, releases, and consents (collectively, “Permissions” and each,
            individually, a “Permission”), including those relating to publicity, privacy,
            and any intellectual property rights as are, or reasonably may be expected to be,
            necessary for SUP to exercise its rights in the Licensed Clips as permitted by
            these Terms without incurring any payment or other obligation to, or otherwise
            violating any right of, any such person or entity. As applicable, you also shall
            obtain any required Permissions from any applicable union or guild, including
            but not limited to American Musicians Federation, SAG-AFTRA, Writers Guild of
            America, and Directors Guild of America, and make all required reuse and other
            fees and residual payments. If any Licensed Clip includes music (including
            without limitation any musical composition and sound recording), you have
            obtained all Permissions, including but not limited to master use,
            synchronization, and other licenses and performer consents, and shall
            pay all fees and other payments, required for use of the music in the
            Licensed Clip as permitted by these Terms.
          </p>
          <p className='text-base text-gray-50 leading-8'>
            b) You further represents and warrant that (i) you have the full right, power,
            and authority to enter into and perform its obligations under these Terms; (i)
            you are the sole owner of the Licensed Clips or is the authorized agent of the
            owner; (ii) SUP’s use of the Licensed Clips as permitted by these Terms will
            not violate or infringe any copyright, trademark, or other intellectual property,
            or any privacy, publicity, or other third-party, right; (iii) you have the power
            and authority to license your Trademarks on the terms and conditions of these
            Terms; and that (iii) no consents from, or payments to, any third party are
            required by your grant of rights to SUP hereunder or by SUP’s exercise of such rights.
          </p>

          {/* INDEMNIFICATION */}
          <p className='text-teal-300 text-lg font-black'>5. Indemnification</p>
          <p className='text-base text-gray-50 leading-8'>
            You shall indemnify, defend, and hold harmless SUP and its officers, directors, employees,
            agents, affiliates, successors, and assigns from and against any losses, damages,
            liabilities, deficiencies, claims, actions, judgments, settlements, interest, awards,
            penalties, fines, costs, or expenses of whatever kind, including attorneys’ fees and
            the cost of enforcing any right to indemnification hereunder and the cost of pursuing
            any insurance providers, arising out of or in connection with any third-party claim,
            suit, action, or proceeding relating to (i) any actual or alleged breach by you of
            your representations, warranties, covenants, or other obligations hereunder; or
            (ii) infringement or other violation of any intellectual property rights of any
            person or entity resulting from the use of the Licensed Clips by SUP or any of
            its affiliates in accordance with this Agreement.
          </p>

          {/* COPYRIGHT POLICY */}
          <p className='text-teal-300 text-lg font-black'>Copyright Policy</p>
          <p className='text-base text-gray-50 leading-8'>
            a)	SUP respects the intellectual property rights of others and expects its users to
            do the same.  In appropriate circumstances and at our sole discretion, we may terminate
            and/or disable the account of persons who we suspect are committing copyright infringement.
            Additionally, SUP may remove or disable access to material on the Site or hosted on our
            systems that SUP believes, in its sole discretion, may be infringing or the subject of
            infringing activity.
          </p>
          <p className='text-base text-gray-50'>
            b)	In accordance with the Digital Millennium Copyright Act of 1998, Title 17 of the United
            States Code, Section 512 (“DMCA”), SUP will respond promptly to claims of copyright infringement
            that are reported to our designated agent:
            <p className='pl-10'>
              ATTN: Designated Agent for Copyright Infringement <br />
              Synced Up Products, LLC <br />
              Address: 205 Andy Johns Dr. <br />
              Smyrna, Tennessee  37167 <br />
              Email: jasonhunt@syncedupdesigns.com <br />
            </p>
          </p>
          <p className='text-base text-gray-50 leading-8'>
            c) If you are a copyright owner (or authorized to act on behalf of the copyright owner) and believe
            that your work’s copyright has been infringed, please report your notice of infringement to us
            by providing our designated agent with a written notice that includes substantially the following:
            </p>
          <div className='pl-10'>
            <p className='text-base text-gray-50 leading-8'>
              i)	A physical or electronic signature of a person authorized to act on behalf of the owner of
              an exclusive right that is allegedly infringed.
            </p>
            <p className='text-base text-gray-50 leading-8'>
              ii)	Identification of the copyrighted work claimed to have been infringed, or, if multiple copyrighted
              works on the Site are to be covered in your notification, a representative list of such works on the
              Site.
            </p>
            <p className='text-base text-gray-50 leading-8'>
              iii)	Identification of the material that is claimed to be infringing or to be the subject of
              infringing activity and that is to be removed or access to which is to be disabled, and
              information reasonably sufficient to permit us to locate the material.
            </p>
            <p className='text-base text-gray-50 leading-8'>
              iv)	Information reasonably sufficient to permit us to contact you, such as an address,
              telephone number, and, if available, an electronic mail address at which you may be contacted.
            </p>
            <p className='text-base text-gray-50 leading-8'>
              v)	A statement that you have a good faith belief that use of the material in the manner
              complained of is not authorized by the copyright owner, its agent, or the law.
            </p>
            <p className='text-base text-gray-50 leading-8'>
              vi)	A statement that the information in the notification is accurate, and under penalty
              of perjury, that you are authorized to act on behalf of the owner of an exclusive right
              that is allegedly infringed.
            </p>
          </div>

          <p className='text-base text-gray-50 leading-8'>
            SUP will investigate notices of copyright infringement and take appropriate actions under the DMCA. Inquiries that do not follow this procedure may not receive a response.
            </p>
        </div>
      </div>
    </div >
  );
};
