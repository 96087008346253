import React, { useState, useEffect } from 'react';
import { useChatContext } from 'stream-chat-react';

// ROUTING
import { useParams } from 'react-router-dom';

// STYLING
import './EditChannel.css';

// TOAST
import cogoToast from 'cogo-toast';

// COMPONENTS
import { UserList } from '../index';
import { CloseCreateChannel } from '../../assets';
import DeleteChannelModal from '../EditChannel/DeleteChannelModal';

// ----------------------------------------------------------------------------------
// ----------------------------------- EDIT CHANNEL ---------------------------------
// ----------------------------------------------------------------------------------

const ChannelNameInput = ({ channelName = '', setChannelName, userListType, setUserListType, setSelectedUsers, updateChannel }) => {
    const handleChange = (event) => {
        event.preventDefault();

        setChannelName(event.target.value);
    }

    return (
        <div className='channel-name-input__wrapper'>
            <p className='mb-1'>Name</p>
            <div className='flex'>
                <input value={channelName} onChange={handleChange} placeholder='channel-name' />
                <button className='px-4 text-white rounded-tr-md rounded-br-md font-bold text-lg bg-searchbarchat hover:opacity-80' onClick={updateChannel}>
                    Save
                </button>
            </div>
            <div className='flex justify-center sm:justify-start'>
                <p
                    className={userListType === 'add-members' ?
                        'text-white py-2 px-6 cursor-pointer bg-searchbarchat rounded-t-md' :
                        'text-white py-2 px-6 cursor-pointer bg-gray-700 rounded-t-md'}
                    onClick={() => {
                        setUserListType('add-members')
                        setSelectedUsers([])
                    }}
                >
                    Add
                </p>
                <p
                    className={userListType === 'remove-members' ?
                        'text-white py-2 px-6 cursor-pointer bg-searchbarchat rounded-t-md' :
                        'text-white py-2 px-6 cursor-pointer bg-gray-700 rounded-t-md'}
                    onClick={() => {
                        setUserListType('remove-members')
                        setSelectedUsers([])
                    }}
                >
                    Remove
                </p>
            </div>
        </div >
    )
}

const EditChannel = ({ setIsEditing }) => {
    const { channel } = useChatContext();
    const [channelName, setChannelName] = useState(channel?.data?.name);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [moddedUsers, setModdedUsers] = useState([]);
    const [userListType, setUserListType] = useState('add-members');
    const [openDelete, setOpenDelete] = useState(false);
    const groupID = useParams();

    // UseEffect to make all users mods for the channel (users can't change anything anyway unless modded for ensembles. It was easier to make everyone a mod Stream Chat side)
    useEffect(() => {
        const usersWithModStatus = []

        if (selectedUsers.length > 0) {
            selectedUsers.map(user => {
                usersWithModStatus.push({ user_id: user, channel_role: 'channel_moderator' })
            })
        }
        setModdedUsers(usersWithModStatus);
    }, [selectedUsers]);

    const updateChannel = async (event) => {
        event.preventDefault();

        const nameChanged = channelName !== (channel.data.name || channel.data.id);

        if (channelName.length < 1) {
            cogoToast.error(`Channel name cannot be empty`, {
                hideAfter: 5
            });
        } else {
            try {
                if (nameChanged) {
                    await channel.update(
                        { name: channelName, team: groupID.id },
                        { text: `Channel name changed to ${channelName}` },
                    )
                        .then(res => {
                            cogoToast.success(`Channel name changed successfully`, {
                                hideAfter: 5
                            });
                        }).catch(err => {
                            cogoToast.error(`Could not edit the channel name`, {
                                hideAfter: 5
                            });
                            console.log(err)
                        })
                }

                if (selectedUsers.length && userListType === 'add-members') {
                    await channel.addMembers(moddedUsers)
                        .then(res => {
                            cogoToast.success(`All selected users have been added to the channel`, {
                                hideAfter: 5
                            });
                        }).catch(err => {
                            cogoToast.error(`Could not edit the channel members`, {
                                hideAfter: 5
                            });
                            console.log(err)
                        })
                }

                if (selectedUsers.length && userListType === 'remove-members') {
                    await channel.removeMembers(selectedUsers)
                        .then(res => {
                            cogoToast.success(`All selected users have been removed from the channel`, {
                                hideAfter: 5
                            });
                        }).catch(err => {
                            cogoToast.error(`Could not edit the channel members`, {
                                hideAfter: 5
                            });
                            console.log(err)
                        })
                }

                setChannelName(null);
                setIsEditing(false);
                setSelectedUsers([]);
                setModdedUsers([]);
            } catch {
                cogoToast.error(`Could not edit the channel`, {
                    hideAfter: 5
                });
            }

        }
    }

    const handleDeleteChannel = async () => {
        try {
            await channel.delete();
            setIsEditing(false);
            cogoToast.success(`Successfully deleted the channel '${channel.data.name}'`, {
                hideAfter: 5
            });
        } catch {
            cogoToast.error(`There was an error deleting the channel '${channel.data.name}'`, {
                hideAfter: 5
            });
        }
    }

    return (
        <div className='edit-channel__container'>
            <div className='edit-channel__header'>
                <p>Edit Channel</p>
                <CloseCreateChannel setIsEditing={setIsEditing} />
            </div>
            <ChannelNameInput channelName={channelName} setChannelName={setChannelName} setIsEditing={setIsEditing} userListType={userListType} setUserListType={setUserListType} setSelectedUsers={setSelectedUsers} updateChannel={updateChannel} />
            <UserList setSelectedUsers={setSelectedUsers} userListType={userListType} />
            <div className='edit-channel__button-wrapper'>
                <p className='bg-red-600 hover:opacity-80' onClick={() => setOpenDelete(true)}>Delete Channel</p>
                <p className='bg-searchbarchat hover:opacity-80' onClick={updateChannel}>Save Changes</p>
            </div>

            {/* MODALS */}
            <DeleteChannelModal open={openDelete} setOpen={setOpenDelete} handleDeleteChannel={handleDeleteChannel} />
        </div>
    )
}

export default EditChannel