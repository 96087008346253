import React, { useState } from 'react';

// ROUTING
import { Link } from 'react-router-dom';

// AUTH
import { Auth } from '@aws-amplify/auth';

// FORMS
import { useForm } from "react-hook-form";

// TOAST
import cogoToast from 'cogo-toast';

// COMPONENTS
import CustomConfirmation from './CustomConfirmation';

// ----------------------------------------------------------------------------------
// --------------------------------- CUSTOM SIGN UP ---------------------------------
// ----------------------------------------------------------------------------------

export default function CustomSignUp(props) {
  // State
  const { register, handleSubmit } = useForm();
  const [submitting, setSubmitting] = useState(false);
  const [screen, setScreen] = useState('signup');
  const [password, setPassword] = useState();
  const [email, setEmail] = useState();
  const [confirmFromSignup, setConfirmFromSignup] = useState(true);
  const groupID = new URLSearchParams(window.location.search).get('group') // Grabs student group ID from url params if exists
  const userEmail = new URLSearchParams(window.location.search).get('email') // Grabs student email from url params if exists
  const [input, setInput] = useState({
    username: ''
  })

  const handleInputChange = (event) => {
    setInput({ ...input, [event.target.name]: event.target.value.toLowerCase().replace(/ /g, "") });
  };

  // Function to handle submitting user Signup
  const onSignUpSubmit = async (data) => {
    setSubmitting(true);

    await Auth.signUp({
      username: userEmail ? userEmail : data.username,
      password: data.password,
      attributes: {
        given_name: data.given_name,
        family_name: data.family_name,
        'custom:group': groupID ? groupID : null
      }
    })
      .then(() => {
        setSubmitting(false);
        setScreen('code');
        setPassword(data.password)
        setEmail(data.username)
        cogoToast.success(`Successfully created your account. Please retrieve your confirmation code from ${data.username}`, {
          hideAfter: 5,
        });
      })
      .catch(err => {
        setSubmitting(false);
        cogoToast.error(err.message, {
          hideAfter: 5,
        });
        console.log(err)
      })
  };

  return (
    <div className="flex justify-center w-full h-screen overflow-hidden bg-fixed bg-cover bg-hero-pattern" slot={props.slot}>
      <div className="flex flex-col justify-center items-center">
        {screen === 'signup' ? (
          <form className="p-10 bg-mclightgreen rounded-lg" onSubmit={handleSubmit(onSignUpSubmit)}>
            <h4 className='text-white text-2xl'>Create a new account</h4>
            <div className="form-group">
              <label className='text-white'>Email address</label>
              <input
                name='username'
                type='username'
                value={userEmail ? userEmail : input.username}
                placeholder='Enter email'
                onChange={handleInputChange}
                className='form-control w-full flex items-center mb-7 mt-3 p-2 rounded-md text-lg'
                ref={register({ required: true })}
                disabled={userEmail ? true : false}
              />
            </div>
            <div className="form-group">
              <label className='text-white'>Password</label>
              <p className='text-white font-thin text-xs'>
                (must contain numbers, lowercase, and uppercase letters)
              </p>
              <input
                name='password'
                type='password'
                placeholder='Enter password'
                className='form-control w-full flex items-center mb-7 mt-3 p-2 rounded-md text-lg'
                ref={register({ required: true })}
              />
            </div>
            <div className="form-group">
              <label className='text-white'>First Name</label>
              <input
                name='given_name'
                type='given_name'
                placeholder='Enter first name'
                className='form-control w-full flex items-center mb-7 mt-3 p-2 rounded-md text-lg'
                ref={register({ required: true })}
              />
            </div>
            <div className="form-group">
              <label className='text-white'>Last Name</label>
              <input
                name='family_name'
                type='family_name'
                placeholder='Enter last name'
                className='form-control w-full flex items-center mb-7 mt-3 p-2 rounded-md text-lg'
                ref={register({ required: true })}
              />
            </div>
            {/* {groupID ? (
              <div className="form-group">
                <label className='text-white'>Ensemble ID</label>
                <input
                  name='group'
                  type='text'
                  placeholder='Enter ensemble ID'
                  className='form-control w-full flex items-center mb-7 mt-3 p-2 rounded-md text-lg'
                  ref={register}
                  value={groupID}
                  disabled={true}
                />
              </div>
            ) : null} */}

            <div className='flex'>
              <div className='mr-20 text-white flex items-center text-xl'>
                <p>Have an account?
                  <span className='ml-1'>
                    <Link
                      to='/login'
                      className='text-mcgreen hover:text-mcfadedgreen focus:outline-none'
                    >
                      Sign In
					          </Link>
                  </span>
                </p>
              </div>
              <button
                type="submit"
                // disabled={!stripe}
                className={`${submitting && 'opacity-50 pointer-events-none'
                  } flex items-center rounded-lg text-lg px-12 py-3 text-center font-medium text-mcgreen bg-mcbuttondarkgreen hover:bg-mcgreen hover:text-mcbuttondarkgreen focus:ring transition duration-150 ease-in-out`}>
                {submitting && (
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    strokeWidth="0"
                    viewBox="0 0 1024 1024"
                    className="w-5 h-5 mr-3 animate-spin"
                    xmlns="http://www.w3.org/2000/svg">
                    <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 0 0-94.3-139.9 437.71 437.71 0 0 0-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                  </svg>
                )}{' '}
                CREATE ACCOUNT
              </button>
            </div>
          </form >

        ) : screen === 'code' ? (
          <CustomConfirmation password={password} email={email} confirmFromSignup={confirmFromSignup} />
        ) : null}
      </div>
    </div>
  );
}