import React from 'react';

// IMAGES
import { ReactComponent as BlankPublisher } from '../../img/icon/BlankPublisher.svg';

// ----------------------------------------------------------------------------------
// ---------------------------------- Chunk Card ------------------------------------
// ----------------------------------------------------------------------------------

export const ChunkCard = ({ data }) => {
  const {
    id,
    name,
    img,
    collectionType,
    difficulty,
    instruments,
    publisher_name,
    publisher_profile_pic
  } = data;

  return (
    <div
      key={id}
      className={`hover:bg-mcgreen max-w-359 hover:bg-opacity-25 p-2 rounded-md`}
    >
      <div className={`overflow-hidden`}>
        {/* TOP IMG */}
        <img
          src={img}
          alt='img for a single chunk'
          className='object-fill w-full h-full min-h-full max-h-40 rounded-t-md'
        />

        {/* color bar */}
        <div className={`relative  w-full`}>

          {/* TYPE */}
          <div className={`flex items-center justify-between px-4 py-2 text-sm text-white rounded-b-lg
          ${collectionType === 'Show Music' && 'bg-showtag'}
          ${collectionType === 'Exercises & Etudes' && 'bg-eetag'}
          ${collectionType === 'Basics for Band' && 'bg-bbtag'}
          ${collectionType === 'Concert Music' && 'bg-conctag'}
          ${collectionType === 'Jazz Pop Combo' && 'bg-jazztag'}
          ${collectionType === 'Visual & Choreography' && 'bg-visualtag'}
          ${collectionType === 'Learn About Music Chunks' && 'bg-learntag'}
          `}
          >
            {collectionType === 'Show Music' ? (
              <span>Marching Arts</span>
            ) : null}
            {collectionType === 'Exercises & Etudes' ? (
              <span>Exercises & Fundamentals</span>
            ) : null}
            {collectionType === 'Basics for Band' ? (
              <span>Music Chunks Band Basics</span>
            ) : null}
            {collectionType === 'Concert Music' ? (
              <span>Concert</span>
            ) : null}
            {collectionType === 'Jazz Pop Combo' ? (
              <span>Jazz Pop Combo</span>
            ) : null}
            {collectionType === 'Visual & Choreography' ? (
              <span>Visual & Choreography</span>
            ) : null}
            {collectionType === 'Learn About Music Chunks' ? (
              <span>Learn About Music Chunks</span>
            ) : null}
            <div className='flex items-center flex-shrink-0'>
              <span className='ml-3'>{difficulty}</span>
            </div>
          </div>
        </div>
        <div className='flex items-start pt-2'>
          <div className='flex-shrink-0 pt-1'>
            {publisher_profile_pic ? (
              <img
                src={publisher_profile_pic}
                alt='img for a publisher profile'
                className='inline-block object-fill w-12 h-12 rounded-md'
              />
            ) : (
              <BlankPublisher className='inline-block object-fill w-12 h-12 rounded-md' />
            )}
          </div>

          <div className='flex-1 w-0 ml-5'>
            <dl>
              <dt className='text-sm font-medium leading-5 text-white truncate-2-lines'>
                {name}
              </dt>
              <dd>
                <div className='text-xs font-medium leading-4 truncate text-mcgreen'>
                  {publisher_name}
                </div>
                <div className='text-xs font-medium text-instruments truncate-2-lines'>
                  {instruments.map((inst) => (
                    <span key={inst.chunk_id} className='mr-2'>
                      {inst}
                    </span>
                  ))}
                </div>
              </dd>
            </dl>
          </div>
        </div>
      </div>
    </div>
  );
};

export const MemoizedChunkCard = React.memo(ChunkCard);