import React, { useState, useEffect } from 'react';
import { useChatContext } from 'stream-chat-react';

// ROUTING
import { useParams } from 'react-router-dom';

// STYLING
import './ChannelSearch.css';

// ICONS
import { SearchIcon } from '../../assets';

// COMPONENTS
import { ResultsDropdown } from '../index'

// ----------------------------------------------------------------------------------
// --------------------------------- CHANNEL SEARCH ---------------------------------
// ----------------------------------------------------------------------------------

const ChannelSearch = ({ setToggleContainer, setToggleChannelContainer }) => {
    const { client, setActiveChannel } = useChatContext();
    const groupID = useParams();
    const [query, setQuery] = useState('');
    const [loading, setLoading] = useState(false);
    const [teamChannels, setTeamChannels] = useState([])
    const [directChannels, setDirectChannels] = useState([])

    useEffect(() => {
        if (!query) {
            setTeamChannels([]);
            setDirectChannels([]);
        }
    }, [query])

    const getChannels = async (text) => {
        try {
            const channelResponse = client.queryChannels({
                type: 'team',
                name: { $autocomplete: text },
                members: { $in: [client.userID] },
                team: groupID.id
            });
            const userResponse = client.queryUsers({
                id: { $ne: client.userID },
                name: { $autocomplete: text },
                teams: { $contains: groupID.id }
            })

            const [channels, { users }] = await Promise.all([channelResponse, userResponse]);

            if (channels.length) setTeamChannels(channels);
            if (users.length) setDirectChannels(users);
        } catch (error) {
            setQuery('')
            console.log(error)
        }
    }

    const onSearch = (event) => {
        event.preventDefault();

        setLoading(true);
        setQuery(event.target.value);
        getChannels(event.target.value)
    }

    const setChannel = (channel) => {
        setQuery('');
        setActiveChannel(channel);
    }

    return (
        <>
            <div className=''>
                <div className='flex justify-between my-4 mx-5'>
                    <div className='self-center py-2 w-1/5 rounded-l-md bg-searchbarchat'>
                        <SearchIcon />
                    </div>
                    <input
                        className='border-none focus:ring-white w-4/5 bg-white rounded-r-md'
                        placeholder='Search'
                        type='text'
                        value={query}
                        onChange={onSearch}
                    />
                </div>
            </div>
            <div className='channel-search__container2'>
                {query && (
                    <ResultsDropdown
                        teamChannels={teamChannels}
                        directChannels={directChannels}
                        loading={loading}
                        setChannel={setChannel}
                        setQuery={setQuery}
                        setToggleContainer={setToggleContainer}
                        setToggleChannelContainer={setToggleChannelContainer}
                    />
                )}
            </div>
        </>
    )
}

export default ChannelSearch