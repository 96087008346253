import React from 'react';

// ----------------------------------------------------------------------------------
// ------------------------------- FEATURED CHUNK CARD ------------------------------
// ----------------------------------------------------------------------------------

export const FeaturedChunkCard = ({ data }) => {

  return (
    <div className='grid grid-cols-1 gap-5 max-w-screen '>
      <div
        key={data?.id}
        className={`hover:bg-mcgreen hover:bg-opacity-25 p-2 rounded-lg`}
      >
        <div className='flex justify-start '>
          <div className='hidden md:flex md:flex-col'>
            <iframe
              src={data?.embed_URL.replace(
                'embed',
                'embed-mini'
              )}
              title='single score view'
              width='300'
              height='100%'
              frameBorder='0'
              allowFullScreen
            ></iframe>
            <div
              className={`z-50 relative  w-full -mt-11 rounded-b-lg ${data?.sections?.some(
                (i) => i === 'woodwind1'
              ) && 'bg-ww1tag'
                }
                    ${data?.sections?.some(
                  (i) => i === 'woodwind2'
                ) && 'bg-ww2tag'
                }
                    ${data?.sections?.some((i) => i === 'brass1') &&
                'bg-brass1tag'
                }
                    ${data?.sections?.some((i) => i === 'brass2') &&
                'bg-brass2tag'
                }
                    ${data?.sections?.some(
                  (i) => i === 'percussion1'
                ) && 'bg-perc1tag'
                }
                    ${data?.sections?.some(
                  (i) => i === 'percussion2'
                ) && 'bg-perc1tag'
                } `}
            >
              <div className='flex items-center justify-between p-1 px-4 py-3 text-sm text-white '>
                {data?.collectionType === 'Show Music' ? (
                  <span>Marching Arts</span>
                ) : null}
                {data?.collectionType ===
                  'Exercises & Etudes' ? (
                    <span>Exercises & Fundamentals</span>
                  ) : null}
                <div className='flex items-center flex-shrink-0'>
                  <span className='ml-3'>
                    {data?.difficulty}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className='flex items-center'>
            <div className='flex-1 ml-0 md:ml-4'>
              <dl>
                <dt className='text-sm font-medium leading-5 text-white truncate-2-lines'>
                  {data?.name}
                </dt>
                <dd>
                  <div className='text-xs font-medium leading-5 text-mcgreen'>
                    {data?.publisher_name}
                  </div>
                </dd>
                <dd>
                  <div className='text-xs font-medium text-instruments truncate-2-lines'>
                    {data?.instruments?.map((inst) => (
                      <span key={inst} className='mr-2'>
                        {inst}
                      </span>
                    ))}
                  </div>
                  <div className='whitespace-pre-wrap text-xs font-medium leading-5 text-white truncate-3-lines'>
                    {data?.description}
                  </div>
                </dd>
              </dl>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const MemoizedFeaturedChunkCard = React.memo(FeaturedChunkCard);