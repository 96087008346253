import React from 'react';

// ROUTING
import { Route, Link } from 'react-router-dom';

// ----------------------------------------------------------------------------------
// --------------------------------- REPORT SELECT ----------------------------------
// ----------------------------------------------------------------------------------

const ReportSelect = () => {


  return (
    <Route exact path='/admin/reports'>
      <div className='px-4 mt-6 sm:px-6 lg:px-8'>
        <h2 className='text-xs font-medium tracking-wide text-gray-500 uppercase'>
          Available Reports
        </h2>

        <ul className='grid grid-cols-1 gap-4 mt-3 sm:gap-6 sm:grid-cols-2 xl:grid-cols-4'>
          <li className='relative flex col-span-1 rounded-md shadow-sm'>
            <div className='flex items-center justify-center flex-shrink-0 w-16 text-sm font-medium leading-5 text-white bg-yellow-600 rounded-l-md'>
              MC
            </div>
            <div className='flex items-center justify-between flex-1 truncate bg-white border-t border-b border-r border-gray-200 rounded-r-md'>
              <div className='flex-1 px-4 py-2 text-sm leading-5 truncate'>
                <Link
                  to='/admin/reports/mc-usage-quarterly'
                  className='font-medium text-gray-900 transition duration-150 ease-in-out hover:text-gray-600'
                >
                  Usage - Quarterly
                </Link>
                <p className='text-gray-500'>All Plans</p>
              </div>
            </div>
          </li>

          <li className='relative flex col-span-1 rounded-md shadow-sm'>
            <div className='flex items-center justify-center flex-shrink-0 w-16 text-sm font-medium leading-5 text-white bg-yellow-600 rounded-l-md'>
              MC
            </div>
            <div className='flex items-center justify-between flex-1 truncate bg-white border-t border-b border-r border-gray-200 rounded-r-md'>
              <div className='flex-1 px-4 py-2 text-sm leading-5 truncate'>
                <Link
                  to='/admin/reports/mc-usage-annual'
                  className='font-medium text-gray-900 transition duration-150 ease-in-out hover:text-gray-600'
                >
                  Usage - Annual
                </Link>
                <p className='text-gray-500'>All Plans</p>
              </div>
            </div>
          </li>

          <li className='relative flex col-span-1 rounded-md shadow-sm'>
            <div className='flex items-center justify-center flex-shrink-0 w-16 text-sm font-medium leading-5 text-white bg-yellow-600 rounded-l-md'>
              MC
            </div>
            <div className='flex items-center justify-between flex-1 truncate bg-white border-t border-b border-r border-gray-200 rounded-r-md'>
              <div className='flex-1 px-4 py-2 text-sm leading-5 truncate'>
                <Link
                  to='/admin/reports/mc-revenue-quarterly'
                  className='font-medium text-gray-900 transition duration-150 ease-in-out hover:text-gray-600'
                >
                  Revenue - Quarterly
                </Link>
                <p className='text-gray-500'>All Plans</p>
              </div>
            </div>
          </li>

          <li className='relative flex col-span-1 rounded-md shadow-sm'>
            <div className='flex items-center justify-center flex-shrink-0 w-16 text-sm font-medium leading-5 text-white bg-yellow-600 rounded-l-md'>
              MC
            </div>
            <div className='flex items-center justify-between flex-1 truncate bg-white border-t border-b border-r border-gray-200 rounded-r-md'>
              <div className='flex-1 px-4 py-2 text-sm leading-5 truncate'>
                <Link
                  to='/admin/reports/mc-revenue-annual'
                  className='font-medium text-gray-900 transition duration-150 ease-in-out hover:text-gray-600'
                >
                  Revenue - Annual
                </Link>
                <p className='text-gray-500'>All Plans</p>
              </div>
            </div>
          </li>

          <li className='relative flex col-span-1 rounded-md shadow-sm'>
            <div className='flex items-center justify-center flex-shrink-0 w-16 text-sm font-medium leading-5 text-white bg-pink-600 rounded-l-md'>
              MLC
            </div>
            <div className='flex items-center justify-between flex-1 truncate bg-white border-t border-b border-r border-gray-200 rounded-r-md'>
              <div className='flex-1 px-4 py-2 text-sm leading-5 truncate'>
                <Link
                  to='/admin/reports/mlc-usage-monthly'
                  className='font-medium text-gray-900 transition duration-150 ease-in-out hover:text-gray-600'
                >
                  Usage - Monthly
                </Link>
                <p className='text-gray-500'>All Plans</p>
              </div>
            </div>
          </li>

          <li className='relative flex col-span-1 rounded-md shadow-sm'>
            <div className='flex items-center justify-center flex-shrink-0 w-16 text-sm font-medium leading-5 text-white bg-pink-600 rounded-l-md'>
              MLC
            </div>
            <div className='flex items-center justify-between flex-1 truncate bg-white border-t border-b border-r border-gray-200 rounded-r-md'>
              <div className='flex-1 px-4 py-2 text-sm leading-5 truncate'>
                <Link
                  to='/admin/reports/mlc-usage-annual'
                  className='font-medium text-gray-900 transition duration-150 ease-in-out hover:text-gray-600'
                >
                  Usage - Annual
                </Link>
                <p className='text-gray-500'>All Plans</p>
              </div>
            </div>
          </li>

          <li className='relative flex col-span-1 rounded-md shadow-sm'>
            <div className='flex items-center justify-center flex-shrink-0 w-16 text-sm font-medium leading-5 text-white bg-pink-600 rounded-l-md'>
              MLC
            </div>
            <div className='flex items-center justify-between flex-1 truncate bg-white border-t border-b border-r border-gray-200 rounded-r-md'>
              <div className='flex-1 px-4 py-2 text-sm leading-5 truncate'>
                <Link
                  to='/admin/reports/mlc-revenue-monthly'
                  className='font-medium text-gray-900 transition duration-150 ease-in-out hover:text-gray-600'
                >
                  Revenue - Monthly
                </Link>
                <p className='text-gray-500'>All Plans</p>
              </div>
            </div>
          </li>

          <li className='relative flex col-span-1 rounded-md shadow-sm'>
            <div className='flex items-center justify-center flex-shrink-0 w-16 text-sm font-medium leading-5 text-white bg-pink-600 rounded-l-md'>
              MLC
            </div>
            <div className='flex items-center justify-between flex-1 truncate bg-white border-t border-b border-r border-gray-200 rounded-r-md'>
              <div className='flex-1 px-4 py-2 text-sm leading-5 truncate'>
                <Link
                  to='/admin/reports/mlc-revenue-annual'
                  className='font-medium text-gray-900 transition duration-150 ease-in-out hover:text-gray-600'
                >
                  Revenue - Annual
                </Link>
                <p className='text-gray-500'>All Plans</p>
              </div>
            </div>
          </li>

          <li className='relative flex col-span-1 rounded-md shadow-sm'>
            <div className='flex items-center justify-center flex-shrink-0 w-16 text-sm font-medium leading-5 text-white bg-bmibutton rounded-l-md'>
              BMI
            </div>
            <div className='flex items-center justify-between flex-1 truncate bg-white border-t border-b border-r border-gray-200 rounded-r-md'>
              <div className='flex-1 px-4 py-2 text-sm leading-5 truncate'>
                <Link
                  to='/admin/reports/bmi-usage-quarterly-standard'
                  className='font-medium text-gray-900 transition duration-150 ease-in-out hover:text-gray-600'
                >
                  Usage - Quarterly
                </Link>
                <p className='text-gray-500'>Standard Plan</p>
              </div>
            </div>
          </li>

          <li className='relative flex col-span-1 rounded-md shadow-sm'>
            <div className='flex items-center justify-center flex-shrink-0 w-16 text-sm font-medium leading-5 text-white bg-bmibutton rounded-l-md'>
              BMI
            </div>
            <div className='flex items-center justify-between flex-1 truncate bg-white border-t border-b border-r border-gray-200 rounded-r-md'>
              <div className='flex-1 px-4 py-2 text-sm leading-5 truncate'>
                <Link
                  to='/admin/reports/bmi-usage-annual-standard'
                  className='font-medium text-gray-900 transition duration-150 ease-in-out hover:text-gray-600'
                >
                  Usage - Annual
                </Link>
                <p className='text-gray-500'>Standard Plan</p>
              </div>
            </div>
          </li>

          {/* <li className='relative flex col-span-1 rounded-md shadow-sm'>
            <div className='flex items-center justify-center flex-shrink-0 w-16 text-sm font-medium leading-5 text-white bg-teal-600 rounded-l-md'>
              BMI
            </div>
            <div className='flex items-center justify-between flex-1 truncate bg-white border-t border-b border-r border-gray-200 rounded-r-md'>
              <div className='flex-1 px-4 py-2 text-sm leading-5 truncate'>
                <Link
                  to='/admin/reports/bmi-usage-annual-ensemble'
                  className='font-medium text-gray-900 transition duration-150 ease-in-out hover:text-gray-600'
                >
                  Usage - Annual
                </Link>
                <p className='text-gray-500'>Ensemble Plan</p>
              </div>
            </div>
          </li> */}

          <li className='relative flex col-span-1 rounded-md shadow-sm'>
            <div className='flex items-center justify-center flex-shrink-0 w-16 text-sm font-medium leading-5 text-white bg-indigo-600 rounded-l-md'>
              ASCAP
            </div>
            <div className='flex items-center justify-between flex-1 truncate bg-white border-t border-b border-r border-gray-200 rounded-r-md'>
              <div className='flex-1 px-4 py-2 text-sm leading-5 truncate'>
                <Link
                  to='/admin/reports/ascap-usage-quarterly-standard'
                  className='font-medium text-gray-900 transition duration-150 ease-in-out hover:text-gray-600'
                >
                  Usage - Quarterly
                </Link>
                <p className='text-gray-500'>Standard Plan</p>
              </div>
            </div>
          </li>

          {/* <li className='relative flex col-span-1 rounded-md shadow-sm'>
            <div className='flex items-center justify-center flex-shrink-0 w-16 text-sm font-medium leading-5 text-white bg-indigo-600 rounded-l-md'>
              ASCAP
            </div>
            <div className='flex items-center justify-between flex-1 truncate bg-white border-t border-b border-r border-gray-200 rounded-r-md'>
              <div className='flex-1 px-4 py-2 text-sm leading-5 truncate'>
                <Link
                  to='/admin/reports/ascap-usage-quarterly-ensemble'
                  className='font-medium text-gray-900 transition duration-150 ease-in-out hover:text-gray-600'
                >
                  Usage - Quarterly
                </Link>
                <p className='text-gray-500'>Ensemble Plan</p>
              </div>
            </div>
          </li> */}

          <li className='relative flex col-span-1 rounded-md shadow-sm'>
            <div className='flex items-center justify-center flex-shrink-0 w-16 text-sm font-medium leading-5 text-white bg-indigo-600 rounded-l-md'>
              ASCAP
            </div>
            <div className='flex items-center justify-between flex-1 truncate bg-white border-t border-b border-r border-gray-200 rounded-r-md'>
              <div className='flex-1 px-4 py-2 text-sm leading-5 truncate'>
                <Link
                  to='/admin/reports/ascap-usage-annual-standard'
                  className='font-medium text-gray-900 transition duration-150 ease-in-out hover:text-gray-600'
                >
                  Usage - Annual
                </Link>
                <p className='text-gray-500'>Standard Plan</p>
              </div>
            </div>
          </li>

          {/* <li className='relative flex col-span-1 rounded-md shadow-sm'>
            <div className='flex items-center justify-center flex-shrink-0 w-16 text-sm font-medium leading-5 text-white bg-indigo-600 rounded-l-md'>
              ASCAP
            </div>
            <div className='flex items-center justify-between flex-1 truncate bg-white border-t border-b border-r border-gray-200 rounded-r-md'>
              <div className='flex-1 px-4 py-2 text-sm leading-5 truncate'>
                <Link
                  to='/admin/reports/ascap-usage-annual-ensemble'
                  className='font-medium text-gray-900 transition duration-150 ease-in-out hover:text-gray-600'
                >
                  Usage - Annual
                </Link>
                <p className='text-gray-500'>Ensemble Plan</p>
              </div>
            </div>
          </li> */}

          <li className='relative flex col-span-1 rounded-md shadow-sm'>
            <div className='flex items-center justify-center flex-shrink-0 w-16 text-sm font-medium leading-5 text-white bg-yellow-600 rounded-l-md'>
              MC
            </div>
            <div className='flex items-center justify-between flex-1 truncate bg-white border-t border-b border-r border-gray-200 rounded-r-md'>
              <div className='flex-1 px-4 py-2 text-sm leading-5 truncate'>
                <Link
                  to='/admin/reports/mc-ensemble-monthly'
                  className='font-medium text-gray-900 transition duration-150 ease-in-out hover:text-gray-600'
                >
                  Ensemble - Monthly
                </Link>
                <p className='text-gray-500'>All Users</p>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </Route>
  )
}

export default ReportSelect;