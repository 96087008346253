import React from 'react';
import { Link } from 'react-router-dom';

// ----------------------------------------------------------------------------------
// ------------------------------------ FAQPAGE -------------------------------------
// ----------------------------------------------------------------------------------

export const FAQPage = () => {
  const url = window.location.href; // GRABS REFERENCE TO THE CURRENT URL

  return (
    <div class='relative bg-hero-pattern bg-fixed bg-cover overflow-hidden'>
      {/* Only show the back button if routing through the landing page */}
      {url.includes('dashboard') ? null : (
        <div className='max-w-lg mx-auto px-4 py-8 sm:px-6 lg:px-8 lg:max-w-7xl '>
          <Link
            to='/'
            className='inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-mcgreen bg-mcbuttondarkgreen hover:bg-mcbuttonlightgreen focus:outline-none focus:border-mcdarkgreen focus:shadow-outline-teal active:bg-mcdarkgreen transition ease-in-out duration-150'
          >
            {/* <!-- Heroicon name: back --> */}
            <svg
              stroke='currentColor'
              fill='currentColor'
              className='mr-2'
              stroke-width='0'
              viewBox='0 0 512 512'
              height='1em'
              width='1em'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path d='M401.4 224h-214l83-79.4c11.9-12.5 11.9-32.7 0-45.2s-31.2-12.5-43.2 0L89 233.4c-6 5.8-9 13.7-9 22.4v.4c0 8.7 3 16.6 9 22.4l138.1 134c12 12.5 31.3 12.5 43.2 0 11.9-12.5 11.9-32.7 0-45.2l-83-79.4h214c16.9 0 30.6-14.3 30.6-32 .1-18-13.6-32-30.5-32z'></path>
            </svg>
          Go back
          </Link>
        </div>
      )}

      <div className='max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8'>
        <h2 className='text-3xl leading-9 font-extrabold text-faqheader sm:text-4xl sm:leading-10'>
          More Frequently Asked Questions
        </h2>
        <div className='mt-6 border-t-2 border-mcgreen pt-6'>
          <dl>
            <div className='md:grid md:grid-cols-12 md:gap-8'>
              <dt className='text-base leading-6 font-medium text-faqquestion md:col-span-5'>
                What is Music Chunks?
              </dt>
              <dd className='mt-2 md:mt-0 md:col-span-7'>
                <p className='text-base leading-6 text-gray-50'>
                  Music Chunks is an educational platform for students and teachers to discover, learn,
                  practice, and coordinate group learning. Top-tier instrumental music basics, movement and
                  visual basics, tons of music for marching, jazz, concert, brass, woodwind, and percussion
                  ensembles which you can play in the easy-to-use interactive player. Video, audio recordings,
                  and sheet music are seamlessly combined and can be played and looped over and over, slowed
                  down or sped up without changing the pitch, and played as full scores or any combination of
                  instruments with ease - giving students a major advantage as they learn.
                </p>
                <br />
                <p className='text-base leading-6 text-gray-50'>
                  Ensembles who subscribe to Music Chunks get a private ensemble portal where they can upload their own private content only visible to their ensemble. The assigned ensemble admins can manage ensemble members, create and manage playlists, and submit new content which Music Chunks will sync for you and your ensemble to use.
                </p>
                <br />
                <p className='text-base leading-6 text-gray-50'>
                  This platform gives you inspired content and better technology to inspire students, and teach the next generation.
                </p>
              </dd>
            </div>

            <div className='mt-8 border-t border-mcgreen pt-6 md:grid md:grid-cols-12 md:gap-8'>
              <dt className='text-base leading-6 font-medium text-faqquestion md:col-span-5'>
                Why should you and your ensemble use Music Chunks?
              </dt>
              <dd className='mt-2 md:mt-0 md:col-span-7'>
                <p className='text-base leading-6 text-gray-50'>
                  Students are using more tech than ever. Take advantage of tools that can help with student
                  engagement, student retention, increase learning speed, expand vocabulary, and deepen their
                  connection with music. 1000’s of inspiring Chunks that are organized and easy to search and
                  save on computers, laptops, tablets, and smart phones. You can slow down and loop a section
                  that needs work, just like in a lesson or rehearsal. You can see entire music scores and easily
                  hide/show other instruments to focus on just yours. Click or tap any place in the music to
                  start there and hit play, it’s that easy!
                </p>
              </dd>
            </div>

            <div className='mt-8 border-t border-mcgreen pt-6 md:grid md:grid-cols-12 md:gap-8'>
              <dt className='text-base leading-6 font-medium text-faqquestion md:col-span-5'>
                What types of Content can you discover and learn?
              </dt>
              <dd className='mt-2 md:mt-0 md:col-span-7'>
                <p className='text-base leading-6 text-gray-50'>
                  Original music, music lessons, instrumental warm-ups and exercises, movement and visual
                  warm-ups and exercise, choreography and dance, and other music education related content
                  by top-tier creators and educators. Our fast growing library has content suitable for all
                  levels of school band with genres including instrumental basics, the marching arts, concert
                  and wind ensembles, jazz band, pop and combo ensembles, steel band, percussion ensemble,
                  and even insightful podcasts and interviews for educators.
                </p>
                <br />
                <p className='text-base leading-6 text-gray-50'>
                  The Content Creators who have licensed the content to Music Chunks are either the owners
                  of the content or have secured all necessary permissions.
                </p>
              </dd>
            </div>

            <div className='mt-8 border-t border-mcgreen pt-6 md:grid md:grid-cols-12 md:gap-8'>
              <dt className='text-base leading-6 font-medium text-faqquestion md:col-span-5'>
                Does my Music Chunks Subscription also give me the right to perform the music publicly,
                at a concert or marching band show?
              </dt>
              <dd className='mt-2 md:mt-0 md:col-span-7'>
                <p className='text-base leading-6 text-gray-50'>
                  No. The permissions granted to Music Chunks users are for private use only. Use the
                  platform in lessons, group rehearsals, have your full ensemble open the same chunk and
                  learn together. You can create infinite curriculums for private lessons students, and
                  learn the chunks of music with the major benefits of the interactive player.
                </p>
                <br />
                <p className='text-base leading-6 text-gray-50'>
                  When you find something you want to perform publicly, or to purchase the whole piece because
                  ou loved the chunk so much, we link directly to the Publisher or Content Creator’s webpage
                  where you can purchase it directly from them or contact them about public performance rights
                  for you or your ensemble.
                </p>
              </dd>
            </div>

            <div className='mt-8 border-t border-mcgreen pt-6 md:grid md:grid-cols-12 md:gap-8'>
              <dt className='text-base leading-6 font-medium text-faqquestion md:col-span-5'>
                What’s the best way to learn Music Chunks?
              </dt>
              <dd className='mt-2 md:mt-0 md:col-span-7'>
                <p className='text-base leading-6 text-gray-50'>
                  Try these easy helpful tools built in to Music Chunks:
                </p>
                <ul className='list-disc list-inside pt-5 text-base leading-6 text-gray-50'>
                  <li>
                    Click or Tap anywhere on the music notation to navigate to that place in the music.
                  </li>
                  <li>
                    Choose which recording or video to practice with in the recordings menu beneath your chunk.
                  </li>
                  <li>
                    Hide/show instruments from the score to focus on just what you want.
                  </li>
                  <li>
                    Easily adjust zoom and video size to see everything comfortably.
                  </li>
                  <li>
                    Turn on a volume-adjustable metronome synced perfectly with the music or video.
                  </li>
                  <li>
                    Drag across any amount of music notation to loop playback of that section.
                  </li>
                  <li>
                    With a loop already created, re-size the loop by dragging either of it’s edges.
                  </li>
                  <li>
                    Use speed controls to slow down or speed up playback without changing pitch.
                  </li>
                  <li>
                    Activate a visual illuminated keyboard that shows any part’s notes played on keyboard,
                    or a visualizer that shows trumpet fingerings or a trombone slide positions.
                  </li>
                </ul>
              </dd>
            </div>

            <div className='mt-8 border-t border-mcgreen pt-6 md:grid md:grid-cols-12 md:gap-8'>
              <dt className='text-base leading-6 font-medium text-faqquestion md:col-span-5'>
                What are Recordings and the Recordings Menu?
              </dt>
              <dd className='mt-2 md:mt-0 md:col-span-7'>
                <p className='text-base leading-6 text-gray-50'>
                  Recordings are the audio or video playback options that each content creator gives you
                  with a chunk. They are “synced” perfectly to the music or written content in the player
                  for a modern learning experience. Some chunks have lots of recordings and others only a
                  few. At the bottom of our interactive player, you’ll see a “Recordings” section showing
                  the name of currently selected recording. Click or tap that to browse the available
                  recordings and select a new one.
                </p>
              </dd>
            </div>

            <div className='mt-8 border-t border-mcgreen pt-6 md:grid md:grid-cols-12 md:gap-8'>
              <dt className='text-base leading-6 font-medium text-faqquestion md:col-span-5'>
                What is the “Synthetic” Recording?
              </dt>
              <dd className='mt-2 md:mt-0 md:col-span-7'>
                <p className='text-base leading-6 text-gray-50'>
                  Synthetic playback is when the software performs the music digitally instead of playing a
                  “synced” recording. While this doesn’t always sound as beautiful as the audio or video
                  recordings, it can be useful to hear a “perfect” computer performance. It also allows for
                  much broader tempo adjustments. See even more ways to
                  <a href='https://www.soundslice.com/help/en/player/basic/7/synthetic-playback/' className='text-mcgreen hover:text-opacity-75' target='_blank'> customize your Synthetic playback experience here</a>
                  . You can select this option in the Recordings menu.
                </p>
              </dd>
            </div>

            <div className='mt-8 border-t border-mcgreen pt-6 md:grid md:grid-cols-12 md:gap-8'>
              <dt className='text-base leading-6 font-medium text-faqquestion md:col-span-5'>
                How do I play a Chunk?
              </dt>
              <dd className='mt-2 md:mt-0 md:col-span-7'>
                <p className='text-base leading-6 text-gray-50'>
                  Simply click or tap on the play button to play and pause. You can also use the
                  spacebar to play and pause.
                </p>
              </dd>
            </div>

            <div className='mt-8 border-t border-mcgreen pt-6 md:grid md:grid-cols-12 md:gap-8'>
              <dt className='text-base leading-6 font-medium text-faqquestion md:col-span-5'>
                How do I navigate to a particular place in the score?
              </dt>
              <dd className='mt-2 md:mt-0 md:col-span-7'>
                <p className='text-base leading-6 text-gray-50'>
                  Click or tap anywhere in the music notation or written content to navigate there.
                  Since the Recordings are all “synced”, they will automatically jump to wherever you
                  navigate. For quick navigation, you can click and drag the progress bar along the
                  bottom left of the player. You’ll see a little pop-up with the time stamp and measure
                  number for easy navigation.
                </p>
              </dd>
            </div>

            <div className='mt-8 border-t border-mcgreen pt-6 md:grid md:grid-cols-12 md:gap-8'>
              <dt className='text-base leading-6 font-medium text-faqquestion md:col-span-5'>
                How can I hide particular instrument parts I don’t want to see?
              </dt>
              <dd className='mt-2 md:mt-0 md:col-span-7'>
                <p className='text-base leading-6 text-gray-50'>
                  With your chunk open, click the gear button in the bottom right corner of the player to
                  Open the Settings Menu. You’ll see all of the instruments on the score listed. Next to
                  each instrument name you can click/tap the “notes" icon to hide or show that instrument.
                  Just beside that is the “solo” icon. If you click/tap this it will hide all other instruments
                  but those with solo selected.
                </p>
              </dd>
            </div>

            <div className='mt-8 border-t border-mcgreen pt-6 md:grid md:grid-cols-12 md:gap-8'>
              <dt className='text-base leading-6 font-medium text-faqquestion md:col-span-5'>
                Tips on viewing Music Chunks
              </dt>
              <dd className='mt-2 md:mt-0 md:col-span-7'>
                <p className='text-base leading-6 text-gray-50'>
                  1. Depending on your device and whether you’re using playback or just reading - you may
                  want to switch between our 3 layout options. Click to learn more about
                  <a href='https://www.soundslice.com/help/en/player/advanced/242/fluid-layout/' className='text-mcgreen hover:text-opacity-75' target='_blank'> Fluid </a>
                  (default),
                  <a href='https://www.soundslice.com/help/en/player/advanced/115/horizontal-layout/' className='text-mcgreen hover:text-opacity-75' target='_blank'> Horizontal </a>
                  , and
                  <a href='https://www.soundslice.com/help/en/player/advanced/241/paged-layout/' className='text-mcgreen hover:text-opacity-75' target='_blank'> Paged </a>
                  layouts. You can switch between layouts at any time.
                </p>
                <br />
                <p className='text-base leading-6 text-gray-50'>
                  2. Hide the instruments you don’t need to see.
                </p>
                <br />
                <p className='text-base leading-6 text-gray-50'>
                  3. Adjust the Zoom until it’s comfortable to read. To adjust zoom, click the gear button in
                  the bottom right corner of the player to Open the Settings Menu. The Zoom control is at
                  the top of the menu adjusts using an easy slider.
                </p>
              </dd>
            </div>

            <div className='mt-8 border-t border-mcgreen pt-6 md:grid md:grid-cols-12 md:gap-8'>
              <dt className='text-base leading-6 font-medium text-faqquestion md:col-span-5'>
                Which Devices Support Music Chunks?
              </dt>
              <dd className='mt-2 md:mt-0 md:col-span-7'>
                <p className='text-base leading-6 text-gray-50'>
                  It’s important to meet students where they are - and that means supporting a wide range
                  of devices. With just an internet connection, you can access Music Chunks on Smart Phones,
                  Laptops, Tablets, and Desktop Computers. Access 1000’s of chunks while rehearsing in the
                  band room, classroom, private lessons, and for your practice at home.
                </p>
              </dd>
            </div>

            <div className='mt-8 border-t border-mcgreen pt-6 md:grid md:grid-cols-12 md:gap-8'>
              <dt className='text-base leading-6 font-medium text-faqquestion md:col-span-5'>
                Are there any special features on touchscreen devices?
              </dt>
              <dd className='mt-2 md:mt-0 md:col-span-7'>
                <p className='text-base leading-6 text-gray-50'>
                  Yes! When you have a Chunk open, you can:
                </p>
                <ul className='list-disc list-inside pt-5 text-base leading-6 text-gray-50'>
                  <li>
                    Tap any place in the music or written content to navigate there.
                  </li>
                  <li>
                    Use a 2-finger “pinch” to zoom in/out on the notation.
                  </li>
                  <li>
                    Tap the Loop Icon in the Control Bar (bottom right of the player) to create a loop, then tap and drag either edge of loop to resize.
                  </li>
                </ul>
              </dd>
            </div>

            <div className='mt-8 border-t border-mcgreen pt-6 md:grid md:grid-cols-12 md:gap-8'>
              <dt className='text-base leading-6 font-medium text-faqquestion md:col-span-5'>
                Is there a way to “Save” favorite Chunks so you can find them again easily?
              </dt>
              <dd className='mt-2 md:mt-0 md:col-span-7'>
                <p className='text-base leading-6 text-gray-50'>
                  Yes! With any Chunk open, click the “Save” button underneath the right side of the player.
                  This automatically adds it to your personal collection called My Chunks. Your My Chunks
                  collection is accessible at the top of the main menu directly beneath “Home”. To remove
                  a Chunk from My Chunks, open that Chunk and un-click the “Save” button.
                </p>
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
  );
};
