import React, { useEffect, useState } from 'react';

import axios from 'axios';
import axiosRetry from 'axios-retry';

// TAILWIND TRANSITION
import { Transition } from '@tailwindui/react';

// IMAGES
import BasicsBanner from '../../img/collections/mc_basics_banner.png'
import ConcertsBanner from '../../img/collections/mc_concerts_banner.png'
import ExercisesBanner from '../../img/collections/mc_exercises_banner.png'
import JazzBanner from '../../img/collections/mc_jazz_banner.png'
import MarchingBanner from '../../img/collections/mc_marching_banner.png'
import MyChunksBanner from '../../img/collections/mc_mychunks_banner.png'
import PublishersBanner from '../../img/collections/mc_publishers_banner.png'
import VisualsBanner from '../../img/collections/mc_visuals_banner.png'
import LearnBanner from '../../img/collections/mc_learn_about_banner.png'
import LearnPublishersContentCard from '../../img/collections/IV_Publishers_Content.png'
import LearnMusicChunksPlayerCard from '../../img/collections/IV_Music_Chunks_Player.png'
import LearnMusicChunksSiteCard from '../../img/collections/IV_Music_Chunks_Site.png'
import LearnHowSchoolsUseCard from '../../img/collections/IV_How_Schools_Use.png'

// COMPONENTS
import { MemoizedChunkCard } from './ChunkCard';
import { MemoizedCollectionCard } from './CollectionCard';
import { MemoizedFeaturedPublisherCard } from './FeaturedPublisherCard';
import { MemoizedCollectionTypeCard } from './CollectionTypeCard';
import { MemoizedInstructionalVideoCard } from './InstructionalVideoCard';

// ROUTING
import { Link, Route } from 'react-router-dom';

// ----------------------------------------------------------------------------------
// ------------------------------------ Home ----------------------------------------
// ----------------------------------------------------------------------------------

export const Home = ({ cognitoUser }) => {
  // State
  const [randFeaturedPublishers, setRandFeaturedPublishers] = useState([]);
  const [randFeaturedChunks, setRandFeaturedChunks] = useState([]);
  const [randFeaturedCollections, setRandFeaturedCollections] = useState([]);
  const [instructionalVideoData, setInstructionalVideoData] = useState({});
  const [openVideo, setOpenVideo] = useState(false);

  // Set axios to retry a set number of times on failed request/return
  axiosRetry(axios, { retries: 3 })

  // UseEffect that gets a list of all featured publishers
  useEffect(() => {
    axios({
      method: 'get',
      url: process.env.REACT_APP_API + `publishers/featured/all`,
      headers: {
        Accept: 'application/json',
        Authorization: process.env.REACT_APP_HEROKU_SERVER_KEY,
      },
    })
      .then((res) => {
        setRandFeaturedPublishers(getRandom(res.data, 4));
      })
      .catch((err) => console.log(err));
  }, []);

  // UseEffect that gets a list of all featured chunks
  useEffect(() => {
    axios({
      method: 'get',
      url: process.env.REACT_APP_API + `chunks/featured/all`,
      headers: {
        Accept: 'application/json',
        Authorization: process.env.REACT_APP_HEROKU_SERVER_KEY,
      },
    })
      .then((res) => {
        // Set Random Featured Chunks to 4 if less than 8 exist. Otherwise, set random 8
        if (res.data.length < 8) {
          setRandFeaturedChunks(getRandom(res.data, 4));
        } else {
          setRandFeaturedChunks(getRandom(res.data, 8));
        }
      })
      .catch((err) => console.log(err));
  }, []);

  // UseEffect that gets a list of all featured collections
  useEffect(() => {
    axios({
      method: 'get',
      url: process.env.REACT_APP_API + `collections/featured/all`,
      headers: {
        Accept: 'application/json',
        Authorization: process.env.REACT_APP_HEROKU_SERVER_KEY,
      },
    })
      .then((res) => {
        // Set Random Featured Collections to 4 if less than 8 exist. Otherwise, set random 8
        if (res.data.length < 8) {
          setRandFeaturedCollections(getRandom(res.data, 4));
        } else {
          setRandFeaturedCollections(getRandom(res.data, 8));
        }
      })
      .catch((err) => console.log(err));
  }, []);

  // Function to help randomize the featured chunks and publishers to a limit of X per page every refresh
  function getRandom(arr, n) {
    var result = new Array(n),
      len = arr.length,
      taken = new Array(len);
    if (n > len)
      throw new RangeError('getRandom: more elements taken than available');
    while (n--) {
      var x = Math.floor(Math.random() * len);
      result[n] = arr[x in taken ? taken[x] : x];
      taken[x] = --len in taken ? taken[len] : len;
    }
    return result;
  }

  // Array of instructioanl videos
  const instructionalVideos = [
    {
      id: 1,
      name: 'LEARN ABOUT - THE PUBLISHERS CONTENT',
      video_img: LearnPublishersContentCard,
      video: 'https://music-chunks.s3-us-east-2.amazonaws.com/videos/MC_Content_Showcase_2.1_NM.mp4'
    },
    {
      id: 2,
      name: 'LEARN ABOUT - THE MUSIC CHUNKS SITE',
      video_img: LearnMusicChunksSiteCard,
      video: 'https://music-chunks.s3-us-east-2.amazonaws.com/videos/MC_2021_Site_Intro_FINAL_2.0_NM.mp4'
    },
    {
      id: 3,
      name: 'LEARN ABOUT - THE MUSIC CHUNKS PLAYER',
      video_img: LearnMusicChunksPlayerCard,
      video: 'https://music-chunks.s3-us-east-2.amazonaws.com/videos/How_To_Using_the_Player.mp4'
    },
    {
      id: 4,
      name: 'LEARN ABOUT - HOW SCHOOLS ARE USING MUSIC CHUNKS',
      video_img: LearnHowSchoolsUseCard,
      video: 'https://music-chunks.s3-us-east-2.amazonaws.com/videos/MC_How_to_High_School_Middle_School_SHORT_v2_NM.mp4'
    }
  ]

  // Array of collection types
  const collectionTypes = [
    {
      id: 1,
      name: 'My Chunks',
      link: '/dashboard/your-chunks',
      banner_pic: MyChunksBanner,
      description: `This is your own saved collection of chunks for easy access. Add or Remove chunks to My Chunks by clicking the SAVE button underneath the player while viewing that chunk.`,
    },
    {
      id: 2,
      name: 'Publishers',
      link: '/dashboard/publishers?publisher=all',
      banner_pic: PublishersBanner,
      description: `Music Chunks’ Composers, Publishers, Educators, and Creators. Discover and Learn their music with audio and video examples using powerful practice tools such as metronome, looping with count-in, and playback speed control.`,
    },
    {
      id: 3,
      name: 'Marching Arts',
      link: '/dashboard/type/showmusic',
      banner_pic: MarchingBanner,
      description: `Marching Arts Collections containing chunks of show music, solos, and etudes ranging from Marching Band, to Indoor Percussion and Winds, and Drum and Bugle Corps. Discover and Learn their music with audio and video examples using powerful practice tools such as metronome, looping with count-in, and playback speed control.`
    },
    {
      id: 4,
      name: 'Concert',
      link: '/dashboard/type/concerts',
      banner_pic: ConcertsBanner,
      description: `Concert Collections containing chunks of concert, percussion, and wind ensemble music as well as solos and etudes. Discover and Learn their music with audio and video examples using powerful practice tools such as metronome, looping with count-in, and playback speed control.`
    },
    {
      id: 5,
      name: 'Jazz Pop Combo',
      link: '/dashboard/type/jazz',
      banner_pic: JazzBanner,
      description: `Collections containing chunks of music for Jazz ensemble, Steel drum ensemble, and Combo ensemble instruments. Discover and Learn their music with audio and video examples using powerful practice tools such as metronome, looping with count-in, and playback speed control.`
    },
    {
      id: 6,
      name: 'Exercises & Etudes',
      link: '/dashboard/type/exercises',
      banner_pic: ExercisesBanner,
      description: `Collections of Fundamental studies and Exercises by today’s top educators. Take your skills to the next level. Discover and Learn their music with audio and video examples using powerful practice tools such as metronome, looping with count-in, and playback speed control.`
    },
    {
      id: 7,
      name: 'Visual & Choreography',
      link: '/dashboard/type/visuals',
      banner_pic: VisualsBanner,
      description: `Collections of Visuals & Choreography by today’s top educators. Take your skills to the next level. Discover and Learn their music with audio and video examples using powerful practice tools such as metronome, looping with count-in, and playback speed control.`
    },
    {
      id: 8,
      name: 'Music Chunks Band Basics',
      link: '/dashboard/type/basics',
      banner_pic: BasicsBanner,
      description: `A One-Stop shop for warm-ups, scales, rudiments, technique-builders, and basics your band program needs. Discover and Learn these basics with audio and video examples using powerful practice tools such as metronome, looping with count-in, and playback speed control.`
    },
    {
      id: 9,
      name: 'Learn About Music Chunks',
      link: '/dashboard/type/learn',
      banner_pic: LearnBanner,
      description: `This is a collection of all instructional videos, tips, tricks, and how-to’s to help navigate and use Music Chunks!`,
    }
  ];

  return (
    <div className='w-full max-w-screen '>
      <div className='pb-1 mx-4'>
        <h3 className='text-2xl leading-6 font-medium text-mcgreen'>
          Welcome to Music Chunks!
        </h3>
        <p className='whitespace-pre-wrap text-white my-4'>
          Music Chunks is a platform built to engage the modern music student. Discover and learn content by
          incredible Creators, Lesson Providers, Publishers, Competitive Organizations, Podcasts and much more
          from the Music and Visual Arts. On the top left panel you will find a list of the Publishers along
          with each Publishers Collections divided into categories of composition or use. On the bottom left
          panel you will find Playlists that bring more focus towards your search criteria. Happy Chunk'n!!!
        </p>
      </div>

      {/* INSTRUCTIONAL VIDEO SECTION */}
      <div className='grid grid-cols-1 gap-5 px-4 pb-4 border-t-4 border-green-600 border-solid max-w-screen'>
        <div className='max-w-lg mt-2'>
          <h1 className='w-full text-2xl font-medium leading-6 text-white'>
            GET STARTED VIDEOS
          </h1>
        </div>

        {/* RENDERS FOUR INSTRUCTIONAL VIDEO CARDS */}
        <div className='grid items-center justify-center gap-10 mt-2 grig-cols-1 sm:grid-cols-2 md:grid-cols-2 xl:grid-cols-4 2xl:px-24'>
          {instructionalVideos?.map((data) => (
            <button
              onClick={() => {
                setOpenVideo(!openVideo)
                setInstructionalVideoData(data)
              }}
            >
              <Route
                render={(props) => (
                  <MemoizedInstructionalVideoCard
                    key={data.id}
                    cognitoUser={cognitoUser}
                    data={data}
                    {...props}
                  />
                )}
              />
            </button>
          ))}
        </div>
      </div>

      {/* FEATURED PUBLISHERS SECTION */}
      <div className='grid grid-cols-1 gap-5 px-4 pb-4 mt-2 border-t-4 border-green-600 border-solid max-w-screen'>
        <div className='w-64 max-w-lg mt-2'>
          <h1 className='w-full text-2xl font-medium leading-6 text-white'>
            FEATURED PUBLISHERS
          </h1>
        </div>

        {/* ONLY RENDERS PUBLISHERS WITH THE FEATURES BOOL MARKED (MAYBE CHANGE TO HIT ON ENDPOINT INSTEAD) */}
        <div className='grid items-center justify-center gap-10 mt-2 grig-cols-1 sm:grid-cols-2 md:grid-cols-2 xl:grid-cols-4 2xl:px-24'>
          {randFeaturedPublishers?.map((data) => (
            <Link key={data.id} to={`/dashboard/publishers/${data.id}`}>
              <Route
                render={(props) => (
                  <MemoizedFeaturedPublisherCard
                    key={data.id}
                    cognitoUser={cognitoUser}
                    data={data}
                    {...props}
                  />
                )}
              />
            </Link>
          ))}
        </div>
      </div>

      {/* FEATURED CHUNKS SECTION */}
      <div className='grid grid-cols-1 gap-5 px-4 pb-4 mt-2 border-t-4 border-green-600 border-solid max-w-screen'>
        <div className='w-64 max-w-lg mt-2'>
          <h1 className='w-full text-2xl font-medium leading-6 text-white'>
            FEATURED CHUNKS
          </h1>
        </div>

        {/* ONLY RENDERS CHUNKS WITH THE FEATURES BOOL MARKED */}
        <div className='grid items-center justify-center gap-10 mt-2 grig-cols-1 sm:grid-cols-2 md:grid-cols-2 xl:grid-cols-4 2xl:px-24'>
          {randFeaturedChunks?.map((data) => (
            <Link key={data.id} to={`/dashboard/chunks/${data.id}`}>
              <Route
                render={(props) => (
                  <MemoizedChunkCard
                    key={data.id}
                    cognitoUser={cognitoUser}
                    data={data}
                    {...props}
                  />
                )}
              />
            </Link>
          ))}
        </div>
      </div>

      {/* FEATURED COLLECTIONS SECTION */}
      <div className='grid grid-cols-1 gap-5 px-4 pb-4 mt-2 border-t-4 border-green-600 border-solid max-w-screen'>
        <div className='max-w-lg mt-2'>
          <h1 className='w-full text-2xl font-medium leading-6 text-white'>
            FEATURED COLLECTIONS
          </h1>
        </div>

        {/* ONLY RENDERS COLLECTIONS WITH THE FEATURES BOOL MARKED */}
        <div className='grid items-center justify-center gap-10 mt-2 grig-cols-1 sm:grid-cols-2 md:grid-cols-2 xl:grid-cols-4 2xl:px-24'>
          {randFeaturedCollections?.map((data) => (
            <Link key={data.id} to={`/dashboard/collections/${data.id}`}>
              <Route
                render={(props) => (
                  <MemoizedCollectionCard
                    key={data.id}
                    cognitoUser={cognitoUser}
                    data={data}
                    {...props}
                  />
                )}
              />
            </Link>
          ))}
        </div>
      </div>

      {/* CATEGORIES SECTION */}
      <div className='grid grid-cols-1 gap-5 px-4 pb-4 mt-2 border-t-4 border-green-600 border-solid max-w-screen'>
        <div className='max-w-lg mt-2'>
          <h1 className='w-full text-2xl font-medium leading-6 text-white'>
            CATEGORIES
          </h1>
        </div>

        {/* RENDERS ALL CATEGORIES */}
        <div>
          {collectionTypes?.map((data) => (
            <Link key={data.id} to={data.link}>
              <Route
                render={(props) => (
                  <MemoizedCollectionTypeCard
                    key={data.id}
                    cognitoUser={cognitoUser}
                    data={data}
                    {...props}
                  />
                )}
              />
            </Link>
          ))}
        </div>
      </div>

      {/* Modal when clicking instructional videos */}
      <Transition show={openVideo}>
        <div class="fixed z-10 inset-0 overflow-y-auto">
          <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div class="fixed inset-0 transition-opacity" aria-hidden="true">
              <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            {/* <!-- This element is to trick the browser into centering the modal contents. --> */}
            <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
              <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div class="sm:flex sm:items-start">
                  <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
                      {instructionalVideoData.name}
                    </h3>
                    <div class="mt-2">
                      <video width="1000" controls>
                        <source src={instructionalVideoData.video} type="video/mp4" />
                      </video>
                    </div>
                  </div>
                </div>
              </div>
              <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button onClick={() => {
                  setOpenVideo(!openVideo)
                }}
                  type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </Transition>
    </div >
  );
};
