import React, { useEffect, useState } from 'react';
import axios from 'axios';
import axiosRetry from 'axios-retry';

// STATE
import { useSetRecoilState } from 'recoil';
import { ForceReload } from '../../state/atoms';

// ROUTING
import { Route, Link } from 'react-router-dom';

// IMAGES
import MyChunksBanner from '../../img/collections/mc_mychunks_banner.png'

// COMPONENTS
import { MemoizedChunkCard } from './ChunkCard';

// ----------------------------------------------------------------------------------
// -------------------------------- My Chunks ---------------------------------------
// ----------------------------------------------------------------------------------

export const MyChunks = ({ cognitoUser }) => {
  // State
  const set = useSetRecoilState(ForceReload);
  const [myChunks, setMyChunks] = useState([]);

  // Set axios to retry a set number of times on failed request/return
  axiosRetry(axios, { retries: 3 })

  // Force reloads the page at random intervals
  useEffect(() => {
    set(Math.random());
  }, [set]);

  // Function that gets a list of all a user's saved chunks
  useEffect(() => {
    axios({
      method: 'get',
      url: process.env.REACT_APP_API + `users/${cognitoUser.data.id}/savedchunks`,
      headers: {
        Accept: 'application/json',
        Authorization: process.env.REACT_APP_HEROKU_SERVER_KEY,
      },
    }).then((res) => {
      setMyChunks(res.data);
    });
  }, [cognitoUser.data.id]);

  return (
    <div className='mb-4'>
      <img
        src={MyChunksBanner}
        alt='img for a collection type'
        className='inline-block object-cover w-full h-full rounded-t-md'
      />
      {/* ABOUT SAVED CHUNKS */}
      <div className='bg-green-700 px-5 py-3 mb-2 rounded-b-md'>
        <h1 className='text-white text-lg mb-5 font-bold'>
          Saved Chunks
        </h1>
        <p className='whitespace-pre-wrap text-white mb-5'>
          This is your own saved collection of chunks for easy access. Add or Remove
          chunks to My Chunks by clicking the SAVE button underneath the player while
          viewing that chunk.
        </p>
      </div>

      <div className='grid justify-center gap-10 mt-2 sm:grid-cols-2 md:grid-cols-2 xl:grid-cols-4 2xl:grid-cols-6 2xl:px-15'>
        {/* <!-- Card --> */}
        {myChunks.length === 0 && (
          <div>
            <p>No saved chunks</p>
          </div>
        )}

        {/* RENDERS ALL OF A USER'S SAVED CHUNKS */}
        {myChunks.map((data) => (
          <Link
            key={data.id}
            to={`/dashboard/chunks/${data.id}`}
          >
            <Route
              render={(props) => (
                <MemoizedChunkCard
                  key={data.id}
                  cognitoUser={cognitoUser}
                  data={data}
                  {...props}
                />
              )}
            />
          </Link>
        ))}
      </div>
    </div>
  );
};