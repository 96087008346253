import React from 'react';

// ----------------------------------------------------------------------------------
// -------------------------------- COLLECTION CARD ---------------------------------
// ----------------------------------------------------------------------------------

export const CollectionCard = ({ data }) => {
  const {
    name,
    img,
    collectionType,
    publisher_name
  } = data;

  return (
    <div
      key={name}
      className={
        'hover:bg-mcgreen max-w-359 hover:bg-opacity-25 p-2 rounded-lg  z-50'
      }
    >
      <div className={`overflow-hidden`}>
        {/* TOP IMG */}
        <img
          src={img}
          alt='collection'
          className='object-fill w-full h-full min-h-full max-h-40 rounded-t-md'
        // className='inline-block object-cover w-full h-40 rounded-t-md'
        />
        {/* color bar */}
        <div className={`relative  w-full  rounded-b-lg bg-collcards`}>
          <div className='flex items-center justify-between px-4 py-2 text-sm text-white'>
            {collectionType === 'Show Music' ? (
              <span>Marching Arts</span>
            ) : null}
            {collectionType === 'Exercises & Etudes' ? (
              <span>Exercises & Fundamentals</span>
            ) : null}
            {collectionType === 'Basics for Band' ? (
              <span>Music Chunks Band Basics</span>
            ) : null}
            {collectionType === 'Concert Music' ? (
              <span>Concert</span>
            ) : null}
            {collectionType === 'Jazz Pop Combo' ? (
              <span>Jazz Pop Combo</span>
            ) : null}
            {collectionType === 'Visual & Choreography' ? (
              <span>Visual & Choreography</span>
            ) : null}
            {collectionType === 'Learn About Music Chunks' ? (
              <span>Learn About Music Chunks</span>
            ) : null}
            {/* <span>{collectionType}</span> */}
          </div>
        </div>
        <div className='flex items-center justify-between px-4 py-2 text-white'>
          <span>{name}</span>
        </div>
        <div className='px-4 text-xs font-medium leading-4 text-white '>
          By: {publisher_name}
        </div>
      </div>
      <div className='flex items-start pt-2'>
        <div className='flex-shrink-0 pt-1'>
          <div className='flex-1 w-0 ml-5'>
            <dl>
              <dd>
                <div className='text-xs font-medium text-teal-400 truncate-2-lines'></div>
              </dd>
            </dl>
          </div>
        </div>
      </div>
    </div>
  );
};

export const MemoizedCollectionCard = React.memo(CollectionCard);