import React, { useState, useEffect } from 'react';
import axios from 'axios';

// ROUTING
import { useParams } from 'react-router-dom';

// COMPONENTS
import ChunkData from '../chunks/ChunkData';

// ----------------------------------------------------------------------------------
// ------------------------------ GROUP CHUNK TABLE ---------------------------------
// ----------------------------------------------------------------------------------

const GroupChunkTable = () => {
  // State
  const [groupChunks, setGroupChunks] = useState([]);  // SAVES LIST OF ALL GROUP CHUNKS
  const { groupID } = useParams();

  // Useffect to grab all of a group's chunks
  useEffect(() => {
    axios({
      method: 'get',
      url: process.env.REACT_APP_API + `groups/${groupID}/allchunks`,
      headers: {
        Accept: 'application/json',
        Authorization: process.env.REACT_APP_HEROKU_SERVER_KEY,
      }
    })
      .then((res) => {
        setGroupChunks(res.data)
      })
  }, [])

  return (
    <div className='py-4 '>
      {groupChunks?.map((data) => (
        <ChunkData key={data?.id} data={data} />
      ))}
    </div>
  );
};

export default GroupChunkTable;