import React, { useState, useEffect } from 'react';
import axios from 'axios';
import axiosRetry from 'axios-retry';

// ROUTING
import { Route, Link } from 'react-router-dom';

// CHAT API
import { StreamChat } from 'stream-chat';

// COMPONENTS
import EnsembleAdminRoute from '../../utils/EnsembleAdminRoute';
import { MemoizedGroupHomeCard } from './GroupHomeCard';
import { MemoizedGroupAboutCard } from './GroupAboutCard';
import { MemoizedGroupClassrooms } from './GroupClassrooms';
import { MemoizedGroupPlaylistPage } from './GroupPlaylistPage';
import { MemoizedGroupAdminCard } from './GroupAdminCard';
import { MemoizedGroupAddUserForm } from './GroupAddUserForm';
import { MemoizedGroupAddPublicChunkForm } from './GroupAddPublicChunkForm';
import { MemoizedGroupAddFeaturedChunkForm } from './GroupAddFeaturedChunkForm';
import { MemoizedGroupEditGroupForm } from './GroupEditGroupForm';
import { MemoizedGroupAddPlaylistForm } from './GroupAddPlaylistForm';
import { MemoizedGroupAddPlaylistChunkForm } from './GroupAddPlaylistChunkForm';
import { MemoizedGroupAddPublicCollectionForm } from './GroupAddPublicCollectionForm';
import { MemoizedGroupEditPlaylistForm } from './GroupEditPlaylistForm';
import { MemoizedGroupCollectionPage } from './GroupCollectionPage';
import { SingleChunk } from './SingleChunk';
import PlaylistPage from './PlaylistPage';
import CollectionPage from './CollectionPage';

// IMAGES
import { ReactComponent as BlankPublisher } from '../../img/icon/BlankPublisher.svg';
import PlaceholderBanner from '../../img/placeholder-banner.jpg';

const client = StreamChat.getInstance(process.env.REACT_APP_STREAM_API_KEY)

// ----------------------------------------------------------------------------------
// ---------------------------------- GROUP ADMIN PAGE ------------------------------
// ----------------------------------------------------------------------------------

const GroupPage = ({ match, cognitoUser, isAdmin }) => {
  const [group, setGroup] = useState({});
  const [groupChunks, setGroupChunks] = useState([]);
  const [groupUsers, setGroupUsers] = useState([]);
  const [groupAdmins, setGroupAdmins] = useState([]);
  const [groupFeaturedChunks, setGroupFeaturedChunks] = useState([]);
  const [groupPlaylists, setGroupPlaylists] = useState([]);
  const [groupCollections, setGroupCollections] = useState([]);
  const [totalUnreadCount, setTotalUnreadCount] = useState();
  const [refresh, setRefresh] = useState();

  // Set axios to retry a set number of times on failed request/return
  axiosRetry(axios, { retries: 3 })

  // UseEffect that grabs the current groups information by ID
  useEffect(() => {
    axios({
      method: 'get',
      url: process.env.REACT_APP_API + `groups/${match.params.groupID}/simple`,
      headers: {
        Accept: 'application/json',
        Authorization: process.env.REACT_APP_HEROKU_SERVER_KEY,
      },
    })
      .then((res) => {
        setGroup(res.data);
      })
      .catch((err) => console.log(err));
  }, [match.params.groupID, refresh]);

  // UseEffect that grabs a list of all the current group's chunks
  useEffect(() => {
    axios({
      method: 'get',
      url:
        process.env.REACT_APP_API + `groups/${match.params.groupID}/allchunks`,
      headers: {
        Accept: 'application/json',
        Authorization: process.env.REACT_APP_HEROKU_SERVER_KEY,
      },
    })
      .then((res) => {
        setGroupChunks(res.data);
      })
      .catch((err) => console.log(err));
  }, [match.params.groupID, refresh]);

  // UseEffect that grabs a list of all the current group's featured chunks
  useEffect(() => {
    axios({
      method: 'get',
      url:
        process.env.REACT_APP_API + `groups/${match.params.groupID}/allfeaturedchunks`,
      headers: {
        Accept: 'application/json',
        Authorization: process.env.REACT_APP_HEROKU_SERVER_KEY,
      },
    })
      .then((res) => {
        setGroupFeaturedChunks(res.data);
      })
      .catch((err) => console.log(err));
  }, [match.params.groupID, refresh]);

  // UseEffect that grabs a list of all the current group's users
  useEffect(() => {
    axios({
      method: 'get',
      url: process.env.REACT_APP_API + `groups/${match.params.groupID}/allusers`,
      headers: {
        Accept: 'application/json',
        Authorization: process.env.REACT_APP_HEROKU_SERVER_KEY,
      },
    })
      .then((res) => {
        // Only grab users that are currently active in the ensemble
        setGroupUsers(res.data.filter((user) => user.active === true));
      })
      .catch((err) => console.log(err));
  }, [match.params.groupID, refresh]);

  // UseEffect that grabs a list of all the current group's admins
  useEffect(() => {
    axios({
      method: 'get',
      url: process.env.REACT_APP_API + `groups/${match.params.groupID}/alladmins`,
      headers: {
        Accept: 'application/json',
        Authorization: process.env.REACT_APP_HEROKU_SERVER_KEY,
      },
    })
      .then((res) => {
        setGroupAdmins(res.data);
      })
      .catch((err) => console.log(err));
  }, [match.params.groupID, refresh]);

  // UseEffect that grabs a list of all the current group's playlists
  useEffect(() => {
    axios({
      method: 'get',
      url: process.env.REACT_APP_API + `groups/${match.params.groupID}/allplaylists`,
      headers: {
        Accept: 'application/json',
        Authorization: process.env.REACT_APP_HEROKU_SERVER_KEY,
      },
    })
      .then((res) => {
        setGroupPlaylists(res.data);
      })
      .catch((err) => console.log(err));
  }, [match.params.groupID, refresh]);

  // UseEffect that grabs a list of all the current group's collections
  useEffect(() => {
    axios({
      method: 'get',
      url: process.env.REACT_APP_API + `groups/${match.params.groupID}/allcollections`,
      headers: {
        Accept: 'application/json',
        Authorization: process.env.REACT_APP_HEROKU_SERVER_KEY,
      },
    })
      .then((res) => {
        setGroupCollections(res.data);
      })
      .catch((err) => console.log(err));
  }, [match.params.groupID, refresh]);

  // UseEffect that sets up connection to Stream Chat API with the signed in user
  useEffect(() => {
    if (!match.url.includes('public')) {
      const setupClient = async () => {
        try {
          // Querys an ensemble's channels to find unreadCount/status
          if (client.user) {
            const ensembleChannels = await client.queryChannels(
              { members: { $in: [cognitoUser.data.cognito_id] }, team: { $in: [group.id] } },
              { unread_count: -1 },
            );

            let unreadCount = 0
            ensembleChannels.map(result => {
              unreadCount += result.state.unreadCount
            })

            setTotalUnreadCount(unreadCount > 0 ? 'New' : null)
          }
        } catch (err) {
          console.log(err);
        }
      };
      setupClient();
    }
  }, [cognitoUser, group]);

  useEffect(() => {
    client.on(event => {
      // Lets a user know if there are new unread messages in the ensemble tab next to 'CHANNELS'
      if (event.unread_channels !== undefined && event.team === group.id) {
        if (event.unread_channels > 0) {
          setTotalUnreadCount('New')
        } else {
          setTotalUnreadCount(null)
        }

      } else if (event.unread_channels !== undefined && event.team !== group.id) {
        setTotalUnreadCount(null)
      }
    })
  }, [])

  return (
    <>
      <div className='mb-4'>
        <div>
          {group?.banner_img ? (
            <img
              className='object-fill w-full h-full min-h-full max-h-52 rounded-t-md'
              src={group?.banner_img}
              alt='banner for a single ensemble'
            />
          ) : (
            <img
              className='object-fill w-full h-full min-h-full max-h-52 rounded-t-md'
              src={PlaceholderBanner}
              alt='placeholder banner for a single ensemble'
            />
          )}
        </div>

        {/* Info Bar */}
        <div className='px-0 sm:px-10 pt-4 pb-1 bg-green-600 rounded-b-lg'>
          {/* Group Info */}
          <div className='sm:flex justify-between'>
            {/* Left Side */}
            <div className='flex justify-center items-start'>
              {group?.profile_img ? (
                <img
                  className='rounded-lg ml-0.5 h-14 w-14 hidden sm:block'
                  src={group?.profile_img}
                  alt='profile for a single ensemble'
                />
              ) : (
                <BlankPublisher className='inline-block object-fill w-12 h-12 rounded-md' />
              )}
              <h1 className='sm:pl-5 text-3xl text-white'>{group?.name}</h1>
            </div>
          </div>

          {/* Tabs */}
          <div className='flex flex-col flex-wrap sm:flex-row items-center sm:justify-between pt-2 text-xl text-white'>
            <Link to={`${match.url}`} className='hover:text-mcgreen'>HOME</Link>
            <Link to={`${match.url}/playlists`} className='hover:text-mcgreen'>PLAYLISTS</Link>
            <Link to={`${match.url}/collections`} className='hover:text-mcgreen'>COLLECTIONS</Link>
            <Link to={`${match.url}/about`} className='hover:text-mcgreen'>ABOUT</Link>
            {match.url.includes('public') ? null : (
              <div className='flex'>
                <Link to={`${match.url}/classrooms`} className='hover:text-mcgreen'>CHANNELS</Link>
                {totalUnreadCount === 'New' ? (
                  <p className='ml-2 px-2 self-center bg-red-600 rounded-full text-sm font-medium'>
                    {totalUnreadCount}
                  </p>
                ) : null}
              </div>
            )}
            {isAdmin ? (
              <Link to={`${match.url}/admin`} className='hover:text-mcgreen'>ADMIN</Link>
            ) : null}
          </div>
        </div>
      </div>

      {/* PRIVATE ENSEMBLE ROUTES */}
      <Route
        exact path={`/dashboard/ensembles/:id`}
        render={(props) => (
          <MemoizedGroupHomeCard
            cognitoUser={cognitoUser}
            group={group}
            groupChunks={groupChunks}
            groupFeaturedChunks={groupFeaturedChunks}
            {...props}
          />
        )}
      />
      <Route
        exact path={`/dashboard/ensembles/:id/playlists`}
        render={(props) => (
          <MemoizedGroupPlaylistPage
            cognitoUser={cognitoUser}
            group={group}
            groupPlaylists={groupPlaylists}
            {...props}
          />
        )}
      />
      <Route
        exact path={`/dashboard/ensembles/:id/playlists/:playlistId`}
        render={(props) => (
          <PlaylistPage
            cognitoUser={cognitoUser}
            group={group}
            groupChunks={groupChunks}
            groupPlaylists={groupPlaylists}
            {...props}
          />
        )}
      />
      <Route
        exact path={`/dashboard/ensembles/:id/collections`}
        render={(props) => (
          <MemoizedGroupCollectionPage
            cognitoUser={cognitoUser}
            group={group}
            groupCollections={groupCollections}
            {...props}
          />
        )}
      />
      <Route
        exact path={`/dashboard/ensembles/:id/collections/:collectionId`}
        render={(props) => (
          <CollectionPage
            cognitoUser={cognitoUser}
            group={group}
            groupChunks={groupChunks}
            groupCollections={groupCollections}
            {...props}
          />
        )}
      />
      <Route
        exact path={`/dashboard/ensembles/:id/about`}
        render={(props) => (
          <MemoizedGroupAboutCard
            cognitoUser={cognitoUser}
            groupAdmins={groupAdmins}
            group={group}
            {...props}
          />
        )}
      />
      <Route
        exact path={`/dashboard/ensembles/:id/classrooms`}
        render={(props) => (
          <MemoizedGroupClassrooms
            cognitoUser={cognitoUser}
            groupAdmins={groupAdmins}
            group={group}
            totalUnreadCount={totalUnreadCount}
            setTotalUnreadCount={setTotalUnreadCount}
            {...props}
          />
        )}
      />
      <Route
        exact path={`/dashboard/ensembles/:groupId/chunks/:id`}
        render={(props) => (
          <SingleChunk
            cognitoUser={cognitoUser}
            group={group}
            {...props}
          />
        )}
      />
      <Route
        exact path={`/dashboard/ensembles/:groupId/playlists/:playlistId/chunks/:id`}
        render={(props) => (
          <SingleChunk
            cognitoUser={cognitoUser}
            group={group}
            {...props}
          />
        )}
      />
      <Route
        exact path={`/dashboard/ensembles/:groupId/collections/:collectionId/chunks/:id`}
        render={(props) => (
          <SingleChunk
            cognitoUser={cognitoUser}
            group={group}
            {...props}
          />
        )}
      />
      <EnsembleAdminRoute
        exact path={`/dashboard/ensembles/:id/admin`}
        groupUsers={groupUsers}
        groupFeaturedChunks={groupFeaturedChunks}
        groupPlaylists={groupPlaylists}
        groupCollections={groupCollections}
        groupChunks={groupChunks}
        group={group}
        refresh={refresh}
        setRefresh={setRefresh}
        component={MemoizedGroupAdminCard}
      />
      <EnsembleAdminRoute
        exact path={`/dashboard/ensembles/:id/admin/add-student`}
        groupUsers={groupUsers}
        groupAdmins={groupAdmins}
        group={group}
        setRefresh={setRefresh}
        refresh={refresh}
        component={MemoizedGroupAddUserForm}
      />
      <EnsembleAdminRoute
        exact path={`/dashboard/ensembles/:id/admin/add-playlist`}
        group={group}
        groupChunks={groupChunks}
        setRefresh={setRefresh}
        refresh={refresh}
        component={MemoizedGroupAddPlaylistForm}
      />
      <EnsembleAdminRoute
        exact path={`/dashboard/ensembles/:id/admin/playlists/:playlistId/edit-playlist`}
        group={group}
        groupChunks={groupChunks}
        setRefresh={setRefresh}
        refresh={refresh}
        component={MemoizedGroupEditPlaylistForm}
      />
      <EnsembleAdminRoute
        exact path={`/dashboard/ensembles/:id/admin/playlists/:playlistId/add-playlistchunks`}
        group={group}
        groupChunks={groupChunks}
        setRefresh={setRefresh}
        refresh={refresh}
        component={MemoizedGroupAddPlaylistChunkForm}
      />
      <EnsembleAdminRoute
        exact path={`/dashboard/ensembles/:id/admin/add-collection`}
        group={group}
        groupCollections={groupCollections}
        setRefresh={setRefresh}
        refresh={refresh}
        component={MemoizedGroupAddPublicCollectionForm}
      />
      <EnsembleAdminRoute
        exact path={`/dashboard/ensembles/:id/admin/add-chunk`}
        group={group}
        groupChunks={groupChunks}
        groupFeaturedChunks={groupFeaturedChunks}
        setRefresh={setRefresh}
        refresh={refresh}
        component={MemoizedGroupAddPublicChunkForm}
      />
      <EnsembleAdminRoute
        exact path={`/dashboard/ensembles/:id/admin/add-featuredchunk`}
        group={group}
        groupChunks={groupChunks}
        groupFeaturedChunks={groupFeaturedChunks}
        setRefresh={setRefresh}
        refresh={refresh}
        component={MemoizedGroupAddFeaturedChunkForm}
      />
      <EnsembleAdminRoute
        exact path={`/dashboard/ensembles/:id/admin/edit-ensemble`}
        group={group}
        setRefresh={setRefresh}
        refresh={refresh}
        component={MemoizedGroupEditGroupForm}
      />

      {/* PUBLIC ENSEMBLE ROUTES */}
      <Route
        exact path={`/dashboard/public/ensembles/:id`}
        render={(props) => (
          <MemoizedGroupHomeCard
            cognitoUser={cognitoUser}
            group={group}
            groupChunks={groupChunks}
            groupFeaturedChunks={groupFeaturedChunks}
            {...props}
          />
        )}
      />
      <Route
        exact path={`/dashboard/public/ensembles/:id/playlists`}
        render={(props) => (
          <MemoizedGroupPlaylistPage
            cognitoUser={cognitoUser}
            group={group}
            groupPlaylists={groupPlaylists}
            {...props}
          />
        )}
      />
      <Route
        exact path={`/dashboard/public/ensembles/:id/playlists/:playlistId`}
        render={(props) => (
          <PlaylistPage
            cognitoUser={cognitoUser}
            group={group}
            groupChunks={groupChunks}
            groupPlaylists={groupPlaylists}
            {...props}
          />
        )}
      />
      <Route
        exact path={`/dashboard/public/ensembles/:id/collections`}
        render={(props) => (
          <MemoizedGroupCollectionPage
            cognitoUser={cognitoUser}
            group={group}
            groupCollections={groupCollections}
            {...props}
          />
        )}
      />
      <Route
        exact path={`/dashboard/public/ensembles/:id/collections/:collectionId`}
        render={(props) => (
          <CollectionPage
            cognitoUser={cognitoUser}
            group={group}
            groupChunks={groupChunks}
            groupCollections={groupCollections}
            {...props}
          />
        )}
      />
      <Route
        exact path={`/dashboard/public/ensembles/:id/about`}
        render={(props) => (
          <MemoizedGroupAboutCard
            cognitoUser={cognitoUser}
            groupAdmins={groupAdmins}
            group={group}
            {...props}
          />
        )}
      />
      <Route
        exact path={`/dashboard/public/ensembles/:groupId/chunks/:id`}
        render={(props) => (
          <SingleChunk
            cognitoUser={cognitoUser}
            group={group}
            {...props}
          />
        )}
      />
      <Route
        exact path={`/dashboard/public/ensembles/:groupId/playlists/:playlistId/chunks/:id`}
        render={(props) => (
          <SingleChunk
            cognitoUser={cognitoUser}
            group={group}
            {...props}
          />
        )}
      />
      <Route
        exact path={`/dashboard/public/ensembles/:groupId/collections/:collectionId/chunks/:id`}
        render={(props) => (
          <SingleChunk
            cognitoUser={cognitoUser}
            group={group}
            {...props}
          />
        )}
      />
      <EnsembleAdminRoute
        exact path={`/dashboard/public/ensembles/:id/admin`}
        groupUsers={groupUsers}
        groupFeaturedChunks={groupFeaturedChunks}
        groupPlaylists={groupPlaylists}
        groupCollections={groupCollections}
        groupChunks={groupChunks}
        group={group}
        refresh={refresh}
        setRefresh={setRefresh}
        component={MemoizedGroupAdminCard}
      />
      {/* <EnsembleAdminRoute
        exact path={`/dashboard/public/ensembles/:id/admin/add-student`}
        groupUsers={groupUsers}
        groupAdmins={groupAdmins}
        group={group}
        setRefresh={setRefresh}
        refresh={refresh}
        component={MemoizedGroupAddUserForm}
      /> */}
      <EnsembleAdminRoute
        exact path={`/dashboard/public/ensembles/:id/admin/add-playlist`}
        group={group}
        groupChunks={groupChunks}
        setRefresh={setRefresh}
        refresh={refresh}
        component={MemoizedGroupAddPlaylistForm}
      />
      <EnsembleAdminRoute
        exact path={`/dashboard/public/ensembles/:id/admin/playlists/:playlistId/edit-playlist`}
        group={group}
        groupChunks={groupChunks}
        setRefresh={setRefresh}
        refresh={refresh}
        component={MemoizedGroupEditPlaylistForm}
      />
      <EnsembleAdminRoute
        exact path={`/dashboard/public/ensembles/:id/admin/playlists/:playlistId/add-playlistchunks`}
        group={group}
        groupChunks={groupChunks}
        setRefresh={setRefresh}
        refresh={refresh}
        component={MemoizedGroupAddPlaylistChunkForm}
      />
      <EnsembleAdminRoute
        exact path={`/dashboard/public/ensembles/:id/admin/add-collection`}
        group={group}
        groupCollections={groupCollections}
        setRefresh={setRefresh}
        refresh={refresh}
        component={MemoizedGroupAddPublicCollectionForm}
      />
      <EnsembleAdminRoute
        exact path={`/dashboard/public/ensembles/:id/admin/add-chunk`}
        group={group}
        groupChunks={groupChunks}
        groupFeaturedChunks={groupFeaturedChunks}
        setRefresh={setRefresh}
        refresh={refresh}
        component={MemoizedGroupAddPublicChunkForm}
      />
      <EnsembleAdminRoute
        exact path={`/dashboard/public/ensembles/:id/admin/add-featuredchunk`}
        group={group}
        groupChunks={groupChunks}
        groupFeaturedChunks={groupFeaturedChunks}
        setRefresh={setRefresh}
        refresh={refresh}
        component={MemoizedGroupAddFeaturedChunkForm}
      />
      <EnsembleAdminRoute
        exact path={`/dashboard/public/ensembles/:id/admin/edit-ensemble`}
        group={group}
        setRefresh={setRefresh}
        refresh={refresh}
        component={MemoizedGroupEditGroupForm}
      />
    </>
  );
};

export default GroupPage;