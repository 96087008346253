import ReactGA from 'react-ga';

// ----------------------------------------------------------------------------------
// ------------------------------------- ANALYTICS ----------------------------------
// ----------------------------------------------------------------------------------

// Accepts a usersID to link them
function init(userID) {
  // Enable debug mode on the local development environment
  const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
  // ReactGA.initialize({ trackingId: process.env.GA_TRACKING_ID }, {
  ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID, {
    debug: isDev, gaOptions: {
      userId: userID
    }
  });
}

// Send a custom event to GA
function sendEvent(payload) {
  ReactGA.event(payload);
}
function sendPageview(path) {
  ReactGA.set({ page: path });
  ReactGA.pageview(path);
}
export default {
  init,
  sendEvent,
  sendPageview,
};