import React from 'react';

// IMAGES
// import { ReactComponent as BlankUser } from '../../img/icon/BlankPublisher.svg';

// ----------------------------------------------------------------------------------
// ------------------------------- GROUP USER CARD ----------------------------------
// ----------------------------------------------------------------------------------

export const GroupUserCard = ({ group, data }) => {
  const {
    user_id,
    email,
    // user_profile_pic,
    first_name,
    last_name
  } = data;

  return (
    <div
      key={user_id}
      className={`hover:bg-mcgreen max-w-359 hover:bg-opacity-25 p-2 rounded-md`}
    >
      <div className={`overflow-hidden`}>
        {/* TOP IMG */}
        <img
          src={group?.banner_img}
          alt='img for a single user'
          className='object-fill w-full h-full min-h-full max-h-40 rounded-t-md'
        />

        {/* color bar */}
        <div className={`relative  w-full  rounded-b-lg bg-collcards`}>
          <div className='flex items-center justify-between px-4 py-2 text-sm text-white'>
            <span>
              {first_name && last_name ? first_name + ' ' + last_name : email}
            </span>
          </div>
        </div>

        {/* IF WE EVER GET PROPER USER PROFILE DATA, THIS COULD BE NICE */}
        {/* <div className='flex items-start pt-2'>
          <div className='flex-shrink-0 pt-1'>
            {user_profile_pic ? (
              <img
                src={user_profile_pic}
                alt='img for a user profile'
                className='inline-block object-fill w-12 h-12 rounded-md'
              />
            ) : (
                <BlankUser className='inline-block object-fill w-12 h-12 rounded-md' />
              )}
          </div>

          <div className='flex-1 w-0 ml-5'>
            <dl>
              <dt className='text-sm font-large leading-5 text-gray-50 truncate-2-lines'>
                {first_name && last_name ? first_name + ' ' + last_name : 'STUDENT NAME'}
              </dt>
              <dd>
                <div className='text-xs font-medium leading-4 truncate text-mcgreen'>
                  {email}
                </div>
              </dd>
            </dl>
          </div>
        </div> */}

      </div>
    </div>
  );
};

export const MemoizedGroupUserCard = React.memo(GroupUserCard);