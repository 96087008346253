import React, { useState } from 'react';
import axios from 'axios';

// ROUTING
import { useHistory, Link } from 'react-router-dom';

// AUTH
import { Auth } from '@aws-amplify/auth';

// FORMS
import { useForm } from "react-hook-form";

// TOAST
import cogoToast from 'cogo-toast';

// ----------------------------------------------------------------------------------
// ------------------------------- CUSTOM CONFIRMATION ------------------------------
// ----------------------------------------------------------------------------------

export default function CustomConfirmation(props) {
  // State
  const { register, handleSubmit } = useForm();
  const [submitting, setSubmitting] = useState(false);
  const groupID = new URLSearchParams(window.location.search).get('group') // Grabs student group ID from url params if exists
  const userEmail = new URLSearchParams(window.location.search).get('email') // Grabs student email from url params if exists

  // React Router
  const history = useHistory();

  // Function to handle confirmation code for account verification
  const onVerificationSubmit = async (data) => {
    setSubmitting(true);

    await Auth.confirmSignUp(userEmail ? userEmail : props.email, data.code)
      .then(() => {
        cogoToast.success('Successfully confirmed your account', {
          hideAfter: 3,
        });
        const user = Auth.signIn(userEmail ? userEmail : props.email, props.password)
          .then((res) => {
            if (user) {
              // Sign them up for Stream if they are part of an ensemble
              axios({
                method: 'get',
                url:
                  process.env.REACT_APP_API + `users/${res.attributes.sub}/streamtoken`,
                headers: {
                  Accept: 'application/json',
                  Authorization: process.env.REACT_APP_HEROKU_SERVER_KEY,
                },
              })
                .then(res => {
                  localStorage.setItem('streamToken', res.data.streamToken)
                  setSubmitting(false);
                  cogoToast.success('Successfully logged in', {
                    hideAfter: 3,
                  })

                  if (userEmail) {
                    history.push(`/dashboard/ensembles/${groupID}`)
                  } else {
                    history.push('/dashboard')
                  }
                })
                .catch(err => console.log(err));
            }
          })
          .catch(err => {
            setSubmitting(false);
            cogoToast.error(err.message + ' Please try logging in again.', {
              hideAfter: 5,
            });
            // setScreen('signin')
            props.changeScreen('signin');
            console.log(err)
          })
      })
      .catch(err => {
        setSubmitting(false);
        cogoToast.error(err.message, {
          hideAfter: 3,
        });
        console.log(err)
      })
  };

  // Function to resend users a new confirmation code
  const resendConfirmationCode = async () => {
    await Auth.resendSignUp(userEmail ? userEmail : props.email)
      .then(() => {
        cogoToast.success(`An email with confirmation code has been sent to ${userEmail ? userEmail : props.email}`, {
          hideAfter: 5,
        });
      })
      .catch(err => {
        cogoToast.error(err.message, {
          hideAfter: 5,
        });
        console.log(err)
      })
  };

  // Prevent Enter key for form submission (causes double posting to DB and Stripe)
  const checkKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  }

  return (
    <form
      className="p-10 bg-mclightgreen rounded-lg"
      onKeyPress={(event) => checkKeyDown(event)}
      onSubmit={handleSubmit(onVerificationSubmit)}
    >
      <h4 className='text-white text-2xl'>Confirm account</h4>
      <div className="form-group">
        <label className='text-white'>Email address</label>
        <input
          name='username'
          type='username'
          placeholder='Enter your email'
          className='form-control w-full flex items-center mb-7 mt-3 p-2 rounded-md text-lg'
          ref={register()}
          value={userEmail ? userEmail : props.email}
          disabled={true}
        />
      </div>
      <div className="form-group">
        <label className='text-white'>Confirmation code</label>
        <input
          name='code'
          type='number'
          placeholder='Enter your code'
          className='form-control w-full flex items-center mb-3 mt-3 p-2 rounded-md text-lg'
          ref={register()}
        />
      </div>

      <div className='mb-9 text-white'>
        <p>Lost your code?
          <span
            className='ml-1 cursor-pointer text-mcgreen hover:text-mcfadedgreen focus:outline-none'
            onClick={() => resendConfirmationCode()}
          >
            Resend Code
          </span>
        </p>
      </div>

      <div className='flex'>
        <div className='mr-20 text-white flex items-center'>
          <p>No account?
            {props.confirmFromSignup ? (
              <span className='ml-1'>
                <Link
                  to='/login'
                  className='text-mcgreen hover:text-mcfadedgreen focus:outline-none'
                >
                  Back to Sign In
                </Link>
              </span>
            ) : (
              <span
                className='ml-1 cursor-pointer text-mcgreen hover:text-mcfadedgreen focus:outline-none'
                onClick={() => props.changeScreen('signin')}
              >
                Back to Sign In
              </span>
            )}
          </p>
        </div>
        <button
          type="submit"
          // disabled={!stripe}
          className={`${submitting && 'opacity-50 pointer-events-none'
            } flex items-center rounded-lg text-lg px-12 py-3 text-center font-medium text-mcgreen bg-mcbuttondarkgreen hover:bg-mcgreen hover:text-mcbuttondarkgreen focus:ring transition duration-150 ease-in-out`}>
          {submitting && (
            <svg
              stroke="currentColor"
              fill="currentColor"
              strokeWidth="0"
              viewBox="0 0 1024 1024"
              className="w-5 h-5 mr-3 animate-spin"
              xmlns="http://www.w3.org/2000/svg">
              <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 0 0-94.3-139.9 437.71 437.71 0 0 0-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
            </svg>
          )}{' '}
          CONFIRM
        </button>
      </div>
    </form>
  );
}