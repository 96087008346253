import React, { useEffect} from 'react';
import { Channel, useChatContext, MessageTeam } from 'stream-chat-react';

// ROUTING
import { useParams } from 'react-router-dom';

// STYLING
import './ChannelContainer.css';

// COMPONENTS
import { ChannelInner, CreateChannel, EditChannel } from '../index';
import { TeamMessageInput } from '../TeamMessageInput/TeamMessageInput';

// ----------------------------------------------------------------------------------
// -------------------------------- CHANNEL CONTAINER -------------------------------
// ----------------------------------------------------------------------------------

const ChannelContainer = ({ isCreating, setIsCreating, isEditing, setIsEditing, createType, cognitoUser, groupAdmins, setToggleChannelContainer }) => {
    const { channel, setActiveChannel } = useChatContext();
    const groupID = useParams();

    // UseEffect that determines whether to reset channel if user swapped ensembles
    useEffect(() => {
        if (groupID.id !== channel?.data?.team) {
            setActiveChannel(null)
        }
    }, [channel]);

    if (isCreating) {
        return (
            <div className='channel__container'>
                <CreateChannel createType={createType} setIsCreating={setIsCreating} />
            </div>
        )
    }

    if (isEditing) {
        return (
            <div className='channel__container'>
                <EditChannel setIsEditing={setIsEditing} />
            </div>
        )
    }

    const onMentionsHover = (event) => {
        const randomColor = Math.floor(Math.random() * 16777215).toString(16);
        event.target.style.color = `#${randomColor}`;
    };

    const EmptyState = () => (
        <div className='channel-empty__container'>
            <p className='channel-empty__first'>This is the beginning of your chat history.</p>
            <p className='channel-empty__second'>Send messages, attachments, links, emojis, and more!</p>
        </div>
    )

    return (
        <div className='channel__container'>
            <Channel
                EmptyStateIndicator={EmptyState}
                Input={TeamMessageInput}
                onMentionsHover={onMentionsHover}
                Message={(messageProps, i) => <MessageTeam key={i} {...messageProps} />}
            >
                <ChannelInner setIsEditing={setIsEditing} cognitoUser={cognitoUser} groupAdmins={groupAdmins} setToggleChannelContainer={setToggleChannelContainer} />
            </Channel>
        </div>
    );
};

export default ChannelContainer;