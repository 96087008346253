import React from 'react';

// STATE
import { useRecoilValue } from 'recoil';
import { cogUserState } from '../state/atoms';

// ROUTING
import { Redirect, Route } from 'react-router-dom';

// ----------------------------------------------------------------------------------
// ----------------------------- ENSEMBLE ADMIN ROUTE -------------------------------
// ----------------------------------------------------------------------------------

const EnsembleAdminRoute = ({ component: Component, ...rest }) => {
  const cognitoUser = useRecoilValue(
    cogUserState(
      localStorage.getItem(
        process.env.REACT_APP_USER_ID,
      ),
    ),
  ); // Get user from our DB

  return (
    <Route
      {...rest}
      render={props => {
        var isAdmin = false

        // Locks out users and admins who do not belong to groups (redirects if they try to force type in url)
        cognitoUser.data.groups.map(userGroup => {

          if (userGroup.id === props.match?.params?.id && userGroup.isAdmin === false) {
            isAdmin = false;
          } else if (userGroup.id === props.match?.params?.id && userGroup.isAdmin === true) {
            isAdmin = true;
          }
        })

        if (isAdmin) {
          return <Component {...props} {...rest} />;
        } else {
          return <Redirect to="/dashboard" />;
        }
      }}
    />
  );
};

export default EnsembleAdminRoute;
