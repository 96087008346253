import React from 'react';

// STYLING
import './TeamChannelList.css';

// ICONS
import { AddChannel } from '../../assets/AddChannel.js';
import { ReactComponent as HowToIcon } from '../../../../img/icon/HowTo.svg';

// ----------------------------------------------------------------------------------
// -------------------------------- TEAM CHANNEL LIST -------------------------------
// ----------------------------------------------------------------------------------

const TeamChannelList = ({ children, error = false, loading, type, isCreating, setIsCreating, setCreateType, setIsEditing, setToggleContainer, setToggleChannelContainer, groupAdmins, cognitoUser }) => {
    const isAdmin = groupAdmins.filter(groupAdmin => groupAdmin.id === cognitoUser.data.id).length > 0 ? true : false

    // if (error && !loading) {
    //     return type === 'team' ? (
    //         <div className='team-channel-list'>
    //             <p className='team-channel-list__message'>
    //                 Connection error, please wait a moment and try again.
    //             </p>
    //         </div>
    //     ) : null;
    // }

    if (loading) {
        return (
            <div className='team-channel-list'>
                <p className='team-channel-list__message loading'>
                    {type === 'team' ? 'Channels' : 'Messages'} loading...
                </p>
            </div>
        )
    }
    return (
        <div className="team-channel-list">
            <div className="team-channel-list__header">
                <p className="team-channel-list__header__title">
                    {type === 'team' ? 'Channels' : 'Direct Messages'}
                </p>
                {isAdmin ? (
                    <div className='flex'>
                        <HowToIcon
                            className='mr-3 h-3.5 w-3.5'
                            color='#AFECEF'
                            title='Music Chunks Channels is a communication tool for directors and students to share lessons and information within our platform. 
                            Directors can create channels and add specific students to each channel. Students will only see the channels they are invited to while 
                            all directors or admins can see all channels in the ensemble. The directors/admins post lessons or information in the main channel, while 
                            students can only respond in the side threads of each post.'
                        />
                        <AddChannel
                            isCreating={isCreating}
                            setIsCreating={setIsCreating}
                            setCreateType={setCreateType}
                            setIsEditing={setIsEditing}
                            type={type === 'team' ? 'team' : 'messaging'}
                            setToggleContainer={setToggleContainer}
                            setToggleChannelContainer={setToggleChannelContainer}
                        />
                    </div>
                ) : null}
            </div>
            <div className='text-center text-gray-300' onClick={() => setToggleChannelContainer ? setToggleChannelContainer(false) : null}>
                {children}
            </div>
        </div>
    );
};

export default TeamChannelList;