import React, { useState, useEffect } from 'react';
import axios from 'axios';
import axiosRetry from 'axios-retry';

// ROUTING
import { Route, Link, useHistory } from 'react-router-dom';

// COMPONENTS
import PublisherAdminData from './PublisherAdminData';
import PublisherAdminFeaturedChunkData from './PublisherAdminFeaturedChunkData';
import PublisherAdminFormData from './PublisherAdminFormData';

// ----------------------------------------------------------------------------------
// -------------------------------- PUBLISHERS TAB ----------------------------------
// ----------------------------------------------------------------------------------

export const PublishersTab = ({ cognitoUser }) => {
  //State
  const history = useHistory();
  const [userPublishers, setUserPublishers] = useState();
  const [refresh, setRefresh] = useState();
  const { id } = cognitoUser.data;
  const [open, setOpen] = useState({});
  const [closed, setClosed] = useState({});
  const [mobileTabToggle, setMobileTabToggle] = useState();
  const url = window.location.href; // GRABS REFERENCE TO THE CURRENT URL TO CHECK WHICH PLAN DATA TO LOAD

  // Set axios to retry a set number of times on failed request/return
  axiosRetry(axios, { retries: 3 })

  // Get User Publisher data from db
  useEffect(() => {
    axios
      ({
        method: 'get',
        url: process.env.REACT_APP_API + `users/${id}/publisheradmins`,
        headers: {
          Accept: 'application/json',
          Authorization: process.env.REACT_APP_HEROKU_SERVER_KEY
        }
      })
      .then((res) => {
        setUserPublishers(res.data)
      })
      .catch((err) => {
        console.log(err);
      });
  }, [refresh])

  // Set open and close parameters for hiding form fields depending on Publisher selected
  useEffect(() => {
    userPublishers?.map(userPub => {
      setOpen(prev => Boolean(!prev[userPub.id]) ? { ...prev, [userPub.id]: false } : { ...prev, [userPub.id]: true });
      setClosed(prev => Boolean(!prev[userPub.id]) ? { ...prev, [userPub.id]: true } : { ...prev, [userPub.id]: false });
    })
  }, [userPublishers])

  // UseEffect for swapping routes based on mobile dropdown menu selection
  useEffect(() => {
    var selectBox = document.getElementById('selected-tab')
    var selectedValue = selectBox.options[selectBox.selectedIndex].value;

    if (selectedValue == 1) {
      history.push('/my-account/publishers/general')
    }
    if (selectedValue == 2) {
      history.push('/my-account/publishers/featured-chunks')
    }
    if (selectedValue == 3) {
      history.push('/my-account/publishers/forms')
    }
  }, [mobileTabToggle])

  return (
    <main class="flex-1 overflow-y-auto focus:outline-none" tabindex="0">
      <div class="relative max-w-4xl mx-auto md:px-8 xl:px-0">
        <div class="pt-10 pb-16">
          <div class="px-4 sm:px-6 md:px-0">
            <h1 class="text-3xl font-extrabold text-gray-900">Publishers</h1>
          </div>
          <div class="px-4 sm:px-6 md:px-0">
            <div class="py-6">
              {/* <!-- Tabs --> */}
              <div class="lg:hidden">
                <label for="selected-tab" class="sr-only">Select a tab</label>
                <select id="selected-tab" name="selected-tab" onChange={() => setMobileTabToggle(!mobileTabToggle)} class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-purple-500 focus:border-purple-500 sm:text-sm rounded-md">
                  <option
                    selected={url.includes('general') ? true : false}
                    value={1}
                  >
                    General
                  </option>
                  <option
                    selected={url.includes('featured-chunks') ? true : false}
                    value={2}
                  >
                    Featured Chunks
                  </option>
                  <option
                    selected={url.includes('forms') ? true : false}
                    value="3"
                  >
                    Forms
                  </option>
                </select>
              </div>
              <div class="hidden lg:block">
                <div class="border-b border-gray-200">
                  <nav class="-mb-px flex space-x-8">
                    {/* <!-- Current: "border-purple-500 text-purple-600", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" --> */}
                    <Link
                      to="/my-account/publishers/general"
                      class={url.includes('general') ?
                        "border-purple-500 text-purple-600 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm" :
                        "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"}
                    >
                      General
                    </Link>
                    <Link
                      to="/my-account/publishers/featured-chunks"
                      class={url.includes('featured-chunks') ?
                        "border-purple-500 text-purple-600 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm" :
                        "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"}
                    >
                      Featured Chunks
                    </Link>
                    <Link
                      to="/my-account/publishers/forms"
                      class={url.includes('forms') ?
                        "border-purple-500 text-purple-600 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm" :
                        "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"}
                    >
                      Forms
                    </Link>
                  </nav>
                </div>
              </div>

              {/* ROUTES */}
              <Route
                exact path={`/my-account/publishers/general`}
                render={(props) => (
                  userPublishers?.length > 0 ? (
                    userPublishers?.map(data => (
                      <PublisherAdminData
                        key={data.id}
                        data={data}
                        refresh={refresh}
                        setRefresh={setRefresh}
                        userPublishers={userPublishers}
                        open={open}
                        setOpen={setOpen}
                        closed={closed}
                        setClosed={setClosed}
                        {...props}
                      />
                    ))
                  ) : (
                    <div class="mt-10 divide-y divide-gray-200">
                      <div class="space-y-1 flex justify-between">
                        <h3 class="text-lg self-center leading-6 font-medium text-gray-900">
                          You are not an admin of any Publishers
                        </h3>
                      </div>
                    </div>
                  ))}
              />

              <Route
                exact path={`/my-account/publishers/featured-chunks`}
                render={(props) => (
                  userPublishers?.length > 0 ? (

                    userPublishers?.map(data => (
                      <PublisherAdminFeaturedChunkData
                        key={data.id}
                        data={data}
                        refresh={refresh}
                        setRefresh={setRefresh}
                        userPublishers={userPublishers}
                        open={open}
                        setOpen={setOpen}
                        closed={closed}
                        setClosed={setClosed}
                        {...props}
                      />
                    ))
                  ) : (
                    <div class="mt-10 divide-y divide-gray-200">
                      <div class="space-y-1 flex justify-between">
                        <h3 class="text-lg self-center leading-6 font-medium text-gray-900">
                          You are not an admin of any Publishers
                          </h3>
                      </div>
                    </div>
                  ))}
              />

              <Route
                exact path={`/my-account/publishers/forms`}
                render={(props) => (
                  userPublishers?.length > 0 ? (
                    <PublisherAdminFormData
                      {...props}
                    />
                  ) : (
                    <div class="mt-10 divide-y divide-gray-200">
                      <div class="space-y-1 flex justify-between">
                        <h3 class="text-lg self-center leading-6 font-medium text-gray-900">
                          You are not an admin of any Publishers
                          </h3>
                      </div>
                    </div>
                  ))}
              />
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default PublishersTab;