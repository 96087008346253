import React from 'react';

// ROUTING
import { useRouteMatch } from 'react-router-dom';

// STATE
import { useRecoilValue } from 'recoil';
import { adminSingleChunkFilter } from '../../../state/atoms';

// COMPONENTS
import ChunkData from './ChunkData'

// ----------------------------------------------------------------------------------
// ------------------------------ SINGLE CHUNKS TABLE -------------------------------
// ----------------------------------------------------------------------------------

const ChunkTable = () => {
  // State
  const route = useRouteMatch();
  const chunks = useRecoilValue(
    adminSingleChunkFilter(route.params?.pubID)
  );

  return (
    <div className='py-4 '>
      {chunks.map((data) => (
        <ChunkData key={data.name} data={data} />
      ))}
    </div>
  );
};

export default ChunkTable;
