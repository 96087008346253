import React, { useState } from 'react';
import axios from 'axios';
import axiosRetry from 'axios-retry';

// TAILWIND TRANSITION
import { Transition } from '@tailwindui/react';

// FORM MANAGEMENT
import { useForm } from 'react-hook-form';

// ROUTING
import { useHistory } from 'react-router-dom';

// STATE
import { useSetRecoilState } from 'recoil';
import { userData } from '../../../state/atoms';

// ----------------------------------------------------------------------------------
// ----------------------------------- USER DATA ------------------------------------
// ----------------------------------------------------------------------------------

const UserData = ({ data }) => {
  const {
    id,
    email,
    stripe_id,
    role,
    cognito_id,
    mailchimp_id
  } = data;

  // State
  const history = useHistory();
  const users = useSetRecoilState(userData);
  const [openOptions, setOpenOptions] = useState(false);
  const [openDetails, setOpenDetails] = useState(false);
  const [openWarning, setOpenWarning] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  // Set axios to retry a set number of times on failed request/return
  axiosRetry(axios, { retries: 3 })

  // Form
  const { register, handleSubmit, errors, reset } = useForm({
    mode: 'onChange',
  });

  // Function to handle deleting a user
  const handleDelete = (id) => {
    axios
      ({
        method: 'delete',
        url: process.env.REACT_APP_API + `users/${id}`,
        headers: {
          Accept: 'application/json',
          Authorization: process.env.REACT_APP_HEROKU_SERVER_KEY
        }
      })
      .then((res) => {
        users((oldList) => {
          const newList = oldList.filter((i) => {
            return i.id !== id;
          });
          return newList;
        });
      })
      .catch((err) => {
        console.log(err);
        alert('something happened, item not deleted');
      });
  };

  // Function that handles submitting updates/edits for user data
  const onSubmit = (data) => {
    setIsEditing(false);
    axios
      ({
        method: 'put',
        url: process.env.REACT_APP_API + `users/${id}`,
        headers: {
          Accept: 'application/json',
          Authorization: process.env.REACT_APP_HEROKU_SERVER_KEY
        }, data: {
          ...data
        }
      })
      .then((res) => {
        users((oldList) => {
          const newList = oldList.map((i) =>
            i.id === id ? res.data.updatedUser : i
          );
          return newList;
        });
        history.push('/admin/users');
      })
      .catch((err) => {
        console.log(err);
        alert('something went wrong');
      });
  };

  return (
    <div className='overflow-hidden shadow bg-gray-50'>
      <div className='flex justify-between px-4 py-5 border-b border-gray-200 sm:px-6'>
        {isEditing ? (
          <input
            id='email'
            type='text'
            name='email'
            placeholder='example@gmail.com'
            defaultValue={email}
            ref={register({ required: true })}
            className='block w-1/3 px-3 py-2 mt-1 transition duration-150 ease-in-out border border-gray-300 rounded-md shadow-sm form-input focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5'
          />
        ) : (
            <h3 className='text-lg font-medium leading-6 text-gray-900'>
              {email}
            </h3>
          )}
        {errors.email && (
          <span className='text-red-500'>This field is required</span>
        )}

        <div className='flex-shrink-0 pr-2'>
          {openDetails ? (
            <button
              onClick={() => setOpenDetails(!openDetails)}
              id='menu dropdown '
              aria-haspopup='true'
              className='inline-flex items-center justify-center w-8 h-8 text-gray-400 transition duration-150 ease-in-out bg-transparent rounded-full hover:text-gray-500 focus:outline-none focus:text-gray-500 focus:bg-gray-100'
            >
              X
            </button>
          ) : (
              <button
                onClick={() => setOpenOptions(!openOptions)}
                id='menu dropdown '
                aria-haspopup='true'
                className='inline-flex items-center justify-center w-8 h-8 text-gray-400 transition duration-150 ease-in-out bg-transparent rounded-full hover:text-gray-500 focus:outline-none focus:text-gray-500 focus:bg-gray-100'
              >
                {/* <!-- Heroicon name: dots-vertical --> */}
                <svg
                  className='w-5 h-5'
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 20 20'
                  fill='currentColor'
                >
                  <path d='M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z' />
                </svg>
              </button>
            )}
          {/* <!--
                  Dropdown panel, show/hide based on dropdown state.

                  Entering: "transition ease-out duration-100"
                    From: "transform opacity-0 scale-95"
                    To: "transform opacity-100 scale-100"
                  Leaving: "transition ease-in duration-75"
                    From: "transform opacity-100 scale-100"
                    To: "transform opacity-0 scale-95"
                --> */}
          <Transition show={openOptions}>
            <div className='absolute z-10 w-48 mx-3 mt-1 origin-top-right rounded-md shadow-lg right-2'>
              <div
                className='bg-white rounded-md shadow-xs'
                role='menu'
                aria-orientation='vertical'
                aria-labelledby='pinned-project-options-menu-0'
              >
                <div className='relative z-40 py-1'>
                  <button
                    onClick={() => {
                      setOpenDetails(!openDetails);
                      setOpenOptions(!openOptions);
                    }}
                    id='pinned-project-options-menu-0'
                    aria-haspopup='true'
                    className='block w-full px-4 py-2 text-sm leading-5 text-left text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900'
                    role='menuitem'
                  >
                    View
                  </button>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>

      <Transition show={openDetails}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='px-4 py-5 sm:px-6'>
            <dl className='grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3'>

              <div className='sm:col-span-1'>
                <dt className='text-sm font-medium leading-5 text-gray-500'>
                  Stripe ID
                </dt>
                {isEditing ? (
                  <input
                    id='stripe_id'
                    type='text'
                    name='stripe_id'
                    defaultValue={stripe_id}
                    ref={register}
                    className='block w-full px-3 py-2 mt-1 transition duration-150 ease-in-out border border-gray-300 rounded-md shadow-sm form-input focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5'
                  />
                ) : (
                    <dd className='mt-1 text-sm leading-5 text-gray-900'>
                      {stripe_id}
                    </dd>
                  )}
              </div>

              <div className='sm:col-span-1'>
                <dt className='text-sm font-medium leading-5 text-gray-500'>
                  Cognito ID
                </dt>
                {isEditing ? (
                  <input
                    id='cognito_id'
                    type='text'
                    name='cognito_id'
                    defaultValue={cognito_id}
                    ref={register}
                    className='block w-full px-3 py-2 mt-1 transition duration-150 ease-in-out border border-gray-300 rounded-md shadow-sm form-input focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5'
                  />
                ) : (
                    <dd className='mt-1 text-sm leading-5 text-gray-900'>
                      {cognito_id}
                    </dd>
                  )}
              </div>

              <div className='sm:col-span-1'>
                <dt className='text-sm font-medium leading-5 text-gray-500'>
                  Mailchimp ID
                </dt>
                {isEditing ? (
                  <input
                    id='mailchimp_id'
                    type='text'
                    name='mailchimp_id'
                    defaultValue={mailchimp_id}
                    ref={register}
                    className='block w-full px-3 py-2 mt-1 transition duration-150 ease-in-out border border-gray-300 rounded-md shadow-sm form-input focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5'
                  />
                ) : (
                    <dd className='mt-1 text-sm leading-5 text-gray-900'>
                      {mailchimp_id}
                    </dd>
                  )}
              </div>

              <div className='sm:col-span-1'>
                <dt className='text-sm font-medium leading-5 text-gray-500'>
                  Payment Plan
                </dt>
                {isEditing ? (
                  <input
                    id='role'
                    type='text'
                    name='role'
                    defaultValue={role}
                    ref={register}
                    className='block w-full px-3 py-2 mt-1 transition duration-150 ease-in-out border border-gray-300 rounded-md shadow-sm form-input focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5'
                  />
                ) : (
                    <dd className='mt-1 text-sm leading-5 text-gray-900'>
                      {role}
                    </dd>
                  )}
              </div>

            </dl>
          </div>

          {/* SUBMIT EDITS OR DELETE */}
          {isEditing && (
            <div className='flex justify-end pb-4 pr-12'>
              {' '}
              <button
                type='submit'
                className=' mr-4 inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs leading-4 font-medium rounded text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150'
              >
                Save
              </button>
              <button
                onClick={() => {
                  setIsEditing(!isEditing);
                  reset();
                }}
                type='reset'
                className='inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs leading-4 font-medium rounded text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150'
              >
                Cancel
              </button>
            </div>
          )}
        </form>

        {!isEditing && (
          <div className='flex justify-end pb-4 pr-12'>
            {/* <button
              type='button'
              onClick={() => {
                setIsEditing(!isEditing);
              }}
              className=' mr-4 inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs leading-4 font-medium rounded text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150'
            >
              Edit
            </button>

            <button
              onClick={() => {
                setOpenWarning(!openWarning)
              }}
              type='reset'
              className='inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs leading-4 font-medium rounded text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150'
            >
              DELETE
            </button> */}

            <Transition show={openWarning}>
              <div class="fixed z-10 inset-0 overflow-y-auto">
                <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                  {/* <!--
                    Background overlay, show/hide based on modal state.
                    Entering: "ease-out duration-300"
                      From: "opacity-0"
                      To: "opacity-100"
                    Leaving: "ease-in duration-200"
                      From: "opacity-100"
                      To: "opacity-0"
                  --> */}
                  <div class="fixed inset-0 transition-opacity">
                    <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
                  </div>
                  {/* <!-- This element is to trick the browser into centering the modal contents. --> */}
                  <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;
                    {/* <!--
                      Modal panel, show/hide based on modal state.
                      Entering: "ease-out duration-300"
                        From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        To: "opacity-100 translate-y-0 sm:scale-100"
                      Leaving: "ease-in duration-200"
                        From: "opacity-100 translate-y-0 sm:scale-100"
                        To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    --> */}
                  <div class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                    <div class="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                      <button onClick={() => {
                        setOpenWarning(!openWarning)
                      }}
                        type="button" class="text-gray-400 hover:text-gray-500 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150" aria-label="Close">
                        {/* <!-- Heroicon name: x --> */}
                        <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                      </button>
                    </div>
                    <div class="sm:flex sm:items-start">
                      <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                        {/* <!-- Heroicon name: exclamation --> */}
                        <svg class="h-6 w-6 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                        </svg>
                      </div>
                      <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                        <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
                          Delete User
                        </h3>
                        <div class="mt-2">
                          <p class="text-sm leading-5 text-gray-500">
                            Are you sure you want to delete this user? This user and all its data will be removed from our servers forever. This action cannot be undone.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                      <span class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
                        <button onClick={() => {
                          handleDelete(id);
                        }}
                          type="button"
                          class="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-red-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                        >
                          Delete
                        </button>
                      </span>
                      <span class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
                        <button onClick={() => {
                          setOpenWarning(!openWarning)
                        }} type="button" class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                          Cancel
                        </button>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </Transition>

          </div>
        )}
      </Transition>
    </div>
  );
};

export default UserData;
