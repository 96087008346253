import React from 'react';

// STATE
import { useRecoilValue } from 'recoil';
import { collectionState } from '../../state/atoms';
import { MemoizedCollectionCard } from './CollectionCard'

// ROUTING
import { Link } from 'react-router-dom';

// ----------------------------------------------------------------------------------
// -------------------------------- Collection List ---------------------------------
// ----------------------------------------------------------------------------------

const CollectionList = ({ match, handleClearSearchBar, cognitoUser }) => {
  // State
  const collections = useRecoilValue(collectionState);

  return (
    <>
      <div className='grid gap-10 mt-2 grid-cols-1 sm:grid-cols-2 md:grid-cols-2 xl:grid-cols-4 2xl:px-15 '>
        {collections.map((data) => (
          <Link
            to={`${match.url}/${data.id}`}
            onClick={() => {
              handleClearSearchBar();
            }}
          >
            <MemoizedCollectionCard key={data.id} data={data} cognitoUser={cognitoUser} />
          </Link>
        ))}
      </div>
    </>
  );

};

export default CollectionList;