import React from 'react';

// ----------------------------------------------------------------------------------
// ----------------------------- PUBLISHER ABOUT CARD -------------------------------
// ----------------------------------------------------------------------------------

const PublisherAboutCard = ({ publisher }) => {
  return (
    <>
      <div className='bg-green-700 px-5 py-3 mt-2 rounded-lg'>
        <p className='whitespace-pre-wrap text-white mb-5'>
          {publisher?.bio}
        </p>
        <a
          className='text-blue-400 flex-shrink-0 hover:text-mcgreen focus:outline-none'
          href={`${publisher?.publisher_URL}`}
          target='_blank'
          rel='noopener noreferrer'
        >
          Check out their website
        </a>
      </div>
    </>
  );
};

export const MemoizedPublisherAboutCard = React.memo(PublisherAboutCard);