import React, { useEffect, useState, useMemo } from 'react';
import axios from 'axios';
import axiosRetry from 'axios-retry';

// REACT TABLE
import { useTable } from 'react-table'
import { CSVLink } from 'react-csv'

// DATE
import moment from 'moment';
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

// ----------------------------------------------------------------------------------
// ------------------------------- MC ENSEMBLE REPORT  ------------------------------
// ----------------------------------------------------------------------------------

const MC_Ensemble_Report = () => {
  // State
  const [data, setData] = useState([]);  // HOLDS AN OBJECT CONTAINING ALL GROUP USERS WITH DYNAMIC DATA (MUST BE LABELED 'data')
  const [month, setMonth] = useState(new Date(new Date().getFullYear(), new Date().getMonth(), 1)); // HOLDS THE CURRENT MONTH FOR QUERYING GROUP USERS
  const [fileName, setFileName] = useState(`MC_ENSEMBLE_MusicChunks_allUsers_${moment(month).format("YYYY-MM-DD")}_${Date.now()}.csv`) // HOLDS THE FILE NAME DEPENDING ON WHICH USAGE FORM IS BEING RENDERED
  const currentMonth = new Date(new Date().getFullYear(), new Date().getMonth(), 1)

  // Set axios to retry a set number of times on failed request/return
  axiosRetry(axios, { retries: 3 })

  // UseEffect to grab every group's users
  useEffect(() => {
    // If we are looking at the current month in progress, set data to a different endpoint for ongoing data
    if (moment(month).format('MM-YYYY') === moment(currentMonth).format('MM-YYYY')) {
      axios({
        method: 'get',
        url: process.env.REACT_APP_API + `groupuserrecords/record/currentmonth`,
        headers: {
          Accept: 'application/json',
          Authorization: process.env.REACT_APP_HEROKU_SERVER_KEY,
        }
      })
        .then((res) => {
          // Filter the data by the selected month and year
          setData(res.data)
        })
        .catch((err) => {
          console.log(err)
        })
    } else {
      // Filter the data by the selected month and year from our backlog of records
      axios({
        method: 'get',
        url: process.env.REACT_APP_API + `groupuserrecords`,
        headers: {
          Accept: 'application/json',
          Authorization: process.env.REACT_APP_HEROKU_SERVER_KEY,
        }
      })
        .then((res) => {
          setData(res.data.filter(record => record.record_date === moment(month).format('MM-YYYY')))
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }, [month])

  // Column headers and accessors linking to keys in data
  const columns = useMemo(
    () => [
      {
        Header: 'Report ID',
        accessor: d => d.id // accessor is the "key" in the data
      },
      {
        Header: 'Publisher Name',
        accessor: d => d.publisher_name
      },
      {
        Header: 'Record Date',
        accessor: d => d.record_date
      },
      {
        Header: 'Total Users',
        accessor: d => d.total_unique_publisher_users
      },
      {
        Header: 'User Emails',
        accessor: d => d.email_array.join(', ')
      }
    ],
    []
  )

  // Table structure
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable({ columns, data })

  // Converts our db data to the correct format for form submission
  const currentData = useTable({ columns, data })
  const reportData = []
  currentData.rows.map(row => {
    reportData.push(row.values)
  })

  return (
    <>
      <div className='mb-3 mt-3 text-center'>
        <dt className='text-sm text-center font-medium leading-5 text-gray-500'>
          Usage Month
          </dt>
        <DatePicker
          className='block w-full mt-2 transition duration-150 ease-in-out form-textarea sm:text-sm sm:leading-5'
          selected={month}
          onChange={date => {
            setMonth(date)
          }}
          selectsStart
          month={month}
          dateFormat='MM/yyyy'
          showMonthYearPicker
        />
      </div>
      <div className='mb-3 text-center'>
        <CSVLink
          className='inline-flex items-center px-4 py-2 text-sm font-medium leading-5 text-white transition duration-150 ease-in-out bg-purple-600 border border-transparent rounded-md hover:bg-purple-500 focus:outline-none focus:shadow-outline-purple focus:border-purple-700 active:bg-indigo-700'
          columns={columns}
          data={reportData}
          filename={fileName}
        >
          Export Report
      </CSVLink>
      </div>

      {/* REACT TABLE */}
      <table {...getTableProps()} style={{ border: 'solid 1px blue' }}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th
                  {...column.getHeaderProps()}
                  style={{
                    borderBottom: 'solid 3px red',
                    background: 'aliceblue',
                    color: 'black',
                    fontWeight: 'bold',
                  }}
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return (
                    <td
                      {...cell.getCellProps()}
                      style={{
                        padding: '10px',
                        border: 'solid 1px gray',
                        background: 'papayawhip',
                      }}
                    >
                      {cell.render('Cell')}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </>
  )
}

export default MC_Ensemble_Report;