import React from 'react';

// ROUTER
import { Link } from 'react-router-dom';

// IMGS
import { ReactComponent as MCLOGO } from '../../img/MCLOGONEW.svg';
import logoNoText from '../../img/logoNoText.png';

// ----------------------------------------------------------------------------------
// ------------------------------------ FAQPAGE -------------------------------------
// ----------------------------------------------------------------------------------

export const FAQEnsemble = () => {
  return (
    <div class='relative bg-hero-pattern bg-fixed bg-cover overflow-hidden'>
      <div className='p-8'>
        <Link
          to='/'
          className='inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-mcgreen bg-mcbuttondarkgreen hover:bg-mcbuttonlightgreen focus:outline-none focus:border-mcdarkgreen focus:shadow-outline-teal active:bg-mcdarkgreen transition ease-in-out duration-150'
        >
          {/* <!-- Heroicon name: back --> */}
          <svg
            stroke='currentColor'
            fill='currentColor'
            className='mr-2'
            stroke-width='0'
            viewBox='0 0 512 512'
            height='1em'
            width='1em'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path d='M401.4 224h-214l83-79.4c11.9-12.5 11.9-32.7 0-45.2s-31.2-12.5-43.2 0L89 233.4c-6 5.8-9 13.7-9 22.4v.4c0 8.7 3 16.6 9 22.4l138.1 134c12 12.5 31.3 12.5 43.2 0 11.9-12.5 11.9-32.7 0-45.2l-83-79.4h214c16.9 0 30.6-14.3 30.6-32 .1-18-13.6-32-30.5-32z'></path>
          </svg>
          Go back
        </Link>
      </div>

      {/* FAQ BODY */}
      <div className='max-w-screen-xl mx-auto pb-5 px-20'>
        <div className='flex flex-col justify-center px-4 py-5 sm:p-6'>
          <MCLOGO className='object-center w-auto h-40 mb-12' />
          <h1 className='text-center text-gray-50 text-5xl mb-12'>
            ENSEMBLE DISCOUNT
          </h1>
          <a
            href='https://forms.monday.com/forms/5f11f8fc03d9eadd954db240e52ed0df'
            className={'sm:mx-0 md:mx-28 lg:mx-40 rounded-lg text-2xl mb-5 px-5 py-3 text-center font-medium text-mcbuttondarkgreen bg-mcgreen hover:bg-mcbuttondarkgreen hover:text-mcgreen focus:ring transition duration-150 ease-in-out'}
            target='_blank'
          >
            Click Here to Sign Up!
          </a>
          <h2 className='text-center font-small text-mcgreen text-4xl'>
            FAQ
          </h2>
        </div>

        <div>
          <dl>
            <div>
              <dt className='mb-2 text-base text-xl leading-6 font-medium text-mcgreen md:col-span-5'>
                What is an ensemble discount?
              </dt>
              <dd className='mt-2 md:mt-0 md:col-span-7'>
                <p className='text-base leading-6 text-gray-50'>
                  An ensemble discount allows schools and other independent performance organizations to sign up
                  their entire organization at a discounted rate. Your students, performers, and staff will receive
                  full access to all of MusicChunks.com's standard features along with access to the music programs
                  private ensemble page.
                </p>
              </dd>
            </div>

            <div>
              <dt className='mb-2 mt-5 text-base text-xl leading-6 font-medium text-mcgreen md:col-span-5'>
                Is there a minimum or maximum number of users per account?
              </dt>
              <dd className='mt-2 md:mt-0 md:col-span-7'>
                <p className='text-base leading-6 text-gray-50'>
                  There is no limit to the number users you can add. We require a minimum of 10 users to qualify for an
                  ensemble discount.
                </p>
              </dd>
            </div>

            <div>
              <dt className='mb-2 mt-5 text-base text-xl leading-6 font-medium text-mcgreen md:col-span-5'>
                What does it cost?
              </dt>
              <dd className='mt-2 md:mt-0 md:col-span-7'>
                <p className='text-base leading-6 text-gray-50'>
                  With an ensemble discount, we charge $2.99 a month or $29.99 a year per student.
                </p>
              </dd>
            </div>

            <div>
              <dt className='mb-2 mt-5 text-base text-xl leading-6 font-medium text-mcgreen md:col-span-5'>
                How long does the subscription last?
              </dt>
              <dd className='mt-2 md:mt-0 md:col-span-7'>
                <p className='text-base leading-6 text-gray-50'>
                  We require a minimum 3 month subscription. You can add as many months as you would like!
                </p>
              </dd>
            </div>

            <div>
              <dt className='mb-2 mt-5 text-base text-xl leading-6 font-medium text-mcgreen md:col-span-5'>
                What payment methods do you accept?
              </dt>
              <dd className='mt-2 md:mt-0 md:col-span-7'>
                <p className='text-base leading-6 text-gray-50'>
                  We accept Credit Cards and Checks.
                </p>
              </dd>
            </div>

            <div>
              <dt className='mb-2 mt-5 text-base text-xl leading-6 font-medium text-mcgreen md:col-span-5'>
                Do you accept purchase orders?
              </dt>
              <dd className='mt-2 md:mt-0 md:col-span-7'>
                <p className='text-base leading-6 text-gray-50'>
                  Yes! Please submit all POs and document requests to <a href="mailto:ensembles@musicchunks.com" class="text-white font-bold underline">ensembles@musicchunks.com</a>
                </p>
              </dd>
            </div>

            <div>
              <dt className='mb-2 mt-5 text-base text-xl leading-6 font-medium text-mcgreen md:col-span-5'>
                How will we receive our login information?
              </dt>
              <dd className='mt-2 md:mt-0 md:col-span-7'>
                <p className='text-base leading-6 text-gray-50'>
                  Once we have received your payment or purchase order, we will invite you to your private
                  platform. You will have total control of who is added or deleted to your schools ensembles
                  within the admin panel.
                </p>
              </dd>
            </div>

            <div>
              <dt className='mb-2 mt-5 text-base text-xl leading-6 font-medium text-mcgreen md:col-span-5'>
                Can I add users after the subscription has started?
              </dt>
              <dd className='mt-2 md:mt-0 md:col-span-7'>
                <p className='text-base leading-6 text-gray-50'>
                  Yes! Just let us know and we can bill that separately if you have passed your 5% cushion we
                  offer for free.
                </p>
                <p className='text-base leading-6 text-gray-50 mt-3'>
                  If you have a student leave your organization, we can transfer the remaining balance of the
                  subscription to a new student.
                </p>
              </dd>
            </div>

            <div>
              <dt className='mb-2 mt-5 text-base text-xl leading-6 font-medium text-mcgreen md:col-span-5'>
                Can I use music from MusicChunks for performance with my ensemble?
              </dt>
              <dd className='mt-2 md:mt-0 md:col-span-7'>
                <p className='text-base leading-6 text-gray-50'>
                  No. You will need to contact the publisher of the music to attain all performance and sync rights.
                </p>
              </dd>
            </div>

            <div>
              <dt className='mb-2 mt-5 text-base text-xl leading-6 font-medium text-mcgreen md:col-span-5'>
                Can our ensemble put music on MusicChunks to practice?
              </dt>
              <dd className='mt-2 md:mt-0 md:col-span-7'>
                <p className='text-base leading-6 text-gray-50'>
                  Yes! Send us your music or video lesson through your admin portal and we will get it synced
                  up for you!
                </p>
              </dd>
            </div>

            <div>
              <dt className='mb-2 mt-5 text-base text-xl leading-6 font-medium text-mcgreen md:col-span-5'>
                Is there a print option?
              </dt>
              <dd className='mt-2 md:mt-0 md:col-span-7'>
                <p className='text-base leading-6 text-gray-50'>
                  Yes and no. On all of our fundamentals MusicChunks Band Basics are set for download and use in
                  class! You can also print music you load up for your private ensemble. However, you CAN NOT print
                  other artist's music from the site unless they have ok'd it. You will see the print function in
                  your tool bar if the option is available.
                </p>
              </dd>
            </div>

            <div>
              <dt className='mb-2 mt-5 text-base text-xl leading-6 font-medium text-mcgreen md:col-span-5'>
                Can I communicate and assign lessons with my students through MusicChunks.com?
              </dt>
              <dd className='mt-2 md:mt-0 md:col-span-7'>
                <p className='text-base leading-6 text-gray-50'>
                  No, coming soon!
                </p>
              </dd>
            </div>

          </dl>
        </div>

        {/* SIGN UP BUTTON */}
        <div className={'flex flex-col justify-center sm:mx-0 md:mx-28 lg:mx-40 px-4 py-5 sm:p-6'}>
          <a
            href='https://forms.monday.com/forms/5f11f8fc03d9eadd954db240e52ed0df'
            className={'rounded-lg text-2xl mb-5 px-5 py-3 text-center font-medium text-mcbuttondarkgreen bg-mcgreen hover:bg-mcbuttondarkgreen hover:text-mcgreen focus:ring transition duration-150 ease-in-out'}
            target='_blank'
          >
            Click Here to Sign Up!
          </a>
        </div>
      </div>
    </div>
  );
};
