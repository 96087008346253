import React from 'react';

// ROUTING
import { useRouteMatch } from 'react-router-dom';

// STATE
import { useRecoilValue } from 'recoil';
import { adminCollectionFilter } from '../../../state/atoms';

// COMPONENTS
import CollectionData from './CollectionData';

// ----------------------------------------------------------------------------------
// ------------------------------- COLLECTION TABLE ---------------------------------
// ----------------------------------------------------------------------------------

const CollectionTable = () => {
  // State
  const route = useRouteMatch();
  const collections = useRecoilValue(
    adminCollectionFilter(route.params?.pubID)
  );

  return (
    <div className='py-4 '>
      {collections.map((data) => (
        <CollectionData key={data.name} data={data} />
      ))}
    </div>
  );
};

export default CollectionTable;
