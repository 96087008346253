import React from 'react';

// ROUTING
import { useRouteMatch } from 'react-router-dom';

// STATE
import { useRecoilValue } from 'recoil';
import { adminChunkFilter } from '../../../state/atoms';

// COMPONENTS
import ChunkData from './ChunkData'

// ----------------------------------------------------------------------------------
// ---------------------------------- CHUNK TABLE -----------------------------------
// ----------------------------------------------------------------------------------

const ChunkTable = () => {
  // State
  const route = useRouteMatch();
  const chunks = useRecoilValue(
    adminChunkFilter(route.params?.colID)
  );

  return (
    <div className='py-4 '>
      {chunks.map((data) => (
        <ChunkData key={data.name} data={data} />
      ))}
    </div>
  );
};

export default ChunkTable;
