import React from 'react';

// STATE
import { useRecoilValue } from 'recoil';
import { publisherData } from '../../../state/atoms';

// COMPONENTS
import PublisherData from './PublisherData';

// ----------------------------------------------------------------------------------
// ------------------------------- PUBLISHER TABLE ----------------------------------
// ----------------------------------------------------------------------------------

const PublisherTable = () => {
  // State
  const publishers = useRecoilValue(publisherData);

  return (
    <div className='py-4 '>
      {publishers.map((data) => (
        <PublisherData key={data.id} data={data} />
      ))}
    </div>
  );
};

export default PublisherTable;
