import React, { useState } from 'react';

// ROUTING
import { Link, withRouter } from 'react-router-dom';

// STATE
import { useRecoilState, useRecoilValue } from 'recoil';
import { chunkFilterState, sectionState } from '../../state/atoms';
import { publicGroupState } from '../../state/atoms';

// TAILWIND TRANSITION
import { Transition } from '@tailwindui/react';

// LOGO
import { ReactComponent as MCLOGO } from '../../img/MCLOGONEW.svg';
import { ReactComponent as MyChunksIcon } from '../../img/icon/MyChunks-06.svg';
import { ReactComponent as ExerciseIcon } from '../../img/icon/exerciseIcon.svg'
import { ReactComponent as ShowmusicIcon } from '../../img/icon/showmusicIcon.svg';
import { ReactComponent as BasicsForBandIcon } from '../../img/icon/basicsIcon.svg';
import { ReactComponent as ConcertMusicIcon } from '../../img/icon/concertsIcon.svg';
import { ReactComponent as JazzPopComboIcon } from '../../img/icon/jazzIcon.svg';
import { ReactComponent as VisualChoreographyIcon } from '../../img/icon/visualsIcon.svg';
import { ReactComponent as LearnIcon } from '../../img/icon/learnIcon.svg';
import { ReactComponent as PublishersIcon } from '../../img/icon/Publishers.svg';
import { ReactComponent as HomeIcon } from '../../img/icon/HomeIcon.svg'
import { ReactComponent as AllChunksIcon } from '../../img/icon/AllChunks.svg';
import { ReactComponent as StudentCapIcon } from '../../img/icon/StudentCap.svg';

// COLLECTION IMGS
import allChunksIcon from '../../img/icon/AllChunks.svg';
import PaymentIcon from '../../img/icon/Payment.svg';
import FAQIcon from '../../img/icon/FAQIcon.svg';
import ContactIcon from '../../img/icon/Contact.svg';
import PrivacyIcon from '../../img/icon/Privacy.svg';
import TermsIcon from '../../img/icon/Terms.svg';
import SettingsIcon from '../../img/icon/Settings.svg';

// ----------------------------------------------------------------------------------
// ------------------------------- Mobile Dash Menu ---------------------------------
// ----------------------------------------------------------------------------------

const MobileDashMenu = ({ offCanvasMenu, setOffCanvasMenu, location, handleClearSearchBar, cognitoUser }) => {
  // React Router
  const [chunkFilter, setChunkFilter] = useRecoilState(chunkFilterState);  // FILTER LIST
  const [alphabetizedEnsembles, setAlphabetizedEnsembles] = useState(cognitoUser?.data?.groups.slice().sort(dynamicSort('name'))); // SET ENSEMBLES IN ALPHABETICAL ORDER
  const sections = useRecoilValue(sectionState);
  const publicGroups = useRecoilValue(publicGroupState);

  // Function to sort objects by key:values
  function dynamicSort(property) {
    var sortOrder = 1;
    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a, b) {
      if (sortOrder == -1) {
        return b[property].localeCompare(a[property]);
      } else {
        return a[property].localeCompare(b[property]);
      }
    }
  }

  // Function that links a user to their payment portal
  const redirectToManage = (e) => {
    fetch(process.env.REACT_APP_PAYMENT_PORTAL, {
      method: 'POST',
      body: cognitoUser.data.cognito_id
    })
      .then((res) => res.json())
      // .then((data => console.log(data))) // Uncomment when testing to check the response
      .then((link) => (window.location.href = link))
      .catch((err) => console.log(err));
  };

  return (
    <Transition show={offCanvasMenu}>
      <div className='lg:hidden'>
        <div className='fixed inset-0 z-40 flex'>
          {/* <!--
        Off-canvas menu overlay, show/hide based on off-canvas menu state.
        Entering: "transition-opacity ease-linear duration-300"
          From: "opacity-0"
          To: "opacity-100"
        Leaving: "transition-opacity ease-linear duration-300"
          From: "opacity-100"
          To: "opacity-0"
      --> */}
          <Transition.Child
            enter='transition-opacity ease-linear duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='transition-opacity ease-linear duration-300'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0'>
              <div className='absolute inset-0 opacity-75 bg-gray-600'></div>
            </div>
          </Transition.Child>
          {/* <!--
        Off-canvas menu, show/hide based on off-canvas menu state.
        Entering: "transition ease-in-out duration-300 transform"
          From: "-translate-x-full"
          To: "translate-x-0"
        Leaving: "transition ease-in-out duration-300 transform"
          From: "translate-x-0"
          To: "-translate-x-full"
      --> */}

          <div className='relative overflow-y-auto flex flex-col flex-1 w-full sm:max-w-xs pt-5 pb-4 bg-gradient-to-t from-mcgreen to-mcdarkgreen'>
            <div className='flex items-center justify-center flex-shrink-0 object-center px-4'>
              <MCLOGO className='object-center w-auto h-15' />
            </div>
            <div className='flex flex-col flex-1 mt-5'>
              <nav className='px-2 space-y-1'>
                <Link
                  to={`/dashboard`}
                  onClick={() => {
                    setChunkFilter('All');
                    handleClearSearchBar();
                    setOffCanvasMenu(false)
                  }}
                  className={`group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md text-white ${location.pathname === '/dashboard' ? 'bg-teal-700' : ''
                    } hover:bg-sidebarhighlight  active:bg-sidebarhighlight focus:outline-none focus:bg-sidebarhighlight transition ease-in-out duration-150`}
                >
                  <HomeIcon className='w-6 h-6 mr-4 transition duration-150 ease-in-out' />
                  Home
                </Link>

                <Link
                  to={`/dashboard/your-chunks`}
                  onClick={() => {
                    setOffCanvasMenu(false)
                  }}
                  className={`group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md text-white ${location.pathname === '/dashboard/your-chunks'
                    ? 'bg-sidebarhighlight'
                    : ''
                    } hover:bg-sidebarhighlight  active:bg-sidebarhighlight focus:outline-none focus:bg-sidebarhighlight transition ease-in-out duration-150`}
                >
                  <MyChunksIcon className='w-6 h-6 mr-4 transition duration-150 ease-in-out' />
                  My Chunks
                </Link>

                <Link
                  to={`/dashboard/publishers?publisher=all`}
                  onClick={() => {
                    setOffCanvasMenu(false)
                    handleClearSearchBar();
                  }}
                  className={`group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md text-white ${location.pathname === '/dashboard/publishers'
                    ? 'bg-sidebarhighlight'
                    : ''
                    } hover:bg-sidebarhighlight  active:bg-sidebarhighlight focus:outline-none focus:bg-sidebarhighlight transition ease-in-out duration-150`}
                >
                  <PublishersIcon className='w-6 h-6 mr-4 transition duration-150 ease-in-out' />
                  Publishers
                </Link>

                <Link
                  to={`/dashboard/type/showmusic`}
                  onClick={() => {
                    setOffCanvasMenu(false)
                    handleClearSearchBar();
                  }}
                  className={`group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md text-white ${location.pathname === '/dashboard/type/showmusic'
                    ? ''
                    : ''
                    } hover:bg-showtag  active:bg-showtag focus:outline-none focus:bg-showtag transition ease-in-out duration-150`}
                >
                  <ShowmusicIcon className='w-6 h-6 mr-4 transition duration-150 ease-in-out' />
                  Marching Arts
                </Link>

                <Link
                  to={`/dashboard/type/concerts`}
                  onClick={() => {
                    setOffCanvasMenu(false)
                    handleClearSearchBar();
                  }}
                  className={`group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md text-white ${location.pathname === '/dashboard/type/concerts'
                    ? ''
                    : ''
                    } hover:bg-conctag  active:bg-conctag focus:outline-none focus:bg-conctag transition ease-in-out duration-150`}
                >
                  <ConcertMusicIcon className='w-6 h-6 mr-4 transition duration-150 ease-in-out' />
                  Concert
                </Link>

                <Link
                  to={`/dashboard/type/jazz`}
                  onClick={() => {
                    setOffCanvasMenu(false)
                    handleClearSearchBar();
                  }}
                  className={`group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md text-white ${location.pathname === '/dashboard/type/jazz'
                    ? ''
                    : ''
                    } hover:bg-jazztag  active:bg-jazztag focus:outline-none focus:bg-jazztag transition ease-in-out duration-150`}
                >
                  <JazzPopComboIcon className='w-6 h-6 mr-4 transition duration-150 ease-in-out' />
                  Jazz Pop Combo
                </Link>

                <Link
                  to={`/dashboard/type/exercises`}
                  onClick={() => {
                    setOffCanvasMenu(false)
                    handleClearSearchBar();
                  }}
                  className={`group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md text-white ${location.pathname === '/dashboard/type/exercises'
                    ? ''
                    : ''
                    } hover:bg-eetag  active:bg-eetag focus:outline-none focus:bg-eetag transition ease-in-out duration-150`}
                >
                  <ExerciseIcon className='w-6 h-6 mr-4 transition duration-150 ease-in-out' />
                  Exercises & Fundamentals
                </Link>

                <Link
                  to={`/dashboard/type/visuals`}
                  onClick={() => {
                    setOffCanvasMenu(false)
                    handleClearSearchBar();
                  }}
                  className={`group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md text-white ${location.pathname === '/dashboard/type/visuals'
                    ? ''
                    : ''
                    } hover:bg-visualtag  active:bg-visualtag focus:outline-none focus:bg-visualtag transition ease-in-out duration-150`}
                >
                  <VisualChoreographyIcon className='w-6 h-6 mr-4 transition duration-150 ease-in-out' />
                  Visual & Choreography
                </Link>

                <Link
                  to={`/dashboard/type/basics`}
                  onClick={() => {
                    setOffCanvasMenu(false)
                    handleClearSearchBar();
                  }}
                  className={`group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md text-white ${location.pathname === '/dashboard/type/basics'
                    ? ''
                    : ''
                    } hover:bg-bbtag  active:bg-bbtag focus:outline-none focus:bg-bbtag transition ease-in-out duration-150`}
                >
                  <BasicsForBandIcon className='w-6 h-6 mr-4 transition duration-150 ease-in-out' />
                  Music Chunks Band Basics
                </Link>

                <Link
                  to={`/dashboard/type/learn`}
                  onClick={() => {
                    setOffCanvasMenu(false)
                    handleClearSearchBar();
                  }}
                  className={`group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md text-white ${location.pathname === '/dashboard/type/learn'
                    ? ''
                    : ''
                    } hover:bg-learntag  active:bg-learntag focus:outline-none focus:bg-learntag transition ease-in-out duration-150`}
                >
                  <LearnIcon className='w-6 h-6 mr-4 transition duration-150 ease-in-out' />
                  Learn About Music Chunks
                </Link>
              </nav>

              {/* PUBLIC ENSEMBLES */}
              <hr className='h-px mt-6 bg-sidebarborder border-none' />
              <div className='mt-6'>
                <nav className='px-2 space-y-1'>
                  <h3 className='px-2 py-2 font-medium leading-6 text-white rounded-md text-md '>
                    Classrooms
                  </h3>
                  {publicGroups.map(groups => (
                    <Link
                      to={`/dashboard/public/ensembles/${groups.id}`}
                      onClick={() => {
                        setOffCanvasMenu(false)
                      }}
                      className={`group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md text-white ${location.pathname === '/dashboard/chunks?collection=all'
                        ? ''
                        : ''
                        } hover:bg-sidebarhighlight active:bg-sidebarhighlight focus:outline-none focus:bg-sidebarhighlight transition ease-in-out duration-150`}
                    >
                      <StudentCapIcon
                        className='w-6 h-6 mr-4 transition duration-150 ease-in-out'
                      />
                      {groups.name}
                    </Link>
                  ))}
                </nav>
              </div>

              {/* PRIVATE ENSEMBLES */}
              {cognitoUser?.data?.groups?.length > 0 ? (
                <div>
                  <hr className='h-px mt-6 bg-sidebarborder border-none' />
                  <div className='mt-6'>
                    <nav className='px-2 space-y-1'>
                      <h3 className='px-2 py-2 font-medium leading-6 text-white rounded-md text-md '>
                        Ensembles
                      </h3>
                      {alphabetizedEnsembles.filter(ensemble => ensemble.is_public_ensemble === false).map(groups => (
                        <Link
                          to={`/dashboard/ensembles/${groups.id}`}
                          onClick={() => {
                            setOffCanvasMenu(false)
                          }}
                          className={`group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md text-white ${location.pathname === '/dashboard/chunks?collection=all'
                            ? ''
                            : ''
                            } hover:bg-sidebarhighlight active:bg-sidebarhighlight focus:outline-none focus:bg-sidebarhighlight transition ease-in-out duration-150`}
                        >
                          <StudentCapIcon
                            className='w-6 h-6 mr-4 transition duration-150 ease-in-out'
                          />
                          {groups.name}
                        </Link>
                      ))}
                    </nav>
                  </div>
                </div>
              ) : null}

              <hr className='h-px mt-6 bg-sidebarborder border-none' />
              <div className='flex-1 h-0 mt-6'>
                <nav className='px-2 space-y-1'
                // ------------ HIDES INSTRUMENT SECTIONS UNLESS VIEWING ALL CHUNKS ------------
                // className={`${location.pathname.includes('chunks') === false && 'hidden'
                //   } px-2 space-y-1`}
                // ------------ HIDES INSTRUMENT SECTIONS UNLESS VIEWING ALL CHUNKS ------------
                >
                  <h3 className='px-2 py-2 font-medium leading-6 text-white rounded-md text-md '>
                    Playlists
                  </h3>
                  <Link
                    to={`/dashboard/chunks?collection=all`}
                    onClick={() => {
                      handleClearSearchBar();
                      setOffCanvasMenu(false)
                    }}
                    className={`group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md text-white ${location.pathname === '/dashboard/chunks?collection=all'
                      ? 'bg-sidebarhighlight'
                      : ''
                      } hover:bg-sidebarhighlight active:bg-sidebarhighlight focus:outline-none focus:bg-sidebarhighlight transition ease-in-out duration-150`}
                  >
                    <AllChunksIcon
                      className='w-6 h-6 mr-4 transition duration-150 ease-in-out'
                    />
                  Search All Chunks
                </Link>
                  {sections.filter(playlist => playlist.filter === 'chunksoftheweek').map((i) => {
                    return (
                      <Link
                        key={i.id}
                        to={`/dashboard/chunks?collection=${i.filter}`}
                        className={`group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md text-white ${location.pathname === '/dashboard/chunks?collection=all'
                          ? ''
                          : ''
                          } hover:bg-sidebarhighlight active:bg-sidebarhighlight focus:outline-none focus:bg-sidebarhighlight transition ease-in-out duration-150`}
                        onClick={() => {
                          // CLEAR SEARCH TERM ON COLLECTION CHANGE
                          handleClearSearchBar();
                          setOffCanvasMenu(false)
                        }}
                      >
                        <img
                          src={allChunksIcon}
                          className='w-6 h-6 mr-4 text-teal-200 transition duration-150 ease-in-out group-hover:text-teal-200 group-focus:text-teal-200'
                          alt=''
                        />
                        {i.type}
                      </Link>
                    );
                  })}
                  {/* CREATE FILTER BUTTONS - FILTER OUT CHUNKS OF THE WEEK AND PRIVATE PLAYLISTS */}
                  {sections.filter(playlist => playlist.public === true && playlist.filter !== 'chunksoftheweek').map((i) => {
                    return (
                      <Link
                        key={i.id}
                        to={`/dashboard/chunks?collection=${i.filter}`}
                        className={`group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md text-white ${location.pathname === '/dashboard/chunks?collection=all'
                          ? ''
                          : ''
                          } hover:bg-sidebarhighlight active:bg-sidebarhighlight focus:outline-none focus:bg-sidebarhighlight transition ease-in-out duration-150`}
                        onClick={() => {
                          // CLEAR SEARCH TERM ON COLLECTION CHANGE
                          handleClearSearchBar();
                          setOffCanvasMenu(false)
                        }}
                      >
                        <img
                          src={allChunksIcon}
                          className='w-6 h-6 mr-4 text-teal-200 transition duration-150 ease-in-out group-hover:text-teal-200 group-focus:text-teal-200'
                          alt=''
                        />
                        {i.type}
                      </Link>
                    );
                  })}
                </nav>
              </div>

              {/* Site Help */}
              <div>
                <hr className='h-px mt-6 bg-sidebarborder border-none' />
                <div className='mt-6'>
                  <nav className='px-2 space-y-1'>
                    <h3 className='px-2 py-2 font-medium leading-6 text-white rounded-md text-md '>
                      Help
                  </h3>
                    <Link
                      to={`/my-account/settings`}
                      className={`group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md text-white ${location.pathname === '/dashboard/chunks?collection=all'
                        ? ''
                        : ''
                        } hover:bg-sidebarhighlight active:bg-sidebarhighlight focus:outline-none focus:bg-sidebarhighlight transition ease-in-out duration-150`}
                    >
                      <img
                        src={SettingsIcon}
                        className='w-6 h-6 mr-4 text-teal-200 transition duration-150 ease-in-out group-hover:text-teal-200 group-focus:text-teal-200'
                        alt=''
                      />
                    My Account
                  </Link>
                    {cognitoUser?.data?.role.includes('student') ? (
                      null
                    ) : (
                      <Link
                        onClick={redirectToManage}
                        className={`group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md text-white ${location.pathname === '/dashboard/chunks?collection=all'
                          ? ''
                          : ''
                          } hover:bg-sidebarhighlight active:bg-sidebarhighlight focus:outline-none focus:bg-sidebarhighlight transition ease-in-out duration-150`}
                      >
                        <img
                          src={PaymentIcon}
                          className='w-6 h-6 mr-4 text-teal-200 transition duration-150 ease-in-out group-hover:text-teal-200 group-focus:text-teal-200'
                          alt=''
                        />
                    Payment Settings
                      </Link>
                    )}
                    <Link
                      to={`/dashboard/faq`}
                      onClick={() => {
                        setOffCanvasMenu(false)
                      }}
                      className={`group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md text-white ${location.pathname === '/dashboard/chunks?collection=all'
                        ? ''
                        : ''
                        } hover:bg-sidebarhighlight active:bg-sidebarhighlight focus:outline-none focus:bg-sidebarhighlight transition ease-in-out duration-150`}
                    >
                      <img
                        src={FAQIcon}
                        className='w-6 h-6 mr-4 text-teal-200 transition duration-150 ease-in-out group-hover:text-teal-200 group-focus:text-teal-200'
                        alt=''
                      />
                    FAQ
                    </Link>
                    <a
                      class='email'
                      href={`mailto:info@musicchunks.com`}
                      onClick={() => {
                        setOffCanvasMenu(false)
                      }}
                      className={`group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md text-white ${location.pathname === '/dashboard/chunks?collection=all'
                        ? ''
                        : ''
                        } hover:bg-sidebarhighlight active:bg-sidebarhighlight focus:outline-none focus:bg-sidebarhighlight transition ease-in-out duration-150`}
                    >
                      <img
                        src={ContactIcon}
                        className='w-6 h-6 mr-4 text-teal-200 transition duration-150 ease-in-out group-hover:text-teal-200 group-focus:text-teal-200'
                        alt=''
                      />
                    Contact Us
                    </a>
                    <Link
                      to={`/dashboard/privacy-policy`}
                      onClick={() => {
                        setOffCanvasMenu(false)
                      }}
                      className={`group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md text-white ${location.pathname === '/dashboard/chunks?collection=all'
                        ? ''
                        : ''
                        } hover:bg-sidebarhighlight active:bg-sidebarhighlight focus:outline-none focus:bg-sidebarhighlight transition ease-in-out duration-150`}
                    >
                      <img
                        src={PrivacyIcon}
                        className='w-6 h-6 mr-4 text-teal-200 transition duration-150 ease-in-out group-hover:text-teal-200 group-focus:text-teal-200'
                        alt=''
                      />
                    Your Privacy Rights
                    </Link>
                    <Link
                      to={`/dashboard/terms-of-service`}
                      onClick={() => {
                        setOffCanvasMenu(false)
                      }}
                      className={`group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md text-white ${location.pathname === '/dashboard/chunks?collection=all'
                        ? ''
                        : ''
                        } hover:bg-sidebarhighlight active:bg-sidebarhighlight focus:outline-none focus:bg-sidebarhighlight transition ease-in-out duration-150`}
                    >
                      <img
                        src={TermsIcon}
                        className='w-6 h-6 mr-4 text-teal-200 transition duration-150 ease-in-out group-hover:text-teal-200 group-focus:text-teal-200'
                        alt=''
                      />
                    Terms and Conditions
                    </Link>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  );
};

export default withRouter(MobileDashMenu);