import React from 'react';

// ----------------------------------------------------------------------------------
// ----------------------------- Featured Publisher Card ----------------------------
// ----------------------------------------------------------------------------------

const FeaturedPublisherCard = ({ data }) => {
  const {
    name,
    bio,
    profile_pic
  } = data;

  return (
    <div className='hover:bg-mcgreen max-w-359 hover:bg-opacity-25 p-2 rounded-lg'>
      <div className={`overflow-hidden`}>
        <img
          src={profile_pic}
          alt='img for a publisher'
          className='object-fill w-full h-full min-h-full rounded-md'
        />
        <div className='flex items-start pt-2'>
          <div className='flex-1 w-0 ml-5'>
            <dt className='text-sm font-medium leading-5 text-white truncate-2-lines'>
              {name}
            </dt>
            <div className='text-xs font-medium leading-4 truncate-2-lines text-mcgreen'>
              {bio}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export const MemoizedFeaturedPublisherCard = React.memo(FeaturedPublisherCard);