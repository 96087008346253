import React from 'react';

// STATE
import { useRecoilValue } from 'recoil';
import { compositionData } from '../../../state/atoms';

// COMPONENTS
import CompositionData from './CompositionData';

// ----------------------------------------------------------------------------------
// ------------------------------- COMPOSITION TABLE --------------------------------
// ----------------------------------------------------------------------------------

const CompositionTable = () => {
  // State
  const compositions = useRecoilValue(compositionData);

  return (
    <div className='py-4 '>
      {compositions.map((data) => (
        <CompositionData key={data.id} data={data} />
      ))}
    </div>
  );
};

export default CompositionTable;