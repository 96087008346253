import React from 'react';

// ROUTING
import { Redirect, Route, useLocation } from 'react-router-dom';

// AUTH
import { Auth } from '@aws-amplify/auth';

// STATE
import { useRecoilValue } from 'recoil';
import { cogUserState } from '../state/atoms';

// ----------------------------------------------------------------------------------
// -------------------------------- PRIVATE ROUTE -----------------------------------
// ----------------------------------------------------------------------------------

const PrivateRoute = ({ component: Component, location, ...rest }) => {
  const { pathname } = useLocation();
  const cognitoUser = useRecoilValue(
    cogUserState(
      localStorage.getItem(
        process.env.REACT_APP_USER_ID,
      ),
    ),
  ); // Get user from our DB

  // Function to help determine if the user is an ensemble admin
  const findEnsembleAdmin = () => {
    if (cognitoUser.data.groups.find(group => group.isAdmin === true)) {
      return true
    } else {
      return false
    }
  }

  const isPayed = cognitoUser.data.payment_info_filled;
  const subscription = cognitoUser.data.role;
  const roles = Auth.user.signInUserSession.accessToken.payload['cognito:groups']
  const isEnsembleAdmin = findEnsembleAdmin()

  const userAuth = () => {
    if ((isPayed && subscription !== 'free') || (roles && roles.includes('Admin')) || (isEnsembleAdmin)) {
      return true
    } else {
      return false
    }
  }

  const renderComponent = (props) => {
    if (!isPayed && pathname !== '/add-payment') {
      return (
        <Redirect to={{ pathname: '/add-payment' }} />
      )
    } else if (isPayed && subscription === 'free' && pathname !== '/no-subscription') {
      return (
        <Redirect to={{ pathname: '/no-subscription' }} />
      )
    } else {
      return <Component {...props} />;
    }
  }

  return (
    <Route
      {...rest}
      render={(rest) =>
        userAuth() ? (
          <Redirect
            to={'/dashboard'}
          />
        ) : (
            renderComponent(rest)
          )}
    />
  );
};

export default PrivateRoute;