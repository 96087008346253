import React from 'react';

// ROUTING
import { useRouteMatch } from 'react-router-dom';

// STATE
import { useRecoilValue } from 'recoil';
import { adminChunkSoundRecordingFilter } from '../../../state/atoms';

// COMPONENTS
import SoundRecordingData from './SoundRecordingData';

// ----------------------------------------------------------------------------------
// -------------------------- CHUNK SOUND RECORDING TABLE ---------------------------
// ----------------------------------------------------------------------------------

const ChunkSoundRecordingTable = () => {
  // State
  const route = useRouteMatch();
  const soundRecordings = useRecoilValue(
    adminChunkSoundRecordingFilter(route.params?.chunkID)
  );

  return (
    <div className='py-4 '>
      {soundRecordings.map((data) => (
        <SoundRecordingData key={data.id} data={data} />
      ))}
    </div>
  );
};

export default ChunkSoundRecordingTable;