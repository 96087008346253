import React from 'react';

// ----------------------------------------------------------------------------------
// ------------------------------- COLLECTION TYPE CARD -----------------------------
// ----------------------------------------------------------------------------------

const CollectionTypeCard = ({ data }) => {
  const {
    name,
    banner_pic
  } = data;

  return (
    <div
      key={name}
      className={`hover:bg-mcgreen hover:bg-opacity-25 p-2 rounded-lg`}
    >
      <div className={`overflow-hidden`}>
        {/* TOP IMG */}
        <img
          src={banner_pic}
          alt='img for a single publisher'
          className='inline-block object-cover w-full h-full rounded-md'
        />
      </div>
    </div>
  );
};
export const MemoizedCollectionTypeCard = React.memo(CollectionTypeCard);
