import React, { useState } from 'react';

// ROUTING
import { useHistory, Link } from 'react-router-dom';

// AUTH
import { Auth } from '@aws-amplify/auth';

// FORMS
import { useForm } from "react-hook-form";

// TOAST
import cogoToast from 'cogo-toast';

// ----------------------------------------------------------------------------------
// --------------------------------- CUSTOM SIGN IN ---------------------------------
// ----------------------------------------------------------------------------------

export default function CustomResetPassword(props) {
  // State
  const { register, handleSubmit } = useForm();
  const [submitting, setSubmitting] = useState(false);
  const [screen, setScreen] = useState('resetpassword');
  const [email, setEmail] = useState()
  const [input, setInput] = useState({
    username: ''
  })

  // React Router
  const history = useHistory();

  const handleInputChange = (event) => {
    setInput({ ...input, [event.target.name]: event.target.value.toLowerCase().replace(/ /g, "") });
  };

  // Function to handle resetting a user's password
  const onPasswordResetSubmit = async (data) => {
    setSubmitting(true);

    if (screen === 'resetpassword') {
      await Auth.forgotPassword(data.username)
        .then(() => {
          setSubmitting(false);
          setEmail(data.username)
          setScreen('resetpassword2')
          cogoToast.success(`An email with confirmation code has been sent to ${data.username}`, {
            hideAfter: 5,
          });
        })
        .catch(err => {
          setSubmitting(false);
          cogoToast.error(err.message, {
            hideAfter: 3,
          });
          console.log(err)
        })
    } else if (screen === 'resetpassword2') {
      await Auth.forgotPasswordSubmit(email, data.code, data.new_password)
        .then(() => {
          setSubmitting(false);
          cogoToast.success(`Your password has been successfully changed`, {
            hideAfter: 5,
          });
          const user = Auth.signIn(email, data.new_password)
            .then(() => {
              if (user) {
                setSubmitting(false);
                cogoToast.success('Successfully logged in', {
                  hideAfter: 3,
                });
                history.push('/dashboard')
              }
            })
            .catch(err => {
              setSubmitting(false);
              cogoToast.error(err.message, {
                hideAfter: 3,
              });
              console.log(err)
            })
        })
        .catch(err => {
          setSubmitting(false);
          cogoToast.error(err.message, {
            hideAfter: 5,
          });
          console.log(err)
        })
    }
  };

  return (
    <>
      {screen === 'resetpassword' ? (
        <form className="p-10 bg-mclightgreen rounded-lg" onSubmit={handleSubmit(onPasswordResetSubmit)}>
          <h4 className='text-white text-2xl'>Reset your password</h4>

          <div className="form-group">
            <label className='text-white'>Email address</label>
            <input
              name='username'
              type='username'
              value={input.username}
              placeholder='Enter your email'
              onChange={handleInputChange}
              className='form-control w-full flex items-center mb-7 mt-3 p-2 rounded-md text-lg'
              ref={register}
            />
          </div>

          <div className='flex mt-9'>
            <div className='mr-20 text-white flex items-center'>
              <p>No account?
                <span
                  className='ml-1 cursor-pointer text-mcgreen hover:text-mcfadedgreen focus:outline-none'
                  onClick={() => props.changeScreen('signin')}
                >
                  Back to Sign In
                </span>
              </p>
            </div>
            <button
              type="submit"
              // disabled={!stripe}
              className={`${submitting && 'opacity-50 pointer-events-none'
                } flex items-center rounded-lg text-lg px-12 py-3 text-center font-medium text-mcgreen bg-mcbuttondarkgreen hover:bg-mcgreen hover:text-mcbuttondarkgreen focus:ring transition duration-150 ease-in-out`}>
              {submitting && (
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  strokeWidth="0"
                  viewBox="0 0 1024 1024"
                  className="w-5 h-5 mr-3 animate-spin"
                  xmlns="http://www.w3.org/2000/svg">
                  <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 0 0-94.3-139.9 437.71 437.71 0 0 0-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                </svg>
              )}{' '}
              SUBMIT
              </button>
          </div>
        </form>

      ) : screen === 'resetpassword2' ? (
        <form className="p-10 bg-mclightgreen rounded-lg" onSubmit={handleSubmit(onPasswordResetSubmit)}>
          <h4 className='text-white text-2xl'>Reset your password</h4>

          <div className="form-group">
            <label className='text-white'>Email address</label>
            <input
              name='username'
              type='username'
              placeholder='Enter email'
              className='form-control w-full flex items-center mb-7 mt-3 p-2 rounded-md text-lg'
              value={email}
              disabled={true}
            />
          </div>
          <div className="form-group">
            <label className='text-white'>Confirmation code</label>
            <input
              name='code'
              type='number'
              placeholder='Enter code'
              className='form-control w-full flex items-center mb-7 mt-3 p-2 rounded-md text-lg'
              ref={register}
            />
          </div>
          <div className="form-group">
            <label className='text-white'>New password</label>
            <input
              name='new_password'
              type='password'
              placeholder='Enter your new password'
              className='form-control w-full flex items-center mb-3 mt-3 p-2 rounded-md text-lg'
              ref={register}
            />
          </div>

          <div className='flex mt-9'>
            <div className='mr-20 text-white flex items-center'>
              <p>No account?
                  <span
                  className='ml-1 cursor-pointer text-mcgreen hover:text-mcfadedgreen focus:outline-none'
                  onClick={() => props.changeScreen('signin')}
                >
                  Back to Sign In
                  </span>
              </p>
            </div>
            <button
              type="submit"
              // disabled={!stripe}
              className={`${submitting && 'opacity-50 pointer-events-none'
                } flex items-center rounded-lg text-lg px-12 py-3 text-center font-medium text-mcgreen bg-mcbuttondarkgreen hover:bg-mcgreen hover:text-mcbuttondarkgreen focus:ring transition duration-150 ease-in-out`}>
              {submitting && (
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  strokeWidth="0"
                  viewBox="0 0 1024 1024"
                  className="w-5 h-5 mr-3 animate-spin"
                  xmlns="http://www.w3.org/2000/svg">
                  <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 0 0-94.3-139.9 437.71 437.71 0 0 0-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                </svg>
              )}{' '}
              SUBMIT
              </button>
          </div>
        </form>
      ) : null}
    </>
  );
}