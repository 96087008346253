import React, { useState } from 'react';

// ROUTING
import { Link } from 'react-router-dom';

// AUTH
import { Auth } from '@aws-amplify/auth';

// STREAM CHAT
import { StreamChat } from 'stream-chat';

// TAILWIND TRANSITION
import { Transition } from '@tailwindui/react';

// IMAGES
import { ReactComponent as Heroimg } from '../../img/MCLOGONEW.svg';


const client = StreamChat.getInstance(process.env.REACT_APP_STREAM_API_KEY)

// ----------------------------------------------------------------------------------
// ----------------------------------- NAVBAR ---------------------------------------
// ----------------------------------------------------------------------------------

const NavBar = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  // SMOOTH SCROLL TO ANCHOR
  document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
    anchor.addEventListener('click', function (e) {
      e.preventDefault();

      document.querySelector(this.getAttribute('href')).scrollIntoView({
        behavior: 'smooth',
      });
    });
  });

  // Function for handling logout
  let logout = () => {
    Auth.signOut()
    client.disconnectUser();
    localStorage.clear()
    window.location.reload();
  };

  return (
    <header>
      <div className='bg-hero-pattern bg-no-repeat bg-center bg-cover px-6 py-2 lg:fixed w-full top-0 z-10 border-b-2 border-mcgreen'>
        <nav className='relative flex items-center justify-between'>
          <div className='flex items-center lg:hidden'>
            <button
              onClick={() => {
                setMenuOpen(true);
              }}
              type='button'
              className='inline-flex items-center justify-center p-2 rounded-md text-white hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out'
              id='main-menu'
              aria-label='Main menu'
              aria-haspopup='true'
            >
              <svg
                className='h-6 w-6'
                stroke='currentColor'
                fill='none'
                viewBox='0 0 24 24'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth='2'
                  d='M4 6h16M4 12h16M4 18h16'
                />
              </svg>
            </button>
          </div>
          <Link
            to={`/`}       
          >
            <Heroimg className='invisible lg:visible flex w-20 lg:w-40'/>
          </Link>
          <div className='hidden lg:flex md:space-x-10'>
            <Link
              to={`/about`}
              className='font-medium text-white hover:text-gray-500 transition duration-300 ease-in-out'
            >
              About
            </Link>
            <Link
              to={`/features`}
              className='font-medium text-white hover:text-gray-500 transition duration-300 ease-in-out'
            >
              How To Use
            </Link>
            <Link
              to={`/ensembles`}
              className='font-medium text-white hover:text-gray-500 transition duration-300 ease-in-out'
            >
              Ensemble Accounts
            </Link>
            <Link
              to={`/prices`}
              className='font-medium text-white hover:text-gray-500 transition duration-300 ease-in-out'
            >
              Pricing
            </Link>
            <Link
              to={`/faq-music-chunks`}
              className='font-medium text-white hover:text-gray-500 transition duration-300 ease-in-out'
            >
              FAQs
            </Link>
          </div>

          {/* CHANGE LOGIN BUTTON TO DASHBOARD DEPENDING ON CREDENTIALS */}
          <div className='hidden lg:flex md:items-center md:inset-y-0 md:right-0'>
            <span className='inline-flex rounded-md shadow'>
              {localStorage.getItem(process.env.REACT_APP_USER_ID) ? (
                <div>
                  <button
                    type='button'
                    onClick={() => logout()}
                    className='py-2 px-4 text-lg px-2 border-2 border-mcgreen text-base leading-6 font-bold rounded-md text-mcgreen hover:bg-mcgreen hover:text-black focus:outline-none transition duration-150 ease-in-out'
                    role='menuitem'
                  >
                    Log Out
                  </button>
                  <Link
                    to={`/dashboard`}
                    className='ml-4 py-2 text-lg px-2 border-2 bg-mcgreen border-mcgreen text-base leading-6 font-bold rounded-md text-black hover:opacity-70 focus:outline-none transition duration-150 ease-in-out'
                  >
                    My Dashboard
                  </Link>
                </div>
              ) : (
                  <div>
                    <Link
                      to={`/signup`}
                      className='mr-4 py-2 text-lg px-2 border-2 bg-mcgreen border-mcgreen text-base leading-6 font-bold rounded-md text-black hover:opacity-70 focus:outline-none transition duration-150 ease-in-out'
                   >
                      Create Account
                    </Link>
                    <Link
                      to={`/login`}
                      className='py-2 px-4 text-lg px-2 border-2 border-mcgreen text-base leading-6 font-bold rounded-md text-mcgreen hover:bg-mcgreen hover:text-black focus:outline-none transition duration-150 ease-in-out'
                    >
                      Log in
                    </Link>
                  </div>
                )}
            </span>
          </div>
        </nav>
      </div>

      {/* <!--
      Mobile menu, show/hide based on menu open state.
      Entering: "duration-150 ease-out"
        From: "opacity-0 scale-95"
        To: "opacity-100 scale-100"
      Leaving: "duration-100 ease-in"
        From: "opacity-100 scale-100"
        To: "opacity-0 scale-95"
    -->  */}
      <Transition
        show={menuOpen}
        enter='duration-400 ease-out'
        enterFrom='opacity-0 scale-95'
        enterTo='opacity-100 scale-100'
        leave='duration-400 ease-in'
        leaveFrom='opacity-100 scale-100'
        leaveTo='opacity-0 scale-95'
      >
        <div className='z-40 absolute top-0 inset-x-0 p-2 transition transform origin-top-right lg:hidden'>
          <div className='rounded-lg shadow-md'>
            <div
              className='rounded-lg bg-gray-50 shadow-xs overflow-hidden'
              role='menu'
              aria-orientation='vertical'
              aria-labelledby='main-menu'
            >
              <div className='px-5 pt-4 flex items-center justify-between'>
                {/* <div>
                  <img
                    className='h-8 w-auto'
                    src='https://tailwindui.com/img/logos/workflow-mark-on-white.svg'
                    alt=''
                  />
                </div> */}
                <div className='-mr-2'>
                  <button
                    type='button'
                    className='inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out'
                    aria-label='Close menu'
                    onClick={() => setMenuOpen(false)}
                  >
                    <svg
                      className='h-6 w-6'
                      stroke='currentColor'
                      fill='none'
                      viewBox='0 0 24 24'
                    >
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        strokeWidth='2'
                        d='M6 18L18 6M6 6l12 12'
                      />
                    </svg>
                  </button>
                </div>
              </div>
              <div className='px-2 pt-2 pb-3'>
                <Link
                  to={`/`}
                  className='block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out'
                >
                  Home
                </Link>
                <Link
                  to={`/about`}
                  className='block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out'
                >
                  About
                </Link>
                <Link
                  to={`/features`}
                  className='block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out'
                >
                  How To Use
                </Link>
                <Link
                  to={`/ensembles`}
                  className='block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out'
                >
                  Ensemble Accounts
                </Link>
                <Link
                  to={`/prices`}
                  className='block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out'
                >
                  Pricing
                </Link>
                <Link
                  to={`/faq-music-chunks`}
                  className='block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out'
                >
                  FAQs
                </Link>
              </div>
              {localStorage.getItem(process.env.REACT_APP_USER_ID) ? (
                <div>
                  <Link
                    to={`/dashboard`}
                    className='block w-full px-5 py-3 text-center font-medium text-mcgreen bg-mcbuttondarkgreen hover:bg-mcgreen hover:text-mcdarkgreen focus:outline-none focus:bg-gray-100 focus:text-indigo-700 transition duration-150 ease-in-out'
                  >
                    My Dashboard
                  </Link>
                </div>
              ) : (
                  <div>
                    <Link
                      to={`/login`}
                      className='block w-full px-5 py-3 text-center font-medium text-mcgreen bg-mcbuttondarkgreen hover:bg-mcgreen hover:text-mcdarkgreen focus:outline-none focus:bg-gray-100 focus:text-indigo-700 transition duration-150 ease-in-out'
                    >
                      Log in
                    </Link>
                  </div>
                )}
            </div>
          </div>
        </div>
      </Transition>
    </header>
  );
};

export default NavBar;