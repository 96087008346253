import React, { useState, useEffect } from 'react';
import axios from 'axios';
import axiosRetry from 'axios-retry';

// ROUTING
import { useHistory } from 'react-router-dom';

// STATE
import { useRecoilValue } from 'recoil';
import { chunkState } from '../../state/atoms';

// FORM MANAGEMENT
import { useForm, Controller } from 'react-hook-form';
import Select from 'react-select';

// TOAST
import cogoToast from 'cogo-toast';

// ----------------------------------------------------------------------------------
// ------------------------ GROUP ADD PUBLIC CHUNK FORM ---------------------------
// ----------------------------------------------------------------------------------

const GroupAddPublicChunkForm = ({ group, groupChunks, refresh, setRefresh, match }) => {
  // State
  const history = useHistory();
  const chunks = useRecoilValue(chunkState);
  const [publicAndPrivateGroupChunks, setPublicAndPrivateGroupChunks] = useState([])
  const [availableChunks, setAvailableChunks] = useState([])
  const [submitting, setSubmitting] = useState(false);

  // Form
  const { handleSubmit, errors, control } = useForm({
    mode: 'onChange',
    defaultValues: {
      public: true,
    },
  });

  // Set axios to retry a set number of times on failed request/return
  axiosRetry(axios, { retries: 3 })

  // UseEffect that grabs a list of all the current group's private chunks
  useEffect(() => {
    axios({
      method: 'get',
      url:
        process.env.REACT_APP_API + `publishers/${group?.publisher_id}/chunks/private`,
      headers: {
        Accept: 'application/json',
        Authorization: process.env.REACT_APP_HEROKU_SERVER_KEY,
      },
    })
      .then((res) => {
        // Combine a group/publisher's private chunks with all public chunks on site
        setPublicAndPrivateGroupChunks(chunks.concat(res.data))
      })
      .catch((err) => console.log(err));
  }, [chunks, group]);

  // UseEffect to filter out groupChunks already assigned from public and private group chunks left available
  useEffect(() => {
    var filteredArray = publicAndPrivateGroupChunks.filter(chunk => {
      return groupChunks.filter(groupChunk => {
        return groupChunk.id === chunk.id
      }).length == 0
    })
    setAvailableChunks(filteredArray)
  }, [publicAndPrivateGroupChunks, groupChunks])

  // Function that handles submitting updates/edits for public group chunks
  const onSubmit = (data) => {
    setSubmitting(true);

    var count = 0

    // As long as we are submitting chunks, add them
    if (data.group_public_chunks) {
      data.group_public_chunks.map(publicChunk => {
        axios
          ({
            method: 'post',
            url: process.env.REACT_APP_API + `groups/addgroupchunk`,
            headers: {
              Accept: 'application/json',
              Authorization: process.env.REACT_APP_HEROKU_SERVER_KEY
            }, data: {
              group_id: group.id,
              chunk_id: publicChunk.value
            }
          })
          .then((res) => {
            setSubmitting(false);
            count += 1;
            setRefresh(!refresh);
            if (count === data.group_public_chunks.length) {
              cogoToast.success(`All chunks added successfully!`, {
                hideAfter: 5
              });
            }
            history.push(`${match.url.replace('/add-chunk', '')}`);
          })
          .catch((err) => {
            console.log(err);
            setSubmitting(false);
            count += 1;
            setRefresh(!refresh);
            if (count === data.group_public_chunks.length) {
              cogoToast.error('Could not add chunks', {
                hideAfter: 5
              });
            }
          });
      })
    } else {
      setSubmitting(false);
      cogoToast.warn('No chunks have been selected', {
        hideAfter: 5,
      });
    }
  };

  return (
    <div className='mt-3 mb-32'>
      {/* ABOUT */}
      <div className='bg-green-700 px-5 py-3 mt-2 rounded-lg'>
        <p className='whitespace-pre-wrap text-white'>
          Use the search bar below to find and add Chunks from the entire Music Chunks library, or your own
          ensemble private content. If you’ve previously uploaded ensemble private content, you’ll receive an
          email in 2-7 days letting you know its ready for use, that’s when you’ll be able to find it here and
          'add it to the ensemble'. Hit Submit when you’re finished selecting Chunks to add to your Ensemble.
          **Chunks must be added here first, to be able to add them to playlists or be featured**
        </p>
      </div>

      {/* TITLE */}
      <div className='mt-2 border-t-4 border-green-600 border-solid'>
        <h1 className='w-full pt-2 text-2xl font-medium leading-6 text-white'>
          ADD CHUNKS
        </h1>
      </div>

      {/* INPUTS */}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='mt-5'>
          <Controller
            as={Select}
            options={availableChunks?.map(ch => ({ label: `${ch.name} / ${ch.collection_name ? ch.collection_name : 'single chunk'} / ${ch.publisher_name}`, value: ch.id }))}
            isMulti
            maxMenuHeight={200}
            key={groupChunks ? groupChunks : chunks} // changing the keys lets the defaultValue grab props on a rerender. hacky solution
            id='group_public_chunks'
            name='group_public_chunks'
            control={control}
          // defaultValue={groupChunks.map(gc => ({ label: gc.name, value: gc.id }))}
          />
          {errors.group_public_chunks && (
            <span className='text-red-500'>This field is required</span>
          )}
        </div>

        {/* SUBMIT AND GO BACK BUTTONS */}
        <div className='flex justify-center sm:justify-end w-full p-5 px-10 '>
          <span className='inline-flex space-x-8 rounded-md shadow-sm'>
            <button
              type='submit'
              className={`${submitting && 'opacity-50 cursor-not-allowed'
                } inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150`}
            >
              {submitting ? '' : 'Submit'}
              <svg
                className={`animate-spin ${!submitting && 'hidden'}`}
                stroke='currentColor'
                fill='currentColor'
                strokeWidth='0'
                viewBox='0 0 16 16'
                height='1em'
                width='1em'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path d='M8 .75c.172 0 .333.034.484.102a1.214 1.214 0 0 1 .664.664c.068.15.102.312.102.484s-.034.333-.102.484a1.214 1.214 0 0 1-.265.399 1.324 1.324 0 0 1-.399.273A1.254 1.254 0 0 1 8 3.25c-.172 0-.333-.031-.484-.094a1.324 1.324 0 0 1-.672-.672A1.254 1.254 0 0 1 6.75 2c0-.172.031-.333.094-.484.067-.151.159-.284.273-.399.115-.114.248-.203.399-.265A1.17 1.17 0 0 1 8 .75zM2.633 3.758a1.111 1.111 0 0 1 .68-1.031 1.084 1.084 0 0 1 .882 0c.136.057.253.138.352.242.104.099.185.216.242.351a1.084 1.084 0 0 1 0 .883 1.122 1.122 0 0 1-.594.594 1.169 1.169 0 0 1-.883 0 1.19 1.19 0 0 1-.359-.234 1.19 1.19 0 0 1-.234-.36 1.169 1.169 0 0 1-.086-.445zM2 7a.941.941 0 0 1 .703.297A.941.941 0 0 1 3 8a.97.97 0 0 1-.078.39 1.03 1.03 0 0 1-.531.532A.97.97 0 0 1 2 9a.97.97 0 0 1-.39-.078 1.104 1.104 0 0 1-.32-.211 1.104 1.104 0 0 1-.212-.32A.97.97 0 0 1 1 8a.97.97 0 0 1 .29-.703A.97.97 0 0 1 2 7zm.883 5.242a.887.887 0 0 1 .531-.805.863.863 0 0 1 .68 0c.11.047.203.11.281.188a.887.887 0 0 1 .188.96.887.887 0 0 1-1.148.461.913.913 0 0 1-.462-.46.863.863 0 0 1-.07-.344zM8 13.25c.208 0 .385.073.531.219A.723.723 0 0 1 8.75 14a.723.723 0 0 1-.219.531.723.723 0 0 1-.531.219.723.723 0 0 1-.531-.219A.723.723 0 0 1 7.25 14c0-.208.073-.385.219-.531A.723.723 0 0 1 8 13.25zm3.617-1.008c0-.177.06-.325.18-.445s.268-.18.445-.18.326.06.445.18c.12.12.18.268.18.445s-.06.326-.18.445a.605.605 0 0 1-.445.18.605.605 0 0 1-.445-.18.605.605 0 0 1-.18-.445zM14 7.5a.48.48 0 0 1 .352.148A.48.48 0 0 1 14.5 8a.48.48 0 0 1-.148.352A.48.48 0 0 1 14 8.5a.48.48 0 0 1-.352-.148A.48.48 0 0 1 13.5 8a.48.48 0 0 1 .148-.352A.48.48 0 0 1 14 7.5zm-1.758-5.117c.188 0 .365.036.531.11a1.413 1.413 0 0 1 .735.734c.073.166.11.343.11.53 0 .188-.037.365-.11.532a1.413 1.413 0 0 1-.735.734 1.31 1.31 0 0 1-.53.11c-.188 0-.365-.037-.532-.11a1.415 1.415 0 0 1-.734-.734 1.31 1.31 0 0 1-.11-.531c0-.188.037-.365.11-.531a1.413 1.413 0 0 1 .734-.735c.167-.073.344-.11.531-.11z'></path>
              </svg>
            </button>
          </span>
        </div>
      </form>
    </div>
  );
};

export const MemoizedGroupAddPublicChunkForm = React.memo(GroupAddPublicChunkForm);