import React, { useState, useRef } from 'react';
import axios from 'axios';
import axiosRetry from 'axios-retry';
import { v4 as uuidv4 } from 'uuid';
import S3 from 'react-aws-s3';

// FORM MANAGEMENT
import { useForm } from 'react-hook-form';

// ROUTING
import { Link, useParams, useHistory } from 'react-router-dom';

// STATE
import { useSetRecoilState } from 'recoil';
import { collectionData } from '../../../state/atoms';

// ----------------------------------------------------------------------------------
// ------------------------------ ADD COLLECTION FORM -------------------------------
// ----------------------------------------------------------------------------------

export const AddCollectionForm = () => {
  // State
  const history = useHistory();
  const collections = useSetRecoilState(collectionData);
  const { pubID } = useParams();

  // Set axios to retry a set number of times on failed request/return
  axiosRetry(axios, { retries: 3 })

  // Form
  const { register, handleSubmit, errors, reset } = useForm({
    mode: 'onChange',
    defaultValues: {
      public: true,
    },
  });

  // Image State
  const [submitting, setSubmitting] = useState(false);
  const [loadingImg, setLoadingImg] = useState(false);
  const [image, setImage] = useState('');

  // AMAZON WEB SERVICES WITH S3
  const fileInput = useRef();

  const uploadImg = async (e) => {
    e.preventDefault();

    let file = fileInput.current.files[0]
    let newFileName = fileInput.current.files[0].name
    let newFileNameWithUUID = 'collections/' + newFileName.substring(0, newFileName.lastIndexOf('.')) + '_' + uuidv4();

    const config = {
      bucketName: process.env.REACT_APP_BUCKET_NAME,
      region: process.env.REACT_APP_REGION,
      accessKeyId: process.env.REACT_APP_ACCESS_ID,
      secretAccessKey: process.env.REACT_APP_ACCESS_KEY,
    };

    const ReactS3Client = new S3(config);
    ReactS3Client.uploadFile(file, newFileNameWithUUID).then(data => {
      if (data.status === 204) {
        const imageBucketURL = data.location
        setImage(imageBucketURL);
        setLoadingImg(false);
        console.log('Image successfully uploaded to S3!');
        return data
      } else {
        console.log('Image failed upload to S3');
      }
    })
  };

  // Function that handles submission of the form
  const onSubmit = (data) => {
    setSubmitting(true);
    axios({
      method: 'post',
      url: process.env.REACT_APP_API + 'collections',
      headers: {
        Accept: 'application/json',
        Authorization: process.env.REACT_APP_HEROKU_SERVER_KEY,
      },
      data: {
        ...data,
        img: image,
        publisher_id: pubID,
      },
    })
      .then((res) => {
        setSubmitting(false);
        collections((oldList) => {
          return [...oldList, res.data];
        });
        history.push(`/admin/publishers/${pubID}/collections`);
      })
      .catch((err) => {
        console.log(err);
        alert('something went wrong');
        setSubmitting(false);
      });
    reset();
  };

  return (
    <div className='flex justify-center'>
      <div className='px-4 py-5 bg-white sm:p-6'>
        <div className='md:grid md:grid-cols-3 md:gap-6'>
          <div className='md:col-span-1'>
            <p className='mt-1 text-sm font-bold leading-5 text-red-700'>
              This form creates a collection ( a show, or an exercise [anything
              that can be grouped together]). If you are looking to create a
              chunk that is not related to other chunks, go create a single
              chunk
            </p>
          </div>

          <div className='col-span-1'>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className='col-span-6 mt-6 sm:col-span-3'>
                <label
                  htmlFor='country'
                  className='text-base font-medium leading-6 text-gray-900'
                >
                  Collection Type
                </label>
                <select
                  id='collectionType'
                  name='collectionType'
                  ref={register}
                  className='block w-4/6 px-3 py-2 mt-1 transition duration-150 ease-in-out bg-white border border-gray-300 rounded-md shadow-sm form-select focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5'
                >
                  <option value=''>Select one</option>
                  <option value='Show Music'>Show Music</option>
                  <option value='Exercises & Etudes'>Exercises & Etudes</option>
                  <option value='Basics for Band'>Basics for Band</option>
                  <option value='Concert Music'>Concert Music</option>
                  <option value='Jazz Pop Combo'>Jazz Pop Combo</option>
                  <option value='Visual & Choreography'>Visual & Choreography</option>
                  <option value='Learn About Music Chunks'>Learn About Music Chunks</option>
                </select>
              </div>

              <div className='col-span-6 mt-6 sm:col-span-4'>
                <label
                  htmlFor='email_address'
                  className='block text-sm font-medium leading-5 text-gray-700'
                >
                  Collection Name
                </label>
                <input
                  id='name'
                  type='text'
                  name='name'
                  ref={register({ required: true })}
                  className='block w-full px-3 py-2 mt-1 transition duration-150 ease-in-out border border-gray-300 rounded-md shadow-sm form-input focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5'
                />
                {errors.name && (
                  <span className='text-red-500'>This field is required</span>
                )}
              </div>

              <div className='mt-6'>
                <label
                  htmlFor='about'
                  className='block text-sm font-medium leading-5 text-gray-700'
                >
                  Collection Description
                </label>
                <div className='rounded-md shadow-sm'>
                  <textarea
                    id='description'
                    name='description'
                    ref={register({ required: true })}
                    rows='7'
                    className='block w-full mt-1 transition duration-150 ease-in-out form-textarea sm:text-sm sm:leading-5'
                    placeholder='Tell us about this collection of music chunks'
                  ></textarea>
                </div>
                <p className='mt-2 text-sm text-gray-500'>
                  Brief description for this Collection
                </p>
                {errors.description && (
                  <span className='text-red-500'>This field is required</span>
                )}
              </div>

              <div className='mt-6 sm:mt-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
                <label
                  htmlFor='img'
                  className='block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2'
                >
                  Collection image
                </label>
                <div className='mt-2 sm:mt-0 sm:col-span-2'>
                  <div className='flex justify-center max-w-lg px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md'>
                    <div className='text-center'>
                      {image && !loadingImg ? (
                        <img src={image} alt='chunk img' />
                      ) : (
                        <svg
                          className='w-12 h-12 mx-auto text-gray-400'
                          stroke='currentColor'
                          fill='none'
                          viewBox='0 0 48 48'
                        >
                          <path
                            d='M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02'
                            strokeWidth='2'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      )}
                      {loadingImg ? (
                        <h3> Loading ...</h3>
                      ) : (
                        <h3>
                          <p className='mt-1 text-sm text-gray-600'>
                            <input
                              type='file'
                              name='file'
                              ref={fileInput}
                              placeholder='Upload an image here.'
                              onChange={uploadImg}
                            />
                          </p>
                          <p className='mt-1 text-xs text-gray-500'>
                            PNG, JPG, SVG up to 150MB
                          </p>
                        </h3>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className='col-span-6 mt-6'>
                <div className='flex justify-between'>
                  <label
                    htmlFor='street_address'
                    className='block text-sm font-medium leading-5 text-gray-700'
                  >
                    Collection URL
                  </label>
                  <span
                    className='text-sm leading-5 text-gray-500'
                    id='tagline-optional'
                  >
                    Optional
                  </span>
                </div>
                <input
                  id='URL'
                  type='url'
                  name='URL'
                  ref={register}
                  className='flex-1 block w-full transition duration-150 ease-in-out rounded-none form-input rounded-r-md sm:text-sm sm:leading-5'
                  placeholder='https://www.SHOW URL.com'
                />
              </div>

              <div className='col-span-6 mt-6 sm:col-span-4'>
                <div className='grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-4'>
                  <div className='flex items-center px-4 border border-red-800'>
                    <input
                      id='public'
                      name='public'
                      type='checkbox'
                      ref={register}
                      className='w-4 h-4 text-indigo-600 transition duration-150 ease-in-out form-checkbox'
                    />
                    <label
                      htmlFor='remember_me'
                      className='block py-2 ml-3 text-sm font-medium leading-5 text-gray-700'
                    >
                      public
                    </label>
                  </div>

                  <div className='flex items-center px-4 border border-red-800'>
                    <input
                      id='featured'
                      name='featured'
                      type='checkbox'
                      ref={register}
                      className='w-4 h-4 text-indigo-600 transition duration-150 ease-in-out form-checkbox'
                    />
                    <label
                      htmlFor='remember_me'
                      className='block py-2 ml-3 text-sm font-medium leading-5 text-gray-700'
                    >
                      featured
                    </label>
                  </div>
                </div>
              </div>

              <div className='flex justify-end w-full p-5 px-10 '>
                <span className='inline-flex space-x-8'>
                  <Link
                    to={`/admin/publishers/${pubID}/collections`}
                    className={`${loadingImg && 'opacity-50 cursor-not-allowed'
                      } ${submitting && 'opacity-50 cursor-not-allowed'
                      }inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs leading-4 font-medium rounded text-white bg-red-500 hover:bg-red-800 focus:outline-none focus:border-indigo-300 focus:shadow-outline-indigo active:bg-indigo-200 transition ease-in-out duration-150`}
                  >
                    Go Back
                  </Link>
                  <button
                    type='submit'
                    className={`${loadingImg && 'opacity-50 cursor-not-allowed'
                      } ${submitting && 'opacity-50 cursor-not-allowed'
                      }  inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150`}
                  >
                    {submitting ? '' : 'Submit'}
                    <svg
                      className={`animate-spin ${!submitting && 'hidden'}`}
                      stroke='currentColor'
                      fill='currentColor'
                      strokeWidth='0'
                      viewBox='0 0 16 16'
                      height='1em'
                      width='1em'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path d='M8 .75c.172 0 .333.034.484.102a1.214 1.214 0 0 1 .664.664c.068.15.102.312.102.484s-.034.333-.102.484a1.214 1.214 0 0 1-.265.399 1.324 1.324 0 0 1-.399.273A1.254 1.254 0 0 1 8 3.25c-.172 0-.333-.031-.484-.094a1.324 1.324 0 0 1-.672-.672A1.254 1.254 0 0 1 6.75 2c0-.172.031-.333.094-.484.067-.151.159-.284.273-.399.115-.114.248-.203.399-.265A1.17 1.17 0 0 1 8 .75zM2.633 3.758a1.111 1.111 0 0 1 .68-1.031 1.084 1.084 0 0 1 .882 0c.136.057.253.138.352.242.104.099.185.216.242.351a1.084 1.084 0 0 1 0 .883 1.122 1.122 0 0 1-.594.594 1.169 1.169 0 0 1-.883 0 1.19 1.19 0 0 1-.359-.234 1.19 1.19 0 0 1-.234-.36 1.169 1.169 0 0 1-.086-.445zM2 7a.941.941 0 0 1 .703.297A.941.941 0 0 1 3 8a.97.97 0 0 1-.078.39 1.03 1.03 0 0 1-.531.532A.97.97 0 0 1 2 9a.97.97 0 0 1-.39-.078 1.104 1.104 0 0 1-.32-.211 1.104 1.104 0 0 1-.212-.32A.97.97 0 0 1 1 8a.97.97 0 0 1 .29-.703A.97.97 0 0 1 2 7zm.883 5.242a.887.887 0 0 1 .531-.805.863.863 0 0 1 .68 0c.11.047.203.11.281.188a.887.887 0 0 1 .188.96.887.887 0 0 1-1.148.461.913.913 0 0 1-.462-.46.863.863 0 0 1-.07-.344zM8 13.25c.208 0 .385.073.531.219A.723.723 0 0 1 8.75 14a.723.723 0 0 1-.219.531.723.723 0 0 1-.531.219.723.723 0 0 1-.531-.219A.723.723 0 0 1 7.25 14c0-.208.073-.385.219-.531A.723.723 0 0 1 8 13.25zm3.617-1.008c0-.177.06-.325.18-.445s.268-.18.445-.18.326.06.445.18c.12.12.18.268.18.445s-.06.326-.18.445a.605.605 0 0 1-.445.18.605.605 0 0 1-.445-.18.605.605 0 0 1-.18-.445zM14 7.5a.48.48 0 0 1 .352.148A.48.48 0 0 1 14.5 8a.48.48 0 0 1-.148.352A.48.48 0 0 1 14 8.5a.48.48 0 0 1-.352-.148A.48.48 0 0 1 13.5 8a.48.48 0 0 1 .148-.352A.48.48 0 0 1 14 7.5zm-1.758-5.117c.188 0 .365.036.531.11a1.413 1.413 0 0 1 .735.734c.073.166.11.343.11.53 0 .188-.037.365-.11.532a1.413 1.413 0 0 1-.735.734 1.31 1.31 0 0 1-.53.11c-.188 0-.365-.037-.532-.11a1.415 1.415 0 0 1-.734-.734 1.31 1.31 0 0 1-.11-.531c0-.188.037-.365.11-.531a1.413 1.413 0 0 1 .734-.735c.167-.073.344-.11.531-.11z'></path>
                    </svg>
                  </button>
                </span>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
