import React, { useState, useEffect } from 'react';
import axios from 'axios';
import axiosRetry from 'axios-retry';

// ROUTING
import { withRouter, Route, Link, useRouteMatch } from 'react-router-dom';

// TAILWIND TRANSITION
import { Transition } from '@tailwindui/react';

// STATE
import { useSetRecoilState, useRecoilValue } from 'recoil';
import {
  publisherData,
  adminStats,
  collectionData,
  chunkData,
  instrumentData,
  sectionData,
  compositionData,
  soundRecordingData,
  groupData,
  userData
} from '../../state/atoms';

// COMPONENTS
import { AddCollectionForm } from './collections/AddCollectionForm';
import { AddPublisherForm } from './publisher/AddPublisherForm';
import { AddChunksForm } from './chunks/AddChunksForm';
import { AddInstrumentForm } from './instruments/AddInstrumentForm';
import { AddSectionForm } from './sections/AddSectionForm';
import { AddCompositionForm } from './compositions/AddCompositionForm';
import { AddSoundRecordingForm } from './soundRecordings/AddSoundRecordingsForm';
import { LinkSoundRecordingForm } from './soundRecordings/LinkSoundRecordingForm';
import { AddGroupForm } from './groups/AddGroupForm';
import { LinkGroupAdminForm } from './groups/LinkGroupAdminForm';
import { LinkGroupChunkForm } from './groups/LinkGroupChunkForm';
import { LinkPublisherAdminForm } from './publisher/LinkPublisherAdminForm';
import PublisherTable from './publisher/PublisherTable';
import CollectionTable from './collections/CollectionTable';
import ChunkTable from './chunks/ChunkTable';
import InstrumentTable from './instruments/InstrumentTable';
import SectionTable from './sections/SectionTable';
import CompositionTable from './compositions/CompositionTable';
import SoundRecordingTable from './soundRecordings/SoundRecordingTable';
import ChunkSoundRecordingTable from './soundRecordings/ChunkSoundRecordingTable';
import SingleChunksTable from './chunks/SingleChunksTable';
import AddSingleChunkForm from './chunks/AddSingleChunkForm';
import GroupTable from './groups/GroupTable';
import GroupAdminTable from './groups/GroupAdminTable';
import GroupChunkTable from './groups/GroupChunkTable';
import PublisherAdminTable from './publisher/PublisherAdminTable';
import ReportSelect from './reports/ReportSelect';
import MC_Usage_Report from './reports/MC/MC_Usage_Report';
import MC_Revenue_Report from './reports/MC/MC_Revenue_Report';
import MC_Ensemble_Report from './reports/MC/MC_Ensemble_Report';
import MLC_Usage_Report from './reports/MLC/MLC_Usage_Report';
import MLC_Revenue_Report from './reports/MLC/MLC_Revenue_Report';
import BMI_Usage_Report from './reports/BMI/BMI_Usage_Report';
import ASCAP_Usage_Report from './reports/ASCAP/ASCAP_Usage_Report';

// ----------------------------------------------------------------------------------
// ---------------------------------- ADMIN HOME ------------------------------------
// ----------------------------------------------------------------------------------

const AdminHome = () => {
  // State
  const [showAdminMenu, setShowAdminMenu] = useState(false);
  const [loadingPage, setLoadingPage] = useState(false);   //LOADING STATE

  // Site Data
  const setPublishers = useSetRecoilState(publisherData);
  const setCollections = useSetRecoilState(collectionData);
  const setChunks = useSetRecoilState(chunkData);
  const setInstruments = useSetRecoilState(instrumentData);
  const setSections = useSetRecoilState(sectionData);
  const setCompositions = useSetRecoilState(compositionData);
  const setSoundRecordings = useSetRecoilState(soundRecordingData);
  const setGroups = useSetRecoilState(groupData);
  const setUsers = useSetRecoilState(userData);

  // Site Stats
  const { totalChunks, totalPublishers, totalCollections, totalInstruments, totalSections, totalCompositions, totalSoundRecordings, totalGroups, totalUsers } = useRecoilValue(
    adminStats
  );

  // Set axios to retry a set number of times on failed request/return
  axiosRetry(axios, { retries: 3 })

  // UseEffect that gets all chunks, publishers, collections, instruments, and sections
  useEffect(() => {
    setLoadingPage(true);

    let chunkRequest = axios.get(process.env.REACT_APP_API + 'chunks', {
      headers: {
        Authorization: process.env.REACT_APP_HEROKU_SERVER_KEY,
      },
    });
    let publishersRequest = axios.get(
      process.env.REACT_APP_API + 'publishers',
      {
        headers: {
          Authorization: process.env.REACT_APP_HEROKU_SERVER_KEY,
        },
      }
    );
    let collectionRequest = axios.get(
      process.env.REACT_APP_API + 'collections',
      {
        headers: {
          Authorization: process.env.REACT_APP_HEROKU_SERVER_KEY,
        },
      }
    );
    let instrumentRequest = axios.get(
      process.env.REACT_APP_API + 'instruments',
      {
        headers: {
          Authorization: process.env.REACT_APP_HEROKU_SERVER_KEY,
        },
      }
    );
    let sectionRequest = axios.get(
      process.env.REACT_APP_API + `sections`,
      {
        headers: {
          Authorization: process.env.REACT_APP_HEROKU_SERVER_KEY,
        },
      }
    );
    let compositionRequest = axios.get(
      process.env.REACT_APP_API + `compositions`,
      {
        headers: {
          Authorization: process.env.REACT_APP_HEROKU_SERVER_KEY,
        },
      }
    );
    let soundRecordingRequest = axios.get(
      process.env.REACT_APP_API + `soundrecordings`,
      {
        headers: {
          Authorization: process.env.REACT_APP_HEROKU_SERVER_KEY,
        },
      }
    );
    let groupRequest = axios.get(
      process.env.REACT_APP_API + `groups/simple`,
      {
        headers: {
          Authorization: process.env.REACT_APP_HEROKU_SERVER_KEY,
        },
      }
    );
    let userRequest = axios.get(
      process.env.REACT_APP_API + `users`,
      {
        headers: {
          Authorization: process.env.REACT_APP_HEROKU_SERVER_KEY,
        },
      }
    );

    axios
      .all([chunkRequest, publishersRequest, collectionRequest, instrumentRequest, sectionRequest, compositionRequest, soundRecordingRequest, groupRequest, userRequest])
      .then(
        axios.spread((...res) => {
          let chunks = res[0].data;
          let publishers = res[1].data;
          let collections = res[2].data;
          let instruments = res[3].data;
          let sections = res[4].data;
          let compositions = res[5].data;
          let soundRecordings = res[6].data;
          let groups = res[7].data;
          let users = res[8].data;

          setChunks(chunks);
          setPublishers(publishers);
          setCollections(collections);
          setInstruments(instruments);
          setSections(sections);
          setCompositions(compositions);
          setSoundRecordings(soundRecordings);
          setGroups(groups);
          setUsers(users);
          setLoadingPage(false);
        })
      )
      .catch((err) => {
        console.log(err);
      });
  }, [setPublishers, setCollections, setChunks, setInstruments, setSections, setCompositions, setSoundRecordings, setGroups, setUsers]);

  // Route matcher for conditional routing
  const homeMatch = useRouteMatch('/admin');
  const reportMatch = useRouteMatch('/admin/reports')
  const instMatch = useRouteMatch('/admin/instruments')
  const groupMatch = useRouteMatch('/admin/ensembles')
  const addInstMatch = useRouteMatch('/admin/instruments/add-instrument')
  const sectionMatch = useRouteMatch('/admin/sections')
  const addSectionMatch = useRouteMatch('/admin/sections/add-section')
  const compositionMatch = useRouteMatch('/admin/compositions')
  const addCompositionMatch = useRouteMatch('/admin/compositions/add-composition')
  const compRecordingsMatch = useRouteMatch(
    '/admin/compositions/:compID/soundrecordings'
  );
  const addCompRecordingMatch = useRouteMatch(
    '/admin/compositions/:compID/add-soundrecording'
  );
  const groupAdminMatch = useRouteMatch('/admin/publishers/:pubID/ensembles/:groupID/admins')
  const groupChunkMatch = useRouteMatch('/admin/publishers/:pubID/ensembles/:groupID/chunks')
  const linkGroupChunkMatch = useRouteMatch('/admin/publishers/:pubID/ensembles/:groupID/link-chunk')
  const linkGroupAdminMatch = useRouteMatch('/admin/publishers/:pubID/ensembles/:groupID/link-admin')
  const publisherAdminMatch = useRouteMatch('/admin/publishers/:pubID/admins')
  const linkPublisherAdminMatch = useRouteMatch('/admin/publishers/:pubID/link-admin')
  const pubMatch = useRouteMatch('/admin/publishers');
  const addPubMatch = useRouteMatch('/admin/publishers/add-publisher');
  const pubEnsembleMatch = useRouteMatch(
    '/admin/publishers/:pubID/ensembles'
  );
  const addPubEnsembleMatch = useRouteMatch(
    '/admin/publishers/:pubID/add-ensemble'
  );
  const pubCollectionMatch = useRouteMatch(
    '/admin/publishers/:pubID/collections'
  );
  const addPubCollectionMatch = useRouteMatch(
    '/admin/publishers/:pubID/add-collection'
  );
  const colChunksMatch = useRouteMatch(
    '/admin/publishers/:pubID/collections/:colID/chunks'
  );
  const addColChunkMatch = useRouteMatch(
    '/admin/publishers/:pubID/collections/:colID/add-chunk'
  );
  const chunkSoundRecordingsMatch = useRouteMatch(
    '/admin/publishers/:pubID/collections/:colID/chunks/:chunkID/soundrecordings'
  );
  const linkChunkSoundRecordingMatch = useRouteMatch(
    '/admin/publishers/:pubID/collections/:colID/chunks/:chunkID/link-soundrecording'
  );
  const pubSingleChunks = useRouteMatch(
    '/admin/publishers/:pubID/singlechunks'
  );
  const addSingleChunk = useRouteMatch(
    '/admin/publishers/:pubID/addsinglechunk'
  );
  const singleChunkSoundRecordingsMatch = useRouteMatch(
    '/admin/publishers/:pubID/singlechunks/:chunkID/soundrecordings'
  );
  const linkSingleChunkSoundRecordingMatch = useRouteMatch(
    '/admin/publishers/:pubID/singlechunks/:chunkID/link-soundrecording'
  );
  const reportMCUsageQuarterlyMatch = useRouteMatch('/admin/reports/mc-usage-quarterly')
  const reportMCUsageAnnualMatch = useRouteMatch('/admin/reports/mc-usage-annual')
  const reportMCRevenueQuarterlyMatch = useRouteMatch('/admin/reports/mc-revenue-quarterly')
  const reportMCRevenueAnnualMatch = useRouteMatch('/admin/reports/mc-revenue-annual')
  const reportMCEnsembleMonthlyMatch = useRouteMatch('/admin/reports/mc-ensemble-monthly')
  const reportMLCUsageMonthlyMatch = useRouteMatch('/admin/reports/mlc-usage-monthly')
  const reportMLCUsageAnnualMatch = useRouteMatch('/admin/reports/mlc-usage-annual')
  const reportMLCRevenueMonthlyMatch = useRouteMatch('/admin/reports/mlc-revenue-monthly')
  const reportMLCRevenueAnnualMatch = useRouteMatch('/admin/reports/mlc-revenue-annual')
  const reportBMIUsageStandardQuarterlyMatch = useRouteMatch('/admin/reports/bmi-usage-quarterly-standard')
  const reportBMIUsageStandardAnnualMatch = useRouteMatch('/admin/reports/bmi-usage-annual-standard')
  const reportBMIUsageEnsembleQuarterlyMatch = useRouteMatch('/admin/reports/bmi-usage-quarterly-ensemble')
  const reportBMIUsageEnsembleAnnualMatch = useRouteMatch('/admin/reports/bmi-usage-annual-ensemble')
  const reportASCAPUsageStandardQuarterlyMatch = useRouteMatch('/admin/reports/ascap-usage-quarterly-standard')
  const reportASCAPUsageStandardAnnualMatch = useRouteMatch('/admin/reports/ascap-usage-Annual-standard')
  const reportASCAPUsageEnsembleQuarterlyMatch = useRouteMatch('/admin/reports/ascap-usage-quarterly-ensemble')
  const reportASCAPUsageEnsembleAnnualMatch = useRouteMatch('/admin/reports/ascap-usage-annual-ensemble')

  // Renders on refresh while page is loading
  if (loadingPage) {
    return (
      <div className='flex items-center justify-center h-screen text-4xl w-100'>
        Loading...
      </div>
    );
  }

  return (
    <div className='flex h-screen overflow-hidden bg-white'>
      {/* <!-- Static sidebar for desktop --> */}
      <div className='flex flex-shrink-0'>
        <div className='flex flex-col w-64 pt-5 pb-4 bg-gray-100 border-r border-gray-200'>
          <div className='flex items-center flex-shrink-0 px-6'>
            {/* <img
              className='w-auto h-8'
              src='https://tailwindui.com/img/logos/workflow-logo-purple-on-white.svg'
              alt='Workflow'
            /> */}
          </div>
          {/* <!-- Sidebar component --> */}
          <div className='flex flex-col flex-1 h-0 overflow-y-auto'>
            {/* <!-- User account dropdown --> */}
            <div className='relative inline-block px-3 mt-6 text-left'>
              {/* <!-- Dropdown menu toggle, controlling the show/hide state of dropdown menu. --> */}
              <div>
                <button
                  type='button'
                  className='group w-full rounded-md px-3.5 py-2 text-sm leading-5 font-medium text-gray-700 hover:bg-gray-50 hover:text-gray-500 focus:outline-none focus:bg-gray-200 focus:border-blue-300 active:bg-gray-50 active:text-gray-800 transition ease-in-out duration-150'
                  id='options-menu'
                  aria-haspopup='true'
                  aria-expanded='true'
                  onClick={() => {
                    setShowAdminMenu(!showAdminMenu);
                  }}
                >
                  <div className='flex items-center justify-between w-full'>
                    <div className='flex items-center justify-between min-w-0 space-x-3'>
                      <div className='flex-1 min-w-0'>
                        <h2 className='text-sm font-medium leading-5 text-gray-900 truncate'>
                          Admin Name?
                        </h2>
                      </div>
                    </div>
                    {/* <!-- Heroicon name: selector --> */}
                    <svg
                      className='flex-shrink-0 w-5 h-5 text-gray-400 group-hover:text-gray-500'
                      xmlns='http://www.w3.org/2000/svg'
                      viewBox='0 0 20 20'
                      fill='currentColor'
                    >
                      <path
                        fillRule='evenodd'
                        d='M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z'
                        clipRule='evenodd'
                      />
                    </svg>
                  </div>
                </button>
              </div>
              {/* <!--
              Dropdown panel, show/hide based on dropdown state.
  
              Entering: "transition ease-out duration-100"
                From: "transform opacity-0 scale-95"
                To: "transform opacity-100 scale-100"
              Leaving: "transition ease-in duration-75"
                From: "transform opacity-100 scale-100"
                To: "transform opacity-0 scale-95"
            --> */}
              <Transition show={showAdminMenu}>
                <div className='absolute left-0 right-0 z-10 mx-3 mt-1 origin-top rounded-md shadow-lg'>
                  <div
                    className='bg-white rounded-md shadow-xs'
                    role='menu'
                    aria-orientation='vertical'
                    aria-labelledby='options-menu'
                  >
                    <div className='py-1'>
                      <a
                        href='/dashboard'
                        className='block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900'
                        role='menuitem'
                      >
                        Go to desktop app
                      </a>
                    </div>
                    {/* <div className='border-t border-gray-100'></div>
                    <div className='py-1'>
                      <a
                        href='/logout'
                        className='block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900'
                        role='menuitem'
                      >
                        Logout
                      </a>
                    </div> */}
                  </div>
                </div>
              </Transition>
            </div>
            {/* <!-- Sidebar Search --> */}
            <div className='px-3 mt-5'>
              <label htmlFor='search' className='sr-only'>
                Search
              </label>
              <div className='relative mt-1 rounded-md shadow-sm'>
                <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
                  {/* <!-- Heroicon name: search --> */}
                  <svg
                    className='w-4 h-4 mr-3 text-gray-400'
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 20 20'
                    fill='currentColor'
                  >
                    <path
                      fillRule='evenodd'
                      d='M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z'
                      clipRule='evenodd'
                    />
                  </svg>
                </div>
                <input
                  id='search'
                  className='block py-2 w-full pl-9 border border-gray-300 rounded-md shadow-sm form-input focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5'
                  placeholder='Search'
                />
              </div>
            </div>
            {/* <!-- Navigation --> */}
            <nav className='px-3 mt-6'>
              <div className='space-y-1'>
                <Link
                  to='/admin'
                  className='flex items-center px-2 py-2 text-sm font-medium leading-5 text-gray-900 transition duration-150 ease-in-out bg-gray-200 rounded-md group focus:outline-none focus:bg-gray-50'
                >
                  {/* <!-- Heroicon name: home --> */}
                  <svg
                    className='w-6 h-6 mr-3 text-gray-500 transition duration-150 ease-in-out group-hover:text-gray-500 group-focus:text-gray-600'
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                    stroke='currentColor'
                  >
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      strokeWidth='2'
                      d='M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6'
                    />
                  </svg>
                  Home
                </Link>

                <Link
                  to='/admin/publishers'
                  className='flex items-center px-2 py-2 text-sm font-medium leading-5 text-gray-700 transition duration-150 ease-in-out rounded-md group hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:bg-gray-50'
                >
                  {/* <!-- Heroicon name: view-list --> */}
                  <svg
                    className='w-6 h-6 mr-3 text-gray-400 transition duration-150 ease-in-out group-hover:text-gray-500 group-focus:text-gray-600'
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                    stroke='currentColor'
                  >
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      strokeWidth='2'
                      d='M4 6h16M4 10h16M4 14h16M4 18h16'
                    />
                  </svg>
                  Publishers
                </Link>

                <Link
                  to='/admin/ensembles'
                  className='flex items-center px-2 py-2 text-sm font-medium leading-5 text-gray-700 transition duration-150 ease-in-out rounded-md group hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:bg-gray-50'
                >
                  {/* <!-- Heroicon name: view-list --> */}
                  <svg
                    className='w-6 h-6 mr-3 text-gray-400 transition duration-150 ease-in-out group-hover:text-gray-500 group-focus:text-gray-600'
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                    stroke='currentColor'
                  >
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      strokeWidth='2'
                      d='M4 6h16M4 10h16M4 14h16M4 18h16'
                    />
                  </svg>
                  Ensembles
                </Link>

                <Link
                  to='/admin/instruments'
                  className='flex items-center px-2 py-2 text-sm font-medium leading-5 text-gray-700 transition duration-150 ease-in-out rounded-md group hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:bg-gray-50'
                >
                  {/* <!-- Heroicon name: view-list --> */}
                  <svg
                    className='w-6 h-6 mr-3 text-gray-400 transition duration-150 ease-in-out group-hover:text-gray-500 group-focus:text-gray-600'
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                    stroke='currentColor'
                  >
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      strokeWidth='2'
                      d='M4 6h16M4 10h16M4 14h16M4 18h16'
                    />
                  </svg>
                  Instruments
                </Link>

                <Link
                  to='/admin/sections'
                  className='flex items-center px-2 py-2 text-sm font-medium leading-5 text-gray-700 transition duration-150 ease-in-out rounded-md group hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:bg-gray-50'
                >
                  {/* <!-- Heroicon name: view-list --> */}
                  <svg
                    className='w-6 h-6 mr-3 text-gray-400 transition duration-150 ease-in-out group-hover:text-gray-500 group-focus:text-gray-600'
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                    stroke='currentColor'
                  >
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      strokeWidth='2'
                      d='M4 6h16M4 10h16M4 14h16M4 18h16'
                    />
                  </svg>
                  Playlists
                </Link>

                <Link
                  to='/admin/compositions'
                  className='flex items-center px-2 py-2 text-sm font-medium leading-5 text-gray-700 transition duration-150 ease-in-out rounded-md group hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:bg-gray-50'
                >
                  <svg
                    className='w-6 h-6 mr-3 text-gray-400 transition duration-150 ease-in-out group-hover:text-gray-500 group-focus:text-gray-600'
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                    stroke='currentColor'
                  >
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      strokeWidth='2'
                      d='M4 6h16M4 10h16M4 14h16M4 18h16'
                    />
                  </svg>
                  Compositions
                </Link>

                <Link
                  to='/admin/reports'
                  className='flex items-center px-2 py-2 text-sm font-medium leading-5 text-gray-700 transition duration-150 ease-in-out rounded-md group hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:bg-gray-50'
                >
                  <svg
                    className='w-6 h-6 mr-3 text-gray-400 transition duration-150 ease-in-out group-hover:text-gray-500 group-focus:text-gray-600'
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                    stroke='currentColor'
                  >
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      strokeWidth='2'
                      d='M4 6h16M4 10h16M4 14h16M4 18h16'
                    />
                  </svg>
                  Reports
                </Link>
              </div>
            </nav>
          </div>
        </div>
      </div>
      {/* <!-- Main column --> */}
      <div className='flex flex-col flex-1 w-0 overflow-hidden'>
        <main
          className='relative z-0 flex-1 overflow-y-auto focus:outline-none'
          tabIndex='0'
        >
          {/* <!-- Page title & actions --> */}
          <div className='px-4 py-4 border-b border-gray-200 sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8'>
            <div className='flex-1 min-w-0'>
              <h1 className='text-lg font-medium leading-6 text-gray-900 sm:truncate'>
                {homeMatch?.isExact && 'Home'}
                {pubMatch?.isExact && 'Publishers'}
                {addPubMatch && 'Add a Publisher'}
                {pubEnsembleMatch?.isExact && 'Ensembles'}
                {addPubEnsembleMatch && 'Add a Ensemble'}
                {pubCollectionMatch?.isExact && 'Collections'}
                {addPubCollectionMatch && 'Add a Collection'}
                {colChunksMatch?.isExact && 'Collection Chunks'}
                {addColChunkMatch && 'Add Chunk to a Collection'}
                {pubSingleChunks?.isExact && 'Chunks not in a Collection'}
                {addSingleChunk?.isExact && 'Add a chunk not in a Collection'}
                {chunkSoundRecordingsMatch?.isExact && 'Chunk Recordings'}
                {linkChunkSoundRecordingMatch?.isExact && 'Link a Recording'}
                {singleChunkSoundRecordingsMatch?.isExact && 'Single Chunk Recordings'}
                {linkSingleChunkSoundRecordingMatch?.isExact && 'Link a Recording to a chunk Not in a Collection'}
                {groupMatch?.isExact && 'Ensembles'}
                {instMatch?.isExact && 'Instruments'}
                {addInstMatch && 'Add an Instrument'}
                {sectionMatch?.isExact && 'Playlists'}
                {addSectionMatch && 'Add a Playlist'}
                {compositionMatch?.isExact && 'Compositions'}
                {addCompositionMatch && 'Add a Composition'}
                {compRecordingsMatch?.isExact && 'Recordings'}
                {addCompRecordingMatch && 'Add a Recording'}
                {groupAdminMatch?.isExact && 'Group Admins'}
                {groupChunkMatch?.isExact && 'Group Chunks'}
                {linkGroupChunkMatch?.isExact && 'Link a Chunk'}
                {linkGroupAdminMatch?.isExact && 'Link an Admin'}
                {publisherAdminMatch?.isExact && 'Publisher Admins'}
                {linkPublisherAdminMatch?.isExact && 'Link an Admin'}
                {reportMatch?.isExact && 'Reports'}
                {reportMCUsageQuarterlyMatch?.isExact && 'MC Usage - Quarterly Report'}
                {reportMCUsageAnnualMatch?.isExact && 'MC Usage - Annual Report'}
                {reportMCRevenueQuarterlyMatch?.isExact && 'MC Revenue - Quarterly Report'}
                {reportMCRevenueAnnualMatch?.isExact && 'MC Revenue - Annual Report'}
                {reportMCEnsembleMonthlyMatch?.isExact && 'MC Ensemble - Monthly Report'}
                {reportMLCUsageMonthlyMatch?.isExact && 'MLC Usage - Monthly Report'}
                {reportMLCUsageAnnualMatch?.isExact && 'MLC Usage - Annual Report'}
                {reportMLCRevenueMonthlyMatch?.isExact && 'MLC Revenue - Monthly Report'}
                {reportMLCRevenueAnnualMatch?.isExact && 'MLC Revenue - Annual Report'}
                {reportBMIUsageStandardQuarterlyMatch?.isExact && 'BMI Usage - Standard Quarterly Report'}
                {reportBMIUsageStandardAnnualMatch?.isExact && 'BMI Usage - Standard Annual Report'}
                {reportBMIUsageEnsembleQuarterlyMatch?.isExact && 'BMI Usage - Ensemble Quarterly Report'}
                {reportBMIUsageEnsembleAnnualMatch?.isExact && 'BMI Usage - Ensemble Annual Report'}
                {reportASCAPUsageStandardQuarterlyMatch?.isExact && 'ASCAP Usage - Standard Quarterly Report'}
                {reportASCAPUsageStandardAnnualMatch?.isExact && 'ASCAP Usage - Standard Annual Report'}
                {reportASCAPUsageEnsembleQuarterlyMatch?.isExact && 'ASCAP Usage - Ensemble Quarterly Report'}
                {reportASCAPUsageEnsembleAnnualMatch?.isExact && 'ASCAP Usage - Ensemble Annual Report'}
              </h1>
            </div>
            {pubMatch?.isExact && (
              <div className='flex mt-4 sm:mt-0 sm:ml-4'>
                {/* <span className='order-1 ml-3 rounded-md shadow-sm sm:order-0 sm:ml-0'>
                <button
                  type='button'
                  className='inline-flex items-center px-4 py-2 text-sm font-medium leading-5 text-gray-700 transition duration-150 ease-in-out bg-white border border-gray-300 rounded-md hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:text-gray-800 active:bg-gray-50'
                >
                  Share
                </button>
              </span> */}
                <span className='rounded-md shadow-sm order-0 sm:order-1 sm:ml-3'>
                  <Link
                    to={'/admin/publishers/add-publisher'}
                    type='button'
                    className='inline-flex items-center px-4 py-2 text-sm font-medium leading-5 text-white transition duration-150 ease-in-out bg-purple-600 border border-transparent rounded-md hover:bg-purple-500 focus:outline-none focus:shadow-outline-purple focus:border-purple-700 active:bg-indigo-700'
                  >
                    Create Publisher
                  </Link>
                </span>
              </div>
            )}

            {pubEnsembleMatch?.isExact && (
              <div className='flex mt-4 sm:mt-0 sm:ml-4'>
                <span className='rounded-md shadow-sm order-0 sm:order-1 sm:ml-3'>
                  <Link
                    to={`/admin/publishers/${pubEnsembleMatch.params.pubID}/add-ensemble`}
                    type='button'
                    className='inline-flex items-center px-4 py-2 text-sm font-medium leading-5 text-white transition duration-150 ease-in-out bg-purple-600 border border-transparent rounded-md hover:bg-purple-500 focus:outline-none focus:shadow-outline-purple focus:border-purple-700 active:bg-indigo-700'
                  >
                    Create Ensemble
                  </Link>
                </span>
              </div>
            )}

            {pubCollectionMatch?.isExact && (
              <div className='flex mt-4 sm:mt-0 sm:ml-4'>
                <span className='rounded-md shadow-sm order-0 sm:order-1 sm:ml-3'>
                  <Link
                    to={`/admin/publishers/${pubCollectionMatch.params.pubID}/add-collection`}
                    type='button'
                    className='inline-flex items-center px-4 py-2 text-sm font-medium leading-5 text-white transition duration-150 ease-in-out bg-purple-600 border border-transparent rounded-md hover:bg-purple-500 focus:outline-none focus:shadow-outline-purple focus:border-purple-700 active:bg-indigo-700'
                  >
                    Create Collection
                  </Link>
                </span>
              </div>
            )}

            {colChunksMatch?.isExact && (
              <div className='flex mt-4 sm:mt-0 sm:ml-4'>
                <span className='rounded-md shadow-sm order-0 sm:order-1 sm:ml-3'>
                  <Link
                    to={`/admin/publishers/${colChunksMatch.params.pubID}/collections/${colChunksMatch.params.colID}/add-chunk`}
                    type='button'
                    className='inline-flex items-center px-4 py-2 text-sm font-medium leading-5 text-white transition duration-150 ease-in-out bg-purple-600 border border-transparent rounded-md hover:bg-purple-500 focus:outline-none focus:shadow-outline-purple focus:border-purple-700 active:bg-indigo-700'
                  >
                    Create Chunk
                  </Link>
                </span>
              </div>
            )}

            {chunkSoundRecordingsMatch?.isExact && (
              <div className='flex mt-4 sm:mt-0 sm:ml-4'>
                <span className='rounded-md shadow-sm order-0 sm:order-1 sm:ml-3'>
                  <Link
                    to={`/admin/publishers/${chunkSoundRecordingsMatch.params.pubID}/collections/${chunkSoundRecordingsMatch.params.colID}/chunks/${chunkSoundRecordingsMatch.params.chunkID}/link-soundrecording`}
                    type='button'
                    className='inline-flex items-center px-4 py-2 text-sm font-medium leading-5 text-white transition duration-150 ease-in-out bg-purple-600 border border-transparent rounded-md hover:bg-purple-500 focus:outline-none focus:shadow-outline-purple focus:border-purple-700 active:bg-indigo-700'
                  >
                    Link a Recording
                  </Link>
                </span>
              </div>
            )}

            {pubSingleChunks?.isExact && (
              <div className='flex mt-4 sm:mt-0 sm:ml-4'>
                <span className='rounded-md shadow-sm order-0 sm:order-1 sm:ml-3'>
                  <Link
                    to={`/admin/publishers/${pubSingleChunks.params.pubID}/addsinglechunk`}
                    type='button'
                    className='inline-flex items-center px-4 py-2 text-sm font-medium leading-5 text-white transition duration-150 ease-in-out bg-purple-600 border border-transparent rounded-md hover:bg-purple-500 focus:outline-none focus:shadow-outline-purple focus:border-purple-700 active:bg-indigo-700'
                  >
                    Create Single Chunk
                  </Link>
                </span>
              </div>
            )}

            {singleChunkSoundRecordingsMatch?.isExact && (
              <div className='flex mt-4 sm:mt-0 sm:ml-4'>
                <span className='rounded-md shadow-sm order-0 sm:order-1 sm:ml-3'>
                  <Link
                    to={`/admin/publishers/${singleChunkSoundRecordingsMatch.params.pubID}/singlechunks/${singleChunkSoundRecordingsMatch.params.chunkID}/link-soundrecording`}
                    type='button'
                    className='inline-flex items-center px-4 py-2 text-sm font-medium leading-5 text-white transition duration-150 ease-in-out bg-purple-600 border border-transparent rounded-md hover:bg-purple-500 focus:outline-none focus:shadow-outline-purple focus:border-purple-700 active:bg-indigo-700'
                  >
                    Link a Recording
                  </Link>
                </span>
              </div>
            )}

            {instMatch?.isExact && (
              <div className='flex mt-4 sm:mt-0 sm:ml-4'>
                <span className='rounded-md shadow-sm order-0 sm:order-1 sm:ml-3'>
                  <Link
                    to={'/admin/instruments/add-instrument'}
                    type='button'
                    className='inline-flex items-center px-4 py-2 text-sm font-medium leading-5 text-white transition duration-150 ease-in-out bg-purple-600 border border-transparent rounded-md hover:bg-purple-500 focus:outline-none focus:shadow-outline-purple focus:border-purple-700 active:bg-indigo-700'
                  >
                    Create Instrument
                  </Link>
                </span>
              </div>
            )}

            {sectionMatch?.isExact && (
              <div className='flex mt-4 sm:mt-0 sm:ml-4'>
                <span className='rounded-md shadow-sm order-0 sm:order-1 sm:ml-3'>
                  <Link
                    to={'/admin/sections/add-section'}
                    type='button'
                    className='inline-flex items-center px-4 py-2 text-sm font-medium leading-5 text-white transition duration-150 ease-in-out bg-purple-600 border border-transparent rounded-md hover:bg-purple-500 focus:outline-none focus:shadow-outline-purple focus:border-purple-700 active:bg-indigo-700'
                  >
                    Create Playlist
                  </Link>
                </span>
              </div>
            )}

            {compositionMatch?.isExact && (
              <div className='flex mt-4 sm:mt-0 sm:ml-4'>
                <span className='rounded-md shadow-sm order-0 sm:order-1 sm:ml-3'>
                  <Link
                    to={'/admin/compositions/add-composition'}
                    type='button'
                    className='inline-flex items-center px-4 py-2 text-sm font-medium leading-5 text-white transition duration-150 ease-in-out bg-purple-600 border border-transparent rounded-md hover:bg-purple-500 focus:outline-none focus:shadow-outline-purple focus:border-purple-700 active:bg-indigo-700'
                  >
                    Create Composition
                  </Link>
                </span>
              </div>
            )}

            {compRecordingsMatch?.isExact && (
              <div className='flex mt-4 sm:mt-0 sm:ml-4'>
                <span className='rounded-md shadow-sm order-0 sm:order-1 sm:ml-3'>
                  <Link
                    to={`/admin/compositions/${compRecordingsMatch.params.compID}/add-soundrecording`}
                    type='button'
                    className='inline-flex items-center px-4 py-2 text-sm font-medium leading-5 text-white transition duration-150 ease-in-out bg-purple-600 border border-transparent rounded-md hover:bg-purple-500 focus:outline-none focus:shadow-outline-purple focus:border-purple-700 active:bg-indigo-700'
                  >
                    Create Recording
                  </Link>
                </span>
              </div>
            )}

            {groupAdminMatch?.isExact && (
              <div className='flex mt-4 sm:mt-0 sm:ml-4'>
                <span className='rounded-md shadow-sm order-0 sm:order-1 sm:ml-3'>
                  <Link
                    to={`/admin/publishers/${pubEnsembleMatch.params.pubID}/ensembles/${groupAdminMatch.params.groupID}/link-admin`}
                    type='button'
                    className='inline-flex items-center px-4 py-2 text-sm font-medium leading-5 text-white transition duration-150 ease-in-out bg-purple-600 border border-transparent rounded-md hover:bg-purple-500 focus:outline-none focus:shadow-outline-purple focus:border-purple-700 active:bg-indigo-700'
                  >
                    Edit Admins
                  </Link>
                </span>
              </div>
            )}

            {groupChunkMatch?.isExact && (
              <div className='flex mt-4 sm:mt-0 sm:ml-4'>
                <span className='rounded-md shadow-sm order-0 sm:order-1 sm:ml-3'>
                  <Link
                    to={`/admin/publishers/${pubMatch.params.pubID}/ensembles/${groupChunkMatch.params.groupID}/link-chunk`}
                    type='button'
                    className='inline-flex items-center px-4 py-2 text-sm font-medium leading-5 text-white transition duration-150 ease-in-out bg-purple-600 border border-transparent rounded-md hover:bg-purple-500 focus:outline-none focus:shadow-outline-purple focus:border-purple-700 active:bg-indigo-700'
                  >
                    Link Chunk
                  </Link>
                </span>
              </div>
            )}

            {publisherAdminMatch?.isExact && (
              <div className='flex mt-4 sm:mt-0 sm:ml-4'>
                <span className='rounded-md shadow-sm order-0 sm:order-1 sm:ml-3'>
                  <Link
                    to={`/admin/publishers/${publisherAdminMatch.params.pubID}/link-admin`}
                    type='button'
                    className='inline-flex items-center px-4 py-2 text-sm font-medium leading-5 text-white transition duration-150 ease-in-out bg-purple-600 border border-transparent rounded-md hover:bg-purple-500 focus:outline-none focus:shadow-outline-purple focus:border-purple-700 active:bg-indigo-700'
                  >
                    Edit Admins
                  </Link>
                </span>
              </div>
            )}
          </div>

          <Route path='/admin/publishers/add-publisher'>
            <AddPublisherForm />
          </Route>
          <Route path='/admin/publishers/:pubID/add-ensemble'>
            <AddGroupForm />
          </Route>
          <Route path='/admin/publishers/:pubID/add-collection'>
            <AddCollectionForm />
          </Route>
          <Route path='/admin/publishers/:pubID/collections/:colID/add-chunk'>
            <AddChunksForm />
          </Route>
          <Route exact path='/admin/publishers/:pubID/collections/:colID/chunks'>
            <ChunkTable />
          </Route>
          <Route exact path='/admin/publishers/:pubID/collections/:colID/chunks/:chunkID/soundrecordings'>
            <ChunkSoundRecordingTable />
          </Route>
          <Route exact path='/admin/publishers/:pubID/collections'>
            <CollectionTable />
          </Route>
          <Route exact path='/admin/publishers/:pubID/ensembles'>
            <GroupTable />
          </Route>
          <Route exact path='/admin/publishers'>
            <PublisherTable />
          </Route>
          <Route exact path='/admin/publishers/:pubID/singlechunks'>
            <SingleChunksTable />
          </Route>
          <Route exact path='/admin/publishers/:pubID/addsinglechunk'>
            <AddSingleChunkForm />
          </Route>
          <Route path='/admin/instruments/add-instrument'>
            <AddInstrumentForm />
          </Route>
          <Route exact path='/admin/instruments'>
            <InstrumentTable />
          </Route>
          <Route path='/admin/sections/add-section'>
            <AddSectionForm />
          </Route>
          <Route exact path='/admin/sections'>
            <SectionTable />
          </Route>
          <Route path='/admin/compositions/add-composition'>
            <AddCompositionForm />
          </Route>
          <Route exact path='/admin/compositions'>
            <CompositionTable />
          </Route>
          <Route exact path='/admin/compositions/:compID/add-soundrecording'>
            <AddSoundRecordingForm />
          </Route>
          <Route exact path='/admin/publishers/:pubID/collections/:colID/chunks/:chunkID/link-soundrecording'>
            <LinkSoundRecordingForm />
          </Route>
          <Route exact path='/admin/compositions/:compID/soundrecordings'>
            <SoundRecordingTable />
          </Route>
          <Route exact path='/admin/publishers/:pubID/singlechunks/:chunkID/soundrecordings'>
            <ChunkSoundRecordingTable />
          </Route>
          <Route exact path='/admin/publishers/:pubID/singlechunks/:chunkID/link-soundrecording'>
            <LinkSoundRecordingForm />
          </Route>
          <Route exact path='/admin/ensembles'>
            <GroupTable />
          </Route>
          <Route exact path='/admin/publishers/:pubID/ensembles/:groupID/admins'>
            <GroupAdminTable />
          </Route>
          <Route exact path='/admin/publishers/:pubID/ensembles/:groupID/link-admin'>
            <LinkGroupAdminForm />
          </Route>
          <Route exact path='/admin/publishers/:pubID/ensembles/:groupID/chunks'>
            <GroupChunkTable />
          </Route>
          <Route exact path='/admin/publishers/:pubID/ensembles/:groupID/link-chunk'>
            <LinkGroupChunkForm />
          </Route>
          <Route exact path='/admin/publishers/:pubID/admins'>
            <PublisherAdminTable />
          </Route>
          <Route exact path='/admin/publishers/:pubID/link-admin'>
            <LinkPublisherAdminForm />
          </Route>
          <Route exact path='/admin/reports'>
            <ReportSelect />
          </Route>
          <Route exact path='/admin/reports/mc-usage-quarterly'>
            <MC_Usage_Report />
          </Route>
          <Route exact path='/admin/reports/mc-usage-annual'>
            <MC_Usage_Report />
          </Route>
          <Route exact path='/admin/reports/mc-revenue-quarterly'>
            <MC_Revenue_Report />
          </Route>
          <Route exact path='/admin/reports/mc-revenue-annual'>
            <MC_Revenue_Report />
          </Route>
          <Route exact path='/admin/reports/mc-ensemble-monthly'>
            <MC_Ensemble_Report />
          </Route>
          <Route exact path='/admin/reports/mlc-usage-monthly'>
            <MLC_Usage_Report />
          </Route>
          <Route exact path='/admin/reports/mlc-usage-annual'>
            <MLC_Usage_Report />
          </Route>
          <Route exact path='/admin/reports/mlc-revenue-monthly'>
            <MLC_Revenue_Report />
          </Route>
          <Route exact path='/admin/reports/mlc-revenue-annual'>
            <MLC_Revenue_Report />
          </Route>
          <Route exact path='/admin/reports/bmi-usage-quarterly-standard'>
            <BMI_Usage_Report />
          </Route>
          <Route exact path='/admin/reports/bmi-usage-annual-standard'>
            <BMI_Usage_Report />
          </Route>
          <Route exact path='/admin/reports/bmi-usage-quarterly-ensemble'>
            <BMI_Usage_Report />
          </Route>
          <Route exact path='/admin/reports/bmi-usage-annual-ensemble'>
            <BMI_Usage_Report />
          </Route>
          <Route exact path='/admin/reports/ascap-usage-quarterly-standard'>
            <ASCAP_Usage_Report />
          </Route>
          <Route exact path='/admin/reports/ascap-usage-annual-standard'>
            <ASCAP_Usage_Report />
          </Route>
          <Route exact path='/admin/reports/ascap-usage-quarterly-ensemble'>
            <ASCAP_Usage_Report />
          </Route>
          <Route exact path='/admin/reports/ascap-usage-annual-ensemble'>
            <ASCAP_Usage_Report />
          </Route>

          <Route exact path='/admin'>
            {/* <!-- Pinned projects --> */}
            <div className='px-4 mt-6 sm:px-6 lg:px-8'>
              <h2 className='text-xs font-medium tracking-wide text-gray-500 uppercase'>
                Site Details
              </h2>
              <ul className='grid grid-cols-1 gap-4 mt-3 sm:gap-6 sm:grid-cols-2 xl:grid-cols-4'>
                <li className='relative flex col-span-1 rounded-md shadow-sm'>
                  <div className='flex items-center justify-center flex-shrink-0 w-16 text-sm font-medium leading-5 text-white bg-pink-600 rounded-l-md'>
                    Ch
                  </div>
                  <div className='flex items-center justify-between flex-1 truncate bg-white border-t border-b border-r border-gray-200 rounded-r-md'>
                    <div className='flex-1 px-4 py-2 text-sm leading-5 truncate'>
                      <Link
                        to='#'
                        className='font-medium text-gray-900 transition duration-150 ease-in-out hover:text-gray-600'
                      >
                        Active Chunks
                      </Link>
                      <p className='text-gray-500'>{totalChunks} Chunks</p>
                    </div>
                    {/* <div className='flex-shrink-0 pr-2'>
                    <button
                      id='pinned-project-options-menu-0'
                      aria-has-popup='true'
                      className='inline-flex items-center justify-center w-8 h-8 text-gray-400 transition duration-150 ease-in-out bg-transparent rounded-full hover:text-gray-500 focus:outline-none focus:text-gray-500 focus:bg-gray-100'
                    >
                      <!-- Heroicon name: dots-vertical -->
                      <svg
                        className='w-5 h-5'
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 20 20'
                        fill='currentColor'
                      >
                        <path d='M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z' />
                      </svg>
                    </button>
                  </div> */}
                  </div>
                </li>

                <li className='relative flex col-span-1 rounded-md shadow-sm'>
                  <div className='flex items-center justify-center flex-shrink-0 w-16 text-sm font-medium leading-5 text-white bg-pink-600 rounded-l-md'>
                    P
                  </div>
                  <div className='flex items-center justify-between flex-1 truncate bg-white border-t border-b border-r border-gray-200 rounded-r-md'>
                    <div className='flex-1 px-4 py-2 text-sm leading-5 truncate'>
                      <Link
                        to='/admin/publishers'
                        className='font-medium text-gray-900 transition duration-150 ease-in-out hover:text-gray-600'
                      >
                        Active Publishers
                      </Link>
                      <p className='text-gray-500'>
                        {totalPublishers} Publishers
                      </p>
                    </div>
                  </div>
                </li>

                <li className='relative flex col-span-1 rounded-md shadow-sm'>
                  <div className='flex items-center justify-center flex-shrink-0 w-16 text-sm font-medium leading-5 text-white bg-pink-600 rounded-l-md'>
                    Co
                  </div>
                  <div className='flex items-center justify-between flex-1 truncate bg-white border-t border-b border-r border-gray-200 rounded-r-md'>
                    <div className='flex-1 px-4 py-2 text-sm leading-5 truncate'>
                      <Link
                        to='#'
                        className='font-medium text-gray-900 transition duration-150 ease-in-out hover:text-gray-600'
                      >
                        Active Collections
                      </Link>
                      <p className='text-gray-500'>{totalCollections} Shows</p>
                    </div>
                  </div>
                </li>

                <li className='relative flex col-span-1 rounded-md shadow-sm'>
                  <div className='flex items-center justify-center flex-shrink-0 w-16 text-sm font-medium leading-5 text-white bg-pink-600 rounded-l-md'>
                    En
                  </div>
                  <div className='flex items-center justify-between flex-1 truncate bg-white border-t border-b border-r border-gray-200 rounded-r-md'>
                    <div className='flex-1 px-4 py-2 text-sm leading-5 truncate'>
                      <Link
                        to='/admin/ensembles'
                        className='font-medium text-gray-900 transition duration-150 ease-in-out hover:text-gray-600'
                      >
                        Active Ensembles
                      </Link>
                      <p className='text-gray-500'>{totalGroups} Ensembles</p>
                    </div>
                  </div>
                </li>

                <li className='relative flex col-span-1 rounded-md shadow-sm'>
                  <div className='flex items-center justify-center flex-shrink-0 w-16 text-sm font-medium leading-5 text-white bg-pink-600 rounded-l-md'>
                    I
                  </div>
                  <div className='flex items-center justify-between flex-1 truncate bg-white border-t border-b border-r border-gray-200 rounded-r-md'>
                    <div className='flex-1 px-4 py-2 text-sm leading-5 truncate'>
                      <Link
                        to='/admin/instruments'
                        className='font-medium text-gray-900 transition duration-150 ease-in-out hover:text-gray-600'
                      >
                        Active Instruments
                      </Link>
                      <p className='text-gray-500'>{totalInstruments} Instruments</p>
                    </div>
                  </div>
                </li>

                <li className='relative flex col-span-1 rounded-md shadow-sm'>
                  <div className='flex items-center justify-center flex-shrink-0 w-16 text-sm font-medium leading-5 text-white bg-pink-600 rounded-l-md'>
                    P
                  </div>
                  <div className='flex items-center justify-between flex-1 truncate bg-white border-t border-b border-r border-gray-200 rounded-r-md'>
                    <div className='flex-1 px-4 py-2 text-sm leading-5 truncate'>
                      <Link
                        to='/admin/sections'
                        className='font-medium text-gray-900 transition duration-150 ease-in-out hover:text-gray-600'
                      >
                        Active Playlists
                      </Link>
                      <p className='text-gray-500'>{totalSections} Playlists</p>
                    </div>
                  </div>
                </li>

                <li className='relative flex col-span-1 rounded-md shadow-sm'>
                  <div className='flex items-center justify-center flex-shrink-0 w-16 text-sm font-medium leading-5 text-white bg-pink-600 rounded-l-md'>
                    Co
                  </div>
                  <div className='flex items-center justify-between flex-1 truncate bg-white border-t border-b border-r border-gray-200 rounded-r-md'>
                    <div className='flex-1 px-4 py-2 text-sm leading-5 truncate'>
                      <Link
                        to='/admin/compositions'
                        className='font-medium text-gray-900 transition duration-150 ease-in-out hover:text-gray-600'
                      >
                        Active Compositions
                      </Link>
                      <p className='text-gray-500'>{totalCompositions} Compositions</p>
                    </div>
                  </div>
                </li>

                <li className='relative flex col-span-1 rounded-md shadow-sm'>
                  <div className='flex items-center justify-center flex-shrink-0 w-16 text-sm font-medium leading-5 text-white bg-pink-600 rounded-l-md'>
                    Sr
                  </div>
                  <div className='flex items-center justify-between flex-1 truncate bg-white border-t border-b border-r border-gray-200 rounded-r-md'>
                    <div className='flex-1 px-4 py-2 text-sm leading-5 truncate'>
                      <Link
                        to='#'
                        className='font-medium text-gray-900 transition duration-150 ease-in-out hover:text-gray-600'
                      >
                        Active Recordings
                      </Link>
                      <p className='text-gray-500'>{totalSoundRecordings} Recordings</p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </Route>
        </main>
      </div>
    </div >
  );
};

export default withRouter(AdminHome);