import React from 'react';

// ROUTING
import { Route } from 'react-router-dom';

// COMPONENTS
import { MemoizedGroupUserCard } from '../dashboard/GroupUserCard'

// ----------------------------------------------------------------------------------
// -------------------------------- GROUP ABOUT CARD --------------------------------
// ----------------------------------------------------------------------------------

const GroupAboutCard = ({ cognitoUser, group, groupAdmins }) => {
  return (
    <>
      {/* ABOUT */}
      {group?.description ? (
        <div className='bg-green-700 px-5 py-3 mt-2 rounded-lg'>
          <p className='whitespace-pre-wrap text-white'>
            {group?.description}
          </p>
        </div>
      ) : (
        <div className='text-white w-full pt-2 text-2xl font-medium leading-6 text-white'>
          <p>This ensemble has no description</p>
        </div>
      )}

      {/* LIST OF ADMINS/DIRECTORS SECTION */}
      {group.public === false ? (
        <div>
          <div className='flex justify-between mt-2 border-t-4 border-green-600 border-solid'>
            <h1 className='pt-2 text-2xl font-medium leading-6 text-white'>
              DIRECTORS
            </h1>
          </div>
          {/* RENDERS ALL OF A GROUP'S ADMINS/TEACHERS */}
          <div className='grid justify-center gap-10 mt-2 sm:grid-cols-2 md:grid-cols-2 xl:grid-cols-4 2xl:grid-cols-6 2xl:px-15'>
            {/* FILTER OUT MUSIC CHUNKS INTERNAL ADMINS */}
            {groupAdmins.filter(groupAdmin => {
              return groupAdmin.email !== 'matthew.a.weidner@gmail.com'
                && groupAdmin.email !== 'shanegwaltney@musicchunks.com'
                && groupAdmin.email !== 'nickmason@musicchunks.com'
                && groupAdmin.email !== 'dantwiford@musicchunks.com'
                && groupAdmin.email !== 'jasonhunt@syncedupdesigns.com'
            }).map((data) => (
              <a class='email' title='Email a teacher' href={`mailto:${data.email}`}
                key={data.id}
                to={`#`}
              >
                <Route
                  render={(props) => (
                    <MemoizedGroupUserCard
                      key={data.id}
                      cognitoUser={cognitoUser}
                      data={data}
                      group={group}
                      {...props}
                    />
                  )}
                />
              </a>
            ))}
          </div>
        </div>
      ) : null}
    </>
  );
};

export const MemoizedGroupAboutCard = React.memo(GroupAboutCard);