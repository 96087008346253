import React from 'react';

// ROUTING
import { Route, Link } from 'react-router-dom';

// COMPONENTS
import { MemoizedChunkCard } from './ChunkCard';
import { MemoizedFeaturedChunkCard } from './FeaturedChunkCard';
import { MemoizedCollectionCard } from './CollectionCard';

// ----------------------------------------------------------------------------------
// ------------------------------ PUBLISHER HOME CARD -------------------------------
// ----------------------------------------------------------------------------------

export const PublisherHomeCard = ({ cognitoUser, chunks, featuredChunks, recentChunks, publisherCollections }) => {

  return (
    <div>
      {/* FEATURED CHUNKS SECTION */}
      {featuredChunks.length === 0 ? null : (
        <div className='mt-2'>
          <h1 className='w-full pt-2 text-2xl font-medium leading-6 text-white'>
            FEATURED
          </h1>
        </div>
      )}

      {/* WOULD NEED A FEATURED ARRAY TO CHECK LENGTH AND DISPLAY WHETHER THERE ARE ANY FEATURED  */}
      <div className='grid grid-cols-1 gap-5 mt-2 max-w-screen '>
        {/* ONLY RENDERS CHUNKS WITH THE FEATURES BOOL MARKED (MAYBE CHANGE TO HIT ON ENDPOINT INSTEAD) */}
        {featuredChunks?.map((data) => (
          <Link
            key={data.id}
            to={`/dashboard/chunks/${data.id}`}
          >
            <Route
              render={(props) => (
                <MemoizedFeaturedChunkCard
                  key={data.id}
                  cognitoUser={cognitoUser}
                  data={data}
                  {...props}
                />
              )}
            />
          </Link>
        ))}
      </div>

      {/* PUBLISHER COLLECTIONS SECTION */}
      {publisherCollections.length === 0 ? null : (
        <div className='mt-2 border-t-4 border-green-600 border-solid'>
          <h1 className='w-full pt-2 text-2xl font-medium leading-6 text-white'>
            COLLECTIONS
          </h1>
        </div>
      )}

      {/* RENDERS A LIST OF ALL A PUBLISHERS COLLECTIONS */}
      <div className='grid items-center justify-center gap-10 mt-2 grig-cols-1 sm:grid-cols-2 md:grid-cols-2 xl:grid-cols-4 2xl:grid-cols-6 2xl:px-15'>
        {publisherCollections?.map((data) => (
          <Link
            key={data.id}
            to={`/dashboard/collections/${data.id}`}
          >
            <Route
              render={(props) => (
                <MemoizedCollectionCard
                  key={data.id}
                  cognitoUser={cognitoUser}
                  data={data}
                  {...props}
                />
              )}
            />
          </Link>
        ))}
      </div>

      {/* RECENT UPLOADS SECTION */}
      {recentChunks.length === 0 ? null : (
        <div className='mt-2 border-t-4 border-green-600 border-solid'>
          <h1 className='w-full pt-2 text-2xl font-medium leading-6 text-white'>
            RECENT UPLOADS
          </h1>
        </div>
      )}

      {/* ONLY RENDERS RECENT UPLOADS WITHIN THE PAST 5 DAYS */}
      <div className='grid items-center justify-center gap-10 mt-2 grig-cols-1 sm:grid-cols-2 md:grid-cols-2 xl:grid-cols-4 2xl:grid-cols-6 2xl:px-15'>
        {recentChunks?.map((data) => (
          <Link
            key={data.id}
            to={`/dashboard/chunks/${data.id}`}
          >
            <Route
              render={(props) => (
                <MemoizedChunkCard
                  key={data.id}
                  cognitoUser={cognitoUser}
                  data={data}
                  {...props}
                />
              )}
            />
          </Link>
        ))}
      </div>

      {/* All CHUNKS SECTION */}
      {chunks.length === 0 ? (
        <div className='text-white w-full pt-2 text-2xl font-medium leading-6 text-white'>
          <p>This publisher has no chunks</p>
        </div>
      ) : (
          <div className='mt-2 border-t-4 border-green-600 border-solid'>
            <h1 className='w-full pt-2 text-2xl font-medium leading-6 text-white'>
              CHUNKS
            </h1>
          </div>
        )}

      {/* RENDERS ALL OF A PUBLISHERS CHUNKS */}
      <div className='grid items-center justify-center gap-10 mt-2 grig-cols-1 sm:grid-cols-2 md:grid-cols-2 xl:grid-cols-4 2xl:grid-cols-6 2xl:px-15'>
        {chunks?.map((data) => (
          <Link
            key={data.id}
            to={`/dashboard/chunks/${data.id}`}
          >
            <Route
              render={(props) => (
                <MemoizedChunkCard
                  key={data.id}
                  cognitoUser={cognitoUser}
                  data={data}
                  {...props}
                />
              )}
            />
          </Link>
        ))}
      </div>

    </div >
  );
};

export const MemoizedPublisherHomeCard = React.memo(PublisherHomeCard);
