import React from 'react';

// STATE
import { useRecoilValue } from 'recoil';
import { sectionData } from '../../../state/atoms';

// COMPONENTS
import SectionData from './SectionData';

// ----------------------------------------------------------------------------------
// ------------------------------- SECTION TABLE ---------------------------------
// ----------------------------------------------------------------------------------

const SectionTable = () => {
  // State
  const sections = useRecoilValue(sectionData);

  return (
    <div className='py-4 '>
      {/* Only filter publc playlists */}
      {sections.filter(playlist => playlist.public === true).map((data) => (
        <SectionData key={data.id} data={data} />
      ))}
    </div>
  );
};

export default SectionTable;