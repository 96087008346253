import React from 'react';

// STATE
import { useRecoilValue } from 'recoil';
import { instrumentData } from '../../../state/atoms';

// COMPONENTS
import InstrumentData from './InstrumentData';

// ----------------------------------------------------------------------------------
// ------------------------------- INSTRUMENT TABLE ---------------------------------
// ----------------------------------------------------------------------------------

const InstrumentTable = () => {
  // State
  const instruments = useRecoilValue(instrumentData);

  return (
    <div className='py-4 '>
      {instruments.map((data) => (
        <InstrumentData key={data.id} data={data} />
      ))}
    </div>
  );
};

export default InstrumentTable;