import React, { useState } from 'react';
import axios from 'axios';
import axiosRetry from 'axios-retry';

// AG-GRID
import { AgGridColumn, AgGridReact } from 'ag-grid-react'
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine-dark.css';

// TOAST
import cogoToast from 'cogo-toast';

// ----------------------------------------------------------------------------------
// ----------------------- PUBLISHER ADMIN FEATURED CHUNK TABLE ---------------------
// ----------------------------------------------------------------------------------

const PublisherAdminFeaturedChunkTable = ({ publisherChunks, featuredChunkTotal, featuredLimit, refresh, setRefresh }) => {
  // State
  const [gridApi, setGridApi] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  // Set axios to retry a set number of times on failed request/return
  axiosRetry(axios, { retries: 3 })

  // Sets grid params for columns
  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  // Function to handle marking a chunk as featured
  const handleUpdate = (params) => {
    if (featuredLimit - featuredChunkTotal > 0 || params.data.featured === true) {
      setSubmitting(true);

      axios
        ({
          method: 'put',
          url: process.env.REACT_APP_API + `chunks/${params.data.id}/individual`,
          headers: {
            Accept: 'application/json',
            Authorization: process.env.REACT_APP_HEROKU_SERVER_KEY
          },
          data: {
            featured: !params.data.featured
          }
        })
        .then((res) => {
          setSubmitting(false);
          setRefresh(!refresh);
          cogoToast.success(`'${params.data.name}' has had its featured status successfully updated`, {
            hideAfter: 5
          });
        })
        .catch((err) => {
          console.log(err);
          setSubmitting(false);
          setRefresh(!refresh);
          cogoToast.error(`Something went wrong`, {
            hideAfter: 5
          });
        });
    } else {
      cogoToast.error(`You have reached the limit for featured chunks`, {
        hideAfter: 5
      });
    }
  };

  return (
    <>
      <div
        id="myGrid"
        style={{
          width: '100%',
        }}
        className="ag-theme-alpine-dark"
      >
        <AgGridReact
          autoGroupColumnDef={{
            headerName: 'Group',
            minWidth: 170,
            field: 'name',
            valueGetter: function (params) {
              if (params.node.group) {
                return params.node.key;
              } else {
                return params.data[params.colDef.field];
              }
            },
            cellRenderer: 'agGroupCellRenderer',
            cellRendererParams: { checkbox: true },
          }}
          defaultColDef={{
            editable: false,
            enableRowGroup: true,
            enablePivot: true,
            enableValue: true,
            sortable: true,
            resizable: true,
            filter: true,
            flex: 1,
            minWidth: 100,
          }}
          suppressRowClickSelection={true}
          groupSelectsChildren={true}
          onCellClicked={handleUpdate}
          rowGroupPanelShow={'always'}
          pivotPanelShow={'always'}
          enableRangeSelection={true}
          pagination={true}
          paginationPageSize={10}
          domLayout='autoHeight'
          onGridReady={onGridReady}
          rowData={publisherChunks}
        >
          <AgGridColumn
            field="name"
            headerName='Chunk Name'
            minWidth={170}
          />
          <AgGridColumn field="featured" />
        </AgGridReact>
      </div>
    </>
  );
};

export const MemoizedPublisherAdminFeaturedChunkTable = React.memo(PublisherAdminFeaturedChunkTable);