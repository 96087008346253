import React, { useState, useEffect } from 'react';
import { useChatContext } from 'stream-chat-react';
import { v4 as uuidv4 } from 'uuid';

// ROUTING
import { useParams } from 'react-router-dom';

// STYLING
import './CreateChannel.css';

// TOAST
import cogoToast from 'cogo-toast';

// COMPONENTS
import { UserList } from '../index';
import { CloseCreateChannel } from '../../assets';

// ----------------------------------------------------------------------------------
// ---------------------------------- CREATE CHANNEL --------------------------------
// ----------------------------------------------------------------------------------

const ChannelNameInput = ({ channelName = '', setChannelName, createChannel }) => {
    const handleChange = (event) => {
        event.preventDefault();

        setChannelName(event.target.value);
    }

    return (
        <div className='channel-name-input__wrapper'>
            <p className='mb-3'>Name</p>
            <div className='flex'>
                <input value={channelName} onChange={handleChange} placeholder='channel-name' />
                <button className='px-4 text-white rounded-tr-md rounded-br-md font-bold text-lg bg-searchbarchat hover:opacity-80' onClick={createChannel}>
                    Create
                </button>
            </div>
            <p>Add Members</p>
        </div>
    )
}

const CreateChannel = ({ createType, setIsCreating }) => {
    const { client, setActiveChannel } = useChatContext();
    const groupID = useParams();
    const [selectedUsers, setSelectedUsers] = useState([client.userID || '']);
    const [moddedUsers, setModdedUsers] = useState([]);
    const [channelName, setChannelName] = useState('');
    const [userListType, setUserListType] = useState('add-members-create')

    // UseEffect to make all users mods for the channel (users can't change anything anyway unless modded for ensembles. It was easier to make everyone a mod Stream Chat side)
    useEffect(() => {
        const usersWithModStatus = []

        if (selectedUsers.length > 0) {
            selectedUsers.map(user => {
                usersWithModStatus.push({ user_id: user, channel_role: 'channel_moderator' })
            })
        }
        setModdedUsers(usersWithModStatus);
    }, [selectedUsers]);

    const createChannel = async (e) => {
        e.preventDefault();

        if (channelName.length < 1 && createType === 'team') {
            cogoToast.error(`Channel name cannot be empty`, {
                hideAfter: 5
            });
        } else {
            try {
                const newChannel = await client.channel(createType, uuidv4(), {
                    name: channelName, team: groupID.id
                });
                
                await newChannel.create();
                await newChannel.addMembers(moddedUsers)

                cogoToast.success(`Channel created successfully with all selected users`, {
                    hideAfter: 5
                });

                setChannelName('');
                setModdedUsers([]);
                setIsCreating(false);
                setSelectedUsers([client.userID]);
                setActiveChannel(newChannel);
            } catch (error) {
                console.log(error);
                cogoToast.error('Could not create channel', {
                    hideAfter: 5
                });
            }
        }
    }

    return (
        <div className='create-channel__container'>
            <div className='create-channel__header'>
                <p>{createType === 'team' ? 'Create a New Channel' : 'Send a Direct Message'}</p>
                <CloseCreateChannel setIsCreating={setIsCreating} />
            </div>
            {createType === 'team' && <ChannelNameInput channelName={channelName} setChannelName={setChannelName} createChannel={createChannel} />}
            <UserList setSelectedUsers={setSelectedUsers} userListType={userListType} />
            <div className='create-channel__button-wrapper' onClick={createChannel}>
                <p className='hover:opacity-80'>{createType === 'team' ? 'Create Channel' : 'Create Message Group'}</p>
            </div>
        </div>
    );
};

export default CreateChannel;