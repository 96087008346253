import React, { useState, useEffect } from 'react';

// CHAT API
import { StreamChat } from 'stream-chat';
import { Chat } from 'stream-chat-react';

// COMPONENTS
import { ChannelListContainer, ChannelContainer } from '../chat/components';

// STYLES
import 'stream-chat-react/dist/css/index.css';
import '../chat/Chat.css';

const client = StreamChat.getInstance(process.env.REACT_APP_STREAM_API_KEY)

// ----------------------------------------------------------------------------------
// -------------------------------- GROUP CLASSROOMS --------------------------------
// ----------------------------------------------------------------------------------

const GroupClassrooms = ({ cognitoUser, group, groupAdmins }) => {
  const [createType, setCreateType] = useState('');
  const [isCreating, setIsCreating] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [toggleChannelContainer, setToggleChannelContainer] = useState(false);
  const [showNotificationBanner, setShowNotificationBanner] = useState(false);

  const filters = [
    { type: 'team', demo: 'team' },
    { type: 'messaging', demo: 'team' },
  ];
  const options = { state: true, watch: true, presence: true, limit: 3 };
  const sort = { last_message_at: -1, updated_at: -1 };

  useEffect(() => {
    if (
      window.Notification &&
      (Notification.permission === 'granted' ||
        Notification.permission === 'denied')
    )
      return;

    setShowNotificationBanner(true);
  }, []);

  function grantPermission() {
    if (Notification.permission === 'granted') {
      new Notification('You are already subscribed to web notifications');
      return;
    }

    if (
      Notification.permission !== 'denied' ||
      Notification.permission === 'default'
    ) {
      Notification.requestPermission().then(result => {
        if (result === 'granted') {
          new Notification('New message from Music Chunks', {
            body: 'Nice, notifications are now enabled!',
          });
        }
      });
    }

    setShowNotificationBanner(false);
  }

  return (
    <Chat client={client} theme='team light'>
      {showNotificationBanner && (
        <div class="alert">
          <p>
            {`Music Chunks needs your permission to `}
            <button onClick={grantPermission}>
              enable desktop notifications
            </button>
          </p>
        </div>
      )}
      <div className='flex relative'>
        <div className='absolute z-20 w-full md:relative right-0 left-0 md:z-0 md:w-1/3 rounded-tl-2xl'>
          <ChannelListContainer
            isCreating={isCreating}
            filters={filters}
            options={options}
            sort={sort}
            setIsCreating={setIsCreating}
            setCreateType={setCreateType}
            setIsEditing={setIsEditing}
            group={group}
            cognitoUser={cognitoUser}
            groupAdmins={groupAdmins}
            toggleChannelContainer={toggleChannelContainer}
            setToggleChannelContainer={setToggleChannelContainer}
          />
        </div>
        <div className='w-full md:w-2/3'>
          <ChannelContainer
            isCreating={isCreating}
            setIsCreating={setIsCreating}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            createType={createType}
            cognitoUser={cognitoUser}
            groupAdmins={groupAdmins}
            setToggleChannelContainer={setToggleChannelContainer}
          />
        </div>
      </div>
    </Chat>
  );
};

export const MemoizedGroupClassrooms = React.memo(GroupClassrooms);