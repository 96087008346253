import axios from 'axios';
import axiosRetry from 'axios-retry';
import { atom, selector, selectorFamily } from 'recoil';

// Set axios to retry a set number of times on failed request/return
axiosRetry(axios, { retries: 3 })

// Used to force reload state
export const ForceReload = atom({
  key: 'forcereload',
  default: 0,
});

// Cognito user state
export const cogUserState = selectorFamily({
  key: 'cogUserState',
  get: (cognitoId) => async ({ get }) => {
    get(ForceReload)
    const response = await axios.get(
      process.env.REACT_APP_API + 'users/cognito/' + cognitoId,
      {
        headers: {
          Authorization: process.env.REACT_APP_HEROKU_SERVER_KEY,
        },
      }
    ).then((res) => {
      return res;
    })

    if (response.error) {
      throw response.error;
    }
    return response;
  }
})

// OVERALL CHUNK STATE
export const chunkState = atom({
  key: 'chunks',
  default: [],
});

// OVERALL ALPHABETIZED CHUNK STATE
export const alphabetizedChunkState = atom({
  key: 'alphabetizedchunks',
  default: [],
});

// OVERALL PUBLISHER STATE
export const publisherState = atom({
  key: 'publishers',
  default: [],
});

// OVERALL SECTION STATE
export const sectionState = atom({
  key: 'sections',
  default: [],
});

// OVER ALL COLLECTION STATE
export const collectionState = atom({
  key: 'collections',
  default: [],
});

// OVER ALL SHOW COLLECTION STATE
export const showCollectionState = atom({
  key: 'showCollections',
  default: [],
});

// OVER ALL CONCERT COLLECTION STATE
export const concertCollectionState = atom({
  key: 'concertCollections',
  default: [],
});

// OVER ALL JAZZ POP COLLECTION STATE
export const jazzPopCollectionState = atom({
  key: 'jazzCollections',
  default: [],
});

// OVER ALL EXERCISE & ETUDES COLLECTION STATE
export const exerciseCollectionState = atom({
  key: 'exerciseCollections',
  default: [],
});

// OVER ALL BASICS FOR BAND COLLECTION STATE
export const basicCollectionState = atom({
  key: 'basicCollections',
  default: [],
});

// OVER ALL VISUAL AND CHOREOGRAPHY COLLECTION STATE
export const visualCollectionState = atom({
  key: 'visualCollections',
  default: [],
});

// OVER ALL LEARN ABOUT MUSIC CHUNKS COLLECTION STATE
export const learnCollectionState = atom({
  key: 'learnCollections',
  default: [],
});

// PUBLIC GROUP STATE
export const publicGroupState = atom({
  key: 'publicGroups',
  default: [],
});

// CHUNK FILTER STATE
export const chunkFilterState = atom({
  key: 'chunkFilterState',
  default: 'all',
});

// PUBLISHER FILTER STATE
export const publisherFilterState = atom({
  key: 'publisherFilterState',
  default: 'all',
});

// COLLECTION FILTER STATE
export const collectionFilterState = atom({
  key: 'collectionFilterState',
  default: 'all',
});

// READ VALUE FOR FILTERED CHUNKS
export const filteredChunkState = selector({
  key: 'chunkListState',
  get: ({ get }) => {
    const filter = get(chunkFilterState);
    const chunks = get(chunkState);
    if (filter === 'all') return chunks;
    return chunks.filter((chunk) =>
      chunk.sections.some((e) => e === filter)
    );
  },
});

// READ VALUE FOR FILTERED PUBLISHERS
export const filteredPublisherState = selector({
  key: 'publisherListState',
  get: ({ get }) => {
    const filter = get(publisherFilterState);
    const publishers = get(publisherState);
    if (filter === 'all') return publishers;
    return publishers.filter(
      (publisher) =>
        publisher
    );
  },
});

// READ VALUE FOR FILTERED COLLECTIONS
export const filteredCollectionState = selector({
  key: 'collectionListState',
  get: ({ get }) => {
    const filter = get(collectionFilterState);
    const showCollections = get(showCollectionState);
    const concertCollections = get(concertCollectionState)
    const jazzCollections = get(jazzPopCollectionState)
    const exerciseCollections = get(exerciseCollectionState)
    const basicCollections = get(basicCollectionState)
    const visualCollections = get(visualCollectionState)
    const learnCollections = get(learnCollectionState)
    if (filter === 'all') return showCollections, concertCollections, jazzCollections, exerciseCollections, basicCollections, visualCollections, learnCollections;
    return {
      show_collections: showCollections.collections.filter((collection) => collection),
      show_chunks: showCollections.chunks.filter((chunk) => chunk.public === true),
      concert_collections: concertCollections.collections.filter((collection) => collection),
      concert_chunks: concertCollections.chunks.filter((chunk) => chunk.public === true),
      jazz_collections: jazzCollections.collections.filter((collection) => collection),
      jazz_chunks: jazzCollections.chunks.filter((chunk) => chunk.public === true),
      exercise_collections: exerciseCollections.collections.filter((collection) => collection),
      exercise_chunks: exerciseCollections.chunks.filter((chunk) => chunk.public === true),
      basic_collections: basicCollections.collections.filter((collection) => collection),
      basic_chunks: basicCollections.chunks.filter((chunk) => chunk.public === true),
      visual_collections: visualCollections.collections.filter((collection) => collection),
      visual_chunks: visualCollections.chunks.filter((chunk) => chunk.public === true),
      learn_collections: learnCollections.collections.filter((collection) => collection),
      learn_chunks: learnCollections.chunks.filter((chunk) => chunk.public === true),
    }
  },
});

// CHUNK STATS
export const chunkStatsState = selector({
  key: 'chunkStatsState',
  get: ({ get }) => {
    const chunks = get(chunkState);
    const totalChunks = chunks.length;

    const ww1Total = chunks.filter((chunk) =>
      chunk.sections.some((e) => e === 'woodwind1')
    ).length;
    const ww2Total = chunks.filter((chunk) =>
      chunk.sections.some((e) => e === 'woodwind2')
    ).length;
    const b1Total = chunks.filter((chunk) =>
      chunk.sections.some((e) => e === 'brass1')
    ).length;
    const b2Total = chunks.filter((chunk) =>
      chunk.sections.some((e) => e === 'brass2')
    ).length;
    const perc1Total = chunks.filter((chunk) =>
      chunk.sections.some((e) => e === 'percussion1')
    ).length;
    const perc2Total = chunks.filter((chunk) =>
      chunk.sections.some((e) => e === 'percussion2')
    ).length;
    const ensembleTotal = chunks.filter((chunk) =>
      chunk.sections.some((e) => e === 'concertbandfullensemble')
    ).length;

    const showmusicTotal = chunks.filter(
      (chunk) => chunk.collectionType === 'Show Music'
    ).length;
    const exerciseTotal = chunks.filter(
      (chunk) => chunk.collectionType === 'Exercises & Etudes'
    ).length;
    const basicsTotal = chunks.filter(
      (chunk) => chunk.collectionType === 'Basics for Band'
    ).length;
    const concertsTotal = chunks.filter(
      (chunk) => chunk.collectionType === 'Concert Music'
    ).length;
    const jazzTotal = chunks.filter(
      (chunk) => chunk.collectionType === 'Jazz Pop Combo'
    ).length;
    const visualsTotal = chunks.filter(
      (chunk) => chunk.collectionType === 'Visual & Choreography'
    ).length;
    const learnTotal = chunks.filter(
      (chunk) => chunk.collectionType === 'Learn About Music Chunks'
    ).length;
    return {
      totalChunks,
      ww1Total,
      ww2Total,
      b1Total,
      b2Total,
      perc1Total,
      perc2Total,
      showmusicTotal,
      exerciseTotal,
      basicsTotal,
      concertsTotal,
      jazzTotal,
      visualsTotal,
      learnTotal,
      ensembleTotal
    };
  },
});

// SEARCH FUNCTIONS
export const searchBarTerm = atom({
  key: 'searchBarTerm',
  default: '',
});

export const searchedChunks = selector({
  key: 'searchedChunks',
  get: ({ get }) => {
    const filter = get(searchBarTerm);
    const chunks = get(filteredChunkState);
    if (filter === '') return chunks;
    return chunks.filter(
      (e) => {
        // return Object.values(e).join(' ').toLowerCase().match(filter.toLowerCase()) ||
        return e.sections.some((i) => i.includes(filter.toLowerCase())) ||
          e.instruments.some((i) => i.includes(filter.toLowerCase())
          ) ||
          e.publisher_name.toLowerCase().includes(filter.toLowerCase()
          ) ||
          e.collectionType.toLowerCase().includes(filter.toLowerCase()
          ) ||
          e.name.toLowerCase().includes(filter.toLowerCase()
          ) ||
          e.description === null ? e.description : e.description.toLowerCase().includes(filter.toLowerCase()
          ) ||
            e.tag_line === null ? e.tag_line : e.tag_line.toLowerCase().includes(filter.toLowerCase())
      }
    );
  },
});

export const searchedPublishers = selector({
  key: 'searchedPublishers',
  get: ({ get }) => {
    const filter = get(searchBarTerm);
    const publishers = get(filteredPublisherState);
    if (filter === '') return publishers;
    return publishers.filter(
      (e) =>
        e.name.toLowerCase().includes(filter.toLowerCase())
    );
  },
});

export const searchedCollections = selector({
  key: 'searchedCollections',
  get: ({ get }) => {
    const filter = get(searchBarTerm);
    const collections = get(filteredCollectionState);
    if (filter === '') return collections;
    return {
      show_collections: collections.show_collections.filter(
        (e) =>
          e.name.toLowerCase().includes(filter.toLowerCase()
          ) ||
          e.publisher_name.toLowerCase().includes(filter.toLowerCase()
          )
      ),
      show_chunks: collections.show_chunks.filter(
        (e) =>
          e.name.toLowerCase().includes(filter.toLowerCase()
          ) ||
            e.instruments.some((i) => i.includes(filter.toLowerCase())
            ) ||
            e.publisher_name.toLowerCase().includes(filter.toLowerCase()
            ) ||
            e.tag_line === null ? e.tag_line : e.tag_line.toLowerCase().includes(filter.toLowerCase())
      ),
      concert_collections: collections.concert_collections.filter(
        (e) =>
          e.name.toLowerCase().includes(filter.toLowerCase()
          ) ||
          e.publisher_name.toLowerCase().includes(filter.toLowerCase()
          )
      ),
      concert_chunks: collections.concert_chunks.filter(
        (e) =>
          e.name.toLowerCase().includes(filter.toLowerCase()
          ) ||
            e.instruments.some((i) => i.includes(filter.toLowerCase())
            ) ||
            e.publisher_name.toLowerCase().includes(filter.toLowerCase()
            ) ||
            e.tag_line === null ? e.tag_line : e.tag_line.toLowerCase().includes(filter.toLowerCase())
      ),
      jazz_collections: collections.jazz_collections.filter(
        (e) =>
          e.name.toLowerCase().includes(filter.toLowerCase()
          ) ||
          e.publisher_name.toLowerCase().includes(filter.toLowerCase()
          )
      ),
      jazz_chunks: collections.jazz_chunks.filter(
        (e) =>
          e.name.toLowerCase().includes(filter.toLowerCase()
          ) ||
            e.instruments.some((i) => i.includes(filter.toLowerCase())
            ) ||
            e.publisher_name.toLowerCase().includes(filter.toLowerCase()
            ) ||
            e.tag_line === null ? e.tag_line : e.tag_line.toLowerCase().includes(filter.toLowerCase())
      ),
      exercise_collections: collections.exercise_collections.filter(
        (e) =>
          e.name.toLowerCase().includes(filter.toLowerCase()
          ) ||
          e.publisher_name.toLowerCase().includes(filter.toLowerCase()
          )
      ),
      exercise_chunks: collections.exercise_chunks.filter(
        (e) =>
          e.name.toLowerCase().includes(filter.toLowerCase()
          ) ||
            e.instruments.some((i) => i.includes(filter.toLowerCase())
            ) ||
            e.publisher_name.toLowerCase().includes(filter.toLowerCase()
            ) ||
            e.tag_line === null ? e.tag_line : e.tag_line.toLowerCase().includes(filter.toLowerCase())
      ),
      basic_collections: collections.basic_collections.filter(
        (e) =>
          e.name.toLowerCase().includes(filter.toLowerCase()
          ) ||
          e.publisher_name.toLowerCase().includes(filter.toLowerCase()
          )
      ),
      basic_chunks: collections.basic_chunks.filter(
        (e) =>
          e.name.toLowerCase().includes(filter.toLowerCase()
          ) ||
            e.instruments.some((i) => i.includes(filter.toLowerCase())
            ) ||
            e.publisher_name.toLowerCase().includes(filter.toLowerCase()
            ) ||
            e.tag_line === null ? e.tag_line : e.tag_line.toLowerCase().includes(filter.toLowerCase())
      ),
      visual_collections: collections.visual_collections.filter(
        (e) =>
          e.name.toLowerCase().includes(filter.toLowerCase()
          ) ||
          e.publisher_name.toLowerCase().includes(filter.toLowerCase()
          )
      ),
      visual_chunks: collections.visual_chunks.filter(
        (e) =>
          e.name.toLowerCase().includes(filter.toLowerCase()
          ) ||
            e.instruments.some((i) => i.includes(filter.toLowerCase())
            ) ||
            e.publisher_name.toLowerCase().includes(filter.toLowerCase()
            ) ||
            e.tag_line === null ? e.tag_line : e.tag_line.toLowerCase().includes(filter.toLowerCase())
      ),
      learn_collections: collections.learn_collections.filter(
        (e) =>
          e.name.toLowerCase().includes(filter.toLowerCase()
          ) ||
          e.publisher_name.toLowerCase().includes(filter.toLowerCase()
          )
      ),
      learn_chunks: collections.learn_chunks.filter(
        (e) =>
          e.name.toLowerCase().includes(filter.toLowerCase()
          ) ||
            e.instruments.some((i) => i.includes(filter.toLowerCase())
            ) ||
            e.publisher_name.toLowerCase().includes(filter.toLowerCase()
            ) ||
            e.tag_line === null ? e.tag_line : e.tag_line.toLowerCase().includes(filter.toLowerCase())
      )
    }
  },
});

// CHUNK FILTER STATE
export const singleChunkState = atom({
  key: 'singleChunkState',
  default: {},
});

// PUBLISHER FILTER STATE
export const singlePublisherState = atom({
  key: 'singlePublisherState',
  default: {},
});

// FILTER FOR A SINGLE CHUNK VIEW
export const singleChunkFilter = selectorFamily({
  key: 'singleChunkFilter',
  get: (ID) => ({ get }) => {
    const chunks = get(chunkState);

    return chunks.find((chunk) => {
      return ID === chunk.id;
    });
  },
});

// FILTER FOR A SINGLE PUBLISHER VIEW
export const singlePublisherFilter = selectorFamily({
  key: 'singlePublisherFilter',
  get: (ID) => ({ get }) => {
    const publishers = get(publisherState);

    return publishers.find((publisher) => {
      return ID === publisher.id;
    });
  },
});

//----------------------------------------------
//-------------------- ADMIN -------------------
//----------------------------------------------

export const chunkData = atom({
  key: 'chunkData',
  default: [],
});

export const publisherData = atom({
  key: 'publisherData',
  default: [],
});

export const collectionData = atom({
  key: 'collectionData',
  default: [],
});

export const instrumentData = atom({
  key: 'instrumentData',
  default: [],
});

export const sectionData = atom({
  key: 'sectionData',
  default: [],
});

export const compositionData = atom({
  key: 'compositionData',
  default: [],
});

export const soundRecordingData = atom({
  key: 'soundRecordingData',
  default: [],
});

export const groupData = atom({
  key: 'groupData',
  default: [],
});

export const userData = atom({
  key: 'userData',
  default: [],
});

export const adminGroupFilter = selectorFamily({
  key: 'adminGroupFilter',
  get: (pubID) => async ({ get }) => {
    const groups = await get(groupData);

    return groups.filter((gr) => {
      return pubID === gr.publisher_id;
    });
  },
});

export const adminCollectionFilter = selectorFamily({
  key: 'adminCollectionFilter',
  get: (pubID) => async ({ get }) => {
    const collections = await get(collectionData);

    return collections.filter((col) => {
      return pubID === col.publisher_id;
    });
  },
});

export const adminChunkFilter = selectorFamily({
  key: 'adminChunkFilter',
  get: (colID) => async ({ get }) => {
    const chunks = await get(chunkData);
    return chunks.filter((chunk) => {
      return colID === chunk.collection_id;
    });
  },
});

export const adminSingleChunkFilter = selectorFamily({
  key: 'adminSingleChunkFilter',
  get: (pubID) => async ({ get }) => {
    const chunks = await get(chunkData);

    return chunks.filter((chunk) => {
      return pubID === chunk.publisher_id && !chunk.collection_id;
    });
  },
});

export const adminSoundRecordingFilter = selectorFamily({
  key: 'adminSoundRecordingFilter',
  get: (compID) => async ({ get }) => {
    const soundRecordings = await get(soundRecordingData);

    return soundRecordings.filter((sr) => {
      return compID === sr.composition_id;
    });
  },
});

export const adminChunkSoundRecordingFilter = selectorFamily({
  key: 'adminChunkSoundRecordingFilter',
  get: (chunkID) => async ({ get }) => {
    const soundRecordings = await get(soundRecordingData);

    return soundRecordings.filter((sr) => {
      return chunkID === sr.chunk_id;
    });
  },
});

export const adminStats = selector({
  key: 'adminStats',
  get: ({ get }) => {
    const pub = get(publisherData);
    const col = get(collectionData);
    const chunks = get(chunkData);
    const inst = get(instrumentData)
    const section = get(sectionData)
    const composition = get(compositionData)
    const soundRecording = get(soundRecordingData)
    const group = get(groupData)
    const user = get(userData)
    const totalChunks = chunks.length;
    const totalPublishers = pub.length;
    const totalCollections = col.length;
    const totalInstruments = inst.length;
    const totalSections = section.length;
    const totalCompositions = composition.length;
    const totalSoundRecordings = soundRecording.length;
    const totalGroups = group.length;
    const totalUsers = user.length;

    return {
      totalChunks,
      totalPublishers,
      totalCollections,
      totalInstruments,
      totalSections,
      totalCompositions,
      totalSoundRecordings,
      totalGroups,
      totalUsers
    };
  },
});
