import React from 'react';

// ROUTING
import { Redirect, Route } from 'react-router-dom';

// AUTH
import { Auth } from '@aws-amplify/auth';

// STATE
import { useRecoilValue } from 'recoil';
import { cogUserState } from '../state/atoms';

// ----------------------------------------------------------------------------------
// -------------------------------- PAYMENT ROUTE -----------------------------------
// ----------------------------------------------------------------------------------

const PaymentRoute = ({ component: Component, location, ...rest }) => {
  const cognitoUser = useRecoilValue(
    cogUserState(
      localStorage.getItem(
        process.env.REACT_APP_USER_ID,
      ),
    ),
  ); // Get user from our DB

  // Function to help determine if the user is an ensemble admin
  const findEnsembleAdmin = () => {
    if (cognitoUser.data.groups.find(group => group.isAdmin === true)) {
      return true
    } else {
      return false
    }
  }

  const isPayed = cognitoUser.data.payment_info_filled;
  const subscription = cognitoUser.data.role;
  const roles = Auth.user.signInUserSession.accessToken.payload['cognito:groups']
  const isEnsembleAdmin = findEnsembleAdmin()

  const userAuth = () => {
    if ((isPayed && subscription !== 'free') || (roles && roles.includes('Admin')) || (isEnsembleAdmin)) {
      return true
    } else {
      return false
    }
  }

  return (
    <Route
      {...rest}
      render={(rest) =>
        userAuth() ? (
          <Component {...rest} />
        ) : (
            <Redirect
              to={{
                pathname: '/add-payment',
              }}
            />
          )}
    />
  );
};

export default PaymentRoute;