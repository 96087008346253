import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import axiosRetry from 'axios-retry';
import { v4 as uuidv4 } from 'uuid';
import S3 from 'react-aws-s3';

// FORM MANAGEMENT
import { useForm, Controller } from 'react-hook-form';
import Select from 'react-select';

// ROUTING
import { Link, useParams, useHistory } from 'react-router-dom';

// STATE
import { useSetRecoilState } from 'recoil';
import { groupData } from '../../../state/atoms';

// ----------------------------------------------------------------------------------
// --------------------------------- ADD GROUP FORM ---------------------------------
// ----------------------------------------------------------------------------------

export const AddGroupForm = () => {
  // State
  const history = useHistory();
  const { pubID } = useParams();
  const groups = useSetRecoilState(groupData);
  const [allChunks, setAllChunks] = useState([]);  // SAVES LIST OF ALL CHUNKS
  const [allUsers, setAllUsers] = useState([]);  // SAVES LIST OF ALL USERS
  const [submitting, setSubmitting] = useState(false);

  // Set axios to retry a set number of times on failed request/return
  axiosRetry(axios, { retries: 3 })

  // Form
  const { register, handleSubmit, errors, reset, control } = useForm({
    mode: 'onChange',
    defaultValues: {
      public: false,
    },
  });

  // Image State
  const [loadingImg, setLoadingImg] = useState(false);
  const [image, setImage] = useState('');
  const [loadingImg2, setLoadingImg2] = useState(false);
  const [image2, setImage2] = useState('');

  // AMAZON WEB SERVICES WITH S3
  const fileInput = useRef();

  // Function that adds group profile images to our Amazon Web Services S3 bucket
  const uploadImg = async (e) => {
    e.preventDefault();

    let file = fileInput.current.files[0]
    let newFileName = fileInput.current.files[0].name
    let newFileNameWithUUID = 'ensembles/' + newFileName.substring(0, newFileName.lastIndexOf('.')) + '_' + uuidv4();

    const config = {
      bucketName: process.env.REACT_APP_BUCKET_NAME,
      region: process.env.REACT_APP_REGION,
      accessKeyId: process.env.REACT_APP_ACCESS_ID,
      secretAccessKey: process.env.REACT_APP_ACCESS_KEY,
    };

    const ReactS3Client = new S3(config);
    ReactS3Client.uploadFile(file, newFileNameWithUUID).then(data => {
      if (data.status === 204) {
        const imageBucketURL = data.location
        setImage(imageBucketURL);
        setLoadingImg(false);
        console.log('Image successfully uploaded to S3!');
        return data
      } else {
        console.log('Image failed upload to S3');
      }
    })
  };

  // Function that adds group banner images to our Amazon Web Services S3 bucket
  const fileInput2 = useRef();

  const uploadImg2 = async (e) => {
    e.preventDefault();

    let file = fileInput2.current.files[0]
    let newFileName = fileInput2.current.files[0].name
    let newFileNameWithUUID = 'ensembles/' + newFileName.substring(0, newFileName.lastIndexOf('.')) + '_' + uuidv4();

    const config = {
      bucketName: process.env.REACT_APP_BUCKET_NAME,
      region: process.env.REACT_APP_REGION,
      accessKeyId: process.env.REACT_APP_ACCESS_ID,
      secretAccessKey: process.env.REACT_APP_ACCESS_KEY,
    };

    const ReactS3Client = new S3(config);
    ReactS3Client.uploadFile(file, newFileNameWithUUID).then(data => {
      if (data.status === 204) {
        const imageBucketURL = data.location
        setImage2(imageBucketURL);
        setLoadingImg2(false);
        console.log('Image successfully uploaded to S3!');
        return data
      } else {
        console.log('Image failed upload to S3');
      }
    })
  };

  // Useffect to grab all chunks
  useEffect(() => {
    axios({
      method: 'get',
      url: process.env.REACT_APP_API + `chunks`,
      headers: {
        Accept: 'application/json',
        Authorization: process.env.REACT_APP_HEROKU_SERVER_KEY,
      }
    })
      .then((res) => {
        setAllChunks(res.data)
      })
  }, [])

  // Useffect to grab all users
  useEffect(() => {
    axios({
      method: 'get',
      url: process.env.REACT_APP_API + `users`,
      headers: {
        Accept: 'application/json',
        Authorization: process.env.REACT_APP_HEROKU_SERVER_KEY,
      }
    })
      .then((res) => {
        setAllUsers(res.data)
      })
  }, [])

  // Function that handles submission of the form
  const onSubmit = (data) => {
    setSubmitting(true);

    // Get an array of only chunk ids and another for admin ids for spreading correctly
    var group_chunk_ids = []
    var group_admin_ids = []

    if (data.group_chunks) {
      data.group_chunks.map(chunk => {
        group_chunk_ids.push(chunk.value)
      })
    }
    if (data.group_admins) {
      data.group_admins.map(admin => {
        group_admin_ids.push(admin.value)
      })
    }

    axios
      ({
        method: 'post',
        url: process.env.REACT_APP_API + `groups`,
        headers: {
          Accept: 'application/json',
          Authorization: process.env.REACT_APP_HEROKU_SERVER_KEY
        }, data: {
          ...data,
          profile_img: image,
          banner_img: image2,
          status: data.status ? data.status : null,
          user_limit: data.user_limit ? data.user_limit : null,
          group_admins: group_admin_ids,
          group_chunks: group_chunk_ids,
          publisher_id: pubID
        }
      })
      .then((res) => {
        setSubmitting(false);
        groups((oldList) => {
          return [...oldList, res.data];
        });
        history.push(`/admin/publishers/${pubID}/ensembles`);
      })
      .catch((err) => {
        console.log(err);
        alert('something went wrong');
        setSubmitting(false);
      });
    // Resets the form on submit (failed or not)
    // reset();
  };

  return (
    <div className='flex justify-center'>
      <div className=''>
        <div className='px-4 py-5 bg-white shadow sm:rounded-lg sm:p-6'>
          <div className='md:grid md:grid-cols-3 md:gap-6'>
            <div className='md:col-span-1'>
              <p className='mt-1 text-sm font-bold leading-5 text-red-700'>
                ONLY FILL OUT THIS FORM IF THE ENSEMBLE IS NOT ALREADY IN THE
                LIST OF ENSEMBLES!!! SUBMITTING A DUPLICATE ENSEMBLE WILL
                FAIL!
              </p>
            </div>

            {/* INPUT FIELDS */}
            <div className='mt-5 md:mt-0 md:col-span-2'>
              <form onSubmit={handleSubmit(onSubmit)}>

                <div className='sm:col-span-1 mt-4'>
                  <dt className='text-sm font-medium leading-5 text-gray-500'>
                    Name
                  <span className='ml-1 text-sm font-medium leading-5 text-red-500'>
                      *
                  </span>
                  </dt>
                  <input
                    id='name'
                    name='name'
                    type='text'
                    placeholder='Tom Landry Middle School'
                    ref={register({ required: true })}
                    className='block w-full mt-1 transition duration-150 ease-in-out form-textarea sm:text-sm sm:leading-5'
                  />
                  {errors.name && (
                    <span className='text-red-500'>This field is required</span>
                  )}
                </div>

                <div className='sm:col-span-1 mt-4'>
                  <dt className='text-sm font-medium leading-5 text-gray-500'>
                    Description
                  </dt>
                  <textarea
                    id='description'
                    name='description'
                    type='text'
                    placeholder=''
                    ref={register}
                    className='block w-full mt-1 transition duration-150 ease-in-out form-textarea sm:text-sm sm:leading-5'
                  />
                </div>

                <div className='mt-6 sm:mt-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
                  <label
                    htmlFor='cover_photo'
                    className='block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2'
                  >
                    Ensemble Profile Image
                  </label>
                  <div className='mt-2 sm:mt-0 sm:col-span-2'>
                    <div className='flex justify-center max-w-lg px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md'>
                      <div className='text-center'>
                        {image && !loadingImg ? (
                          <img src={image} alt='chunk img' />
                        ) : (
                          <svg
                            className='w-12 h-12 mx-auto text-gray-400'
                            stroke='currentColor'
                            fill='none'
                            viewBox='0 0 48 48'
                          >
                            <path
                              d='M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02'
                              strokeWidth='2'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                          </svg>
                        )}
                        {loadingImg ? (
                          <h3> Loading ...</h3>
                        ) : (
                          <h3>
                            <p className='mt-1 text-sm text-gray-600'>
                              <input
                                type='file'
                                name='file'
                                ref={fileInput}
                                placeholder='Upload an image here.'
                                onChange={uploadImg}
                              />
                            </p>
                            <p className='mt-1 text-xs text-gray-500'>
                              PNG, JPG, SVG up to 150MB
                            </p>
                          </h3>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className='mt-6 sm:mt-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
                  <label
                    htmlFor='cover_photo'
                    className='block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2'
                  >
                    Ensemble Banner Image
                  </label>
                  <div className='mt-2 sm:mt-0 sm:col-span-2'>
                    <div className='flex justify-center max-w-lg px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md'>
                      <div className='text-center'>
                        {image2 && !loadingImg2 ? (
                          <img
                            src={image2}
                            alt='chunk img'
                            className='w-full h-56'
                          />
                        ) : (
                          <svg
                            className='w-12 h-12 mx-auto text-gray-400'
                            stroke='currentColor'
                            fill='none'
                            viewBox='0 0 48 48'
                          >
                            <path
                              d='M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02'
                              strokeWidth='2'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                          </svg>
                        )}
                        {loadingImg2 ? (
                          <h3> Loading ...</h3>
                        ) : (
                          <h3>
                            <p className='mt-1 text-sm text-gray-600'>
                              <input
                                type='file'
                                name='file'
                                ref={fileInput2}
                                placeholder='Upload a banner pic here.'
                                onChange={uploadImg2}
                              />
                            </p>
                            <p className='mt-1 text-xs text-gray-500'>
                              PNG, JPG, SVG up to 150MB
                            </p>
                          </h3>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className='sm:col-span-1 mt-4'>
                  <dt className='text-sm font-medium leading-5 text-gray-500'>
                    Coupon Code
                  </dt>
                  <input
                    id='coupon_code'
                    name='coupon_code'
                    type='text'
                    placeholder=''
                    ref={register}
                    className='block w-full mt-1 transition duration-150 ease-in-out form-textarea sm:text-sm sm:leading-5'
                  />
                </div>

                <div className='sm:col-span-1 mt-4'>
                  <dt className='text-sm font-medium leading-5 text-gray-500'>
                    User Limit
                  <span className='ml-1 text-sm font-medium leading-5 text-red-500'>
                      *
                  </span>
                  </dt>
                  <input
                    id='user_limit'
                    name='user_limit'
                    type='number'
                    placeholder=''
                    ref={register({ required: true })}
                    className='block w-full mt-1 transition duration-150 ease-in-out form-textarea sm:text-sm sm:leading-5'
                  />
                  {errors.user_limit && (
                    <span className='text-red-500'>This field is required</span>
                  )}
                </div>

                <fieldset className='mt-6'>
                  <legend className='text-base font-medium leading-6 text-gray-900'>
                    Account Status
                  </legend>
                  <p className='text-sm text-gray-500 '>
                    Choose only one.
                  </p>
                  <div className='grid grid-cols-1 gap-6 my-2 sm:grid-cols-2 lg:grid-cols-4'>
                    {[0, 1, 2].map((value) => (
                      <div key={value} className='flex items-center my-2'>
                        <input
                          id='status'
                          name='status'
                          value={value}
                          type='radio'
                          ref={register}
                          className='w-4 h-4 text-indigo-600 transition duration-150 ease-in-out form-radio'
                        />
                        <label htmlFor='difficulty-medium' className='ml-3'>
                          <span className='block text-sm font-medium leading-5 text-gray-700'>
                            {value === 0 ? 'Active' : value === 1 ? 'Inactive' : value === 2 ? 'Suspended' : null}
                          </span>
                        </label>
                      </div>
                    ))}
                  </div>
                </fieldset>

                <div className='sm:col-span-1 mt-4'>
                  <dt className='text-sm font-medium leading-5 text-gray-500'>
                    Public
                  </dt>
                  <input
                    id='public'
                    name='public'
                    type='checkbox'
                    ref={register}
                    className='w-4 h-4 text-indigo-600 transition duration-150 ease-in-out form-checkbox'
                  />
                </div>

                <div className='col-span-6 sm:col-span-4'>
                  <label
                    className='block text-sm font-medium leading-5 text-gray-700'
                  >
                    Group Admins
                  </label>
                  <p className='text-sm text-gray-500 '>
                    Choose all that apply.
                  </p>
                  <Controller
                    as={Select}
                    options={allUsers?.map(user => ({ label: `${user.email} - ${user.cognito_id}`, value: user.id }))}
                    isMulti
                    name='group_admins'
                    control={control}
                  />
                </div>

                <div className='col-span-6 sm:col-span-4'>
                  <label
                    className='block text-sm font-medium leading-5 text-gray-700'
                  >
                    Group Chunks
                  </label>
                  <p className='text-sm text-gray-500 '>
                    Choose all that apply.
                  </p>
                  <Controller
                    as={Select}
                    options={allChunks?.map(ch => ({ label: `${ch.name} - ${ch.publisher_name}`, value: ch.id }))}
                    isMulti
                    name='group_chunks'
                    control={control}
                  />
                </div>

                {/* SUBMIT AND GO BACK BUTTONS */}
                <div className='flex justify-end w-full p-5 px-10 '>
                  <span className='inline-flex space-x-8 rounded-md shadow-sm'>
                    <Link
                      to={`/admin/publishers/${pubID}/ensembles`}
                      className={`${submitting && 'opacity-50 cursor-not-allowed'
                        }inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs leading-4 font-medium rounded text-white bg-red-500 hover:bg-red-800 focus:outline-none focus:border-indigo-300 focus:shadow-outline-indigo active:bg-indigo-200 transition ease-in-out duration-150`}
                    >
                      Go Back
                    </Link>
                    <button
                      type='submit'
                      className={`${submitting && 'opacity-50 cursor-not-allowed'
                        }  inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150`}
                    >
                      {submitting ? '' : 'Submit'}
                      <svg
                        className={`animate-spin ${!submitting && 'hidden'}`}
                        stroke='currentColor'
                        fill='currentColor'
                        strokeWidth='0'
                        viewBox='0 0 16 16'
                        height='1em'
                        width='1em'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path d='M8 .75c.172 0 .333.034.484.102a1.214 1.214 0 0 1 .664.664c.068.15.102.312.102.484s-.034.333-.102.484a1.214 1.214 0 0 1-.265.399 1.324 1.324 0 0 1-.399.273A1.254 1.254 0 0 1 8 3.25c-.172 0-.333-.031-.484-.094a1.324 1.324 0 0 1-.672-.672A1.254 1.254 0 0 1 6.75 2c0-.172.031-.333.094-.484.067-.151.159-.284.273-.399.115-.114.248-.203.399-.265A1.17 1.17 0 0 1 8 .75zM2.633 3.758a1.111 1.111 0 0 1 .68-1.031 1.084 1.084 0 0 1 .882 0c.136.057.253.138.352.242.104.099.185.216.242.351a1.084 1.084 0 0 1 0 .883 1.122 1.122 0 0 1-.594.594 1.169 1.169 0 0 1-.883 0 1.19 1.19 0 0 1-.359-.234 1.19 1.19 0 0 1-.234-.36 1.169 1.169 0 0 1-.086-.445zM2 7a.941.941 0 0 1 .703.297A.941.941 0 0 1 3 8a.97.97 0 0 1-.078.39 1.03 1.03 0 0 1-.531.532A.97.97 0 0 1 2 9a.97.97 0 0 1-.39-.078 1.104 1.104 0 0 1-.32-.211 1.104 1.104 0 0 1-.212-.32A.97.97 0 0 1 1 8a.97.97 0 0 1 .29-.703A.97.97 0 0 1 2 7zm.883 5.242a.887.887 0 0 1 .531-.805.863.863 0 0 1 .68 0c.11.047.203.11.281.188a.887.887 0 0 1 .188.96.887.887 0 0 1-1.148.461.913.913 0 0 1-.462-.46.863.863 0 0 1-.07-.344zM8 13.25c.208 0 .385.073.531.219A.723.723 0 0 1 8.75 14a.723.723 0 0 1-.219.531.723.723 0 0 1-.531.219.723.723 0 0 1-.531-.219A.723.723 0 0 1 7.25 14c0-.208.073-.385.219-.531A.723.723 0 0 1 8 13.25zm3.617-1.008c0-.177.06-.325.18-.445s.268-.18.445-.18.326.06.445.18c.12.12.18.268.18.445s-.06.326-.18.445a.605.605 0 0 1-.445.18.605.605 0 0 1-.445-.18.605.605 0 0 1-.18-.445zM14 7.5a.48.48 0 0 1 .352.148A.48.48 0 0 1 14.5 8a.48.48 0 0 1-.148.352A.48.48 0 0 1 14 8.5a.48.48 0 0 1-.352-.148A.48.48 0 0 1 13.5 8a.48.48 0 0 1 .148-.352A.48.48 0 0 1 14 7.5zm-1.758-5.117c.188 0 .365.036.531.11a1.413 1.413 0 0 1 .735.734c.073.166.11.343.11.53 0 .188-.037.365-.11.532a1.413 1.413 0 0 1-.735.734 1.31 1.31 0 0 1-.53.11c-.188 0-.365-.037-.532-.11a1.415 1.415 0 0 1-.734-.734 1.31 1.31 0 0 1-.11-.531c0-.188.037-.365.11-.531a1.413 1.413 0 0 1 .734-.735c.167-.073.344-.11.531-.11z'></path>
                      </svg>
                    </button>
                  </span>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};