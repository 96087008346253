import React, { useState } from 'react';

// COMPONENTS
import NavBar from './NavBar';
import { Footer } from './Footer';

// ----------------------------------------------------------------------------------
// -------------------------------------- FAQ ---------------------------------------
// ----------------------------------------------------------------------------------

export const Faq = () => {
  // State to access the accordion
  const [open, setOpen] = useState({
    one: false,
    two: false,
    three: false,
    four: false,
  });

  return (
    <>
    <NavBar />
      <div className='bg-gray-50'>
        <div className='max-w-screen-xl mx-auto py-8 lg:pt-36 px-4 sm:px-6 lg:px-8'>
          <div className='max-w-3xl mx-auto'>
            <h2 className='text-center text-3xl leading-9 font-extrabold text-gray-900 sm:text-4xl sm:leading-10'>
              Frequently asked questions
            </h2>
            <p className='mt-4 max-w-3xl mx-auto text-center text-xl leading-7 text-gray-500'>
              Howdy! If your questions aren’t answered in this FAQ, please feel
              free to contact us any time.
            </p>
            <div className='mt-6 border-t-2 border-gray-200 pt-6'>
              <dl>
                {/* FAQ 1 */}
                <div>
                  <dt className='text-lg leading-7'>
                    {/* <!-- Expand/collapse question button --> */}
                    <button
                      className='text-left w-full flex justify-between items-start text-gray-400 focus:outline-none focus:text-gray-900'
                      onClick={() => {
                        open.one
                          ? setOpen({ ...open, one: false })
                          : setOpen({
                            one: true,
                            two: false,
                            three: false,
                            four: false,
                            five: false,
                            six: false,
                          });
                      }}
                    >
                      <span className='font-medium text-gray-900'>
                        What is Music Chunks?
                      </span>
                      <span className='ml-6 h-7 flex items-center'>
                        {/* <!--
                      Expand/collapse icon, toggle classes based on question open state.

                      Open: "-rotate-180", Closed: "rotate-0"
                    --> */}
                        <svg
                          className={`${open.one ? '-rotate-180' : 'rotate-0'
                            } h-6 w-6 transform`}
                          fill='none'
                          viewBox='0 0 24 24'
                          stroke='currentColor'
                        >
                          <path
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            strokeWidth='2'
                            d='M19 9l-7 7-7-7'
                          />
                        </svg>
                      </span>
                    </button>
                  </dt>
                  <dd className='mt-2 pr-12'>
                    <span className={`${!open.one && 'hidden'} text-base leading-6 text-gray-500`}>
                      <p>
                        Music Chunks is an educational platform for students and teachers to discover, learn,
                        practice, and coordinate group learning. Top-tier instrumental music basics, movement
                        and visual basics, tons of music for marching, jazz, concert, brass, woodwind, and
                        percussion ensembles which you can play in the easy-to-use interactive player. Video,
                        audio recordings, and sheet music are seamlessly combined and can be played and looped
                        over and over, slowed down or sped up without changing the pitch, and played as full
                        scores or any combination of instruments with ease - giving students a major advantage
                        as they learn.
                      </p>
                      <br />
                      <p >
                        Ensembles who subscribe to Music Chunks get a private ensemble portal where they can upload
                        their own private content only visible to their ensemble. The assigned ensemble admins can
                        manage ensemble members, create and manage playlists, and submit new content which Music
                        Chunks will sync for you and your ensemble to use.
                      </p>
                      <br />
                      <p>
                        This platform gives you inspired content and better technology to inspire students, and teach
                        the next generation.
                      </p>
                    </span>
                  </dd>
                </div>
                <div className='mt-6 border-t border-gray-200 pt-6'>
                  {/* FAQ 2 */}
                  <div>
                    <dt className='text-lg leading-7'>
                      {/* <!-- Expand/collapse question button --> */}
                      <button
                        className='text-left w-full flex justify-between items-start text-gray-400 focus:outline-none focus:text-gray-900'
                        onClick={() => {
                          open.two
                            ? setOpen({ ...open, two: false })
                            : setOpen({
                              one: false,
                              two: true,
                              three: false,
                              four: false,
                              five: false,
                              six: false,
                            });
                        }}
                      >
                        <span className='font-medium text-gray-900'>
                          Why should you and your Ensembles use Music Chunks?
                        </span>
                        <span className='ml-6 h-7 flex items-center'>
                          {/* <!--
                        Expand/collapse icon, toggle classes based on question open state.

                        Open: "-rotate-180", Closed: "rotate-0"
                      --> */}
                          <svg
                            className={`${open.two ? '-rotate-180' : 'rotate-0'
                              } h-6 w-6 transform`}
                            fill='none'
                            viewBox='0 0 24 24'
                            stroke='currentColor'
                          >
                            <path
                              strokeLinecap='round'
                              strokeLinejoin='round'
                              strokeWidth='2'
                              d='M19 9l-7 7-7-7'
                            />
                          </svg>
                        </span>
                      </button>
                    </dt>
                    <dd className='mt-2 pr-12'>
                      <span className={`${!open.two && 'hidden'} text-base leading-6 text-gray-500`}>
                        <p>
                          Music Chunks gives students a chance to see, hear, and practice
                          along with examples, both with and without their instructor
                          present. Maybe you are short a staff member for a particular
                          section of your ensemble - that’s a great time to have that section
                          rehearse with Music Chunks. Maybe you have students who
                          haven’t seen a great performer on their instrument play their
                          music selection - Music Chunks would give them the inspiring
                          demonstration and the chance to slow it down and play along.
                          Maybe you want to make your own videos and have them
                          synched with an adjustable metronome for your students - you
                          can load your videos into your private Music Chunks Ensemble
                          portal, only visible to your admitted students.
                        </p>
                        <br />
                        <p>
                          Music Chunks is a tool to help with student engagement, student
                          retention, performance skills development, and deepening
                          students’ enjoyment of music.
                        </p>
                      </span>
                    </dd>
                  </div>
                </div>
                <div className='mt-6 border-t border-gray-200 pt-6'>
                  {/* FAQ 3 */}
                  <div>
                    <dt className='text-lg leading-7'>
                      {/* <!-- Expand/collapse question button --> */}
                      <button
                        className='text-left w-full flex justify-between items-start text-gray-400 focus:outline-none focus:text-gray-900'
                        onClick={() => {
                          open.three
                            ? setOpen({ ...open, three: false })
                            : setOpen({
                              one: false,
                              two: false,
                              three: true,
                              four: false,
                              five: false,
                              six: false,
                            });
                        }}
                      >
                        <span className='font-medium text-gray-900'>
                          What types of content are on Music Chunks?
                        </span>
                        <span className='ml-6 h-7 flex items-center'>
                          {/* <!--
                        Expand/collapse icon, toggle classes based on question open state.

                        Open: "-rotate-180", Closed: "rotate-0"
                      --> */}
                          <svg
                            className={`${open.three ? '-rotate-180' : 'rotate-0'
                              } h-6 w-6 transform`}
                            fill='none'
                            viewBox='0 0 24 24'
                            stroke='currentColor'
                          >
                            <path
                              strokeLinecap='round'
                              strokeLinejoin='round'
                              strokeWidth='2'
                              d='M19 9l-7 7-7-7'
                            />
                          </svg>
                        </span>
                      </button>
                    </dt>
                    <dd className='mt-2 pr-12'>
                      <span className={`${!open.three && 'hidden'} text-base leading-6 text-gray-500`}>
                        <p>
                          Original music, music lessons, instrumental warm-ups and exercises, movement and
                          visual warm-ups and exercise, choreography and dance, and other music education
                          related content by top-tier creators and educators. Our fast growing library has
                          content suitable for all levels of school band with genres including instrumental
                          basics, the marching arts, concert and wind ensembles, jazz band, pop and combo
                          ensembles, steel band, percussion ensemble, and even insightful podcasts and interviews
                          for educators.
                        </p>
                        <br />
                        <p>
                          The Content Creators who have licensed the content to Music Chunks are either the owners
                          of the content or have secured all necessary permissions.
                        </p>
                      </span>
                    </dd>
                  </div>
                </div>
                <div className='mt-6 border-t border-gray-200 pt-6'>
                  {/* FAQ 4 */}
                  <div>
                    <dt className='text-lg leading-7'>
                      {/* <!-- Expand/collapse question button --> */}
                      <button
                        className='text-left w-full flex justify-between items-start text-gray-400 focus:outline-none focus:text-gray-900'
                        onClick={() => {
                          open.four
                            ? setOpen({ ...open, four: false })
                            : setOpen({
                              one: false,
                              two: false,
                              three: false,
                              four: true,
                              five: false,
                              six: false,
                            });
                        }}
                      >
                        <span className='font-medium text-gray-900'>
                          Does my Music Chunks subscription include the rights to perform the music publicly, at a concert
                          or marching band show?
                        </span>
                        <span className='ml-6 h-7 flex items-center'>
                          {/* <!--
                        Expand/collapse icon, toggle classes based on question open state.

                        Open: "-rotate-180", Closed: "rotate-0"
                      --> */}
                          <svg
                            className={`${open.four ? '-rotate-180' : 'rotate-0'
                              } h-6 w-6 transform`}
                            fill='none'
                            viewBox='0 0 24 24'
                            stroke='currentColor'
                          >
                            <path
                              strokeLinecap='round'
                              strokeLinejoin='round'
                              strokeWidth='2'
                              d='M19 9l-7 7-7-7'
                            />
                          </svg>
                        </span>
                      </button>
                    </dt>
                    <dd className='mt-2 pr-12'>
                      <span className={`${!open.four && 'hidden'} text-base leading-6 text-gray-500`}>
                        <p>
                          No. The permissions granted to Music Chunks users are for private use only. Use the
                          platform in lessons, group rehearsals, have your full ensemble open the same chunk
                          and learn together. You can create infinite curriculums for private lessons students,
                          and learn the chunks of music with the major benefits of the interactive player.
                        </p>
                        <br />
                        <p>
                          When you find something you want to perform publicly, or to purchase the whole piece
                          because you loved the chunk so much, we link directly to the Publisher or Content
                          Creator’s webpage where you can purchase it directly from them or contact them about
                          public performance rights for you or your ensemble.
                        </p>
                      </span>
                    </dd>
                  </div>
                </div>
              </dl>
            </div>
          </div>
          <div className='flex justify-end '>
            <a
              href='/faq'
              className='inline-flex items-center px-2.5 py-1.5 mt-8 border border-transparent text-xs leading-4 font-medium rounded text-mcbuttondarkgreen bg-mcgreen hover:text-mcgreen hover:bg-mcbuttondarkgreen focus:outline-none transition ease-in-out duration-150'
            >
              Have more questions?
            </a>
          </div>
        </div>
      </div>
    <Footer />
    </>
  );
};
