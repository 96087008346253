import React, { useState, useEffect } from 'react';
import axios from 'axios';
import axiosRetry from 'axios-retry';

// ROUTING
import { useHistory } from 'react-router-dom';

// AUTH
import { Auth } from '@aws-amplify/auth';

// FORM MANAGEMENT
import { WithContext as ReactTags } from 'react-tag-input';

// TOAST
import cogoToast from 'cogo-toast';

// ----------------------------------------------------------------------------------
// ------------------------------ GROUP ADD USER FORM -------------------------------
// ----------------------------------------------------------------------------------

const GroupAddUserForm = ({ group, groupUsers, groupAdmins, refresh, setRefresh, match }) => {
  // State
  const history = useHistory();
  const [emails, setEmails] = useState({ tags: [] }) // Holds a list of typed user emails
  const [successUsers, setSuccessUsers] = useState([]) // Holds list of user data for users successfully added to the ensemble/group
  const [failedUsers, setFailedUsers] = useState([]) // Holds list of user data for users not added to the ensemble/group. (users not in our DB)
  const [submitting, setSubmitting] = useState(false);
  const [usageCount, setUsageCount] = useState();
  const [groupUserIds, setGroupUserIds] = useState();
  const [groupAdminIds, setGroupAdminIds] = useState();

  const cogUserAttributes = Auth.user.attributes;
  const { tags } = emails;
  const KeyCodes = {
    comma: 188,
    enter: 13,
  };
  const delimiters = [KeyCodes.comma, KeyCodes.enter]; // Keys that allow multiple tags to be added

  // Set axios to retry a set number of times on failed request/return
  axiosRetry(axios, { retries: 3 })

  // UseEffect that sets the usageCount and created various lists of ids
  useEffect(() => {
    var tempGroupUserIds = []
    var tempGroupAdminIds = []

    groupUsers.map(groupUser => {
      tempGroupUserIds.push(groupUser.user_id)
    })
    groupAdmins.map(groupAdmin => {
      tempGroupAdminIds.push(groupAdmin.id)
    })

    setGroupUserIds(tempGroupUserIds)
    setGroupAdminIds(tempGroupAdminIds)
    setUsageCount(group.user_limit - groupUsers.length)
  }, [groupUsers, group]);

  // Handle removing emails from list
  const handleDelete = ((i) => {
    const { tags } = emails;

    setSuccessUsers(
      successUsers.filter((tag, index) => tag.email !== tags[i].text.toLowerCase().replaceAll(/ /g, ''))
    );
    setFailedUsers(
      failedUsers.filter((tag, index) => tag !== tags[i].text.toLowerCase().replaceAll(/ /g, ''))
    );
    setEmails({
      tags: tags.filter((tag, index) => index !== i),
    });
  })

  const handleAddition = ((tag) => {
    var failedUserEmails = failedUsers
    var successUserEmails = successUsers

    // Make sure we are submitting valid emails
    if (validateEmail(tag.text.toLowerCase().replaceAll(/ /g, ''))) {
      axios({
        method: 'get',
        url: process.env.REACT_APP_API + `users/email/${tag.text.replaceAll(/ /g, '').toLowerCase()}`,
        headers: {
          Accept: 'application/json',
          Authorization: process.env.REACT_APP_HEROKU_SERVER_KEY,
        },
      })
        .then((res) => {
          // Only push the new user if they are not already part of the group
          if (groupUserIds.includes(res.data.id)) {
            cogoToast.error(`User with email '${tag.text.replaceAll(/ /g, '').toLowerCase()}' is already a member.`, {
              hideAfter: 5,
            })
          } else if (groupAdminIds.includes(res.data.id)) {
            cogoToast.error(`User with email '${tag.text.replaceAll(/ /g, '').toLowerCase()}' is an admin. They cannot be added as a student.`, {
              hideAfter: 5,
            })
          } else {
            setEmails(state => ({ tags: [...state.tags, tag] }));
            successUserEmails.push(res.data)
            setSuccessUsers(successUserEmails)
            cogoToast.success(`User with email '${tag.text.replaceAll(/ /g, '').toLowerCase()}' has a Music Chunks account`, {
              hideAfter: 5,
            })
          }
        })
        .catch((err) => {
          console.log(err.response.data);
          cogoToast.warn(`User with email '${tag.text.replaceAll(/ /g, '').toLowerCase()}' does not have a Music Chunks account. On form submission, 
          we'll send an email telling them to register an account.`, {
            hideAfter: 5,
          })
          setEmails(state => ({ tags: [...state.tags, tag] }));
          failedUserEmails.push(tag.text.replaceAll(/ /g, '').toLowerCase())
          setFailedUsers(failedUserEmails)
        });
    } else {
      cogoToast.error(`'${tag.text.replaceAll(/ /g, '').toLowerCase()}' is not a valid email address`, {
        hideAfter: 5,
      })
    }
  })

  const handleDrag = ((tag, currPos, newPos) => {
    const tags = [...emails?.tags];
    const newTags = tags.slice();
    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);
    // re-render
    setEmails({ tags: newTags });
  })

  // Function to determine whether an email is valid
  const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  // Function that handles submission of the form
  const onSubmit = () => {
    setSubmitting(true);

    var newUserIds = []
    var successUserEmails = []

    if (successUsers.length > 0) {
      successUsers.map(newUser => {
        newUserIds.push(newUser.id)
        successUserEmails.push(newUser.email)
      })
    }

    // Combine a list of current users and new users
    var oldUsersAndNewUsers = groupUserIds
    oldUsersAndNewUsers = groupUserIds.concat(newUserIds)

    // Combine a list of all submitted emails to get total for user_limit check
    var emailCountPlusExistingUserCount = failedUsers.length + successUsers.length + groupUserIds.length

    // If they copy paste a list of emails, and it exceeds the user limit, do not submit and have them remove some emails
    if (emailCountPlusExistingUserCount <= group.user_limit) {
      // Only run the axios call if new users are being submitted
      if (successUsers.length > 0 || failedUsers.length > 0) {
        // Lambda function that sends emails to all users (confirmation if user is in system and 'please sign up' if not)
        fetch(process.env.REACT_APP_ADD_ENSEMBLE_USER_EMAIL, {
          method: 'POST',
          body: JSON.stringify({
            admin_last_name: cogUserAttributes.family_name,
            admin_first_name: cogUserAttributes.given_name,
            group_name: group.name,
            group_id: group.id,
            success_user_emails: successUserEmails,
            failed_user_emails: failedUsers
          })
        })
          .then((res) => {
            setSubmitting(false);
            if (successUserEmails.length > 0) {
              cogoToast.success(`The following users were added to the ensemble: ${successUserEmails.join(', ')}`, {
                hideAfter: 20
              });
            }
            if (failedUsers.length > 0) {
              cogoToast.warn(`The following users were sent an email to join Music Chunks and will automatically join the ensemble upon account creation: ${failedUsers.join(', ')}`, {
                hideAfter: 20
              });
            }
            setRefresh(!refresh);
            history.push(`${match.url.replace('/add-student', '')}`);
          })
          .catch((err) => {
            cogoToast.error('Could not send confirmation emails to students', { hideAfter: 5 });
            setSubmitting(false);
          });
      } else {
        setSubmitting(false);
        cogoToast.error('You have not added any email addresses.', {
          hideAfter: 5,
        });
      };
    } else {
      setSubmitting(false);
      cogoToast.error(`You have entered more emails than seats available. Please remove at least ${emailCountPlusExistingUserCount - group.user_limit} email(s).`, {
        hideAfter: 8,
      });
    }
  }

  return (
    <div className='mt-3'>
      {/* ABOUT */}
      <div className='bg-green-700 px-5 py-3 mt-2 rounded-lg'>
        <p className='whitespace-pre-wrap text-white'>
          Add students to give access to the private page. You can only add as many
          students as ther are seats available. To invite new students, copy/paste multiple email addresses
          (comma separated) into the box, then HIT ENTER, then CLICK SUBMIT.
          Alternatively, you can type in individual email addresses to add, HIT ENTER,
          then CLICK SUBMIT when all students emails have been confirmed.
        </p>
      </div>

      {/* TITLE */}
      <div className='mt-2 border-t-4 border-green-600 border-solid'>
        <h1 className='w-full pt-2 text-2xl font-medium leading-6 text-white'>
          ADD STUDENTS
        </h1>
      </div>
      <div className='mt-5'>
        {usageCount - emails.tags.length > 0 ? (
          <ReactTags
            tags={tags}
            type='email'
            handleDelete={handleDelete}
            handleAddition={handleAddition}
            handleDrag={handleDrag}
            delimiters={delimiters}
            placeholder='Press enter to add multiple student email addresses'
            allowDeleteFromEmptyInput={false}
          />
        ) : (
          <ReactTags
            tags={tags}
            handleDelete={handleDelete}
            handleAddition={(() =>
              cogoToast.error(`You have reached the user limit for this plan`, {
                hideAfter: 5,
              }))}
            delimiters={delimiters}
            placeholder='User limit for current plan reached'
            allowDeleteFromEmptyInput={false}
          />
        )}
      </div>

      <div className='flex justify-center sm:justify-end w-full p-5 px-10 '>
        <span className='inline-flex space-x-8 rounded-md shadow-sm'>
          <button
            type='submit'
            onClick={onSubmit}
            className={`${submitting && 'opacity-50 cursor-not-allowed'
              } inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150`}
          >
            {submitting ? '' : 'Submit'}
            <svg
              className={`animate-spin ${!submitting && 'hidden'}`}
              stroke='currentColor'
              fill='currentColor'
              strokeWidth='0'
              viewBox='0 0 16 16'
              height='1em'
              width='1em'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path d='M8 .75c.172 0 .333.034.484.102a1.214 1.214 0 0 1 .664.664c.068.15.102.312.102.484s-.034.333-.102.484a1.214 1.214 0 0 1-.265.399 1.324 1.324 0 0 1-.399.273A1.254 1.254 0 0 1 8 3.25c-.172 0-.333-.031-.484-.094a1.324 1.324 0 0 1-.672-.672A1.254 1.254 0 0 1 6.75 2c0-.172.031-.333.094-.484.067-.151.159-.284.273-.399.115-.114.248-.203.399-.265A1.17 1.17 0 0 1 8 .75zM2.633 3.758a1.111 1.111 0 0 1 .68-1.031 1.084 1.084 0 0 1 .882 0c.136.057.253.138.352.242.104.099.185.216.242.351a1.084 1.084 0 0 1 0 .883 1.122 1.122 0 0 1-.594.594 1.169 1.169 0 0 1-.883 0 1.19 1.19 0 0 1-.359-.234 1.19 1.19 0 0 1-.234-.36 1.169 1.169 0 0 1-.086-.445zM2 7a.941.941 0 0 1 .703.297A.941.941 0 0 1 3 8a.97.97 0 0 1-.078.39 1.03 1.03 0 0 1-.531.532A.97.97 0 0 1 2 9a.97.97 0 0 1-.39-.078 1.104 1.104 0 0 1-.32-.211 1.104 1.104 0 0 1-.212-.32A.97.97 0 0 1 1 8a.97.97 0 0 1 .29-.703A.97.97 0 0 1 2 7zm.883 5.242a.887.887 0 0 1 .531-.805.863.863 0 0 1 .68 0c.11.047.203.11.281.188a.887.887 0 0 1 .188.96.887.887 0 0 1-1.148.461.913.913 0 0 1-.462-.46.863.863 0 0 1-.07-.344zM8 13.25c.208 0 .385.073.531.219A.723.723 0 0 1 8.75 14a.723.723 0 0 1-.219.531.723.723 0 0 1-.531.219.723.723 0 0 1-.531-.219A.723.723 0 0 1 7.25 14c0-.208.073-.385.219-.531A.723.723 0 0 1 8 13.25zm3.617-1.008c0-.177.06-.325.18-.445s.268-.18.445-.18.326.06.445.18c.12.12.18.268.18.445s-.06.326-.18.445a.605.605 0 0 1-.445.18.605.605 0 0 1-.445-.18.605.605 0 0 1-.18-.445zM14 7.5a.48.48 0 0 1 .352.148A.48.48 0 0 1 14.5 8a.48.48 0 0 1-.148.352A.48.48 0 0 1 14 8.5a.48.48 0 0 1-.352-.148A.48.48 0 0 1 13.5 8a.48.48 0 0 1 .148-.352A.48.48 0 0 1 14 7.5zm-1.758-5.117c.188 0 .365.036.531.11a1.413 1.413 0 0 1 .735.734c.073.166.11.343.11.53 0 .188-.037.365-.11.532a1.413 1.413 0 0 1-.735.734 1.31 1.31 0 0 1-.53.11c-.188 0-.365-.037-.532-.11a1.415 1.415 0 0 1-.734-.734 1.31 1.31 0 0 1-.11-.531c0-.188.037-.365.11-.531a1.413 1.413 0 0 1 .734-.735c.167-.073.344-.11.531-.11z'></path>
            </svg>
          </button>
        </span>
      </div>
    </div>
  );
};

export const MemoizedGroupAddUserForm = React.memo(GroupAddUserForm);