import React from 'react';

// ----------------------------------------------------------------------------------
// ----------------------------- INSTRUCTIONAL VIDEO CARD ---------------------------
// ----------------------------------------------------------------------------------

const InstructionalVideoCard = ({ data }) => {
  const {
    video_img
  } = data;

  return (
    <div className='hover:bg-mcgreen max-w-359 hover:bg-opacity-25 p-2 rounded-lg'>
      <div className={`overflow-hidden`}>
        <img
          src={video_img}
          alt='img for a video'
          className='object-fill w-full h-full min-h-full rounded-md'
        />
      </div>
    </div>
  );
};
export const MemoizedInstructionalVideoCard = React.memo(InstructionalVideoCard);