import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import axiosRetry from 'axios-retry';
import { v4 as uuidv4 } from 'uuid';
import S3 from 'react-aws-s3';

// TAILWIND TRANSITION
import { Transition } from '@tailwindui/react';

// IMAGES
import { ReactComponent as Checkmark } from '../../../img/icon/checkmark.svg';

// FORM MANAGEMENT
import { useForm, Controller } from 'react-hook-form';
import Select from 'react-select';

// ROUTING
import { useHistory, useRouteMatch, Link } from 'react-router-dom';

// STATE
import { useSetRecoilState } from 'recoil';
import { chunkData } from '../../../state/atoms';

// TOAST
import cogoToast from 'cogo-toast';

// ----------------------------------------------------------------------------------
// ---------------------------------- CHUNK DATA ------------------------------------
// ----------------------------------------------------------------------------------
const ChunkData = ({ data }) => {
  const {
    id,
    name,
    description,
    img,
    tag_line,
    difficulty,
    embed_URL,
    purchase_URL,
    additional_notes_URL,
    free_sub,
    standard_sub,
    premium_sub,
    featured,
    instruments,
    sections,
    sound_recordings,
    collectionType,
  } = data;

  const history = useHistory();
  const { url } = useRouteMatch();

  // State
  const chunks = useSetRecoilState(chunkData);
  const [allInstruments, setAllInstruments] = useState([]);  // SAVES LIST OF ALL INSTRUMENTS
  const [chunkInstruments, setChunkInstruments] = useState([]);  // SAVES LIST OF ALL A CHUNK'S INSTRUMENTS
  const [allSections, setAllSections] = useState([]);  // SAVES LIST OF ALL SECTIONS
  const [chunkSections, setChunkSections] = useState([]);  // SAVES LIST OF ALL A CHUNK'S SECTIONS

  // Set axios to retry a set number of times on failed request/return
  axiosRetry(axios, { retries: 3 })

  // Form
  const { register, handleSubmit, errors, reset, control } = useForm({
    mode: 'onChange',
  });

  // Editing state
  const [openOptions, setOpenOptions] = useState(false);
  const [openDetails, setOpenDetails] = useState(false);
  const [openWarning, setOpenWarning] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  // Image state
  const [loadingImg, setLoadingImg] = useState(false);
  const [image, setImage] = useState('');

  // UseEffect that sets the image
  useEffect(() => {
    setImage(img);
  }, [img]);

  // Useffect to grab all instruments for editing
  useEffect(() => {
    axios({
      method: 'get',
      url: process.env.REACT_APP_API + `instruments`,
      headers: {
        Accept: 'application/json',
        Authorization: process.env.REACT_APP_HEROKU_SERVER_KEY,
      }
    })
      .then((res) => {
        setAllInstruments(res.data)
      })
  }, [])

  // Useffect to grab all sections for editing
  useEffect(() => {
    axios({
      method: 'get',
      url: process.env.REACT_APP_API + `sections/public`,
      headers: {
        Accept: 'application/json',
        Authorization: process.env.REACT_APP_HEROKU_SERVER_KEY,
      }
    })
      .then((res) => {
        setAllSections(res.data)
      })
  }, [])

  // UseEffect to grab all instruments tied to the current chunk
  useEffect(() => {
    var chunk_instruments = []

    allInstruments.filter(instrument => {
      if (instruments.includes(instrument.name)) {
        chunk_instruments.push(instrument)
      }
    })
    setChunkInstruments(chunk_instruments)
  }, [allInstruments, instruments])

  // UseEffect to grab all sections tied to the current chunk
  useEffect(() => {
    var chunk_sections = []

    allSections.filter(section => {
      if (sections.includes(section.filter) && section.public === true) {
        chunk_sections.push(section)
      }
    })
    setChunkSections(chunk_sections)
  }, [allSections, sections])

  // AMAZON WEB SERVICES WITH S3
  const fileInput = useRef();

  const uploadImg = async (e) => {
    e.preventDefault();

    let file = fileInput.current.files[0]
    let newFileName = fileInput.current.files[0].name
    let newFileNameWithUUID = 'single-chunks/' + newFileName.substring(0, newFileName.lastIndexOf('.')) + '_' + uuidv4();

    const config = {
      bucketName: process.env.REACT_APP_BUCKET_NAME,
      region: process.env.REACT_APP_REGION,
      accessKeyId: process.env.REACT_APP_ACCESS_ID,
      secretAccessKey: process.env.REACT_APP_ACCESS_KEY,
    };

    const ReactS3Client = new S3(config);
    ReactS3Client.uploadFile(file, newFileNameWithUUID).then(data => {
      if (data.status === 204) {
        const imageBucketURL = data.location
        setImage(imageBucketURL);
        setLoadingImg(false);
        console.log('Image successfully uploaded to S3!');
        return data
      } else {
        console.log('Image failed upload to S3');
      }
    })
  };

  // Function that handles deleting a chunk
  const handleDelete = (id) => {
    axios({
      method: 'delete',
      url: process.env.REACT_APP_API + `chunks/${id}`,
      headers: {
        Accept: 'application/json',
        Authorization: process.env.REACT_APP_HEROKU_SERVER_KEY,
      },
    })
      .then((res) => {
        chunks((oldList) => {
          const newList = oldList.filter((i) =>
            i.id === id ? res.data.updatedChunk : i
          );
          return newList;
        });
      })
      .catch((err) => {
        console.log(err);
        alert('something happened, item not deleted');
      });
  };

  // Function that handles updating/editing a chunk on submission
  const onSubmit = (data) => {
    // Get an array of only collection ids for spreading correctly
    var instrument_ids = []
    var section_ids = []

    // If instrument and section ids exist, push them to an array
    if (data.instruments && data.sections) {
      data.instruments.map(chunkInstruments => {
        instrument_ids.push(chunkInstruments.value)
      })

      data.sections.map(chunkSections => {
        section_ids.push(chunkSections.value)
      })

      axios({
        method: 'put',
        url: process.env.REACT_APP_API + `chunks/${id}`,
        headers: {
          Accept: 'application/json',
          Authorization: process.env.REACT_APP_HEROKU_SERVER_KEY,
        },
        data: {
          ...data,
          img: image,
          instruments: instrument_ids,
          sections: section_ids
        }
      })
        .then((res) => {
          chunks((oldList) => {
            const newList = oldList.map((i) =>
              i.id === id ? res.data.updatedChunk : i
            );
            return newList;
          });
          setIsEditing(false);
          cogoToast.success('Chunk successfully updated!', {
            hideAfter: 5,
          });
          history.push(url);
        })
        .catch((err) => {
          console.log(err);
          cogoToast.error('Something went wrong. Could not update the chunk', {
            hideAfter: 5,
          });
        });
    } else {
      cogoToast.error('Instruments and/or sections cannot be empty', {
        hideAfter: 5,
      });
    }
  };

  return (
    <div className='overflow-hidden shadow bg-gray-50'>
      <div className='flex justify-between px-4 py-5 border-b border-gray-200 sm:px-6'>
        {isEditing ? (
          <input
            id='name'
            type='text'
            name='name'
            defaultValue={name}
            ref={register({ required: true })}
            className='block w-1/3 px-3 py-2 mt-1 transition duration-150 ease-in-out border border-gray-300 rounded-md shadow-sm form-input focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5'
          />
        ) : (
          <div className='flex'>
            <h3 className='text-lg font-medium leading-6 text-gray-900 mr-5'>
              {name}
            </h3>
            {(sound_recordings?.length > 0 && Object.entries(sound_recordings[0])?.length === 0) || sound_recordings?.length === 0 ? (
              null
            ) : (
              <Checkmark className='inline-block object-fill w-7 h-7 rounded-md' />
            )}
          </div>
        )}
        {errors.name && (
          <span className='text-red-500'>This field is required</span>
        )}

        <div className='flex-shrink-0 pr-2'>
          {openDetails ? (
            <button
              onClick={() => setOpenDetails(!openDetails)}
              id='menu dropdown '
              aria-haspopup='true'
              className='inline-flex items-center justify-center w-8 h-8 text-gray-400 transition duration-150 ease-in-out bg-transparent rounded-full hover:text-gray-500 focus:outline-none focus:text-gray-500 focus:bg-gray-100'
            >
              X
            </button>
          ) : (
            <button
              onClick={() => setOpenOptions(!openOptions)}
              id='menu dropdown '
              aria-haspopup='true'
              className='inline-flex items-center justify-center w-8 h-8 text-gray-400 transition duration-150 ease-in-out bg-transparent rounded-full hover:text-gray-500 focus:outline-none focus:text-gray-500 focus:bg-gray-100'
            >
              {/* <!-- Heroicon name: dots-vertical --> */}
              <svg
                className='w-5 h-5'
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 20 20'
                fill='currentColor'
              >
                <path d='M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z' />
              </svg>
            </button>
          )}
          {/* <!--
                  Dropdown panel, show/hide based on dropdown state.
                  Entering: "transition ease-out duration-100"
                    From: "transform opacity-0 scale-95"
                    To: "transform opacity-100 scale-100"
                  Leaving: "transition ease-in duration-75"
                    From: "transform opacity-100 scale-100"
                    To: "transform opacity-0 scale-95"
                --> */}
          <Transition show={openOptions}>
            <div className='absolute z-10 w-48 mx-3 mt-1 origin-top-right rounded-md shadow-lg right-2'>
              <div
                className='bg-white rounded-md shadow-xs'
                role='menu'
                aria-orientation='vertical'
                aria-labelledby='pinned-project-options-menu-0'
              >
                <div className='relative z-40 py-1'>
                  <button
                    onClick={() => {
                      setOpenDetails(!openDetails);
                      setOpenOptions(!openOptions);
                    }}
                    id='pinned-project-options-menu-0'
                    aria-haspopup='true'
                    className='block w-full px-4 py-2 text-sm leading-5 text-left text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900'
                    role='menuitem'
                  >
                    View
                  </button>
                </div>
                <div className='border-t border-gray-100'></div>
                <div className='py-1'>
                  <Link
                    to={`${url}/${id}/soundrecordings`}
                    className='block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900'
                    role='menuitem'
                  >
                    View Recordings
                  </Link>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>

      <Transition show={openDetails}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='px-4 py-5 sm:px-6'>
            <dl className='grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3'>
              <div className='sm:col-span-1'>
                <dt className='text-sm font-medium leading-5 text-gray-500'>
                  Chunk Img
                </dt>
                {isEditing ? (
                  <div className='mt-2 sm:mt-0 sm:col-span-2'>
                    <div className='flex justify-center max-w-lg px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md'>
                      <div className='text-center'>
                        {image && !loadingImg ? (
                          <img
                            src={image}
                            alt='chunk img '
                            className='w-20 h-20'
                          />
                        ) : (
                          <svg
                            className='w-12 h-12 mx-auto text-gray-400'
                            stroke='currentColor'
                            fill='none'
                            viewBox='0 0 48 48'
                          >
                            <path
                              d='M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02'
                              strokeWidth='2'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                          </svg>
                        )}
                        {loadingImg ? (
                          <h3> Loading ...</h3>
                        ) : (
                          <h3>
                            <p className='mt-1 text-sm text-gray-600'>
                              <input
                                type='file'
                                name='file'
                                ref={fileInput}
                                placeholder='Upload an image here.'
                                onChange={uploadImg}
                              />
                            </p>
                            <p className='mt-1 text-xs text-gray-500'>
                              PNG, JPG, SVG up to 150MB
                            </p>
                          </h3>
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <dd className='mt-1'>
                    <img
                      src={image}
                      alt='img for a single chunk'
                      className='inline-block object-cover w-20 h-20 rounded-md'
                    />
                  </dd>
                )}
              </div>

              <div className='sm:col-span-1'>
                <dt className='text-sm font-medium leading-5 text-gray-500'>
                  Purchase URL
                </dt>
                {isEditing ? (
                  <input
                    id='purchase_URL'
                    type='url'
                    name='purchase_URL'
                    defaultValue={purchase_URL}
                    ref={register}
                    className='block w-full px-3 py-2 mt-1 transition duration-150 ease-in-out border border-gray-300 rounded-md shadow-sm form-input focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5'
                  />
                ) : (
                  <dd className='mt-1 text-sm leading-5 text-gray-900'>
                    {purchase_URL}
                  </dd>
                )}
              </div>

              <div className='sm:col-span-1'>
                <dt className='text-sm font-medium leading-5 text-gray-500'>
                  Additional Notes URL
                </dt>
                {isEditing ? (
                  <input
                    id='additional_notes_URL'
                    type='url'
                    name='additional_notes_URL'
                    defaultValue={additional_notes_URL}
                    ref={register}
                    className='block w-full px-3 py-2 mt-1 transition duration-150 ease-in-out border border-gray-300 rounded-md shadow-sm form-input focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5'
                  />
                ) : (
                  <dd className='mt-1 text-sm leading-5 text-gray-900'>
                    {additional_notes_URL}
                  </dd>
                )}
              </div>

              <div className='sm:col-span-1'>
                <dt className='text-sm font-medium leading-5 text-gray-500'>
                  Embed URL
                </dt>
                {isEditing ? (
                  <input
                    id='embed_URL'
                    type='url'
                    name='embed_URL'
                    defaultValue={embed_URL}
                    ref={register({ required: true })}
                    className='block w-full px-3 py-2 mt-1 transition duration-150 ease-in-out border border-gray-300 rounded-md shadow-sm form-input focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5'
                  />
                ) : (
                  <dd className='mt-1 text-sm leading-5 text-gray-900'>
                    {embed_URL}
                  </dd>
                )}
                {errors.embed_URL && (
                  <span className='text-red-500'>This field is required</span>
                )}
              </div>

              <div className='sm:col-span-1'>
                <dt className='text-sm font-medium leading-5 text-gray-500'>
                  Tag Line
                </dt>
                {isEditing ? (
                  <textarea
                    id='tag_line'
                    name='tag_line'
                    ref={register}
                    defaultValue={tag_line}
                    className='block w-full mt-1 transition duration-150 ease-in-out form-textarea sm:text-sm sm:leading-5'
                    placeholder='Try to keep under 100 characters'
                  ></textarea>
                ) : (
                  <dd className='w-1/2 mt-1 text-sm leading-5 text-gray-900'>
                    {tag_line}
                  </dd>
                )}
              </div>

              <div className='sm:col-span-1'>
                <dt className='text-sm font-medium leading-5 text-gray-500'>
                  Description
                </dt>
                {isEditing ? (
                  <textarea
                    id='description'
                    name='description'
                    ref={register}
                    defaultValue={description}
                    rows='7'
                    className='block w-full mt-1 transition duration-150 ease-in-out form-textarea sm:text-sm sm:leading-5'
                    placeholder='Try to keep under 750 characters'
                  ></textarea>
                ) : (
                  <dd className='w-1/2 mt-1 text-sm leading-5 text-gray-900'>
                    {description}
                  </dd>
                )}
              </div>

              <div className='sm:col-span-1'>
                <dt className='text-sm font-medium leading-5 text-gray-500'>
                  Difficulty
                </dt>
                {isEditing ? (
                  ['Easy', 'Medium', 'Hard'].map((value) => (
                    <div key={value} className='flex items-center my-2'>
                      <input
                        id='difficulty'
                        name='difficulty'
                        value={value}
                        type='radio'
                        ref={register}
                        defaultChecked={value === difficulty}
                        className='w-4 h-4 text-indigo-600 transition duration-150 ease-in-out form-radio'
                      />
                      <label htmlFor='difficulty-medium' className='ml-3'>
                        <span className='block text-sm font-medium leading-5 text-gray-700'>
                          {value}
                        </span>
                      </label>
                    </div>
                  ))
                ) : (
                  <dd className='w-1/2 mt-1 text-sm leading-5 text-gray-900'>
                    {difficulty}
                  </dd>
                )}
              </div>

              <div className='sm:col-span-1'>
                <dt className='text-sm font-medium leading-5 text-gray-500'>
                  Public
                </dt>
                {isEditing ? (
                  <input
                    id='public'
                    name='public'
                    type='checkbox'
                    ref={register}
                    defaultChecked={data.public}
                    className='w-4 h-4 text-indigo-600 transition duration-150 ease-in-out form-checkbox'
                  />
                ) : (
                  <dd
                    className={`mt-1 text-sm leading-5 font-bold ${data.public ? 'text-green-500' : 'text-red-600'
                      } `}
                  >
                    {data.public ? 'YES' : 'NO'}
                  </dd>
                )}
              </div>

              <div className='sm:col-span-1'>
                <dt className='text-sm font-medium leading-5 text-gray-500'>
                  Featured
                </dt>
                {isEditing ? (
                  <input
                    id='featured'
                    name='featured'
                    type='checkbox'
                    defaultChecked={featured}
                    ref={register}
                    className='w-4 h-4 text-indigo-600 transition duration-150 ease-in-out form-checkbox'
                  />
                ) : (
                  <dd
                    className={`mt-1 text-sm leading-5 font-bold ${featured ? 'text-green-500' : 'text-red-600'
                      } `}
                  >
                    {featured ? 'YES' : 'NO'}
                  </dd>
                )}
              </div>

              <div className='sm:col-span-1'>
                <dt className='text-sm font-medium leading-5 text-gray-500'>
                  Downloadable
                </dt>
                {isEditing ? (
                  <input
                    id='isDownloadable'
                    name='isDownloadable'
                    type='checkbox'
                    ref={register}
                    defaultChecked={data.isDownloadable}
                    className='w-4 h-4 text-indigo-600 transition duration-150 ease-in-out form-checkbox'
                  />
                ) : (
                  <dd
                    className={`mt-1 text-sm leading-5 font-bold ${data.isDownloadable ? 'text-green-500' : 'text-red-600'
                      } `}
                  >
                    {data.isDownloadable ? 'YES' : 'NO'}
                  </dd>
                )}
              </div>

              <div className='sm:col-span-1'>
                <dt className='text-sm font-medium leading-5 text-gray-500'>
                  Free Sub
                </dt>
                {isEditing ? (
                  <input
                    id='free_sub'
                    name='free_sub'
                    type='checkbox'
                    defaultChecked={free_sub}
                    ref={register}
                    className='w-4 h-4 text-indigo-600 transition duration-150 ease-in-out form-checkbox'
                  />
                ) : (
                  <dd
                    className={`mt-1 text-sm leading-5 font-bold ${free_sub ? 'text-green-500' : 'text-red-600'
                      } `}
                  >
                    {free_sub ? 'YES' : 'NO'}
                  </dd>
                )}
              </div>

              <div className='sm:col-span-1'>
                <dt className='text-sm font-medium leading-5 text-gray-500'>
                  Standard Sub
                </dt>
                {isEditing ? (
                  <input
                    id='standard_sub'
                    name='standard_sub'
                    type='checkbox'
                    defaultChecked={standard_sub}
                    ref={register}
                    className='w-4 h-4 text-indigo-600 transition duration-150 ease-in-out form-checkbox'
                  />
                ) : (
                  <dd
                    className={`mt-1 text-sm leading-5 font-bold ${standard_sub ? 'text-green-500' : 'text-red-600'
                      } `}
                  >
                    {standard_sub ? 'YES' : 'NO'}
                  </dd>
                )}
              </div>

              <div className='sm:col-span-1'>
                <dt className='text-sm font-medium leading-5 text-gray-500'>
                  Premium Sub
                </dt>
                {isEditing ? (
                  <input
                    id='premium_sub'
                    name='premium_sub'
                    type='checkbox'
                    defaultChecked={premium_sub}
                    ref={register}
                    className='w-4 h-4 text-indigo-600 transition duration-150 ease-in-out form-checkbox'
                  />
                ) : (
                  <dd
                    className={`mt-1 text-sm leading-5 font-bold ${premium_sub ? 'text-green-500' : 'text-red-600'
                      } `}
                  >
                    {premium_sub ? 'YES' : 'NO'}
                  </dd>
                )}
              </div>

              <div className='sm:col-span-1'>
                <dt className='text-sm font-medium leading-5 text-gray-500'>
                  Instruments
                </dt>
                {isEditing ? (
                  <div>
                    <Controller
                      as={Select}
                      options={allInstruments?.map(ai => ({ label: ai.name, value: ai.id }))}
                      isMulti
                      maxMenuHeight={200}
                      key={instruments ? instruments : allInstruments} // changing the keys lets the defaultValue grab props on a rerender. hacky solution
                      id='instruments'
                      name='instruments'
                      control={control}
                      defaultValue={chunkInstruments?.map(i => ({ label: i.name, value: i.id }))}
                    />
                  </div>
                ) : (
                  <dd className='w-1/2 mt-1 text-sm leading-5 text-gray-900'>
                    {instruments?.map((instrument) => (
                      <p>{instrument}</p>
                    ))}
                  </dd>
                )}
              </div>

              <div className='sm:col-span-1'>
                <dt className='text-sm font-medium leading-5 text-gray-500'>
                  Playlists
                </dt>
                {isEditing ? (
                  <div>
                    <Controller
                      as={Select}
                      options={allSections?.map(ai => ({ label: ai.type, value: ai.id }))}
                      isMulti
                      maxMenuHeight={200}
                      key={sections ? sections : allSections} // changing the keys lets the defaultValue grab props on a rerender. hacky solution
                      id='sections'
                      name='sections'
                      control={control}
                      defaultValue={chunkSections?.map(i => ({ label: i.type, value: i.id }))}
                    />
                  </div>
                ) : (
                  <dd className='w-1/2 mt-1 text-sm leading-5 text-gray-900'>
                    {chunkSections?.map((section) => (
                      <p>{section.type}</p>
                    ))}
                  </dd>
                )}
              </div>

              <div className='sm:col-span-1'>
                <dt className='text-sm font-medium leading-5 text-gray-500'>
                  Collection Type
                </dt>
                {isEditing ? (
                  ['Exercises & Etudes', 'Show Music', 'Basics for Band', 'Jazz Pop Combo', 'Concert Music', 'Visual & Choreography', 'Learn About Music Chunks'].map((value) => (
                    <div key={value} className='flex items-center my-2'>
                      <input
                        id='collectionType'
                        name='collectionType'
                        value={value}
                        type='radio'
                        ref={register}
                        defaultChecked={value === collectionType}
                        className='w-4 h-4 text-indigo-600 transition duration-150 ease-in-out form-radio'
                      />
                      <label htmlFor='difficulty-medium' className='ml-3'>
                        <span className='block text-sm font-medium leading-5 text-gray-700'>
                          {value}
                        </span>
                      </label>
                    </div>
                  ))
                ) : (
                  <dd className='w-1/2 mt-1 text-sm leading-5 text-gray-900'>
                    {collectionType}
                  </dd>
                )}
              </div>
            </dl>
          </div>

          {isEditing && (
            <div className='flex justify-end pb-4 pr-12'>
              {' '}
              <button
                type='submit'
                className=' mr-4 inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs leading-4 font-medium rounded text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150'
              >
                Save
              </button>
              <button
                onClick={() => {
                  setIsEditing(!isEditing);
                  reset();
                }}
                type='reset'
                className='inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs leading-4 font-medium rounded text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150'
              >
                Cancel
              </button>
            </div>
          )}
        </form>

        {!isEditing && (
          <div className='flex justify-end pb-4 pr-12'>
            {url?.includes('ensembles') ? null : (
              <button
                type='button'
                onClick={() => {
                  setIsEditing(!isEditing);
                }}
                className=' mr-4 inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs leading-4 font-medium rounded text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150'
              >
                Edit
              </button>
            )}
            {url?.includes('ensembles') ? null : (
              <button
                onClick={() => {
                  setOpenWarning(!openWarning)
                }}
                type='reset'
                className='inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs leading-4 font-medium rounded text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150'
              >
                DELETE
              </button>
            )}

            <Transition show={openWarning}>
              <div class="fixed z-10 inset-0 overflow-y-auto">
                <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                  {/* <!--
                    Background overlay, show/hide based on modal state.
                    Entering: "ease-out duration-300"
                      From: "opacity-0"
                      To: "opacity-100"
                    Leaving: "ease-in duration-200"
                      From: "opacity-100"
                      To: "opacity-0"
                  --> */}
                  <div class="fixed inset-0 transition-opacity">
                    <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
                  </div>
                  {/* <!-- This element is to trick the browser into centering the modal contents. --> */}
                  <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;
                    {/* <!--
                      Modal panel, show/hide based on modal state.
                      Entering: "ease-out duration-300"
                        From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        To: "opacity-100 translate-y-0 sm:scale-100"
                      Leaving: "ease-in duration-200"
                        From: "opacity-100 translate-y-0 sm:scale-100"
                        To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    --> */}
                  <div class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                    <div class="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                      <button onClick={() => {
                        setOpenWarning(!openWarning)
                      }}
                        type="button" class="text-gray-400 hover:text-gray-500 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150" aria-label="Close">
                        {/* <!-- Heroicon name: x --> */}
                        <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                      </button>
                    </div>
                    <div class="sm:flex sm:items-start">
                      <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                        {/* <!-- Heroicon name: exclamation --> */}
                        <svg class="h-6 w-6 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                        </svg>
                      </div>
                      <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                        <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
                          Delete Chunk
                        </h3>
                        <div class="mt-2">
                          <p class="text-sm leading-5 text-gray-500">
                            Are you sure you want to delete this chunk? This chunk and all its data will
                            be removed from our servers forever. This will also delete any sound recordings attached.
                            This action cannot be undone.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                      <span class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
                        <button onClick={() => {
                          handleDelete(id);
                        }}
                          type="button"
                          class="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-red-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                        >
                          Delete
                        </button>
                      </span>
                      <span class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
                        <button onClick={() => {
                          setOpenWarning(!openWarning)
                        }} type="button" class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                          Cancel
                        </button>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </Transition>

          </div>
        )}
      </Transition>
    </div>
  );
};

export default ChunkData;