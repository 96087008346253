import React, { useState, useEffect } from 'react';

// AUTH
import Amplify from '@aws-amplify/core';
import { AmplifyAuthenticator } from '@aws-amplify/ui-react';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import { Auth } from '@aws-amplify/auth';
import awsmobile from './aws-exports.js';

// ROUTING
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import AdminRoute from './utils/AdminRoute';
import PaymentRoute from './utils/PaymentRoute';
import PrivateRoute from './utils/PrivateRoute';

// STYLING
import './tailwind.generated.css';
import './App.css';

// GOOGLE ANALYTICS
import { useGoogleAnalytics } from './utils/useGoogleAnalytics';

// COMPONENTS
import { Landing } from './components/landing/Landing';
import { MemoizedDashboard } from './components/dashboard/Dashboard';
import { FAQPage } from './components/landing/FAQPage';
import { FAQEnsemble } from './components/landing/FAQEnsemble';
import { TOS } from './components/legal/TOS';
import { TOSPublishers } from './components/legal/TOSPublishers';
import { Privacy } from './components/legal/Privacy';
import { About } from './components/landing/About';
import { Features } from './components/landing/Features';
import { Ensembles } from './components/landing/Ensembles';
import { Prices } from './components/landing/Prices';
import { Faq } from './components/landing/Faq';
import Help from './components/landing/Help';
import CustomSignIn from './pages/Onboarding/CustomSignIn';
import CustomSignUp from './pages/Onboarding/CustomSignUp';
import PaymentOnSignup from './pages/Onboarding/PaymentOnSignup';
import PaymentOnExpiration from './pages/Onboarding/PaymentOnExpiration';
import MyAccountPage from './components/account/MyAccountPage';
import ScrollToTop from './utils/ScrollToTop';

// ADMIN
import AdminHome from './components/admin/AdminHome';

Amplify.configure(awsmobile);
Auth.configure(awsmobile);

// ----------------------------------------------------------------------------------
// ----------------------------------- APP ------------------------------------------
// ----------------------------------------------------------------------------------

const App = () => {
  // State
  const [authState, setAuthState] = useState(); // AUTH STATE
  const [cogUser, setCogUser] = useState(); // AWS COGNITO USER STATE

  useGoogleAnalytics(localStorage.getItem(process.env.REACT_APP_USER_ID));

  // UseEffect that grabs state for auth and user
  useEffect(() => {
    return onAuthUIStateChange((nextAuthState, authData) => {
      setAuthState(nextAuthState);
      setCogUser(authData)
    })
  }, [])

  return (
    <>
      <ScrollToTop />
      <Switch>

        {/* PUBLIC ROUTES ANYONE CAN VISIT */}
        <Route exact path='/' component={Landing} />
        <Route path="/about" component={About} />
        <Route path="/features" component={Features} />
        <Route path="/ensembles" component={Ensembles} />
        <Route path="/prices" component={Prices} />
        <Route path="/faq-music-chunks" component={Faq} />
        <Route path='/faq' component={FAQPage} />
        <Route path='/faq-ensemble' component={FAQEnsemble} />
        <Route path='/terms-of-service' component={TOS} />
        <Route path='/terms-of-service-publishers' component={TOSPublishers} />
        <Route path='/privacy-policy' component={Privacy} />
        <Route path='/how-to' component={Help} />
        <Route path="/login" component={CustomSignIn} />
        <Route path="/signup" component={CustomSignUp} />

        {/* PRIVATE ROUTES REQUIRING AUTHENTICATION */}
        {authState === AuthState.SignedIn && cogUser ? (
          <>
            <PaymentRoute path="/dashboard" component={MemoizedDashboard} />
            <PrivateRoute path="/add-payment" component={PaymentOnSignup} />
            <PrivateRoute path="/no-subscription" component={PaymentOnExpiration} />
            <AdminRoute path="/admin" component={AdminHome} />
            <Route path={`/my-account`} component={MyAccountPage} />
          </>
        ) : (
          <AmplifyAuthenticator>
            <CustomSignIn slot="sign-in" />
            <CustomSignUp slot="sign-up" />
          </AmplifyAuthenticator>
        )}
        {/* Redirect all 404's to home */}
        <Redirect to='/' />
      </Switch>
    </>
  )
};

export default App;