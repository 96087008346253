import React, { useEffect, useState, useMemo } from 'react';
import axios from 'axios';
import axiosRetry from 'axios-retry';

// REACT TABLE
import { useTable } from 'react-table'
import { CSVLink } from 'react-csv'

// DATE
import moment from 'moment';
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

// COMPONENTS
import { LoadingPage } from '../../../LoadingPage';

// ----------------------------------------------------------------------------------
// -------------------------------- MC REVENUE REPORT  ------------------------------
// ----------------------------------------------------------------------------------

const MC_Revenue_Report = () => {
  // State
  const [clicks, setClicks] = useState([]);  // HOLDS AN OBJECT CONTAINING ALL CLICKS
  const [data, setData] = useState([]);  // HOLDS AN OBJECT CONTAINING ALL CLICKS WITH DYNAMIC DATA (MUST BE LABELED 'data')
  const [startDate, setStartDate] = useState(); // HOLDS THE START DATE FOR QUERYING CLICKS
  const [endDate, setEndDate] = useState(); // HOLDS THE END DATE FOR QUERYING CLICKS
  const [isQuarterlyReport, setIsQuarterlyReport] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const url = window.location.href; // GRABS REFERENCE TO THE CURRENT URL TO CHECK WHICH PLAN DATA TO LOAD
  const [fileName, setFileName] = useState(`MC_REV_MusicChunks_v1.0_all_${moment(startDate).format("YYYY-MM-DD")}--${moment(endDate).format("YYYY-MM-DD")}_${Date.now()}.csv`) // HOLDS THE FILE NAME DEPENDING ON WHICH USAGE FORM IS BEING RENDERED

  // Set axios to retry a set number of times on failed request/return
  axiosRetry(axios, { retries: 3 })

  // UseEffect to grab either yearly or quarterly state on initial load depending on URL match
  useEffect(() => {
    if (url.includes('quarterly')) {
      var first = new Date(new Date().getFullYear(), Math.floor((new Date().getMonth() / 3)) * 3, 1)
      var last = new Date(first.getFullYear(), first.getMonth() + 3, 0)

      setIsQuarterlyReport(true);
      setStartDate(first)
      setEndDate(last)
    } else if (url.includes('annual')) {
      setIsQuarterlyReport(false);
      setStartDate(new Date(new Date().getFullYear(), 0, 1))
      setEndDate(new Date(new Date().getFullYear(), 12, 0))
    }
  }, [url])

  // UseEffect to grab every plan's public chunk clicks total
  useEffect(() => {
    setIsLoading(true);

    var currentMonthFirst = new Date(new Date().getFullYear(), Math.floor((new Date().getMonth() / 3)) * 3, 1)
    var currentMonthLast = new Date(currentMonthFirst.getFullYear(), currentMonthFirst.getMonth() + 3, 0)
    var currentMonth = false

    // Pull realtime data if the current monthly period is selected. Otherwise, pull archived data from clickRecords table
    if (moment(currentMonthFirst).format("MM-DD-YYYY") === moment(startDate).format("MM-DD-YYYY") && moment(currentMonthLast).format("MM-DD-YYYY") === moment(endDate).format("MM-DD-YYYY")) {
      currentMonth = true
    } else {
      currentMonth = false
    }

    if (startDate !== undefined || endDate !== undefined) {
      axios({
        method: 'get',
        url: currentMonth ?
          process.env.REACT_APP_API + `clicks/all/public/current/${moment(startDate).format("YYYY-MM-DD")}/${moment(endDate).format("YYYY-MM-DD")}` :
          process.env.REACT_APP_API + `clicks/all/record/${moment(startDate).format("YYYY-MM-DD")}/${moment(endDate).format("YYYY-MM-DD")}`,
        headers: {
          Accept: 'application/json',
          Authorization: process.env.REACT_APP_HEROKU_SERVER_KEY,
        }
      })
        .then((res) => {
          setIsLoading(false);
          setClicks(res.data)
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err)
        })
    }
  }, [startDate, endDate])

  // UseEffect to add dynamic key value pairs to sound recordings
  useEffect(() => {
    setData(newData(clicks))
  }, [clicks])

  // Function to add dynamic key value pairs to sound recordings
  const newData = (oldData) => {
    return oldData.map((oldRecordings) => ({
      ...oldRecordings,
      Gross_Revenue: 0 // FOR NOW IT'S ALWAYS ZERO. WILL CHANGE IN THE FUTURE
    }))
  }

  // Column headers and accessors linking to keys in data
  const columns = useMemo(
    () => [
      {
        Header: 'Usage Start Date',
        accessor: d => d.Usage_Start_Date
      },
      {
        Header: 'Usage End Date',
        accessor: d => d.Usage_End_Date
      },
      {
        Header: 'Storefront',
        accessor: d => d.Storefront
      },
      {
        Header: 'Service Configuration',
        accessor: d => d.Service_Configuration
      },
      {
        Header: 'Number of Active Users this Period',
        accessor: d => d.end_of_year_member_count
      },
      {
        Header: 'Gross Revenue',
        accessor: d => d.Gross_Revenue
      },
      {
        Header: 'Original Total Number of Usages',
        accessor: d => d.total_public_clicks
      }
    ],
    [isQuarterlyReport]
  )

  // Table structure
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable({ columns, data })

  // Converts our db data to the correct format for form submission
  const currentData = useTable({ columns, data })
  const reportData = []
  currentData.rows.map(row => {
    reportData.push(row.values)
  })

  return (
    <>
      {isQuarterlyReport ? (
        <div className='mb-3 mt-3 text-center'>
          <dt className='text-sm text-center font-medium leading-5 text-gray-500'>
            Usage Quarter
          </dt>
          <DatePicker
            className='block w-full mt-2 transition duration-150 ease-in-out form-textarea sm:text-sm sm:leading-5'
            selected={startDate}
            onChange={date => {
              setStartDate(date)
              setEndDate(new Date(date.getFullYear(), date.getMonth() + 3, 0))
            }}
            dateFormat='yyyy, QQQ'
            showQuarterYearPicker
          />
        </div>
      ) : (
        <div className='mb-3 mt-3 text-center'>
          <dt className='text-sm text-center font-medium leading-5 text-gray-500'>
            Usage Annual
            </dt>
          <DatePicker
            className='block w-full mt-2 transition duration-150 ease-in-out form-textarea sm:text-sm sm:leading-5'
            selected={startDate}
            onChange={date => {
              setStartDate(date)
              setEndDate(new Date(date.getFullYear(), 12, 0))
            }}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            dateFormat='yyyy'
            showYearPicker
          />
        </div>
      )}

      <div className='mb-3 mt-3 text-center'>
        <p>{moment(startDate).format('MM-DD-YYYY')} - {moment(endDate).format('MM-DD-YYYY')}</p>
      </div>

      <div className='mb-3 text-center'>
        <CSVLink
          className='inline-flex items-center px-4 py-2 text-sm font-medium leading-5 text-white transition duration-150 ease-in-out bg-purple-600 border border-transparent rounded-md hover:bg-purple-500 focus:outline-none focus:shadow-outline-purple focus:border-purple-700 active:bg-indigo-700'
          columns={columns}
          data={reportData}
          filename={fileName}
        >
          Export Report
      </CSVLink>
      </div>

      {isLoading ? (
        <LoadingPage />
      ) : (
        <div>
          {/* REACT TABLE */}
          <table {...getTableProps()} style={{ border: 'solid 1px blue' }}>
            <thead>
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <th
                      {...column.getHeaderProps()}
                      style={{
                        borderBottom: 'solid 3px red',
                        background: 'aliceblue',
                        color: 'black',
                        fontWeight: 'bold',
                      }}
                    >
                      {column.render('Header')}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map(row => {
                prepareRow(row)
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map(cell => {
                      return (
                        <td
                          {...cell.getCellProps()}
                          style={{
                            padding: '10px',
                            border: 'solid 1px gray',
                            background: 'papayawhip',
                          }}
                        >
                          {cell.render('Cell')}
                        </td>
                      )
                    })}
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      )}
    </>
  )
}

export default MC_Revenue_Report;