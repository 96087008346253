import React, { useEffect } from 'react';

// ROUTING
import { Link, useLocation } from 'react-router-dom';

// STATE
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  publisherFilterState,
  searchedPublishers,
  searchBarTerm
} from '../../state/atoms';

// IMAGES
import PublishersBanner from '../../img/collections/mc_publishers_banner.png'

// COMPONENTS
import { MemoizedPublisherCard } from './PublisherCard';

// ----------------------------------------------------------------------------------
// ------------------------------- PUBLISHER LIST -----------------------------------
// ----------------------------------------------------------------------------------

const PublisherList = ({ match, location, handleClearSearchBar, cognitoUser }) => {
  // STATE
  const publishers = useRecoilValue(searchedPublishers);
  const searchTerm = useRecoilValue(searchBarTerm);
  const publisherFilter = useSetRecoilState(publisherFilterState);
  const { pathname } = useLocation();

  // Function for the search bar to help filtering publishers
  useEffect(() => {
    publisherFilter(location.search.split('=')[1]);
  }, [publisherFilter, location.search]);

  // Function for autoscrolling to top of page (DOES NOT WORK)
  useEffect(() => {
    window.scrollTo(0, 0);
    // window.location(0, 0)
  }, [pathname]);

  return (
    <>
      {/* ABOUT PUBLISHERS */}
      <img
        src={PublishersBanner}
        alt='img for a collection type'
        className='inline-block object-cover w-full h-full rounded-t-md'
      />
      <div className='bg-green-700 px-5 py-3 mb-2 rounded-b-md'>
        <h1 className='text-white text-lg mb-5 font-bold'>
          List of Publishers
        </h1>
        <p className='whitespace-pre-wrap text-white mb-5'>
          Music Chunks’ Composers, Publishers, Educators, and Creators.
          Discover and Learn their music with audio and video examples using powerful
          practice tools such as metronome, looping with count-in, and playback speed control.
        </p>
      </div>

      {/* LIST OF PUBLISHERS */}
      {publishers?.length === 0 && searchTerm !== '' ? (
        <div className='flex flex-col items-center justify-center p-16 '>
          <p className='text-lg font-bold leading-6 text-emptySearchResults'>
            Couldn't find
          </p>
          <p className='text-lg font-bold text-emptySearchResults'>"{searchTerm}"</p>
          <p className='text-sm text-center text-white'>
            Try searching again using a different spelling or keyword.
          </p>
        </div>
      ) : (
        <div>
          {publishers?.map((i) =>
            <Link
              to={`${match.url}/${i.id}`}
              onClick={() => {
                handleClearSearchBar();
              }}
            >
              <MemoizedPublisherCard
                data={i}
                cognitoUser={cognitoUser}
                handleClearSearchBar={handleClearSearchBar}
                match={match}
              />
            </Link>
          )}
        </div>
      )}
    </>
  );
};

export default PublisherList;