import React from 'react';

// STATE
import { useRecoilValue } from 'recoil';
import { cogUserState } from '../state/atoms';

// ROUTING
import { Redirect, Route } from 'react-router-dom';

// ----------------------------------------------------------------------------------
// ------------------------------ ENSEMBLE USER ROUTE -------------------------------
// ----------------------------------------------------------------------------------

const EnsembleUserRoute = ({ component: Component, ...rest }) => {
  const cognitoUser = useRecoilValue(
    cogUserState(
      localStorage.getItem(
        process.env.REACT_APP_USER_ID,
      ),
    ),
  ); // Get user from our DB

  return (
    <Route
      {...rest}
      render={rest => {
        var isUser = false
        var isAdmin = false

        // Locks out users and admins who do not belong to groups (redirects if they try to force type in url)
        cognitoUser.data.groups.map(userGroup => {
          if (userGroup.id === rest.match?.params?.groupID && userGroup.isAdmin === false) {
            isUser = true;
            isAdmin = false;
          } else if (userGroup.id === rest.match?.params?.groupID && userGroup.isAdmin === true) {
            isUser = true;
            isAdmin = true;
          }
        })

        if (isUser) {
          return <Component {...rest} cognitoUser={cognitoUser} isAdmin={isAdmin} />;
        } else {
          return <Redirect to="/dashboard" />;
        }
      }}
    />
  );
};

export default EnsembleUserRoute;
