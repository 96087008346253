import React from 'react';

// AUTH
import { Auth } from '@aws-amplify/auth';

// ROUTING
import { Redirect, Route } from 'react-router-dom';

// ----------------------------------------------------------------------------------
// -------------------------------- ADMIN ROUTE -------------------------------------
// ----------------------------------------------------------------------------------

const AdminRoute = ({ component: Component, location, ...rest }) => {
  // Grab the user's roles from the accessToken to see if they have admin privelages
  var roles = Auth.user.signInUserSession.accessToken.payload['cognito:groups']

  return (
    <Route
      {...rest}
      render={rest => {
        if (roles && roles.includes('Admin')) {
          return <Component {...rest} />;
        } else {
          return <Redirect to="/dashboard" />;
        }
      }}
    />
  );
};

export default AdminRoute;
