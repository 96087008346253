import React from 'react';

// Router
import { Link } from 'react-router-dom';

// COMPONENTS
import NavBar from './NavBar';
import { Footer } from './Footer';

// ICONS
import { ReactComponent as ScrollDownIcon } from '../../img/icon/ScrollDownIcon.svg';
import { ReactComponent as Heroimg } from '../../img/MCLOGONEW.svg';

//IMAGES
import DynamicPlayer from '../../img/DynamicPlayer.png';
import ContentCreators from '../../img/ContentCreators.png';
import DiverseContent from '../../img/DiverseContent.png';
import PrivateEnsemblePage from '../../img/PrivateEnsemblePage.png';

// ----------------------------------------------------------------------------------
// ----------------------------------- LANDING --------------------------------------
// ----------------------------------------------------------------------------------

export const Landing = () => {
  return (
    <>
    <NavBar />
    {/* SPLASH IMG */}
    <div className='relative bg-no-repeat bg-hero-pattern bg-center bg-cover overflow-hidden'>        
      <main>
        {/* MAIN HERO */}
        <div className='lg:flex lg:h-screen items-center mb-14 lg:mb-0 overflow-hidden'>
          <div className='mt-10 mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 xl:mt-28'>
            <div className=' text-center'>
              <Heroimg className='w-auto' />
              <p className='mt-3 max-w-md mx-auto text-base text-gray-100 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl'>
                Music Chunks is an online music practice platform. Students and teachers can access
                fundamental exercises and skill-builders from top content creators, as well as “chunks”
                of published music literature from current composers. The platform goes beyond sheet
                music, enhancing the experience with audio and video playback.
              </p>
              <div className=' mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8'>
                <div className='rounded-md shadow'>
                  <a
                    href='#images'
                    className='w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-mcdarkgreen bg-mcgreen hover:bg-mcbuttondarkgreen hover:text-mcgreen focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10'
                  >
                    <p className='mr-2'>
                      Learn More
                    </p>
                    <ScrollDownIcon className='w-6 h-6' />
                  </a>
                </div>
                <div className='mt-3 rounded-md shadow sm:mt-0 sm:ml-3'>
                  <Link
                    to='/signup'
                    className='w-full flex items-center justify-center px-12 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-mcgreen bg-mcbuttondarkgreen hover:text-mcbuttondarkgreen hover:bg-mcgreen focus:outline-none transition duration-150 ease-in-out md:py-4 md:text-lg md:px-13'
                  >
                    Get Started
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* IMAGE SECTION */}
        <div id='images' className='lg:flex justify-between px-14 py-28 bg-gray-50'>
          <div className='text-center mb-10 lg:mb-0'>
            <p className='text-2xl font-bold'>Dynamic Player</p>
            <img
              src={DynamicPlayer}
              className='w-auto lg:w-52 xl:w-64 mt-4'
              alt='dynamic player'
            />
          </div>
          <div className='text-center mb-10 lg:mb-0'>
            <p className='text-2xl font-bold'>Content Creators</p>
            <img
              src={ContentCreators}
              className='w-auto lg:w-52 xl:w-64 mt-4'
              alt='content creators'
            />
          </div>
          <div className='text-center mb-10 lg:mb-0'>
            <p className='text-2xl font-bold'>Diverse Content</p>
            <img
              src={DiverseContent}
              className='w-auto lg:w-52 xl:w-64 mt-4'
              alt='diverse content'
            />
          </div>
          <div className='text-center'>
            <p className='text-2xl font-bold'>Private Ensembles</p>
            <img
              src={PrivateEnsemblePage}
              className='w-auto lg:w-52 xl:w-64 mt-4'
              alt='private ensemble page'
            />
          </div>
        </div>
      </main>
    </div>
    <Footer />
  </>
  );
};