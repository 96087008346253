import React, { useEffect, useState } from 'react';
import axios from 'axios';
import axiosRetry from 'axios-retry';

// ROUTING
import { Link, useHistory } from 'react-router-dom';

// ICONS
import { ReactComponent as HowToIcon } from '../../img/icon/HowTo.svg';

// TOAST
import cogoToast from 'cogo-toast';

// ----------------------------------------------------------------------------------
// --------------------------------- SINGLE CHUNK -----------------------------------
// ----------------------------------------------------------------------------------

export const SingleChunk = ({ match, cognitoUser }) => {
  // State
  const history = useHistory();
  const [chunk, setChunk] = useState({});  // CHUNK STATE
  const [currentSoundsliceRecordingID, setCurrentSoundsliceRecordingID] = useState('');  // CURRENT SOUND RECORDING ID
  const [myChunks, setMyChunks] = useState([]);  // SAVED CHUNK STATE
  const [on, setOn] = useState(false);  // TOGGLE STATE
  const [isCounting, setIsCounting] = useState(false);  // TOGGLE STATE FOR COUNTER
  const [allowClick, setAllowClick] = useState(true);  // TOGGLE STATE FOR WHETHER A CLICK HAPPENED (ONLY ONE PER RENDER/REFRESH)
  const [userCountryCode, setUserCountryCode] = useState(); // SAVES COUNTRY CODE OF USER TO SEE IF THEY LIVE IN THE UNITED STATES
  const [seconds, setSeconds] = useState(0); // COUNTER FOR CALCULATING WHEN TO REGISTER A CLICK FOR ANALYTICS
  const [currentSoundRecordingIndex, setCurrentSoundRecordingIndex] = useState(); // HOLDS THE INDEX OF THE CURRENT RECORDING
  const [timerArray, setTimerArray] = useState([]); // HOLDS TIMER VALUES FOR EACH SONG RECORDING
  const [changingRecording, setChangingRecording] = useState(false); // BOOL THAT LOGS WHEN A RECORDING SWAP OCCURS
  const [isPlaying, setIsPlaying] = useState(false); // BOOL THAT LOGS WHEN A RECORDING SWAP OCCURS
  const [noRecordings, setNoRecordings] = useState(false); // BOOL THAT CHECKS IF A CHUNK HAS RECORDINGS TO DETERMINE HOW TO REGISTER CLICKS

  // Set axios to retry a set number of times on failed request/return
  axiosRetry(axios, { retries: 3 })

  // UseEffect that grabs the name of the country the user is browsing in
  useEffect(() => {
    axios.get('https://ipapi.co/json/').then(res => {
      setUserCountryCode(res.data.country_code)
    }).catch((error) => {
      console.log(error);
    });
  }, [])

  // Event listeners on soundslice to grab clicks for analytics
  useEffect(() => {
    window.addEventListener('message', function (event) {
      var cmd = ''
      if (event.origin === 'https://www.soundslice.com') {
        cmd = JSON.parse(event.data);
      }

      switch (cmd.method) {
        case 'ssPlayerReady':
          // console.log('Player is ready.');
          break;
        case 'ssPlay':
          // console.log('Play has started.');
          setIsCounting(true)
          setIsPlaying(true)
          break;
        case 'ssPause':
          // console.log('Play has stopped.');
          setIsCounting(false)
          setIsPlaying(false)
          break;
        case 'ssAudioSourceChanged':
          // console.log('Recording changed to ' + cmd.arg);
          if (chunk?.sound_recordings) {
            setCurrentSoundsliceRecordingID(chunk?.sound_recordings[cmd.arg]?.sound_recording_id)
            setCurrentSoundRecordingIndex(cmd.arg)
            if (isPlaying) {
              setChangingRecording(true)
            } else if (!isPlaying) {
              setIsCounting(false)
            }
          }
          break;
      }
    });
  }, [chunk, isPlaying])

  // Useffect to grab the current chunk
  useEffect(() => {
    axios({
      method: 'get',
      url: process.env.REACT_APP_API + `chunks/${match.params.id}`,
      headers: {
        Accept: 'application/json',
        Authorization: process.env.REACT_APP_HEROKU_SERVER_KEY,
      }
    })
      .then((res) => {
        var canAccessChunk = false

        // Map through a user's groups to see if they have access to the group's chunk
        cognitoUser.data.groups.map(userGroup => {
          if (userGroup.id === match?.params?.groupId) {
            canAccessChunk = true;
          }
        })

        // Only render page if chunk is marked as public or the user belongs to a group hosting the private chunk
        if (res.data.public || canAccessChunk) {
          let countArray = []
          if (res.data.sound_recordings.length > 0) {
            setNoRecordings(false)
          } else {
            setNoRecordings(true)
          }

          res.data.sound_recordings.map(recording => {
            countArray.push(0)
          })
          setChunk(res.data)
          setTimerArray(countArray)
        } else {
          // The chunk is private and the user is blocked from viewing
          history.push('/dashboard')
        }
      })
      .catch((err) => {
        console.log(err);
        cogoToast.error('Something went wrong getting the chunk data. Please refresh the page', {
          hideAfter: 5,
        });
      });
  }, [])

  // UseEffect to to trigger counter and register a click on slice without accounting for recording ID after a set period of time
  useEffect(() => {
    if (noRecordings) {

      if (seconds > 30) {
        setIsCounting(false)
      }
      // If counting is true (playback of video) and the user is in the United States, start the timer
      let intervalID;
      if (isCounting && userCountryCode === 'US') {
        intervalID = setInterval(() => {
          setSeconds(seconds => seconds + 1);
          // console.log(seconds); // TURN ON TO TEST/SEE THE COUNT FOR EACH RECORDING
        }, 1000);
      } else {
        clearInterval(intervalID);
      }

      // Only post to clicks table if 30 seconds pass and it is the first click after a page refresh
      if (seconds === 30 && allowClick) {
        // console.log('CLICKITY CLICK') // TURN ON TO TEST/SEE WHEN A CLICK GETS RECORDED
        axios({
          method: 'post',
          url: process.env.REACT_APP_API + 'clicks',
          headers: {
            Accept: 'application/json',
            Authorization: process.env.REACT_APP_HEROKU_SERVER_KEY,
          },
          data: {
            user_id: cognitoUser.data.id,
            chunk_id: match.params.id,
            plan: cognitoUser.data.role,
            public: chunk?.public === true ? true : false
          }
        })
          .then(res => {
            setAllowClick(false);
          })
          .catch((err) => {
            console.log(err);
          });
      }
      return () => clearInterval(intervalID);
    }
  }, [isCounting, noRecordings, seconds]);

  // UseEffect to to trigger counter and register a click on slice accounting for Recording IDs after a set period of time
  useEffect(() => {
    if (!noRecordings) {

      let countArray = timerArray
      let secs = countArray[currentSoundRecordingIndex]

      // Makes sure counter keeps running when swapping recording while player is running
      if (changingRecording) {
        setIsCounting(true);
        setChangingRecording(false)
      }

      // Determines when to start counting and prevents double click posts to db from occurring if the counter is done
      if (secs >= 30) {
        setIsCounting(false)
        setAllowClick(false)
      } else {
        setAllowClick(true)
      }

      // If counting is true (playback of video), the sound recording ID is not null, and the user is in the United States, start the timer
      let intervalID;
      if (isCounting && currentSoundsliceRecordingID && userCountryCode === 'US') {
        intervalID = setInterval(() => {
          secs += 1;
          countArray[currentSoundRecordingIndex] = secs
          setTimerArray(countArray)
          setSeconds(secs)
          setAllowClick(true)
          // console.log(countArray) // TURN ON TO TEST/SEE THE COUNT FOR EACH RECORDING
        }, 1000);
      } else {
        clearInterval(intervalID);
      }

      // Only post to clicks table if 30 seconds pass, a chunk is linked to at least one sound recording, and it is the first click after a page refresh
      if (secs === 30 && allowClick && currentSoundsliceRecordingID) {
        // console.log('CLICKITY CLICK') // TURN ON TO TEST/SEE WHEN A CLICK GETS RECORDED
        axios({
          method: 'post',
          url: process.env.REACT_APP_API + 'clicks',
          headers: {
            Accept: 'application/json',
            Authorization: process.env.REACT_APP_HEROKU_SERVER_KEY,
          },
          data: {
            user_id: cognitoUser.data.id,
            sound_recording_id: currentSoundsliceRecordingID,
            chunk_id: match.params.id,
            plan: cognitoUser.data.role,
            public: chunk?.public === true ? true : false
          }
        })
          .then(res => {
            setAllowClick(false);
            // Setting secs to 100 prevents clicks from logging between recording swaps
            countArray[currentSoundRecordingIndex] = 100
            setTimerArray(countArray)
          })
          .catch((err) => {
            console.log(err);
          });
      }
      return () => clearInterval(intervalID);
    }
  }, [isCounting, noRecordings, changingRecording, seconds, timerArray, currentSoundRecordingIndex]);

  // Useffect to grab all a users saved chunks
  useEffect(() => {
    axios({
      method: 'get',
      url: process.env.REACT_APP_API + `users/${cognitoUser.data.id}/savedchunks`,
      headers: {
        Accept: 'application/json',
        Authorization: process.env.REACT_APP_HEROKU_SERVER_KEY,
      }
    })
      .then((res) => {
        setMyChunks(res.data)
      })
  }, [cognitoUser.data.id])

  // Function to add to user saved chunks
  const addToUserSaved = (chunkID) => {
    axios({
      method: 'post',
      url: process.env.REACT_APP_API + `users/${cognitoUser.data.id}/savechunk`,
      headers: {
        Accept: 'application/json',
        Authorization: process.env.REACT_APP_HEROKU_SERVER_KEY,
      },
      data: {
        chunk_id: chunkID
      }
    })
      .then(res => {
        cogoToast.success('Chunk has been saved!', {
          hideAfter: 3,
        });
      })
      .catch(err => {
        cogoToast.error('Sorry, Cant save this chunk. Try again later.', {
          hideAfter: 3,
        });
      })
  }

  // Function to remove a saved chunk from the user
  const removeFromUserSaved = (chunkID) => {
    axios({
      method: 'delete',
      url: process.env.REACT_APP_API + `users/${cognitoUser.data.id}/savechunk`,
      headers: {
        Accept: 'application/json',
        Authorization: process.env.REACT_APP_HEROKU_SERVER_KEY,
      },
      data: {
        chunk_id: chunkID
      }
    })
      .then(res => {
        cogoToast.success('Chunk has been unsaved!', {
          hideAfter: 3,
        });
      })
      .catch(err => {
        cogoToast.error('Sorry, Cant remove this chunk. Try again later.', {
          hideAfter: 3,
        });
      })
  }

  // Keeps toggle turned on with refresh if chunk is saved 
  useEffect(() => {
    if (!myChunks) {
      return;
    } else if (myChunks.some((i) => i.id === chunk?.id)) {
      setOn(true);
    }
  }, [chunk?.id, myChunks]);

  return (
    <div id='container' className='flex w-full max-w-screen'>
      <div className='w-screen'>
        {/* Video */}
        {/* Only render iFrame if chunk data exists (prevents glitch on hitting back button eating the page) */}
        {chunk?.embed_URL ? (
          <div>
            {/* Only let the soundslice chunk be downloadable if marked as downloadable and user is on standard account */}
            {chunk?.isDownloadable && cognitoUser.data.role.includes('standard') ? ( // If on standard plan and chunk is marked as downloadable, enable printing
              <iframe
                id='ssembed'
                src={
                  chunk?.embed_URL +
                  `?u=${cognitoUser.data.cognito_id}&force_top_video=1&branding=2&show_title=0&zoom=-11&api=1`
                }
                title='single chunk view'
                className='rounded-md'
                height='600'
                width='100%'
                frameBorder='0'
                allowFullScreen
              ></iframe>
            ) : (
              <iframe
                id='ssembed'
                src={
                  chunk?.embed_URL +
                  `?u=${cognitoUser.data.cognito_id}&force_top_video=1&branding=2&show_title=0&enable_print=0&zoom=-11&api=1`
                }
                title='single chunk view'
                className='rounded-md'
                height='600'
                width='100%'
                frameBorder='0'
                allowFullScreen
              ></iframe>
            )}
          </div>
        ) : null}

        {/* INFO CONTAINER */}
        <div className='pt-5'>
          {/* Title container */}
          <div className='text-center sm:text-left mb-2'>
            <h1 className='text-xl text-mcgreen'> {chunk?.name} </h1>
          </div>

          {/* SAVE  */}
          <div className='flex items-center justify-center sm:justify-end'>
            <p className='text-white'> Save: </p>
            {/* <!-- On: "bg-mcgreen", Off: "bg-gray-200" --> */}
            <span
              onClick={() => {
                setOn(!on);
                if (on) {
                  removeFromUserSaved(chunk.id);
                }
                if (!on) {
                  addToUserSaved(chunk.id);
                }
              }}
              role='checkbox'
              tabIndex='0'
              aria-checked='false'
              className={`${on ? 'bg-mcgreen' : 'bg-gray-300'
                } relative mx-3 inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:shadow-outline`}
            >
              {/* <!-- On: "translate-x-5", Off: "translate-x-0" --> */}
              <span
                aria-hidden='true'
                className={`${on ? 'translate-x-5' : 'translate-x-0'
                  } translate-x-0 relative inline-block h-5 w-5 rounded-full bg-white shadow transform transition ease-in-out duration-200`}
              >
                {/* <!-- On: "opacity-0 ease-out duration-100", Off: "opacity-100 ease-in duration-200" --> */}
                <span
                  className={`${on
                    ? 'opacity-0 ease-out duration-100'
                    : 'opacity-100 ease-in duration-200'
                    } opacity-100 ease-in duration-200 absolute inset-0 h-full w-full flex items-center justify-center transition-opacity`}
                ></span>
                {/* <!-- On: "opacity-100 ease-in duration-200", Off: "opacity-0 ease-out duration-100" --> */}
                <span
                  className={`${on
                    ? 'opacity-100 ease-in duration-200'
                    : 'opacity-0 ease-out duration-100'
                    } opacity-0 ease-out duration-100 absolute inset-0 h-full w-full flex items-center justify-center transition-opacity`}
                >
                  <svg
                    className='w-3 h-3 text-indigo-600'
                    fill='currentColor'
                    viewBox='0 0 12 12'
                  >
                    <path d='M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z' />
                  </svg>
                </span>
              </span>
            </span>
            {chunk?.purchase_URL && (
              <a
                href={`${chunk?.purchase_URL}`}
                target='_blank'
                rel='noopener noreferrer'
                className='inline-flex items-center px-4 py-2 text-xs font-medium leading-4 text-white transition duration-150 ease-in-out bg-mcbuttondarkgreen border border-transparent rounded-full hover:bg-mcbuttonlightgreen focus:outline-none'
              >
                Purchase
              </a>)}
            <a
              href='https://www.soundslice.com/help/en/player/basic/99/overview/'
              target='_blank'
              rel='noopener noreferrer'
            >
              <HowToIcon
                className='ml-3 h-7 w-7'
                color='#AFECEF'
                title='How To Use Soundslice'
              />
            </a>
          </div>

          {/* PUBLISHER CONTAINER */}
          <div className='flex'>
            <div className='flex-shrink-0 block focus:outline-none'>
              <div className='flex items-center'>
                <div>
                  <img
                    className='inline-block object-cover rounded-md h-14 w-14'
                    src={chunk?.publisher_profile_pic}
                    alt=''
                  />
                </div>
                <Link
                  to={`/dashboard/publishers/${chunk?.publisher_id}`}
                  className='ml-6 group'
                >
                  <p className='text-sm font-medium leading-5 text-white group-hover:text-mcgreen'>
                    {chunk?.publisher_name}
                  </p>
                  <p className='text-xs font-medium leading-4 transition duration-150 ease-in-out text-white group-hover:text-gray-500 group-focus:underline'>
                    More by this publisher
                  </p>
                </Link>

                {chunk?.additional_notes_URL ? (
                  <div className='ml-5'>
                    <a
                      className='inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-mcbuttondarkgreen hover:bg-mcbuttonlightgreen focus:outline-none focus:border-mcdarkgreen focus:shadow-outline-teal active:bg-mcdarkgreen transition ease-in-out duration-150'
                      href={chunk?.additional_notes_URL} target='_blank'
                    >
                      Additional Content for Download
                    </a>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <div className='sm:w-1/2 ml-20'>
            <p className='whitespace-pre-wrap text-white'>{chunk?.description}</p>
          </div>
        </div>
      </div>
    </div >
  );
};