import React, { useEffect, useState, useMemo } from 'react';
import axios from 'axios';
import axiosRetry from 'axios-retry';

// REACT TABLE
import { useTable } from 'react-table'
import { CSVLink } from 'react-csv'

// DATE
import moment from 'moment';
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

// UUID
import { v4 as uuidv4 } from 'uuid';

// COMPONENTS
import { LoadingPage } from '../../../LoadingPage';

// ----------------------------------------------------------------------------------
// -------------------------------- MLC USAGE REPORT  -------------------------------
// ----------------------------------------------------------------------------------

const MLC_Usage_Report = () => {
  // State
  const [soundRecordings, setSoundRecordings] = useState([]);  // HOLDS A LIST OF SOUND RECORDINGS
  const [data, setData] = useState([]);  // HOLDS A LIST OF SOUND RECORDINGS WITH DYNAMIC DATA (MUST BE LABELED 'data')
  const [startDate, setStartDate] = useState(); // HOLDS THE START DATE FOR QUERYING CLICKS
  const [endDate, setEndDate] = useState(); // HOLDS THE END DATE FOR QUERYING CLICKS
  const [isMonthlyReport, setIsMonthlyReport] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const url = window.location.href; // GRABS REFERENCE TO THE CURRENT URL TO CHECK WHICH PLAN DATA TO LOAD
  const [fileName, setFileName] = useState(`MLC_USG_MusicChunks_v1.0_all_${moment(startDate).format("YYYY-MM-DD")}--${moment(endDate).format("YYYY-MM-DD")}_${Date.now()}.csv`) // HOLDS THE FILE NAME DEPENDING ON WHICH USAGE FORM IS BEING RENDERED
  const [fileName2, setFileName2] = useState(`MLC_RCO_MusicChunks_v1.0_all_${moment(startDate).format("YYYY-MM-DD")}--${moment(endDate).format("YYYY-MM-DD")}_${Date.now()}.csv`) // HOLDS THE FILE NAME DEPENDING ON WHICH USAGE FORM IS BEING RENDERED

  // Set axios to retry a set number of times on failed request/return
  axiosRetry(axios, { retries: 3 })

  // UseEffect to grab either yearly or monthly state on initial load depending on URL match
  useEffect(() => {
    if (url.includes('monthly')) {
      setIsMonthlyReport(true);
      setStartDate(new Date(new Date().getFullYear(), new Date().getMonth(), 1))
      setEndDate(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0))
    } else if (url.includes('annual')) {
      setIsMonthlyReport(false);
      setStartDate(new Date(new Date().getFullYear(), 0, 1))
      setEndDate(new Date(new Date().getFullYear(), 12, 0))
    }
  }, [url])

  // UseEffect to grab all soundrecordings with clicks
  useEffect(() => {
    setIsLoading(true);

    var currentMonthFirst = new Date(new Date().getFullYear(), Math.floor((new Date().getMonth() / 3)) * 3, 1)
    var currentMonth = false

    // Pull realtime data if the current monthly period is selected. Otherwise, pull archived data from clickRecords table
    if (moment(currentMonthFirst).format("MM-DD-YYYY") === moment(startDate).format("MM-DD-YYYY")) {
      currentMonth = true
    } else {
      currentMonth = false
    }

    if (startDate !== undefined || endDate !== undefined) {
      axios({
        method: 'get',
        url: currentMonth ?
          process.env.REACT_APP_API + `soundRecordings/withclicks/current/${moment(startDate).format("MM-DD-YYYY")}/${moment(endDate).format("MM-DD-YYYY")}` :
          process.env.REACT_APP_API + `soundRecordings/withclicks/${moment(startDate).format("MM-DD-YYYY")}/${moment(endDate).format("MM-DD-YYYY")}`,
        headers: {
          Accept: 'application/json',
          Authorization: process.env.REACT_APP_HEROKU_SERVER_KEY,
        }
      })
        .then((res) => {
          // Map through our response to create a single array of objects
          var singleObjectArray = []
          res.data.map(tripleChunk => {
            tripleChunk.map(allChunks => {
              singleObjectArray.push(allChunks)
            })
          })
          setSoundRecordings(singleObjectArray)
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err)
        })
    }
  }, [startDate, endDate])

  // UseEffect to add dynamic key value pairs to sound recordings
  useEffect(() => {
    setData(newData(soundRecordings))
  }, [soundRecordings])

  // Function to add dynamic key value pairs to sound recordings
  const newData = (oldData) => {
    return oldData.map((oldRecordings) => ({
      ...oldRecordings,
      Usage_Identifier: uuidv4()
    }))
  }

  // Column headers and accessors linking to keys in data
  const columns = useMemo(
    () => [
      {
        Header: 'DSP Identifier',
        accessor: d => d.DSP_Identifier // accessor is the "key" in the data
      },
      {
        Header: 'DSP Name',
        accessor: d => d.DSP_Name
      },
      {
        Header: 'Usage Start Date',
        accessor: d => {
          return moment(startDate).format('MM-DD-YYYY')
        }
      },
      {
        Header: 'Usage End Date',
        accessor: d => {
          return moment(endDate).format('MM-DD-YYYY')
        }
      },
      {
        Header: 'Storefront',
        accessor: d => d.Storefront
      },
      {
        Header: 'Service Configuration',
        accessor: d => d.plan
      },
      {
        Header: 'Usage Identifier',
        accessor: d => d.Usage_Identifier
      },
      {
        Header: 'DSP Recording Identifier',
        accessor: d => d.DSP_Recording_Identifier
      },
      {
        Header: 'ISRC',
        accessor: d => d.ISRC
      },
      {
        Header: 'Recording Title',
        accessor: d => d.Recording_Title
      },
      {
        Header: 'Recording Version Title',
        accessor: d => d.Recording_Version_Title
      },
      {
        Header: 'Recording Alternative Titles',
        accessor: d => {
          if (d.Recording_Alternative_Titles) {
            const piped = d.Recording_Alternative_Titles.replace('\n', '|')
            return piped
          } else {
            return ''
          }
        }
      },
      {
        Header: 'Recording Artist',
        accessor: d => d.Recording_Artist
      },
      {
        Header: 'Recording Playing Time',
        accessor: d => {
          let newString = 'PT' + d.Recording_Playing_Time.replace(':', 'H').replace(':', 'M') + 'S'
          let newStringArray = []
          let prev_char = ''

          for (var char of newString) {
            if (char === '0' && prev_char !== '0') {
              // Do nothing
            } else {
              newStringArray.push(char)
            }
            prev_char = char
          }
          newString = newStringArray.join('')
          return newString
        }
      },
      {
        Header: 'Recording Date First Utilized',
        accessor: d => {
          if (d.Recording_Date_First_Utilized) {
            return moment(d.Recording_Date_First_Utilized).format("YYYY-MM-DD")
          } else {
            return ''
          }
        }
      },
      {
        Header: 'Recording Studio Producer',
        accessor: d => d.Recording_Studio_Producer
      },
      {
        Header: 'ICPN',
        accessor: d => d.ICPN
      },
      {
        Header: 'Catalog Number',
        accessor: d => d.Catalog_Number
      },
      {
        Header: 'Product Distributor Identifier',
        accessor: d => d.Product_Distributor_Identifier
      },
      {
        Header: 'Product Title',
        accessor: d => d.Product_Title
      },
      {
        Header: 'Release Date',
        accessor: d => {
          if (d.Release_Date) {
            return moment(d.Release_Date).format("YYYY-MM-DD")
          } else {
            return ''
          }
        }
      },
      {
        Header: 'PLine',
        accessor: d => d.PLine
      },
      {
        Header: 'Label Name',
        accessor: d => d.Label_Name
      },
      {
        Header: 'Distributor',
        accessor: d => d.Distributor
      },
      {
        Header: 'MLC Song Code',
        accessor: d => d.MLC_Song_Code
      },
      {
        Header: 'ISWC',
        accessor: d => d.ISWC
      },
      {
        Header: 'Work Title',
        accessor: d => d.Work_Title
      },
      {
        Header: 'Work Alternative Titles',
        accessor: d => {
          if (d.Work_Alternative_Titles) {
            const piped = d.Work_Alternative_Titles.replace('\n', '|')
            return piped
          } else {
            return ''
          }
        }
      },
      {
        Header: 'Writers',
        accessor: d => {
          if (d.Writers) {
            const piped = d.Writers.replace('\n', '|')
            return piped
          } else {
            return ''
          }
        }
      },
      {
        Header: 'Writer IPI',
        accessor: d => {
          if (d.Writer_IPI) {
            const piped = d.Writer_IPI.replace('\n', '|')
            return piped
          } else {
            return ''
          }
        }
      },
      {
        Header: 'Writer ISNI',
        accessor: d => {
          if (d.Writer_ISNI) {
            const piped = d.Writer_ISNI.replace('\n', '|')
            return piped
          } else {
            return ''
          }
        }
      },
      {
        Header: 'Believed Public Domain',
        accessor: d => d.Believed_Public_Domain.toString()
      },
      {
        Header: 'Original Number of Usages',
        accessor: d => d.total_clicks
      }
    ],
    [startDate, endDate]
  )

  // Table structure
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable({ columns, data })

  // Converts our db data to the correct format for Usage form submission
  const currentData = useTable({ columns, data })

  const reportData = []
  currentData.rows.map(row => {
    reportData.push(row.values)
  })

  // Converts our db data to the correct format for Rights Controller form submission
  const controllerReport = []
  currentData.data.map(key => {
    if (key.publisher_name && key.publisher_name.toLowerCase() === 'synced up designs' ||
      key.publisher_name && key.publisher_name.toLowerCase() === 'synced up design' ||
      key.publisher_name && key.publisher_name.toLowerCase() === 'synced up publisher' ||
      key.publisher_name && key.publisher_name.toLowerCase() === 'synced up products') {
      controllerReport.push(
        {
          Usage_Identifier: key.Usage_Identifier,
          Musical_Work_Rights_Controller_Name: key.publisher_name,
          Is_the_DSP_this_Musical_Work_Copyright_Controller: 'true'
        }
      )
    } else {
      controllerReport.push(
        {
          Usage_Identifier: key.Usage_Identifier,
          Musical_Work_Rights_Controller_Name: key.publisher_name,
          Is_the_DSP_this_Musical_Work_Copyright_Controller: 'false'
        }
      )
    }
  })

  // Function to help convert controller report without underscores
  function convert(obj) {
    const result = {};
    Object.keys(obj).forEach(function (key) {
      result[key.replace(/_/g, ' ')] = obj[key];
    });
    return result;
  }
  var convertedControllerReport = controllerReport.map(function (o) {
    return convert(o);
  });

  return (
    <>
      {isMonthlyReport ? (
        <div className='mb-3 mt-3 text-center'>
          <dt className='text-sm text-center font-medium leading-5 text-gray-500'>
            Usage Month
          </dt>
          <DatePicker
            className='block w-full mt-2 transition duration-150 ease-in-out form-textarea sm:text-sm sm:leading-5'
            selected={startDate}
            onChange={date => {
              setStartDate(date)
              setEndDate(new Date(date.getFullYear(), date.getMonth() + 1, 0))
            }}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            dateFormat='MM/yyyy'
            showMonthYearPicker
          />
        </div>
      ) : (
        <div className='mb-3 mt-3 text-center'>
          <dt className='text-sm text-center font-medium leading-5 text-gray-500'>
            Usage Annual
          </dt>
          <DatePicker
            className='block w-full mt-2 transition duration-150 ease-in-out form-textarea sm:text-sm sm:leading-5'
            selected={startDate}
            onChange={date => {
              setStartDate(date)
              setEndDate(new Date(date.getFullYear(), 12, 0))
            }}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            dateFormat='yyyy'
            showYearPicker
          />
        </div>
      )}

      <div className='mb-3 flex justify-center'>
        <div className='text-center'>
          <CSVLink
            className='mr-5 inline-flex items-center px-4 py-2 text-sm font-medium leading-5 text-white transition duration-150 ease-in-out bg-purple-600 border border-transparent rounded-md hover:bg-purple-500 focus:outline-none focus:shadow-outline-purple focus:border-purple-700 active:bg-indigo-700'
            columns={columns}
            data={reportData}
            filename={fileName}
          >
            Export Usage Report
          </CSVLink>
        </div>
        <div className='text-center'>
          <CSVLink
            className='inline-flex items-center px-4 py-2 text-sm font-medium leading-5 text-white transition duration-150 ease-in-out bg-purple-600 border border-transparent rounded-md hover:bg-purple-500 focus:outline-none focus:shadow-outline-purple focus:border-purple-700 active:bg-indigo-700'
            columns={columns}
            data={convertedControllerReport}
            filename={fileName2}
          >
            Export Controller Report
          </CSVLink>
        </div>
      </div>

      {isLoading ? (
        <LoadingPage />
      ) : (
        <div>
          {/* REACT TABLE */}
          <table {...getTableProps()} style={{ border: 'solid 1px blue' }}>
            <thead>
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <th
                      {...column.getHeaderProps()}
                      style={{
                        borderBottom: 'solid 3px red',
                        background: 'aliceblue',
                        color: 'black',
                        fontWeight: 'bold',
                      }}
                    >
                      {column.render('Header')}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map(row => {
                prepareRow(row)
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map(cell => {
                      return (
                        <td
                          {...cell.getCellProps()}
                          style={{
                            padding: '10px',
                            border: 'solid 1px gray',
                            background: 'papayawhip',
                          }}
                        >
                          {cell.render('Cell')}
                        </td>
                      )
                    })}
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      )}
    </>
  )
}

export default MLC_Usage_Report;