import React, { useState, useEffect } from 'react';
import axios from 'axios';
import axiosRetry from 'axios-retry';

// ROUTING
import { Route, Link } from 'react-router-dom';

// STATE
import { useRecoilValue } from 'recoil';
import { singlePublisherFilter } from '../../state/atoms';
import { MemoizedPublisherHomeCard } from './PublisherHomeCard';
import { MemoizedPublisherAboutCard } from './PublisherAboutCard';

// IMAGES
import { SocialIcon } from 'react-social-icons';
import { ReactComponent as BlankPublisher } from '../../img/icon/BlankPublisher.svg';
import PlaceholderBanner from '../../img/placeholder-banner.jpg';

// ----------------------------------------------------------------------------------
// ------------------------------- PUBLISHER PAGE -----------------------------------
// ----------------------------------------------------------------------------------

const PublisherPage = ({ match, cognitoUser }) => {
  const publisher = useRecoilValue(singlePublisherFilter(match.params.id));
  const [chunks, setChunks] = useState([]);
  const [featuredChunks, setFeaturedChunks] = useState([]);
  const [recentChunks, setRecentChunks] = useState([]);
  const [publisherCollections, setPublisherCollections] = useState([]);

  // Set axios to retry a set number of times on failed request/return
  axiosRetry(axios, { retries: 3 })

  useEffect(() => {
    axios({
      method: 'get',
      url: process.env.REACT_APP_API + `publishers/${match.params.id}/chunks/public`,
      headers: {
        Accept: 'application/json',
        Authorization: process.env.REACT_APP_HEROKU_SERVER_KEY,
      },
    })
      .then((res) => {
        setChunks(res.data);
      })
      .catch((err) => console.log(err));
  }, [match.params.id]);

  useEffect(() => {
    axios({
      method: 'get',
      url:
        process.env.REACT_APP_API +
        `publishers/${match.params.id}/featuredchunks`,
      headers: {
        Accept: 'application/json',
        Authorization: process.env.REACT_APP_HEROKU_SERVER_KEY,
      },
    })
      .then((res) => {
        setFeaturedChunks(res.data);
      })
      .catch((err) => console.log(err));
  }, [match.params.id]);

  useEffect(() => {
    axios({
      method: 'get',
      url: process.env.REACT_APP_API + `publishers/${match.params.id}/chunks/recent`,
      headers: {
        Accept: 'application/json',
        Authorization: process.env.REACT_APP_HEROKU_SERVER_KEY,
      },
    })
      .then((res) => {
        setRecentChunks(res.data);
      })
      .catch((err) => console.log(err));
  }, [match.params.id]);

  useEffect(() => {
    axios({
      method: 'get',
      url: process.env.REACT_APP_API + `publishers/${match.params.id}/collections`,
      headers: {
        Accept: 'application/json',
        Authorization: process.env.REACT_APP_HEROKU_SERVER_KEY,
      },
    })
      .then((res) => {
        setPublisherCollections(res.data);
      })
      .catch((err) => console.log(err));
  }, [match.params.id]);

  return (
    <>
      <div>
        <div>
          {publisher?.banner_pic ? (
            <img
              className='rounded-t-lg'
              src={publisher?.banner_pic}
              alt='banner for a single publisher'
            />
          ) : (
              <img
                className='rounded-t-lg'
                src={PlaceholderBanner}
                alt='placeholder banner for a single publisher'
              />
            )}
        </div>

        {/* Info Bar */}
        <div className='px-10 pt-4 pb-1 bg-green-600 rounded-b-lg'>
          {/* Publisher Info */}
          <div className='block sm:flex justify-between'>
            {/* Left Side */}
            <div className='flex justify-center items-start'>
              {publisher?.profile_pic ? (
                <img
                  className='rounded-lg h-14 w-14 hidden sm:block'
                  src={publisher?.profile_pic}
                  alt='profile for a single publisher'
                />
              ) : (
                  <BlankPublisher className='inline-block object-fill w-12 h-12 rounded-md' />
                )}
              <a href={`${publisher?.publisher_URL}`} target="_blank" rel='noopener noreferrer' className='sm:pl-5 text-3xl text-white hover:text-mcgreen'>{publisher?.name}</a>
            </div>

            {/* Right Side */}
            <div className='flex justify-center self-end mt-2 sm:mt-0'>
              {!publisher?.facebook_URL ? null : (
                <div className='sm:ml-5'>
                  <SocialIcon url={publisher?.facebook_URL} fgColor='white' target='_blank' />
                </div>
              )}
              {!publisher?.twitter_URL ? null : (
                <div className='ml-5'>
                  <SocialIcon url={publisher?.twitter_URL} fgColor='white' target='_blank' />
                </div>
              )}
              {!publisher?.youtube_URL ? null : (
                <div className='ml-5'>
                  <SocialIcon url={publisher?.youtube_URL} fgColor='white' target='_blank' />
                </div>
              )}
              {!publisher?.instagram_URL ? null : (
                <div className='ml-5'>
                  <SocialIcon url={publisher?.instagram_URL} fgColor='white' target='_blank' />
                </div>
              )}
              {!publisher?.twitch_URL ? null : (
                <div className='ml-5'>
                  <SocialIcon url={publisher?.twitch_URL} fgColor='white' target='_blank' />
                </div>
              )}
              {!publisher?.tiktok_URL ? null : (
                <div className='ml-5'>
                  <SocialIcon url={publisher?.tiktok_URL} fgColor='white' target='_blank' />
                </div>
              )}

              {/* SUBSCRIBE BUTTON */}
              {/* <button
                href={''}
                target='_blank'
                rel='noopener noreferrer'
                className='inline-flex items-center px-12 py-1 ml-24 text-sm text-lg leading-4 text-white transition duration-150 ease-in-out bg-green-900 border border-transparent rounded-lg hover:bg-teal-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700'
              >
                SUBSCRIBE
            </button> */}
            </div>
          </div>

          {/* Tabs */}
          <div className='flex justify-evenly sm:justify-start pt-2 text-xl text-white'>
            <Link to={`${match.url}`} className='mr-10 hover:text-mcgreen'>HOME</Link>
            {/* <Link to={`${match.url}/chunks`}>CHUNKS</Link> */}
            <Link to={`${match.url}/about`} className='hover:text-mcgreen'>ABOUT</Link>
          </div>
        </div>
      </div>

      {/* SWAPS TABS DEPENDING ON ROUTE CHOSEN */}
      <Route
        exact
        path={`/dashboard/publishers/:id`}
        render={(props) => (
          <MemoizedPublisherHomeCard
            cognitoUser={cognitoUser}
            chunks={chunks}
            featuredChunks={featuredChunks}
            recentChunks={recentChunks}
            publisherCollections={publisherCollections}
            {...props}
          />
        )}
      />
      <Route
        exact
        path={`/dashboard/publishers/:id/about`}
        render={(props) => (
          <MemoizedPublisherAboutCard
            cognitoUser={cognitoUser}
            chunks={chunks}
            publisher={publisher}
            {...props}
          />
        )}
      />
    </>
  );
};

export default PublisherPage;