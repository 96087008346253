import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import axiosRetry from 'axios-retry';
import { v4 as uuidv4 } from 'uuid';
import S3 from 'react-aws-s3';

// ROUTING
import { useParams, useHistory } from 'react-router-dom';

// FORM MANAGEMENT
import { useForm } from 'react-hook-form';

// TAILWIND TRANSITION
import { Transition } from '@tailwindui/react';

// COMPONENTS
import { MemoizedGroupPlaylistChunksTable } from './GroupPlaylistChunksTable';

// TOAST
import cogoToast from 'cogo-toast';

// ----------------------------------------------------------------------------------
// --------------------------- GROUP EDIT PLAYLIST FORM -----------------------------
// ----------------------------------------------------------------------------------

const GroupEditPlaylistForm = ({ group, refresh, setRefresh, match }) => {
  // State
  const history = useHistory();
  const { playlistId } = useParams();
  const [playlist, setPlaylist] = useState({});
  const [playlistChunks, setPlaylistChunks] = useState([]);
  const [openWarning, setOpenWarning] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  // Image State
  const [loadingImg, setLoadingImg] = useState(false);
  const [image, setImage] = useState('');

  // Form
  const { register, handleSubmit, errors, reset } = useForm({
    mode: 'onChange',
  });

  // Set axios to retry a set number of times on failed request/return
  axiosRetry(axios, { retries: 3 })

  // Useffect to grab the current playlist
  useEffect(() => {
    axios({
      method: 'get',
      url: process.env.REACT_APP_API + `sections/${playlistId}`,
      headers: {
        Accept: 'application/json',
        Authorization: process.env.REACT_APP_HEROKU_SERVER_KEY,
      }
    })
      .then((res) => {
        setPlaylist(res.data)
      })
      .catch((err) => {
        console.log(err);
      });
  }, [isEditing, refresh])

  // UseEffect that grabs a list of all the current playlist's chunks
  useEffect(() => {
    axios({
      method: 'get',
      url: process.env.REACT_APP_API + `sections/${playlistId}/allchunks`,
      headers: {
        Accept: 'application/json',
        Authorization: process.env.REACT_APP_HEROKU_SERVER_KEY,
      },
    })
      .then((res) => {
        setPlaylistChunks(res.data);
      })
      .catch((err) => console.log(err));
  }, [isEditing, refresh]);

  // UseEffect that sets playlist image for cards
  useEffect(() => {
    setImage(playlist?.img);
  }, [playlist?.img]);

  // Function that adds playlist card images to our Amazon Web Services S3 bucket
  const fileInput = useRef();

  const uploadImg = async (e) => {
    e.preventDefault();

    let file = fileInput.current.files[0]
    let newFileName = fileInput.current.files[0].name
    let newFileNameWithUUID = 'playlists/' + newFileName.substring(0, newFileName.lastIndexOf('.')) + '_' + uuidv4();

    const config = {
      bucketName: process.env.REACT_APP_BUCKET_NAME,
      region: process.env.REACT_APP_REGION,
      accessKeyId: process.env.REACT_APP_ACCESS_ID,
      secretAccessKey: process.env.REACT_APP_ACCESS_KEY,
    };

    const ReactS3Client = new S3(config);
    ReactS3Client.uploadFile(file, newFileNameWithUUID).then(data => {
      if (data.status === 204) {
        const imageBucketURL = data.location
        setImage(imageBucketURL);
        setLoadingImg(false);
        console.log('Image successfully uploaded to S3!');
        return data
      } else {
        console.log('Image failed upload to S3');
      }
    })
  };

  // Function to handle deleting a private section
  const handleDelete = (id) => {
    axios
      ({
        method: 'delete',
        url: process.env.REACT_APP_API + `sections/${id}`,
        headers: {
          Accept: 'application/json',
          Authorization: process.env.REACT_APP_HEROKU_SERVER_KEY
        }
      })
      .then((res) => {
        cogoToast.success(`Playlist successfully deleted from the ensemble!`, {
          hideAfter: 5,
        });
        setRefresh(!refresh)
        history.push(`/dashboard/ensembles/${group?.id}/admin`);
      })
      .catch((err) => {
        console.log(err);
        cogoToast.error(`Playlist was not able to be removed from the ensemble`, {
          hideAfter: 5,
        });
      });
  };

  // Function that handles submitting updates/edits for group data
  const onSubmit = (data) => {
    setIsEditing(false);

    // Get an array of only chunk ids for spreading correctly
    var playlist_chunk_ids = []

    if (data.playlist_chunks) {
      data.playlist_chunks.map(playlistChunk => {
        playlist_chunk_ids.push(playlistChunk.value)
      })
    }

    axios
      ({
        method: 'put',
        url: process.env.REACT_APP_API + `sections/${playlistId}/simple`,
        headers: {
          Accept: 'application/json',
          Authorization: process.env.REACT_APP_HEROKU_SERVER_KEY
        }, data: {
          ...data,
          img: image
        }
      })
      .then((res) => {
        setIsEditing(false)
        setRefresh(!refresh);
        cogoToast.success(`Playlist edited successfully!`, {
          hideAfter: 5,
        });
      })
      .catch((err) => {
        console.log(err);
        setIsEditing(false)
        cogoToast.error(`There was an error editing the playlist`, {
          hideAfter: 5,
        });
      });
  };

  return (
    <div className='mt-3'>
      {/* ABOUT */}
      <div className='bg-green-700 px-5 py-3 mt-2 rounded-lg'>
        <p className='whitespace-pre-wrap text-white'>
          Change the imagery, name, and the description of this playlist, as well as
          any of its chunks. These can include your private content along
          with any chunk from the entire platform that you’ve already loaded into your
          ENSEMBLE CHUNKS.
        </p>
      </div>

      {/* TITLE */}
      <div className='mt-2 border-t-4 border-green-600 border-solid'>
        <h1 className='w-full pt-2 text-2xl font-medium leading-6 text-white'>
          EDIT PLAYLIST
        </h1>
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='px-4 py-5 sm:px-6'>
          <dl className='grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3'>

            <div className='sm:col-span-1'>
              <dt className='text-md font-medium leading-5 text-white'>
                Name
              </dt>
              {isEditing ? (
                <input
                  id='type'
                  type='text'
                  name='type'
                  defaultValue={playlist?.type}
                  ref={register({ required: true })}
                  className='block w-full px-3 py-2 mt-1 transition duration-150 ease-in-out border border-gray-300 rounded-md shadow-sm form-input focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5'
                />
              ) : (
                <dd className='mt-1 text-sm leading-5 text-white'>
                  {playlist?.type}
                </dd>
              )}
              {errors.playlist?.type && (
                <span className='text-red-500'>This field is required</span>
              )}
            </div>

            <div className='sm:col-span-1'>
              <dt className='text-md font-medium leading-5 text-white'>
                Description
              </dt>
              {isEditing ? (
                <textarea
                  id='description'
                  type='text'
                  name='description'
                  defaultValue={playlist?.description}
                  rows='6'
                  ref={register}
                  className='block w-full px-3 py-2 mt-1 transition duration-150 ease-in-out border border-gray-300 rounded-md shadow-sm form-input focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5'
                />
              ) : (
                <dd className=' truncate-3-lines mt-1 text-sm leading-5 text-white'>
                  {playlist?.description}
                </dd>
              )}
            </div>

            <div className='sm:col-span-1'>
              <dt className='text-md font-medium leading-5 text-white'>
                Playlist Card Image
                </dt>
              {isEditing ? (
                <div className='mt-2 sm:mt-0 sm:col-span-2'>
                  <div className='flex justify-center max-w-lg px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md'>
                    <div className='text-center'>
                      {image && !loadingImg ? (
                        <img
                          src={image}
                          alt='playlist img'
                          className='w-20 h-20'
                        />
                      ) : (
                        <svg
                          className='w-12 h-12 mx-auto text-gray-400'
                          stroke='currentColor'
                          fill='none'
                          viewBox='0 0 48 48'
                        >
                          <path
                            d='M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02'
                            strokeWidth='2'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      )}
                      {loadingImg ? (
                        <h3> Loading ...</h3>
                      ) : (
                        <h3>
                          <p className='mt-1 text-sm text-gray-600'>
                            <input
                              type='file'
                              name='file'
                              ref={fileInput}
                              placeholder='Upload an image here.'
                              onChange={uploadImg}
                            />
                          </p>
                          <p className='mt-1 text-xs text-gray-500'>
                            PNG, JPG, SVG up to 150MB
                            </p>
                        </h3>
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                <dd className='mt-1'>
                  <img
                    src={image}
                    alt='img for a playlist'
                    className='inline-block object-cover w-20 h-20 rounded-md'
                  />
                </dd>
              )}
            </div>
          </dl>

          {!isEditing && (
            <div className='flex w-full justify-center sm:justify-end mt-5'>
              <button
                type='button'
                onClick={() => {
                  setIsEditing(!isEditing);
                }}
                className=' mr-4 inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs leading-4 font-medium rounded text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150'
              >
                Edit Playlist
              </button>
              <button
                onClick={() => {
                  setOpenWarning(!openWarning)
                }}
                type='reset'
                className='inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs leading-4 font-medium rounded text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150'
              >
                Remove Playlist
              </button>
            </div>
          )}
        </div>

        {/* EDIT PLAYLIST CHUNKS */}
        {!isEditing ? (
          <div>
            <div className='mt-2 mb-3 border-t-4 border-green-600 border-solid'>
              <h1 className='w-full pt-2 text-2xl font-medium leading-6 text-white'>
                EDIT PLAYLIST CHUNKS
            </h1>
            </div>
            {/* RENDERS TABLE FOR ALL OF A GROUP'S PUBLIC CHUNKS */}
            <MemoizedGroupPlaylistChunksTable group={group} playlistChunks={playlistChunks} playlist={playlist} refresh={refresh} setRefresh={setRefresh} match={match} />
          </div>
        ) : null}

        <Transition show={openWarning}>
          <div class="fixed z-10 inset-0 overflow-y-auto">
            <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <div class="fixed inset-0 transition-opacity">
                <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
              </div>
              {/* <!-- This element is to trick the browser into centering the modal contents. --> */}
              <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;
                      <div class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                <div class="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                  <button onClick={() => {
                    setOpenWarning(!openWarning)
                  }}
                    type="button" class="text-gray-400 hover:text-gray-500 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150" aria-label="Close">
                    {/* <!-- Heroicon name: x --> */}
                    <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </button>
                </div>
                <div class="sm:flex sm:items-start">
                  <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    {/* <!-- Heroicon name: exclamation --> */}
                    <svg class="h-6 w-6 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                    </svg>
                  </div>
                  <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
                      Delete Playlist From Ensemble
                        </h3>
                    <div class="mt-2">
                      <p class="text-sm leading-5 text-gray-500">
                        Are you sure you want to delete this playlist from the ensemble?
                        Deleting is permanent and you will no longer have access to this playlist.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <span class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
                    <button onClick={() => {
                      handleDelete(playlist?.id);
                    }}
                      type="button"
                      class="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-red-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                    >
                      Remove
                        </button>
                  </span>
                  <span class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
                    <button onClick={() => {
                      setOpenWarning(!openWarning)
                    }} type="button" class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                      Cancel
                        </button>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </Transition>

        {/* SUBMIT EDITS OR DELETE */}
        {isEditing && (
          <div className='flex w-full justify-center sm:justify-end'>
            {' '}
            <button
              type='submit'
              className=' mr-4 inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs leading-4 font-medium rounded text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150'
            >
              Save
              </button>
            <button
              onClick={() => {
                setIsEditing(!isEditing);
                reset();
              }}
              type='reset'
              className='inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs leading-4 font-medium rounded text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150'
            >
              Cancel
              </button>
          </div>
        )}
      </form>
    </div>
  );
};

export const MemoizedGroupEditPlaylistForm = React.memo(GroupEditPlaylistForm);