import React, { useState } from 'react';
import axios from 'axios';

// ROUTING
import { useHistory, Link } from 'react-router-dom';

// AUTH
import Auth from '@aws-amplify/auth';

// FORMS
import { useForm } from "react-hook-form";

// TOAST
import cogoToast from 'cogo-toast';

// COMPONENTS
import CustomConfirmation from './CustomConfirmation';
import CustomResetPassword from './CustomResetPassword';

// ----------------------------------------------------------------------------------
// --------------------------------- CUSTOM SIGN IN ---------------------------------
// ----------------------------------------------------------------------------------

export default function CustomSignIn(props) {
  // State
  const { register, handleSubmit } = useForm();
  const [submitting, setSubmitting] = useState(false);
  const [screen, setScreen] = useState('signin');
  const [password, setPassword] = useState();
  const [email, setEmail] = useState()
  const [confirmFromSignup, setConfirmFromSignup] = useState(false);
  const [input, setInput] = useState({
    username: ''
  })

  // React Router
  const history = useHistory();

  const handleInputChange = (event) => {
    setInput({ ...input, [event.target.name]: event.target.value.toLowerCase().replace(/ /g, "") });
  };

  // Function to handle submitting Login form
  const onSubmit = async (data) => {
    setSubmitting(true);

    await Auth.signIn(data.username, data.password)
      .then((res) => {
        // Sign them up for Stream if they are part of an ensemble
        axios({
          method: 'get',
          url:
            process.env.REACT_APP_API + `users/${res.attributes.sub}/streamtoken`,
          headers: {
            Accept: 'application/json',
            Authorization: process.env.REACT_APP_HEROKU_SERVER_KEY,
          },
        })
          .then((res) => {
           localStorage.setItem('streamToken', res.data.streamToken)
            setSubmitting(false);
            cogoToast.success('Successfully logged in', {
              hideAfter: 3,
            });
            history.push('/dashboard')
          })
          .catch((err) => console.log(err));
      })
      .catch(err => {
        if (err.message === 'User is not confirmed.') {
          setSubmitting(false);
          setPassword(data.password)
          setEmail(data.username)
          setScreen('code');
          cogoToast.warn(err.message + ' Please confirm your account.', {
            hideAfter: 5,
          });
          console.log(err)
        } else {
          setSubmitting(false);
          cogoToast.error(err.message, {
            hideAfter: 3,
          });
          console.log(err)
        }
      })
  };

  // Helper function to change screens from children
  let changeScreen = (screenName) => {
    setScreen(screenName);
  }

  return (
    <div className="flex justify-center w-full h-screen overflow-hidden bg-fixed bg-cover bg-hero-pattern" slot={props.slot}>
      <div className="flex flex-col justify-center items-center">
        {screen === 'signin' ? (
          <form className="p-10 bg-mclightgreen rounded-lg" onSubmit={handleSubmit(onSubmit)}>
            <h4 className='text-white text-2xl'>Sign in to your account</h4>
            <div className="form-group">
              <label className='text-white'>Email address</label>
              <input
                name='username'
                type='username'
                value={input.username}
                placeholder='Enter your email'
                onChange={handleInputChange}
                className='form-control w-full flex items-center mb-7 mt-3 p-2 rounded-md text-lg'
                ref={register()}
              />
            </div>
            <div className="form-group">
              <label className='text-white'>Password</label>
              <input
                name='password'
                type='password'
                placeholder='Enter your password'
                className='form-control w-full flex items-center mb-3 mt-3 p-2 rounded-md text-lg'
                ref={register()}
              />
            </div>
            <div className='mb-9 text-white'>
              <p>Forgot your password?
                <span
                  className='ml-1 cursor-pointer text-mcgreen hover:text-mcfadedgreen focus:outline-none'
                  onClick={() => setScreen('resetpassword')}
                >
                  Reset password
                </span>
              </p>
            </div>
            <div className='flex'>
              <div className='mr-20 text-white flex items-center text-xl'>
                <p>No account?
                <span className='ml-1'>
                    <Link
                      to='/signup'
                      className='text-mcgreen hover:text-mcfadedgreen focus:outline-none'
                    >
                      Create account
					          </Link>
                  </span>
                </p>
              </div>
              <button
                type="submit"
                // disabled={!stripe}
                className={`${submitting && 'opacity-50 pointer-events-none'
                  } flex items-center rounded-lg text-lg px-12 py-3 text-center font-medium text-mcgreen bg-mcbuttondarkgreen hover:bg-mcgreen hover:text-mcbuttondarkgreen focus:ring transition duration-150 ease-in-out`}>
                {submitting && (
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    strokeWidth="0"
                    viewBox="0 0 1024 1024"
                    className="w-5 h-5 mr-3 animate-spin"
                    xmlns="http://www.w3.org/2000/svg">
                    <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 0 0-94.3-139.9 437.71 437.71 0 0 0-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                  </svg>
                )}{' '}
              SIGN IN
              </button>
            </div>
          </form>

        ) : screen === 'code' ? (
          <CustomConfirmation password={password} email={email} confirmFromSignup={confirmFromSignup} changeScreen={changeScreen} />

        ) : screen === 'resetpassword' ? (
          <CustomResetPassword changeScreen={changeScreen} />

        ) : null}
      </div>
    </div >
  );
}