import React from 'react';

// ROUTING
import { Link } from 'react-router-dom';

// COMPONENTS
import NavBar from './NavBar';
import { Footer } from './Footer';

// IMAGES
import musicSheetBg from '../../img/musicSheetBg.png';

// ----------------------------------------------------------------------------------
// ----------------------------------- ABOUT ----------------------------------------
// ----------------------------------------------------------------------------------

export const About = () => {
  return (
    <>
        <NavBar />
        <main>
            <div className='lg:flex lg:h-screen bg-hero-pattern lg:bg-none items-center pb-14 lg:pb-0 overflow-hidden'>
                <img src={musicSheetBg}
                    className='hidden lg:flex opacity-40'
                    alt='sheet music' 
                />
                <div className='lg:absolute mt-6 lg:mt-28 mx-14'>
                    <div className='flex flex-col lg:flex-row justify-center lg:justify-between items-center mb-14'>
                        <div className='text-white lg:w-1/2 mb-12 lg:mb-0 lg:mr-8'>
                            <h1 className='text-5xl font-bold mb-6'>
                                What is Music Chunks?
                            </h1>
                            <p className='text-md mb-10'>
                                Music Chunks is an online music practice platform. Students and teachers can access
                                fundamental exercises and skill-builders from top content creators, as well as “chunks”
                                of published music literature from current composers. The platform goes beyond sheet
                                music, enhancing the experience with audio and video playback.
                            </p>
                            {localStorage.getItem(process.env.REACT_APP_USER_ID) ? (
                                <Link
                                    to='/dashboard'
                                    className='py-2 text-lg px-2 border-2 bg-mcgreen border-mcgreen text-base leading-6 font-bold rounded-md text-black hover:opacity-70 focus:outline-none transition duration-150 ease-in-out'
                                    >
                                    My Dashboard
                                </Link>
                            ) : (
                                <div>
                                    <Link
                                        to='/login'
                                        className='mr-3 py-2 px-4 text-lg px-2 border-2 border-mcgreen text-base leading-6 font-bold rounded-md text-mcgreen hover:bg-mcgreen hover:text-black focus:outline-none transition duration-150 ease-in-out'
                                        >
                                        Sign In
                                    </Link>
                                    <Link
                                        to='/signup'
                                        className='py-2 text-lg px-2 border-2 bg-mcgreen border-mcgreen text-base leading-6 font-bold rounded-md text-black hover:opacity-70 focus:outline-none transition duration-150 ease-in-out'
                                        >
                                        Start Free Trial
                                    </Link>
                                </div>
                            )}
                        </div>
                        <iframe className='w-full lg:w-1/2' height="360" src="https://www.youtube.com/embed/k0-hvhlf6AY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                    <div className='flex justify-center'>
                        <a 
                            href='#more-info'
                            className='py-2 text-lg px-2 border-2 border-mcgreen text-base leading-6 font-bold rounded-md text-mcgreen hover:bg-mcgreen hover:text-black focus:outline-none transition duration-150 ease-in-out'
                            >
                            Learn More
                        </a>
                    </div>
                </div>
            </div>
            <div id='more-info' className='py-14 px-8 bg-gray-50'>
                <h3 className='text-3xl font-extrabold leading-8 tracking-tight text-center text-gray-900 sm:text-4xl sm:leading-10'>
                    ABOUT MUSIC CHUNKS
                </h3>
                <div className='max-w-3xl mx-auto mt-4 text-xl leading-7 text-center text-gray-500'>
                    <p>
                        Music Chunks is an online music practice platform built for all levels of musicians and
                        music teachers. Concert, jazz, and marching band, color guard and movement
                        fundamentals are included. Everything can be accessed from your smartphone, tablet,
                        laptop, or desktop computer.
                    </p>
                    <br/>
                    <p>
                        The practice experience for students goes beyond sheet music with our built-in player
                        which synchronizes the sheet music with a selection of playable audio and video
                        examples for each chunk. Seeing and Hearing an example of the music is such a major
                        enhancement for most students’ learning processes.
                    </p>
                    <br/>
                    <p>
                        The built-in player has a metronome tool that is synchronized to the audio and and
                        video performances, and gives students the ability to slow it all down to learn at their
                        own pace. If they only want to work on a small portion at a time, they can create loops
                        for practice with a click and drag on the music.
                    </p>
                    <br/>
                    <p>
                        The music and exercises featured on the Music Chunks platform are provided by the
                        highest quality publishers, composers, and educators. Your subscription payment
                        directly supports these artists and music professionals through royalties paid for each
                        play of their music.
                    </p>
                    <br/>
                    <p>
                        Individual and Ensemble (both school and non-school) Subscriptions are available.
                    </p>
                    <br/>
                    <p>
                        We offer a 7 day free trial with full standard access for individual users. For Ensembles,
                        we offer a full 1-month trial.
                    </p>
                </div>
            </div>
        </main>
        <Footer />
    </>
  );
};