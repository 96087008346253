import React from 'react';

import { CoffeeLoading } from 'react-loadingg';

// ----------------------------------------------------------------------------------
// -------------------------------- LOADING PAGE ------------------------------------
// ----------------------------------------------------------------------------------

export const LoadingPage = () => {
  return (
    <div className='h-screen flex overflow-hidden bg-cover bg-fixed bg-hero-pattern'>
      <CoffeeLoading />
    </div>
  );
};
