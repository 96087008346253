import React, { useCallback, useContext } from 'react';
import { ImageDropzone, FileUploadButton } from 'react-file-utils';

import {
  ChatAutoComplete,
  EmojiPicker,
  UploadsPreview,
  useChannelStateContext,
  useMessageInputContext,
} from 'stream-chat-react';

import './ThreadMessageInput.css';

import { GiphyContext } from '../ChannelContainer/ChannelInner';

// ICONS 
import { LightningBoltSmall } from '../../assets/LightningBoltSmall';
import { SendButton } from '../../assets/SendButton';
import { SmileyFace } from '../../assets/SmileyFace';

export const ThreadMessageInput = (props) => {
  const { giphyState, setGiphyState } = useContext(GiphyContext)

  const {
    acceptedFiles,
    maxNumberOfFiles,
    multipleUploads
  } = useChannelStateContext();

  const messageInput = useMessageInputContext();

  const onChange = useCallback(
    (event) => {
      const { value } = event.target;
      const deletePressed = event.nativeEvent?.inputType === 'deleteContentBackward';

      if (messageInput.text.length === 1 && deletePressed) {
        setGiphyState(false);
      }

      if (!giphyState && messageInput.text.startsWith('/giphy') && !messageInput.numberOfUploads) {
        event.target.value = value.replace('/giphy', '');
        setGiphyState(true);
      }

      messageInput.handleChange(event);
    },
    [giphyState, messageInput, setGiphyState],
  );

  const GiphyIcon = () => (
    <div className='giphy-icon__wrapper'>
      <LightningBoltSmall />
      <p className='giphy-icon__text'>GIPHY</p>
    </div>
  );

  return (
    <div className='thread-message-input__wrapper'>
      <ImageDropzone
        accept={acceptedFiles}
        handleFiles={messageInput.uploadNewFiles}
        multiple={multipleUploads}
        disabled={
          (maxNumberOfFiles !== undefined && messageInput.numberOfUploads >= maxNumberOfFiles) ||
          giphyState
        }>
        <div className='thread-message-input__input'>
          {giphyState && <GiphyIcon />}
          <UploadsPreview />
          <ChatAutoComplete
            onChange={onChange}
            rows={1}
            placeholder='Reply'
          />
          <div className='thread-message-input__icons'>
            <SmileyFace openEmojiPicker={messageInput.openEmojiPicker} />
          </div>
          <div className='mb-4 mr-2'>
            <FileUploadButton
              accept={acceptedFiles}
              handleFiles={messageInput.uploadNewFiles}
              multiple={multipleUploads}
              disabled={
                (maxNumberOfFiles !== undefined && messageInput.numberOfUploads >= maxNumberOfFiles) ||
                giphyState}
            />
          </div>
          <div
            className='thread-message-input__button'
            role='button'
            aria-roledescription='button'
            onClick={messageInput.handleSubmit}
          >
            <SendButton />
          </div>
        </div>
      </ImageDropzone>
      <EmojiPicker />
    </div>
  );
};
