import React, { useState } from 'react';
import axios from 'axios';
import axiosRetry from 'axios-retry';

// ROUTING
import { Link } from 'react-router-dom';

// TAILWIND TRANSITION
import { Transition } from '@tailwindui/react';

// AG-GRID
import { AgGridColumn, AgGridReact } from 'ag-grid-react'
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine-dark.css';

// TOAST
import cogoToast from 'cogo-toast';

// ----------------------------------------------------------------------------------
// ---------------------------- GROUP PUBLIC CHUNK TABLE ----------------------------
// ----------------------------------------------------------------------------------

const GroupPublicChunkTable = ({ group, groupChunks, refresh, setRefresh, match }) => {
  // State
  const [gridApi, setGridApi] = useState(null);
  const [chunksToBeRemoved, setChunksToBeRemoved] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [openWarning, setOpenWarning] = useState(false);

  // Set axios to retry a set number of times on failed request/return
  axiosRetry(axios, { retries: 3 })

  // Sets grid params for columns
  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  // Important functions for setting multi checkboxes
  var checkboxSelection = function (params) {
    return params.columnApi.getRowGroupColumns().length === 0;
  };
  var headerCheckboxSelection = function (params) {
    return params.columnApi.getRowGroupColumns().length === 0;
  };

  // Function that sets the selected rows to state for removal
  const onSelectionChanged = () => {
    var selectedRows = gridApi.getSelectedRows();
    setChunksToBeRemoved(selectedRows)
  };

  // Function to handle removing a chunk from a group
  const handleRemoval = (chunkIds) => {
    setSubmitting(true);

    var removedSuccessfully = []
    var removedFailure = []
    var count = 0

    // Maps through selected chunks and removes them from the ensemble
    chunkIds.map(chunk => {
      setSubmitting(true);

      axios
        ({
          method: 'delete',
          url: process.env.REACT_APP_API + `groups/${group.id}/chunks/${chunk.id}`,
          headers: {
            Accept: 'application/json',
            Authorization: process.env.REACT_APP_HEROKU_SERVER_KEY
          }
        })
        .then((res) => {
          setSubmitting(false);
          removedSuccessfully.push(chunk.name)
          count += 1;
          setRefresh(!refresh);
          setOpenWarning(false);
          if (removedSuccessfully.length > 0 && count === chunkIds.length) {
            cogoToast.success(`The following chunks were removed from the ensemble: ${removedSuccessfully.join(', ')}`, {
              hideAfter: 10
            });
          }
        })
        .catch((err) => {
          console.log(err);
          setSubmitting(false);
          removedFailure.push(chunk.name)
          count += 1;
          setRefresh(!refresh);
          setOpenWarning(false);
          if (removedFailure.length > 0 && count === chunkIds.length) {
            cogoToast.error(`The following chunks were not removed from the ensemble: ${removedFailure.join(', ')}`, {
              hideAfter: 10
            });
          }
        });
    })
  };

  return (
    <>
      <div
        id="myGrid"
        style={{
          // height: '100%',
          width: '100%',
        }}
        className="ag-theme-alpine-dark"
      >
        <AgGridReact
          autoGroupColumnDef={{
            headerName: 'Group',
            minWidth: 170,
            field: 'name',
            valueGetter: function (params) {
              if (params.node.group) {
                return params.node.key;
              } else {
                return params.data[params.colDef.field];
              }
            },
            headerCheckboxSelection: true,
            cellRenderer: 'agGroupCellRenderer',
            cellRendererParams: { checkbox: true },
          }}
          defaultColDef={{
            editable: false,
            enableRowGroup: true,
            enablePivot: true,
            enableValue: true,
            sortable: true,
            resizable: true,
            filter: true,
            flex: 1,
            minWidth: 100,
          }}
          suppressRowClickSelection={true}
          groupSelectsChildren={true}
          rowSelection={'multiple'}
          onSelectionChanged={onSelectionChanged}
          rowGroupPanelShow={'always'}
          pivotPanelShow={'always'}
          enableRangeSelection={true}
          pagination={true}
          paginationPageSize={10}
          domLayout='autoHeight'
          onGridReady={onGridReady}
          rowData={groupChunks}
        >
          <AgGridColumn
            field="name"
            headerName='Chunk Name'
            minWidth={170}
            checkboxSelection={checkboxSelection}
            headerCheckboxSelection={headerCheckboxSelection}
          />
          <AgGridColumn field="publisher_name" headerName='Publisher Name' />
        </AgGridReact>
      </div>

      {/* REMOVE CHUNK BUTTON */}
      <div className='flex w-full justify-center sm:justify-end mt-5'>
        <Link
          to={`${match.url}/add-chunk`}
          className='mr-4 mb-5 inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs leading-4 font-medium rounded text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150'
        >
          Add Chunks to Ensemble
        </Link>
        <a href='https://forms.monday.com/forms/690e098d416e8cdf82191d02679c301b'
          target='_blank'
          className='mr-4 mb-5 inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs leading-4 font-medium rounded text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150'
        >
          Upload Ensemble Private Content
        </a>
        <button
          onClick={() => {
            // Create warning if no chunks have been selected for removal
            if (chunksToBeRemoved?.length < 1) {
              cogoToast.warn(`No chunks selected for removal`, {
                hideAfter: 5,
              });
            } else {
              setOpenWarning(!openWarning)
            }
          }}
          type='reset'
          className='mb-5 inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs leading-4 font-medium rounded text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150'
        >
          Remove Chunks
        </button>
      </div>

      {/* MODAL FOR REMOVE CHUNK BUTTON */}
      <Transition show={openWarning}>
        <div class="fixed z-10 inset-0 overflow-y-auto">
          <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div class="fixed inset-0 transition-opacity">
              <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            {/* <!-- This element is to trick the browser into centering the modal contents. --> */}
            <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;
                      <div class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
              <div class="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                <button onClick={() => {
                  setOpenWarning(!openWarning)
                }}
                  type="button" class="text-gray-400 hover:text-gray-500 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150" aria-label="Close">
                  {/* <!-- Heroicon name: x --> */}
                  <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
              <div class="sm:flex sm:items-start">
                <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                  {/* <!-- Heroicon name: exclamation --> */}
                  <svg class="h-6 w-6 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                  </svg>
                </div>
                <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
                    Remove Chunk(s) From Ensemble
                        </h3>
                  <div class="mt-2">
                    <p class="text-sm leading-5 text-gray-500">
                      Are you sure you want to remove the selected chunk(s) from the ensemble? This will also remove them
                      from your private playlists and featured chunk list. You can always add them back later.
                    </p>
                  </div>
                </div>
              </div>
              <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <span class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
                  <button onClick={() => {
                    handleRemoval(chunksToBeRemoved);
                  }}
                    type="button"
                    className={`${submitting && 'opacity-50 cursor-not-allowed'
                      } inline-flex justify-center w-full rounded-md px-4 py-2 text-base bg-red-600 leading-6 font-medium text-white shadow-sm hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red active:bg-red-700 transition ease-in-out duration-150`}
                  >
                    {submitting ? '' : 'Remove'}
                    <svg
                      className={`animate-spin ${!submitting && 'hidden'}`}
                      stroke='currentColor'
                      fill='currentColor'
                      strokeWidth='0'
                      viewBox='0 0 16 16'
                      height='1em'
                      width='1em'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path d='M8 .75c.172 0 .333.034.484.102a1.214 1.214 0 0 1 .664.664c.068.15.102.312.102.484s-.034.333-.102.484a1.214 1.214 0 0 1-.265.399 1.324 1.324 0 0 1-.399.273A1.254 1.254 0 0 1 8 3.25c-.172 0-.333-.031-.484-.094a1.324 1.324 0 0 1-.672-.672A1.254 1.254 0 0 1 6.75 2c0-.172.031-.333.094-.484.067-.151.159-.284.273-.399.115-.114.248-.203.399-.265A1.17 1.17 0 0 1 8 .75zM2.633 3.758a1.111 1.111 0 0 1 .68-1.031 1.084 1.084 0 0 1 .882 0c.136.057.253.138.352.242.104.099.185.216.242.351a1.084 1.084 0 0 1 0 .883 1.122 1.122 0 0 1-.594.594 1.169 1.169 0 0 1-.883 0 1.19 1.19 0 0 1-.359-.234 1.19 1.19 0 0 1-.234-.36 1.169 1.169 0 0 1-.086-.445zM2 7a.941.941 0 0 1 .703.297A.941.941 0 0 1 3 8a.97.97 0 0 1-.078.39 1.03 1.03 0 0 1-.531.532A.97.97 0 0 1 2 9a.97.97 0 0 1-.39-.078 1.104 1.104 0 0 1-.32-.211 1.104 1.104 0 0 1-.212-.32A.97.97 0 0 1 1 8a.97.97 0 0 1 .29-.703A.97.97 0 0 1 2 7zm.883 5.242a.887.887 0 0 1 .531-.805.863.863 0 0 1 .68 0c.11.047.203.11.281.188a.887.887 0 0 1 .188.96.887.887 0 0 1-1.148.461.913.913 0 0 1-.462-.46.863.863 0 0 1-.07-.344zM8 13.25c.208 0 .385.073.531.219A.723.723 0 0 1 8.75 14a.723.723 0 0 1-.219.531.723.723 0 0 1-.531.219.723.723 0 0 1-.531-.219A.723.723 0 0 1 7.25 14c0-.208.073-.385.219-.531A.723.723 0 0 1 8 13.25zm3.617-1.008c0-.177.06-.325.18-.445s.268-.18.445-.18.326.06.445.18c.12.12.18.268.18.445s-.06.326-.18.445a.605.605 0 0 1-.445.18.605.605 0 0 1-.445-.18.605.605 0 0 1-.18-.445zM14 7.5a.48.48 0 0 1 .352.148A.48.48 0 0 1 14.5 8a.48.48 0 0 1-.148.352A.48.48 0 0 1 14 8.5a.48.48 0 0 1-.352-.148A.48.48 0 0 1 13.5 8a.48.48 0 0 1 .148-.352A.48.48 0 0 1 14 7.5zm-1.758-5.117c.188 0 .365.036.531.11a1.413 1.413 0 0 1 .735.734c.073.166.11.343.11.53 0 .188-.037.365-.11.532a1.413 1.413 0 0 1-.735.734 1.31 1.31 0 0 1-.53.11c-.188 0-.365-.037-.532-.11a1.415 1.415 0 0 1-.734-.734 1.31 1.31 0 0 1-.11-.531c0-.188.037-.365.11-.531a1.413 1.413 0 0 1 .734-.735c.167-.073.344-.11.531-.11z'></path>
                    </svg>
                  </button>
                </span>
                <span class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
                  <button onClick={() => {
                    setOpenWarning(!openWarning)
                  }} type="button" class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                    Cancel
                  </button>
                </span>
              </div>
            </div>
          </div>
        </div>
      </Transition>
    </>
  );
};

export const MemoizedGroupPublicChunkTable = React.memo(GroupPublicChunkTable);