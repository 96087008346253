import { useEffect } from 'react';

// Google Analytics
import analytics from './analytics';
import { useLocation } from 'react-router-dom';

// ----------------------------------------------------------------------------------
// ------------------------------- USE GOOGLE ANALYTICS -----------------------------
// ----------------------------------------------------------------------------------

export const useGoogleAnalytics = (userID) => {
  // location
  const location = useLocation();

  useEffect(() => {
    // initialize analytics
    analytics.init(userID);
    // no dependancies needed
  }, []);

  useEffect(() => {
    // track page views
    const currentPath = location.pathname + location.search;
    analytics.sendPageview(currentPath);
  }, [location]);
};