import React, { useState, useEffect } from 'react';
import axios from 'axios';
import axiosRetry from 'axios-retry';

// FORM MANAGEMENT
import { useForm } from 'react-hook-form';

// ROUTING
import { Link } from 'react-router-dom';

// TOAST
import cogoToast from 'cogo-toast';

// ----------------------------------------------------------------------------------
// ----------------------------------- USER TAB -------------------------------------
// ----------------------------------------------------------------------------------

export const UserTab = ({ cognitoUser }) => {
  //State
  const [user, setUser] = useState();
  const [isEditing, setIsEditing] = useState();
  const [refresh, setRefresh] = useState();
  const { id } = cognitoUser.data;
  const url = window.location.href; // GRABS REFERENCE TO THE CURRENT URL TO CHECK WHICH PLAN DATA TO LOAD
  const [input, setInput] = useState({
    first_name: '',
    last_name: ''
  })
  const [inputDisabled, setInputDisabled] = useState({
    first_name: false,
    last_name: false
  })

  // Form
  const { register, handleSubmit, errors, reset } = useForm({
    mode: 'onChange',
  });

  // Set axios to retry a set number of times on failed request/return
  axiosRetry(axios, { retries: 3 })

  // Get User data from db
  useEffect(() => {
    axios
      ({
        method: 'get',
        url: process.env.REACT_APP_API + `users/${id}`,
        headers: {
          Accept: 'application/json',
          Authorization: process.env.REACT_APP_HEROKU_SERVER_KEY
        }
      })
      .then((res) => {
        setUser(res.data)
        setInput({
          first_name: res.data.first_name,
          last_name: res.data.last_name
        })
      })
      .catch((err) => {
        console.log(err);
      });
  }, [refresh])

  // Toggle which inputs should be disabled depending on what is being typed in
  useEffect(() => {
    if (input?.first_name !== user?.first_name) {
      setInputDisabled({
        first_name: false,
        last_name: true
      })
    } else if (input?.last_name !== user?.last_name) {
      setInputDisabled({
        first_name: true,
        last_name: false
      })
    } else {
      setInputDisabled({
        first_name: false,
        last_name: false
      })
    }
  }, [input])

  // Function that handles submitting updates/edits for user data
  const handleEdit = (data) => {
    setIsEditing(true);

    if (!inputDisabled.first_name && !inputDisabled.last_name) {
      setIsEditing(false)
      cogoToast.error(`No changes detected`, {
        hideAfter: 5,
      });
    } else {
      axios
        ({
          method: 'put',
          url: process.env.REACT_APP_API + `users/${id}`,
          headers: {
            Accept: 'application/json',
            Authorization: process.env.REACT_APP_HEROKU_SERVER_KEY
          }, data: {
            ...data
          }
        })
        .then((res) => {
          setIsEditing(false)
          setRefresh(!refresh);
          cogoToast.success(`User data updated successfully!`, {
            hideAfter: 5,
          });
        })
        .catch((err) => {
          console.log(err);
          setIsEditing(false)
          cogoToast.error(`There was an error editing the user data`, {
            hideAfter: 5,
          });
        });
      // Resets the form on submit (failed or not)
      reset();
    }
  };

  const handleInputChange = (event) => {
    setInput({ ...input, [event.target.name]: event.target.value });
  };

  return (
    <main class="flex-1 overflow-y-auto focus:outline-none" tabindex="0">
      <div class="relative max-w-4xl mx-auto md:px-8 xl:px-0">
        <div class="pt-10 pb-16">
          <div class="px-4 sm:px-6 md:px-0">
            <h1 class="text-3xl font-extrabold text-gray-900">User Settings</h1>
          </div>
          <div class="px-4 sm:px-6 md:px-0">
            <div class="py-6">
              {/* <!-- Tabs --> */}
              <div class="lg:hidden">
                <label for="selected-tab" class="sr-only">Select a tab</label>
                <select id="selected-tab" name="selected-tab" class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-purple-500 focus:border-purple-500 sm:text-sm rounded-md">
                  <option selected>General</option>
                </select>
              </div>
              <div class="hidden lg:block">
                <div class="border-b border-gray-200">
                  <nav class="-mb-px flex space-x-8">
                    {/* <!-- Current: "border-purple-500 text-purple-600", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" --> */}
                    <Link
                      to="/my-account/settings"
                      class={url.includes('settings') ?
                        "border-purple-500 text-purple-600 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm" :
                        "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"}
                    >
                      General
                    </Link>
                  </nav>
                </div>
              </div>

              {/* <!-- Description list with inline editing --> */}
              <div class="mt-10 divide-y divide-gray-200">
                <div class="space-y-1">
                  <h3 class="text-lg leading-6 font-medium text-gray-900">
                    Profile
                      </h3>
                  <p class="max-w-2xl text-sm text-gray-500">
                    This information will be displayed publicly so be careful what you share.
                  </p>
                </div>
                <div class="mt-6">
                  <dl class="divide-y divide-gray-200">
                    <form onSubmit={handleSubmit(handleEdit)}>
                      <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                        <dt class="text-sm font-medium self-center text-gray-500">
                          First Name
                        </dt>
                        <dd class="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                          <input
                            id='first_name'
                            type='text'
                            name='first_name'
                            onChange={handleInputChange}
                            class={inputDisabled.first_name ? "flex-grow rounded-md text-sm opacity-50" : "flex-grow rounded-md text-sm"}
                            disabled={inputDisabled.first_name ? true : false}
                            defaultValue={input.first_name}
                            ref={register}
                          />
                          <span class="ml-4 flex-shrink-0 self-center">
                            <button
                              disabled={inputDisabled.first_name ? true : false}
                              class={inputDisabled.first_name ? "text-opacity-50 bg-white rounded-md font-medium text-purple-600" : "bg-white rounded-md font-medium text-purple-600 hover:text-purple-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"}
                              type="submit"
                            >
                              {isEditing && !inputDisabled.first_name && (
                                <svg
                                  stroke="currentColor"
                                  fill="currentColor"
                                  strokeWidth="0"
                                  viewBox="0 0 1024 1024"
                                  className="w-5 h-5 mr-3 animate-spin"
                                  xmlns="http://www.w3.org/2000/svg">
                                  <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 0 0-94.3-139.9 437.71 437.71 0 0 0-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                                </svg>
                              )}
                              {isEditing && !inputDisabled.first_name ? null : 'Update'}
                            </button>
                          </span>
                        </dd>
                      </div>
                    </form>
                    <form onSubmit={handleSubmit(handleEdit)}>
                      <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                        <dt class="text-sm font-medium self-center text-gray-500">
                          Last Name
                        </dt>
                        <dd class="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                          <input
                            id='last_name'
                            type='text'
                            name='last_name'
                            onChange={handleInputChange}
                            class={inputDisabled.last_name ? "flex-grow rounded-md text-sm opacity-50" : "flex-grow rounded-md text-sm"}
                            disabled={inputDisabled.last_name ? true : false}
                            defaultValue={input?.last_name}
                            ref={register}
                          />
                          <span class="ml-4 flex-shrink-0 self-center">
                            <button
                              disabled={inputDisabled.last_name ? true : false}
                              class={inputDisabled.last_name ? "text-opacity-50 bg-white rounded-md font-medium text-purple-600" : "bg-white rounded-md font-medium text-purple-600 hover:text-purple-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"}
                              type="submit"
                            >
                              {isEditing && !inputDisabled.last_name && (
                                <svg
                                  stroke="currentColor"
                                  fill="currentColor"
                                  strokeWidth="0"
                                  viewBox="0 0 1024 1024"
                                  className="w-5 h-5 mr-3 animate-spin"
                                  xmlns="http://www.w3.org/2000/svg">
                                  <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 0 0-94.3-139.9 437.71 437.71 0 0 0-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                                </svg>
                              )}
                              {isEditing && !inputDisabled.last_name ? null : 'Update'}
                            </button>
                          </span>
                        </dd>
                      </div>
                    </form>
                    {/* <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:pt-5">
                      <dt class="text-sm font-medium text-gray-500">
                        Photo
                      </dt>
                      <dd class="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        <span class="flex-grow">
                          <img class="h-8 w-8 rounded-full" src="https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&ixqx=gbC7rAd1g3&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt="" />
                        </span>
                        <span class="ml-4 flex-shrink-0 flex items-start space-x-4">
                          <button type="button" class="bg-white rounded-md font-medium text-purple-600 hover:text-purple-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">
                            Update
                          </button>
                          <span class="text-gray-300" aria-hidden="true">|</span>
                          <button type="button" class="bg-white rounded-md font-medium text-purple-600 hover:text-purple-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">
                            Remove
                          </button>
                        </span>
                      </dd>
                    </div> */}
                    <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:pt-5">
                      <dt class="text-sm font-medium text-gray-500">
                        Email
                      </dt>
                      <dd class="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        <span class="flex-grow">{user?.email}</span>
                        <span class="ml-4 flex-shrink-0">
                          {/* <button type="button" class="bg-white rounded-md font-medium text-purple-600 hover:text-purple-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">
                            Update
                          </button> */}
                        </span>
                      </dd>
                    </div>
                  </dl>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main >
  );
};

export default UserTab;