import React from 'react';

// STATE
import { useRecoilValue } from 'recoil';
import { alphabetizedChunkState, collectionState } from '../../state/atoms';

// ROUTING
import { useParams, Link } from 'react-router-dom';
import { MemoizedChunkCard } from './ChunkCard';

// ----------------------------------------------------------------------------------
// -------------------------------- Collection Page ---------------------------------
// ----------------------------------------------------------------------------------

const CollectionPage = ({ cognitoUser, match }) => {
  // State
  const alphabetizedChunks = useRecoilValue(alphabetizedChunkState);
  const collections = useRecoilValue(collectionState)
  const params = useParams();
  const url = window.location.href; // GRABS REFERENCE TO THE CURRENT URL TO CHECK WHICH PLAN DATA TO LOAD
  const newChunks = alphabetizedChunks.filter((c) => {
    return c.collection_id === params.collectionId;
  });
  const currentCollection = collections.filter((c) => {
    return c.id === params.collectionId;
  });

  return (
    <>
      {/* ABOUT THE COLLECTION */}
      <div className='bg-green-700 px-5 py-3 mt-2 rounded-lg'>
        <h1 className='text-white text-lg font-bold'>
          {currentCollection[0]?.name}
        </h1>
        <h2 className='mb-5 text-mcgreen'>
          By {currentCollection[0]?.publisher_name}
        </h2>
        <p className='whitespace-pre-wrap text-white mb-5'>
          {currentCollection[0]?.description}
        </p>
        <a
          className='text-blue-400 flex-shrink-0 hover:text-mcgreen focus:outline-none'
          href={`${currentCollection[0]?.URL}`}
          target='_blank'
          rel='noopener noreferrer'
        >
          Check out their website
        </a>
      </div>

      {/* COLLECTION CARDS */}
      <div className='grid justify-center gap-10 mt-2 sm:grid-cols-2 md:grid-cols-2 xl:grid-cols-4 2xl:grid-cols-6 2xl:px-15'>
        {newChunks?.map((chunk) => {
          return (
            <div>
              {url.includes('ensembles') ? (
                <Link
                  to={`${match.url}/chunks/${chunk?.id}`}
                >
                  <MemoizedChunkCard
                    data={chunk}
                    cognitoUser={cognitoUser}
                    match={match}
                  />
                </Link>
              ) : (
                <Link
                  to={`${match.url}/chunks/${chunk?.id}`}
                >
                  <MemoizedChunkCard
                    data={chunk}
                    cognitoUser={cognitoUser}
                    match={match}
                  />
                </Link>
              )}
            </div>
          );
        })}
      </div>
    </>
  );
};
export default CollectionPage;
