import React, { useState } from 'react';
import { MessageList, MessageInput, Thread, Window, useChannelActionContext, Avatar, useChannelStateContext, useChatContext } from 'stream-chat-react';

// ICONS
import { ReactComponent as BackButton } from '../../assets/BackButton.svg';

// COMPONENTS
import { ThreadMessageInput } from '../TeamMessageInput/ThreadMessageInput';

export const GiphyContext = React.createContext({});

// ----------------------------------------------------------------------------------
// ---------------------------------- CHANNEL INNER ---------------------------------
// ----------------------------------------------------------------------------------

const ChannelInner = ({ setIsEditing, cognitoUser, groupAdmins, setToggleChannelContainer }) => {
    const [giphyState, setGiphyState] = useState(false);
    const { sendMessage } = useChannelActionContext();
    const { client, channel } = useChatContext();
    const isAdmin = groupAdmins.filter(groupAdmin => groupAdmin.id === cognitoUser.data.id).length > 0 ? true : false

    const overrideSubmitHandler = (message) => {
        let updatedMessage = {
            attachments: message.attachments,
            mentioned_users: message.mentioned_users,
            parent_id: message.parent?.id,
            parent: message.parent,
            text: message.text
        };

        if (giphyState) {
            updatedMessage = { ...updatedMessage, text: `/giphy ${message.text}` };
        }

        if (sendMessage) {
            sendMessage(updatedMessage);
            setGiphyState(false);
        }
    };

    const overrideThreadSubmitHandler = (message) => {
        let mentioned_user_ids = []

        message.mentioned_users.map(mentionedUser => {
            mentioned_user_ids.push(mentionedUser.id)
        })

        let updatedMessage = {
            attachments: message.attachments,
            mentioned_users: mentioned_user_ids,
            parent_id: message.parent?.id,
            parent: message.parent,
            text: message.text,
            silent: true // Silences thread messages / does not count them in unread messages
        };

        if (giphyState) {
            updatedMessage = { ...updatedMessage, text: `/giphy ${message.text}` };
        }

        if (sendMessage) {
            channel.sendMessage(updatedMessage);
            setGiphyState(false);
        }
    };

    const getMessageActions = () => {
        if (client.user.role === "admin") {
            return ["delete", "flag", "mute", "pin", "react", "reply"];
        }

        if (client.user.role === "user") {
            return ["delete", "edit", 'reply'];
        }
    };

    return (
        <GiphyContext.Provider value={{ giphyState, setGiphyState }}>
            <div className='flex w-full'>
                <Window>
                    <TeamChannelHeader setIsEditing={setIsEditing} isAdmin={isAdmin} setToggleChannelContainer={setToggleChannelContainer} />
                    <MessageList messageActions={getMessageActions()} />
                    {isAdmin ? (
                        <MessageInput grow overrideSubmitHandler={overrideSubmitHandler} />
                    ) : (
                        <div className='py-4 rounded-br-2xl bg-messagechatgray' />
                    )}
                </Window>
                <Thread additionalMessageInputProps={{ grow: true, Input: ThreadMessageInput, overrideSubmitHandler: overrideThreadSubmitHandler }} />
            </div>
        </GiphyContext.Provider>
    );
};

const TeamChannelHeader = ({ setIsEditing, isAdmin, setToggleChannelContainer }) => {
    const { channel, watcher_count } = useChannelStateContext();
    const { client } = useChatContext();

    const MessagingHeader = () => {
        const members = Object.values(channel.state.members).filter(({ user }) => user.id !== client.userID);
        const additionalMembers = members.length - 3;

        if (channel.type === 'messaging') {
            return (
                <div className='team-channel-header__name-wrapper'>
                    {members.map(({ user }, i) => (
                        <div key={i} className='team-channel-header__name-multi'>
                            <Avatar image={user.image} name={user.fullName || user.id} size={32} />
                            <p className='team-channel-header__name user'>{user.fullName || user.id}</p>
                        </div>
                    ))}

                    {additionalMembers > 0 && <p className='team-channel-header__name user'>and {additionalMembers} more</p>}
                </div>
            );
        }

        return (
            <div className='team-channel-header__channel-wrapper'>
                <div className='flex mr-4 md:hidden cursor-pointer' onClick={() => setToggleChannelContainer(true)}>
                    <BackButton />
                </div>
                <p className='team-channel-header__name'># {channel.data.name}</p>
                {isAdmin ? (
                    <span style={{ display: 'flex' }} onClick={() => setIsEditing(true)}>
                        <button className='text-xs py-1 px-2 bg-gray-500 rounded-md text-white hover:opacity-50'>
                            EDIT
                        </button>
                    </span>
                ) : null}
            </div>
        );
    };

    const getWatcherText = (watchers) => {
        if (!watchers) return 'No users online';
        if (watchers === 1) return '1 user online';
        return `${watchers} users online`;
    };

    return (
        <div className='team-channel-header__container'>
            <MessagingHeader />
            {channel.type === 'team' ? (
                <div className='hidden md:flex'>
                    <p className='team-channel-header__right-text'>{getWatcherText(watcher_count)}</p>
                </div>
            ) : null}
        </div>
    );
};

export default ChannelInner;