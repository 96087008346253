import React from 'react';

// CHAT API
import { ChannelList, useChatContext } from 'stream-chat-react';

// STYLING
import './ChannelListContainer.css';

// COMPONENTS
import { ChannelSearch, TeamChannelList, TeamChannelPreview } from '..';

// IMAGES
import MCLogoChannels from '../../../../img/MCLogo_Channels.png';

// ----------------------------------------------------------------------------------
// ----------------------------- CHANNEL LIST CONTAINER -----------------------------
// ----------------------------------------------------------------------------------

const CompanyHeader = ({ group }) => (
    <div className='channel-list__header'>
        <p className='channel-list__header__text'>{group?.name}</p>
    </div>
)

const customChannelTeamFilter = (channels) => {
    return channels.filter((channel) => channel.type === 'team' && window.location.href.includes(channel.data.team));
}

// const customChannelMessagingFilter = (channels) => {
//     return channels.filter((channel) => channel.type === 'messaging' && window.location.href.includes(channel.data.team));
// }

const ChannelListContent = ({ isCreating, setIsCreating, setCreateType, setIsEditing, setToggleContainer, setToggleChannelContainer, group, groupAdmins, cognitoUser }) => {
    const { client } = useChatContext();
    const filters = { members: { $in: [client.userID] } };

    return (
        <div className='flex flex-col w-full bg-chatcolor rounded-xl md:rounded-tr-none md:rounded-br-none h-screen overflow-x-hidden'>
            <CompanyHeader group={group} />
            <img className='h-32 w-32 self-center'
                src={MCLogoChannels}
                title='Music Chunks Channels is a communication tool for directors and students to share lessons and information within our platform. 
                Directors can create channels and add specific students to each channel. Students will only see the channels they are invited to while 
                all directors or admins can see all channels in the ensemble. The directors/admins post lessons or information in the main channel, while 
                students can only respond in the side threads of each post.'
            />
            <ChannelSearch setToggleContainer={setToggleContainer} setToggleChannelContainer={setToggleChannelContainer} />
            <ChannelList
                filters={filters}
                channelRenderFilterFn={customChannelTeamFilter}
                List={(listProps) => (
                    <TeamChannelList
                        {...listProps}
                        type='team'
                        isCreating={isCreating}
                        setIsCreating={setIsCreating}
                        setCreateType={setCreateType}
                        setIsEditing={setIsEditing}
                        setToggleContainer={setToggleContainer}
                        setToggleChannelContainer={setToggleChannelContainer}
                        cognitoUser={cognitoUser}
                        groupAdmins={groupAdmins}
                    />
                )}
                Preview={(previewProps) => (
                    <TeamChannelPreview
                        {...previewProps}
                        setIsCreating={setIsCreating}
                        setIsEditing={setIsEditing}
                        setToggleContainer={setToggleContainer}
                        type='team'
                    />
                )}
            />
            {/* <ChannelList
                filters={filters}
                channelRenderFilterFn={customChannelMessagingFilter}
                List={(listProps) => (
                    <TeamChannelList
                        {...listProps}
                        type='messaging'
                        isCreating={isCreating}
                        setIsCreating={setIsCreating}
                        setCreateType={setCreateType}
                        setIsEditing={setIsEditing}
                        setToggleContainer={setToggleContainer}
                        setToggleChannelContainer={setToggleChannelContainer}
                        cognitoUser={cognitoUser}
                        groupAdmins={groupAdmins}
                    />
                )}
                Preview={(previewProps) => (
                    <TeamChannelPreview
                        {...previewProps}
                        setIsCreating={setIsCreating}
                        setIsEditing={setIsEditing}
                        setToggleContainer={setToggleContainer}
                        type='messaging'
                    />
                )} */}
        </div>
    );
};

const ChannelListContainer = ({ setCreateType, setIsCreating, setIsEditing, group, groupAdmins, cognitoUser, toggleChannelContainer, setToggleChannelContainer }) => {
    return (
        <>
            <div className='hidden md:flex'>
                <ChannelListContent
                    setIsCreating={setIsCreating}
                    setCreateType={setCreateType}
                    setIsEditing={setIsEditing}
                    group={group}
                    cognitoUser={cognitoUser}
                    groupAdmins={groupAdmins}
                />
            </div>
            {toggleChannelContainer ? (
                <div className='flex md:hidden'>
                    <ChannelListContent
                        setIsCreating={setIsCreating}
                        setCreateType={setCreateType}
                        setIsEditing={setIsEditing}
                        group={group}
                        cognitoUser={cognitoUser}
                        groupAdmins={groupAdmins}
                        setToggleChannelContainer={setToggleChannelContainer}
                    />
                </div>
            ) : null}
        </>
    )
};

export default ChannelListContainer;