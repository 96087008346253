import React, { useEffect, useState, useMemo } from 'react';
import axios from 'axios';
import axiosRetry from 'axios-retry';

// REACT TABLE
import { useTable } from 'react-table'
import { CSVLink } from 'react-csv'

// DATE
import moment from 'moment';
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

// UUID
import { v4 as uuidv4 } from 'uuid';

// COMPONENTS
import { LoadingPage } from '../../../LoadingPage';

// ----------------------------------------------------------------------------------
// ------------------------------- BMI USAGE REPORT ---------------------------------
// ----------------------------------------------------------------------------------

const BMI_Usage_Report = () => {
  // State
  const [soundRecordings, setSoundRecordings] = useState([]);  // HOLDS A LIST OF SOUND RECORDINGS
  const [startDate, setStartDate] = useState(); // HOLDS THE START DATE FOR QUERYING CLICKS
  const [endDate, setEndDate] = useState(); // HOLDS THE END DATE FOR QUERYING CLICKS
  const [isQuarterlyReport, setIsQuarterlyReport] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const url = window.location.href; // GRABS REFERENCE TO THE CURRENT URL TO CHECK WHICH PLAN DATA TO LOAD
  let fileName = '' // HOLDS THE FILE NAME DEPENDING ON WHICH USAGE FORM IS BEING RENDERED

  console.log(soundRecordings)
  // Set axios to retry a set number of times on failed request/return
  axiosRetry(axios, { retries: 3 })

  // UseEffect to grab either yearly or quarterly state on initial load depending on URL match
  useEffect(() => {
    if (url.includes('quarterly')) {
      var first = new Date(new Date().getFullYear(), Math.floor((new Date().getMonth() / 3)) * 3, 1)
      var last = new Date(first.getFullYear(), first.getMonth() + 3, 0)

      setIsQuarterlyReport(true);
      setStartDate(first)
      setEndDate(last)
    } else if (url.includes('annual')) {
      setIsQuarterlyReport(false);
      setStartDate(new Date(new Date().getFullYear(), 0, 1))
      setEndDate(new Date(new Date().getFullYear(), 12, 0))
    }
  }, [url])

  // UseEffect to grab all soundrecordings with clicks
  useEffect(() => {
    setIsLoading(true);

    var currentMonthFirst = new Date(new Date().getFullYear(), Math.floor((new Date().getMonth() / 3)) * 3, 1)
    var currentMonthLast = new Date(currentMonthFirst.getFullYear(), currentMonthFirst.getMonth() + 3, 0)
    var currentMonth = false

    // Pull realtime data if the current monthly period is selected. Otherwise, pull archived data from clickRecords table
    if (moment(currentMonthFirst).format("MM-DD-YYYY") === moment(startDate).format("MM-DD-YYYY") && moment(currentMonthLast).format("MM-DD-YYYY") === moment(endDate).format("MM-DD-YYYY")) {
      currentMonth = true
    } else {
      currentMonth = false
    }

    if (startDate !== undefined || endDate !== undefined) {
      axios({
        method: 'get',
        url: currentMonth ?
          process.env.REACT_APP_API + `soundRecordings/withclickstotal/current/${moment(startDate).format("YYYY-MM-DD")}/${moment(endDate).format("YYYY-MM-DD")}` :
          process.env.REACT_APP_API + `soundRecordings/withclickstotal/${moment(startDate).format("YYYY-MM-DD")}/${moment(endDate).format("YYYY-MM-DD")}`,
        headers: {
          Accept: 'application/json',
          Authorization: process.env.REACT_APP_HEROKU_SERVER_KEY,
        }
      })
        .then((res) => {
          setSoundRecordings(res.data)
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err)
        })
    }
  }, [startDate, endDate])

  // Function to add dynamic key value pairs to sound recordings
  const newData = (oldData) => {
    let currentPlan = ''

    // Dynamically choose the plan name based on which form is being pulled
    if (url.includes('standard')) {
      currentPlan = 'Standard Plan'
      fileName = `BMI_USG_12345_v1.0_MusicChunks-StandardPlan_${moment(startDate).format("YYYY-MM-DD")}--${moment(endDate).format("YYYY-MM-DD")}_${Date.now()}.csv`
    } else if (url.includes('ensemble')) {
      fileName = `BMI_USG_12345_v1.0_MusicChunks-EnsemblePlan_${moment(startDate).format("YYYY-MM-DD")}--${moment(endDate).format("YYYY-MM-DD")}_${Date.now()}.csv`
    }

    return oldData.map((oldRecordings) => ({
      ...oldRecordings,
      Usage_Identifier: uuidv4(),
      Service_Configuration: currentPlan
    }))
  }

  const data = newData(soundRecordings); // Holds a list of soundrecordings with added dynamic data (MUST BE LABELED 'data')

  // Column headers and accessors linking to keys in data
  const columns = useMemo(
    () => [
      {
        Header: 'Report Period',
        accessor: d => {
          if (isQuarterlyReport) {
            if (moment(startDate).format('MM-DD-YYYY').includes('01-01') &&
              moment(endDate).format('MM-DD-YYYY').includes('03-31')) {
              return `QTR1-${moment(endDate).format('YYYY')}`
            } else if (moment(startDate).format('MM-DD-YYYY').includes('04-01') &&
              moment(endDate).format('MM-DD-YYYY').includes('06-30')) {
              return `QTR2-${moment(endDate).format('YYYY')}`
            } else if (moment(startDate).format('MM-DD-YYYY').includes('07-01') &&
              moment(endDate).format('MM-DD-YYYY').includes('09-30')) {
              return `QTR3-${moment(endDate).format('YYYY')}`
            } else if (moment(startDate).format('MM-DD-YYYY').includes('10-01') &&
              moment(endDate).format('MM-DD-YYYY').includes('12-31')) {
              return `QTR4-${moment(endDate).format('YYYY')}`
            }
          } else {
            return moment(endDate).format('YYYY')
          }
        }
      },
      {
        Header: 'Your Unique ID #',
        accessor: d => d.DSP_Recording_Identifier
      },
      {
        Header: 'Program Name',
        accessor: d => d.Recording_Title
      },
      {
        Header: 'Performance Count',
        accessor: d => {
          // Dynamically choose the which plan click to attach to the report
          if (url.includes('standard')) {
            return d.total_standard_clicks
          } else if (url.includes('ensemble')) {
            return d.total_ensemble_clicks
          }
        }
      },
      // {
      //   Header: 'Distributor',
      //   accessor: d => d.Distributor
      // },
      // {
      //   Header: 'DSP Name',
      //   accessor: d => d.DSP_Name
      // },
      // {
      //   Header: 'Storefront',
      //   accessor: d => d.Storefront
      // },
      // {
      //   Header: 'Service Configuration',
      //   accessor: d => d.Service_Configuration
      // },
      // {
      //   Header: 'Usage Identifier',
      //   accessor: d => d.Usage_Identifier
      // },
      // {
      //   Header: 'DSP Recording Identifier',
      //   accessor: d => d.DSP_Recording_Identifier
      // },
      // {
      //   Header: 'ISRC',
      //   accessor: d => d.ISRC
      // },
      // {
      //   Header: 'Recording Version Title',
      //   accessor: d => d.Recording_Version_Title
      // },
      // {
      //   Header: 'Recording Alternative Titles',
      //   accessor: d => {
      //     if (d.Recording_Alternative_Titles) {
      //       const piped = d.Recording_Alternative_Titles.replace('\n', '|')
      //       return piped
      //     } else {
      //       return ''
      //     }
      //   }
      // },
      // {
      //   Header: 'Recording Artist',
      //   accessor: d => d.Recording_Artist
      // },
      // {
      //   Header: 'Run Time Duration',
      //   accessor: d => d.Recording_Playing_Time
      // },
      // {
      //   Header: 'Recording Date First Utilized',
      //   accessor: d => {
      //     if (d.Recording_Date_First_Utilized) {
      //       return moment(d.Recording_Date_First_Utilized).format("YYYY-MM-DD")
      //     } else {
      //       return ''
      //     }
      //   }
      // },
      // {
      //   Header: 'Recording Studio Producer',
      //   accessor: d => d.Recording_Studio_Producer
      // },
      // {
      //   Header: 'ICPN',
      //   accessor: d => d.ICPN
      // },
      // {
      //   Header: 'Catalog Number',
      //   accessor: d => d.Catalog_Number
      // },
      // {
      //   Header: 'Product Distributor Identifier',
      //   accessor: d => d.Product_Distributor_Identifier
      // },
      // {
      //   Header: 'Product Title',
      //   accessor: d => d.Product_Title
      // },
      // {
      //   Header: 'Air Date',
      //   accessor: d => {
      //     if (d.Release_Date) {
      //       return moment(d.Release_Date).format("YYYY-MM-DD")
      //     } else {
      //       return ''
      //     }
      //   }
      // },
      // {
      //   Header: 'PLine',
      //   accessor: d => d.PLine
      // },
      // {
      //   Header: 'Label Name',
      //   accessor: d => d.Label_Name
      // },
      // {
      //   Header: 'ISWC',
      //   accessor: d => d.ISWC
      // },
      // {
      //   Header: 'Work Title',
      //   accessor: d => d.Work_Title
      // },
      // {
      //   Header: 'Work Alternative Titles',
      //   accessor: d => {
      //     if (d.Work_Alternative_Titles) {
      //       const piped = d.Work_Alternative_Titles.replace('\n', '|')
      //       return piped
      //     } else {
      //       return ''
      //     }
      //   }
      // },
      // {
      //   Header: 'Writers',
      //   accessor: d => {
      //     if (d.Writers) {
      //       const piped = d.Writers.replace('\n', '|')
      //       return piped
      //     } else {
      //       return ''
      //     }
      //   }
      // },
      // {
      //   Header: 'Writer IPI',
      //   accessor: d => {
      //     if (d.Writer_IPI) {
      //       const piped = d.Writer_IPI.replace('\n', '|')
      //       return piped
      //     } else {
      //       return ''
      //     }
      //   }
      // },
      // {
      //   Header: 'Writer ISNI',
      //   accessor: d => {
      //     if (d.Writer_ISNI) {
      //       const piped = d.Writer_ISNI.replace('\n', '|')
      //       return piped
      //     } else {
      //       return ''
      //     }
      //   }
      // },
      // {
      //   Header: 'Believed Public Domain',
      //   accessor: d => d.Believed_Public_Domain.toString()
      // },
    ],
    [startDate, endDate]
  )

  // Table structure
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable({ columns, data })

  // Converts our db data to the correct format for form submission
  const currentData = useTable({ columns, data })
  const reportData = []
  currentData.rows.map(row => {
    reportData.push(row.values)
  })

  return (
    <>
      {isQuarterlyReport ? (
        <div className='mb-3 mt-3 text-center'>
          <dt className='text-sm text-center font-medium leading-5 text-gray-500'>
            Usage Quarter
          </dt>
          <DatePicker
            className='block w-full mt-2 transition duration-150 ease-in-out form-textarea sm:text-sm sm:leading-5'
            selected={startDate}
            onChange={date => {
              setStartDate(date)
              setEndDate(new Date(date.getFullYear(), date.getMonth() + 3, 0))
            }}
            dateFormat='yyyy, QQQ'
            showQuarterYearPicker
          />
        </div>
      ) : (
        <div className='mb-3 mt-3 text-center'>
          <dt className='text-sm text-center font-medium leading-5 text-gray-500'>
            Usage Annual
          </dt>
          <DatePicker
            className='block w-full mt-2 transition duration-150 ease-in-out form-textarea sm:text-sm sm:leading-5'
            selected={startDate}
            onChange={date => {
              setStartDate(date)
              setEndDate(new Date(date.getFullYear(), 12, 0))
            }}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            dateFormat='yyyy'
            showYearPicker
          />
        </div>
      )}

      <div className='mb-3 mt-3 text-center'>
        <p>{moment(startDate).format('MM-DD-YYYY')} - {moment(endDate).format('MM-DD-YYYY')}</p>
      </div>

      <div className='mb-3 text-center'>
        <CSVLink
          className='inline-flex items-center px-4 py-2 text-sm font-medium leading-5 text-white transition duration-150 ease-in-out bg-purple-600 border border-transparent rounded-md hover:bg-purple-500 focus:outline-none focus:shadow-outline-purple focus:border-purple-700 active:bg-indigo-700'
          columns={columns}
          data={reportData}
          filename={fileName}
        >
          Export Report
        </CSVLink>
      </div>

      {isLoading ? (
        <LoadingPage />
      ) : (
        <div>
          {/* REACT TABLE */}
          <table {...getTableProps()} style={{ border: 'solid 1px blue' }}>
            <thead>
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <th
                      {...column.getHeaderProps()}
                      style={{
                        borderBottom: 'solid 3px red',
                        background: 'aliceblue',
                        color: 'black',
                        fontWeight: 'bold',
                      }}
                    >
                      {column.render('Header')}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map(row => {
                prepareRow(row)
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map(cell => {
                      return (
                        <td
                          {...cell.getCellProps()}
                          style={{
                            padding: '10px',
                            border: 'solid 1px gray',
                            background: 'papayawhip',
                          }}
                        >
                          {cell.render('Cell')}
                        </td>
                      )
                    })}
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      )}
    </>
  )
}

export default BMI_Usage_Report;