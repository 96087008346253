import React, { useState, useEffect } from 'react';
import axios from 'axios';

// ROUTING
import { useParams } from 'react-router-dom';

// COMPONENTS
import UserData from '../users/UserData';

// ----------------------------------------------------------------------------------
// ---------------------------- PUBLISHER ADMIN TABLE -------------------------------
// ----------------------------------------------------------------------------------

const PublisherAdminTable = () => {
  // State
  const [publisherAdmins, setPublisherAdmins] = useState([]);  // SAVES LIST OF ALL PUBLISHER ADMINS
  const { pubID } = useParams();

  // Useffect to grab all of a publisher's admins
  useEffect(() => {
    axios({
      method: 'get',
      url: process.env.REACT_APP_API + `publishers/${pubID}/alladmins`,
      headers: {
        Accept: 'application/json',
        Authorization: process.env.REACT_APP_HEROKU_SERVER_KEY,
      }
    })
      .then((res) => {
        setPublisherAdmins(res.data)
      })
  }, [])

  return (
    <div className='py-4 '>
      {publisherAdmins?.map((data) => (
        <UserData key={data?.id} data={data} />
      ))}
    </div>
  );
};

export default PublisherAdminTable;