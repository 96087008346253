import React from 'react';

// ----------------------------------------------------------------------------------
// -------------------------- PUBLISHER ADMIN FORM DATA -----------------------------
// ----------------------------------------------------------------------------------

const PublisherAdminFormData = () => {
  return (
    <div class="mt-10 divide-y divide-gray-200">
      <div class="space-y-1 flex justify-between">
        <h3 class="text-lg self-center leading-6 font-medium text-gray-900">
          Forms
        </h3>
      </div>

      {/* PUBLISHER DATA WHEN DROPDOWN SELECTED */}
      <div class="mt-6">
        <dl class="divide-y divide-gray-200">
          <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
            <dt class="text-sm font-medium self-center text-gray-500">
              Chunks
            </dt>
            <dd class="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              <p class="flex-grow rounded-md text-sm">
                Submit Chunks for Uploading
              </p>
              <span class="ml-4 flex-shrink-0 self-center">
                <a
                  href='https://forms.monday.com/forms/83e8742d1d24abbd7df6fabe420beb98'
                  class="bg-white rounded-md font-medium text-purple-600 hover:text-purple-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                  target='_blank'
                >
                  Upload
                </a>
              </span>
            </dd>
          </div>

          <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-b sm:border-gray-200">
            <dt class="text-sm font-medium self-center text-gray-500">
              Ensemble
            </dt>
            <dd class="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              <p class="flex-grow rounded-md text-sm">
                Set Up a Private Ensemble
              </p>
              <span class="ml-4 flex-shrink-0 self-center">
                <a
                  href='https://forms.monday.com/forms/5f11f8fc03d9eadd954db240e52ed0df'
                  class="bg-white rounded-md font-medium text-purple-600 hover:text-purple-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                  target='_blank'
                >
                  Upload
                </a>
              </span>
            </dd>
          </div>
        </dl>
      </div>
    </div>
  );
};

export default PublisherAdminFormData;