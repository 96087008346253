import React from 'react';

// ROUTING
import { useHistory } from 'react-router-dom';

// STREAM CHAT
import { StreamChat } from 'stream-chat';

// STATE
import { useRecoilValue } from 'recoil';
import { cogUserState } from '../../state/atoms';

// AUTH
import { Auth } from '@aws-amplify/auth';

const client = StreamChat.getInstance(process.env.REACT_APP_STREAM_API_KEY)

// ----------------------------------------------------------------------------------
// ------------------------------ PAYMENT ON EXPIRATION -----------------------------
// ----------------------------------------------------------------------------------

const PaymentOnExpiration = () => {
  // React Router
  const history = useHistory();

  // Grab the cognito user ID from localstorage and link to the user in our backend database 
  const cognitoUser = useRecoilValue(cogUserState(localStorage.getItem(process.env.REACT_APP_USER_ID)))   // Get user from our DB

  // Function that links a user to their payment portal
  const redirectToManage = (e) => {
    fetch(process.env.REACT_APP_PAYMENT_PORTAL, {
      method: 'POST',
      body: cognitoUser.data.cognito_id
    })
      .then((res) => res.json())
      // .then((data => console.log(data))) // Uncomment when testing to check the response
      .then((link) => (window.location.href = link))
      .catch((err) => console.log(err));
  };

  // Function for handling logout
  let logout = () => {
    Auth.signOut()
    client.disconnectUser();
    localStorage.clear()
    history.push('/')
  };

  return (
    <div className="flex flex-col items-center justify-center w-full h-screen space-y-6 overflow-hidden bg-fixed bg-cover bg-hero-pattern">
      <div className="flex flex-col justify-center space-y-3 text-center">
        <h1 className="text-6xl text-mcgreen">Your subscription has expired!</h1>
        <h3 className="text-3xl font-bold text-white">
          {' '}
          Resubscribe to gain full access to MusicChunks
        </h3>
        {/* <p className="max-w-2xl text-xl text-gray-500">
          Trial includes EVERYTHING we have to offer. Note you can cancel this
          trial anytime before {moment().add(7, 'days').calendar()} and will not
          be charged. Exceeding this time frame confirms your monthly
          subscription.
        </p> */}
      </div>

      <div className=" flex justify-center w-full max-w-3xl mx-auto">
        <button
          type="button"
          onClick={redirectToManage}
          className={'flex items-center rounded-lg text-2xl px-5 py-3 mr-15 text-center font-medium text-mcgreen bg-mcbuttondarkgreen hover:bg-mcgreen hover:text-mcbuttondarkgreen focus:ring transition duration-150 ease-in-out'}
        >
          Subscribe
        </button>
        <button
          type="button"
          onClick={() => logout()}
          className={'flex items-center rounded-lg text-2xl ml-5 px-5 py-3 text-center font-medium text-mcgreen bg-mcbuttondarkgreen hover:bg-mcgreen hover:text-mcbuttondarkgreen focus:ring transition duration-150 ease-in-out'}
        >
          Log out
        </button>
      </div>
    </div>
  );
};

export default PaymentOnExpiration;