import React from 'react';

// ROUTING
import { useRouteMatch } from 'react-router-dom';

// STATE
import { useRecoilValue } from 'recoil';
import { adminGroupFilter, groupData } from '../../../state/atoms';

// COMPONENTS
import GroupData from './GroupData';

// ----------------------------------------------------------------------------------
// ---------------------------------- GROUP TABLE -----------------------------------
// ----------------------------------------------------------------------------------

const GroupTable = () => {
  // State
  const route = useRouteMatch();
  const groups = useRecoilValue(route.params?.pubID ?
    adminGroupFilter(route.params?.pubID) : groupData
  );

  return (
    <div className='py-4 '>
      {groups.map((data) => (
        <GroupData key={data.id} data={data} />
      ))}
    </div>
  );
};

export default GroupTable;