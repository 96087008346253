import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import axiosRetry from 'axios-retry';
import { v4 as uuidv4 } from 'uuid';
import S3 from 'react-aws-s3';

// FORM MANAGEMENT
import { useForm } from 'react-hook-form';

// TOAST
import cogoToast from 'cogo-toast';

// ----------------------------------------------------------------------------------
// --------------------------- PUBLISHER ADMIN DATA ---------------------------------
// ----------------------------------------------------------------------------------

const PublisherAdminData = ({ data, refresh, setRefresh, open, setOpen, closed, setClosed }) => {
  const {
    id,
    name,
    bio,
    profile_pic,
    banner_pic,
    publisher_URL,
    facebook_URL,
    twitter_URL,
    youtube_URL,
    instagram_URL,
    twitch_URL,
    tiktok_URL
  } = data;

  // State
  const [isEditing, setIsEditing] = useState(false);
  const [loadingImg, setLoadingImg] = useState(false);
  const [loadingImg2, setLoadingImg2] = useState(false);
  const [image, setImage] = useState('');
  const [image2, setImage2] = useState('');
  const [input, setInput] = useState({
    name: name,
    bio: bio,
    publisher_URL: publisher_URL,
    facebook_URL: facebook_URL,
    twitter_URL: twitter_URL,
    youtube_URL: youtube_URL,
    instagram_URL: instagram_URL,
    twitch_URL: twitch_URL,
    tiktok_URL: tiktok_URL,
    profile_pic: profile_pic,
    banner_pic: banner_pic
  })
  const [inputDisabled, setInputDisabled] = useState({
    name: false,
    bio: false,
    publisher_URL: false,
    facebook_URL: false,
    twitter_URL: false,
    youtube_URL: false,
    instagram_URL: false,
    twitch_URL: false,
    tiktok_URL: false,
    profile_pic: false,
    banner_pic: false
  })

  // Form
  const { register, handleSubmit, errors, reset } = useForm({
    mode: 'onChange',
  });

  // Set axios to retry a set number of times on failed request/return
  axiosRetry(axios, { retries: 3 })

  // Reset input to updated data
  useEffect(() => {
    setInput({
      name: name,
      bio: bio,
      publisher_URL: publisher_URL,
      facebook_URL: facebook_URL,
      twitter_URL: twitter_URL,
      youtube_URL: youtube_URL,
      instagram_URL: instagram_URL,
      twitch_URL: twitch_URL,
      tiktok_URL: tiktok_URL,
      profile_pic: profile_pic,
      banner_pic: banner_pic
    })
  }, [data])

  // Toggle which inputs should be disabled depending on what is being typed in
  useEffect(() => {
    if (input?.name !== name) {
      setInputDisabled({
        name: false, bio: true, publisher_URL: true,
        facebook_URL: true, twitter_URL: true, youtube_URL: true,
        instagram_URL: true, twitch_URL: true, tiktok_URL: true,
        profile_pic: true, banner_pic: true
      })
    } else if (input?.bio !== bio) {
      setInputDisabled({
        name: true, bio: false, publisher_URL: true,
        facebook_URL: true, twitter_URL: true, youtube_URL: true,
        instagram_URL: true, twitch_URL: true, tiktok_URL: true,
        profile_pic: true, banner_pic: true
      })
    } else if (input?.publisher_URL !== publisher_URL) {
      setInputDisabled({
        name: true, bio: true, publisher_URL: false,
        facebook_URL: true, twitter_URL: true, youtube_URL: true,
        instagram_URL: true, twitch_URL: true, tiktok_URL: true,
        profile_pic: true, banner_pic: true
      })
    } else if (input?.facebook_URL !== facebook_URL) {
      setInputDisabled({
        name: true, bio: true, publisher_URL: true,
        facebook_URL: false, twitter_URL: true, youtube_URL: true,
        instagram_URL: true, twitch_URL: true, tiktok_URL: true,
        profile_pic: true, banner_pic: true
      })
    } else if (input?.twitter_URL !== twitter_URL) {
      setInputDisabled({
        name: true, bio: true, publisher_URL: true,
        facebook_URL: true, twitter_URL: false, youtube_URL: true,
        instagram_URL: true, twitch_URL: true, tiktok_URL: true,
        profile_pic: true, banner_pic: true
      })
    } else if (input?.youtube_URL !== youtube_URL) {
      setInputDisabled({
        name: true, bio: true, publisher_URL: true,
        facebook_URL: true, twitter_URL: true, youtube_URL: false,
        instagram_URL: true, twitch_URL: true, tiktok_URL: true,
        profile_pic: true, banner_pic: true
      })
    } else if (input?.instagram_URL !== instagram_URL) {
      setInputDisabled({
        name: true, bio: true, publisher_URL: true,
        facebook_URL: true, twitter_URL: true, youtube_URL: true,
        instagram_URL: false, twitch_URL: true, tiktok_URL: true,
        profile_pic: true, banner_pic: true
      })
    } else if (input?.twitch_URL !== twitch_URL) {
      setInputDisabled({
        name: true, bio: true, publisher_URL: true,
        facebook_URL: true, twitter_URL: true, youtube_URL: true,
        instagram_URL: true, twitch_URL: false, tiktok_URL: true,
        profile_pic: true, banner_pic: true
      })
    } else if (input?.tiktok_URL !== tiktok_URL) {
      setInputDisabled({
        name: true, bio: true, publisher_URL: true,
        facebook_URL: true, twitter_URL: true, youtube_URL: true,
        instagram_URL: true, twitch_URL: true, tiktok_URL: false,
        profile_pic: true, banner_pic: true
      })
    } else if (profile_pic !== image) {
      setInputDisabled({
        name: true, bio: true, publisher_URL: true,
        facebook_URL: true, twitter_URL: true, youtube_URL: true,
        instagram_URL: true, twitch_URL: true, tiktok_URL: true,
        profile_pic: false, banner_pic: true
      })
    } else if (banner_pic !== image2) {
      setInputDisabled({
        name: true, bio: true, publisher_URL: true,
        facebook_URL: true, twitter_URL: true, youtube_URL: true,
        instagram_URL: true, twitch_URL: true, tiktok_URL: true,
        profile_pic: true, banner_pic: false
      })
    } else {
      setInputDisabled({
        name: false, bio: false, publisher_URL: false,
        facebook_URL: false, twitter_URL: false, youtube_URL: false,
        instagram_URL: false, twitch_URL: false, tiktok_URL: false,
        profile_pic: false, banner_pic: false
      })
    }
  }, [input, image, image2])

  // Function that handles submitting updates/edits for publisher data
  const handleEdit = (data) => {
    setIsEditing(true);

    if (!inputDisabled.name && !inputDisabled.bio && !inputDisabled.publisher_URL
      && !inputDisabled.facebook_URL && !inputDisabled.twitter_URL && !inputDisabled.youtube_URL
      && !inputDisabled.instagram_URL && !inputDisabled.twitch_URL
      && !inputDisabled.profile_pic) {
      setIsEditing(false)
      cogoToast.error(`No changes detected`, {
        hideAfter: 5,
      });
    } else {
      axios
        ({
          method: 'put',
          url: process.env.REACT_APP_API + `publishers/${id}`,
          headers: {
            Accept: 'application/json',
            Authorization: process.env.REACT_APP_HEROKU_SERVER_KEY
          }, data: {
            ...data,
            profile_pic: image,
            banner_pic: image2
          }
        })
        .then((res) => {
          setIsEditing(false)
          setRefresh(!refresh);
          cogoToast.success(`Publisher data updated successfully!`, {
            hideAfter: 5,
          });
        })
        .catch((err) => {
          console.log(err);
          setIsEditing(false)
          cogoToast.error(`There was an error editing the publisher data`, {
            hideAfter: 5,
          });
        });
      // Resets the form on submit (failed or not)
      reset();
    }
  };

  const handleInputChange = (event) => {
    setInput({ ...input, [event.target.name]: event.target.value });
  };

  // UseEffect that sets profile and banner pics for publishers
  useEffect(() => {
    setImage(profile_pic);
    setImage2(banner_pic);
  }, [profile_pic, banner_pic]);

  // Function that adds publisher profile images to our Amazon Web Services S3 bucket
  const fileInput = useRef();

  const uploadImg = async (e) => {
    e.preventDefault();
    setLoadingImg(true);

    let file = fileInput.current.files[0]

    // Prevents canceling out of file selection from crashing site
    if (file) {
      let newFileName = fileInput.current.files[0].name
      let newFileNameWithUUID = 'publishers/' + newFileName.substring(0, newFileName.lastIndexOf('.')) + '_' + uuidv4();

      const config = {
        bucketName: process.env.REACT_APP_BUCKET_NAME,
        region: process.env.REACT_APP_REGION,
        accessKeyId: process.env.REACT_APP_ACCESS_ID,
        secretAccessKey: process.env.REACT_APP_ACCESS_KEY,
      };

      const ReactS3Client = new S3(config);
      ReactS3Client.uploadFile(file, newFileNameWithUUID).then(data => {
        if (data.status === 204) {
          const imageBucketURL = data.location
          setImage(imageBucketURL);
          setLoadingImg(false);
          console.log('Image successfully uploaded to S3!');
          return data
        } else {
          console.log('Image failed upload to S3');
        }
      })
    } else {
      setLoadingImg(false)
    }
  };

  // Function that adds publisher banner images to our Amazon Web Services S3 bucket
  const fileInput2 = useRef();

  const uploadImg2 = async (e) => {
    e.preventDefault();
    setLoadingImg2(true);

    let file = fileInput2.current.files[0]

    // Prevents canceling out of file selection from crashing site
    if (file) {
      let newFileName = fileInput2.current.files[0].name
      let newFileNameWithUUID = 'publishers/' + newFileName.substring(0, newFileName.lastIndexOf('.')) + '_' + uuidv4();

      const config = {
        bucketName: process.env.REACT_APP_BUCKET_NAME,
        region: process.env.REACT_APP_REGION,
        accessKeyId: process.env.REACT_APP_ACCESS_ID,
        secretAccessKey: process.env.REACT_APP_ACCESS_KEY,
      };

      const ReactS3Client = new S3(config);
      ReactS3Client.uploadFile(file, newFileNameWithUUID).then(data => {
        if (data.status === 204) {
          const imageBucketURL = data.location
          setImage2(imageBucketURL);
          setLoadingImg2(false);
          console.log('Image successfully uploaded to S3!');
          return data
        } else {
          console.log('Image failed upload to S3');
        }
      })
    } else {
      setLoadingImg2(false);
    }
  };

  // Toggles whether data is being displayed for specific publishers
  const toggleOpen = (id) => {
    let openObject = {}
    let closedObject = {}

    Object.keys(open).map(function (key, index) {
      if (key === id && closed[key] === true) {
        openObject[key] = true
        closedObject[key] = false
      } else {
        openObject[key] = false
        closedObject[key] = true
      }
    })
    setOpen(openObject)
    setClosed(closedObject)
  };

  // Two functions for handling custom built buttons for adding profile and banner images
  const handleProfileImageClick = () => {
    fileInput.current.click();
  };
  const handleBannerImageClick = () => {
    fileInput2.current.click();
  };

  return (
    <div class="mt-10">
      <div class="space-y-1 flex justify-between">
        <h3 class="text-lg self-center leading-6 font-medium text-gray-900">
          {name}
        </h3>
        {/* <p class="max-w-2xl text-sm text-gray-500">
        This information will be displayed publicly so be careful what you share.
        </p> */}
        <div className={'self-center'}>
          {name ?
            <button
              onClick={() => toggleOpen(id)}
              id='menu dropdown '
              aria-haspopup='true'
              className='inline-flex items-center justify-center w-8 h-8 text-gray-400 transition duration-150 ease-in-out bg-transparent rounded-full hover:text-gray-500 focus:outline-none'
            >
              <svg
                className={`${open[id] ? '-rotate-180' : 'rotate-0'
                  } h-6 w-6 transform`}
                fill='none'
                viewBox='0 0 24 24'
                stroke='currentColor'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth='2'
                  d='M19 9l-7 7-7-7'
                />
              </svg>
            </button> : null}
        </div>
      </div>

      {/* PUBLISHER DATA WHEN DROPDOWN SELECTED */}
      {open[id] ? (
        <div class="mt-6">
          <dl class="">
            <form onSubmit={handleSubmit(handleEdit)}>
              <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-b sm:border-gray-200">
                <dt class="text-sm font-medium self-center text-gray-500">
                  Name
                </dt>
                <dd class="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  <input
                    id='name'
                    type='text'
                    name='name'
                    onChange={handleInputChange}
                    class={inputDisabled.name ? "flex-grow rounded-md text-sm opacity-50" : "flex-grow rounded-md text-sm"}
                    disabled={inputDisabled.name ? true : false}
                    defaultValue={input.name}
                    ref={register({ required: true })}
                  />
                  <span class="ml-4 flex-shrink-0 self-center">
                    {errors.name ? (
                      <button
                        disabled={true}
                        class={"text-opacity-50 bg-white rounded-md font-medium text-red-600"}
                        type="submit"
                      >
                        Required
                      </button>
                    ) : (
                      <button
                        disabled={inputDisabled.name ? true : false}
                        class={inputDisabled.name ? "text-opacity-50 bg-white rounded-md font-medium text-purple-600" : "bg-white rounded-md font-medium text-purple-600 hover:text-purple-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"}
                        type="submit"
                      >
                        {isEditing && !inputDisabled.name && (
                          <svg
                            stroke="currentColor"
                            fill="currentColor"
                            strokeWidth="0"
                            viewBox="0 0 1024 1024"
                            className="w-5 h-5 mr-3 animate-spin"
                            xmlns="http://www.w3.org/2000/svg">
                            <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 0 0-94.3-139.9 437.71 437.71 0 0 0-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                          </svg>
                        )}
                        {isEditing && !inputDisabled.name ? null : 'Update'}
                      </button>
                    )}
                  </span>
                </dd>
              </div>

              <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:pt-5 sm:border-b sm:border-gray-200">
                <dt class="text-sm font-medium text-gray-500 self-center">
                  Profile Picture
                </dt>
                <dd class="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  <span class="flex-grow">
                    {profile_pic ? (
                      <img class="h-14 w-14 rounded-lg" src={profile_pic} alt="" />
                    ) : (
                      <dt class="text-sm font-medium self-center text-gray-500">
                        No Profile Provided
                      </dt>
                    )}
                  </span>
                  <span class="ml-4 flex-shrink-0 flex items-start space-x-4">
                    <input
                      type='file'
                      name='file'
                      class='hidden'
                      disabled={inputDisabled.profile_pic ? true : false}
                      ref={fileInput}
                      onChange={uploadImg}
                    />
                    <button
                      type='button'
                      onClick={handleProfileImageClick}
                      disabled={inputDisabled.profile_pic ? true : false}
                      class={inputDisabled.profile_pic ? "bg-white self-center rounded-md font-medium text-purple-600 hover:text-purple-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 opacity-50" : "bg-white self-center rounded-md font-medium text-purple-600 hover:text-purple-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"}
                    >
                      {loadingImg && !inputDisabled.profile_pic && (
                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          strokeWidth="0"
                          viewBox="0 0 1024 1024"
                          className="w-5 h-5 mr-3 animate-spin"
                          xmlns="http://www.w3.org/2000/svg">
                          <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 0 0-94.3-139.9 437.71 437.71 0 0 0-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                        </svg>
                      )}
                      {loadingImg && !inputDisabled.profile_pic ? null : profile_pic !== image ? 'File Ready for Upload' : 'Choose File'}
                    </button>
                    <span class="text-gray-300 self-center" aria-hidden="true">|</span>
                    <button
                      type="submit"
                      class={inputDisabled.profile_pic ? "bg-white self-center rounded-md font-medium text-purple-600 hover:text-purple-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 opacity-50" : "bg-white self-center rounded-md font-medium text-purple-600 hover:text-purple-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"}
                      disabled={inputDisabled.profile_pic ? true : false}
                    >
                      {isEditing && !inputDisabled.profile_pic && (
                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          strokeWidth="0"
                          viewBox="0 0 1024 1024"
                          className="w-5 h-5 mr-3 animate-spin"
                          xmlns="http://www.w3.org/2000/svg">
                          <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 0 0-94.3-139.9 437.71 437.71 0 0 0-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                        </svg>
                      )}
                      {isEditing && !inputDisabled.profile_pic ? null : 'Update'}
                    </button>
                  </span>
                </dd>
              </div>

              <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:pt-5 sm:border-b sm:border-gray-200">
                <dt class="text-sm font-medium text-gray-500 self-center">
                  Banner Picture
                </dt>
                <dd class="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  <span class="flex-grow">
                    {banner_pic ? (
                      <img class="h-30 w-100 rounded-lg" src={banner_pic} alt="" />
                    ) : (
                      <dt class="text-sm font-medium self-center text-gray-500">
                        No Banner Provided
                      </dt>
                    )}
                  </span>
                  <span class="ml-4 flex-shrink-0 flex items-start space-x-4">
                    <input
                      type='file'
                      name='file'
                      class='hidden'
                      disabled={inputDisabled.banner_pic ? true : false}
                      ref={fileInput2}
                      onChange={uploadImg2}
                    />
                    <button
                      type='button'
                      onClick={handleBannerImageClick}
                      disabled={inputDisabled.banner_pic ? true : false}
                      class={inputDisabled.banner_pic ? "bg-white self-center rounded-md font-medium text-purple-600 hover:text-purple-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 opacity-50" : "bg-white self-center rounded-md font-medium text-purple-600 hover:text-purple-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"}
                    >
                      {loadingImg2 && !inputDisabled.banner_pic && (
                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          strokeWidth="0"
                          viewBox="0 0 1024 1024"
                          className="w-5 h-5 mr-3 animate-spin"
                          xmlns="http://www.w3.org/2000/svg">
                          <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 0 0-94.3-139.9 437.71 437.71 0 0 0-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                        </svg>
                      )}
                      {loadingImg2 && !inputDisabled.banner_pic ? null : banner_pic !== image2 ? 'File Ready for Upload' : 'Choose File'}
                    </button>
                    <span class="text-gray-300 self-center" aria-hidden="true">|</span>
                    <button
                      type="submit"
                      class={inputDisabled.banner_pic ? "bg-white self-center rounded-md font-medium text-purple-600 hover:text-purple-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 opacity-50" : "bg-white self-center rounded-md font-medium text-purple-600 hover:text-purple-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"}
                      disabled={inputDisabled.banner_pic ? true : false}
                    >
                      {isEditing && !inputDisabled.banner_pic && (
                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          strokeWidth="0"
                          viewBox="0 0 1024 1024"
                          className="w-5 h-5 mr-3 animate-spin"
                          xmlns="http://www.w3.org/2000/svg">
                          <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 0 0-94.3-139.9 437.71 437.71 0 0 0-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                        </svg>
                      )}
                      {isEditing && !inputDisabled.banner_pic ? null : 'Update'}
                    </button>
                  </span>
                </dd>
              </div>

              <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-b sm:border-gray-200">
                <dt class="text-sm font-medium self-center text-gray-500">
                  Bio
                </dt>
                <dd class="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  <textarea
                    id='bio'
                    type='text'
                    name='bio'
                    rows='5'
                    onChange={handleInputChange}
                    class={inputDisabled.bio ? "flex-grow rounded-md text-sm opacity-50" : "flex-grow rounded-md text-sm"}
                    disabled={inputDisabled.bio ? true : false}
                    defaultValue={input.bio}
                    ref={register}
                  />
                  <span class="ml-4 flex-shrink-0 self-center">
                    <button
                      disabled={inputDisabled.bio ? true : false}
                      class={inputDisabled.bio ? "text-opacity-50 bg-white rounded-md font-medium text-purple-600" : "bg-white rounded-md font-medium text-purple-600 hover:text-purple-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"}
                      type="submit"
                    >
                      {isEditing && !inputDisabled.bio && (
                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          strokeWidth="0"
                          viewBox="0 0 1024 1024"
                          className="w-5 h-5 mr-3 animate-spin"
                          xmlns="http://www.w3.org/2000/svg">
                          <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 0 0-94.3-139.9 437.71 437.71 0 0 0-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                        </svg>
                      )}
                      {isEditing && !inputDisabled.bio ? null : 'Update'}
                    </button>
                  </span>
                </dd>
              </div>

              <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-b sm:border-gray-200">
                <dt class="text-sm font-medium self-center text-gray-500">
                  Publisher URL
                </dt>
                <dd class="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  <input
                    id='publisher_URL'
                    type='text'
                    name='publisher_URL'
                    onChange={handleInputChange}
                    class={inputDisabled.publisher_URL ? "flex-grow rounded-md text-sm opacity-50" : "flex-grow rounded-md text-sm"}
                    disabled={inputDisabled.publisher_URL ? true : false}
                    defaultValue={input.publisher_URL}
                    ref={register}
                  />
                  <span class="ml-4 flex-shrink-0 self-center">
                    <button
                      disabled={inputDisabled.publisher_URL ? true : false}
                      class={inputDisabled.publisher_URL ? "text-opacity-50 bg-white rounded-md font-medium text-purple-600" : "bg-white rounded-md font-medium text-purple-600 hover:text-purple-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"}
                      type="submit"
                    >
                      {isEditing && !inputDisabled.publisher_URL && (
                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          strokeWidth="0"
                          viewBox="0 0 1024 1024"
                          className="w-5 h-5 mr-3 animate-spin"
                          xmlns="http://www.w3.org/2000/svg">
                          <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 0 0-94.3-139.9 437.71 437.71 0 0 0-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                        </svg>
                      )}
                      {isEditing && !inputDisabled.publisher_URL ? null : 'Update'}
                    </button>
                  </span>
                </dd>
              </div>

              <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-b sm:border-gray-200">
                <dt class="text-sm font-medium self-center text-gray-500">
                  Facebook URL
                </dt>
                <dd class="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  <input
                    id='facebook_URL'
                    type='text'
                    name='facebook_URL'
                    onChange={handleInputChange}
                    class={inputDisabled.facebook_URL ? "flex-grow rounded-md text-sm opacity-50" : "flex-grow rounded-md text-sm"}
                    disabled={inputDisabled.facebook_URL ? true : false}
                    defaultValue={input.facebook_URL}
                    ref={register}
                  />
                  <span class="ml-4 flex-shrink-0 self-center">
                    <button
                      disabled={inputDisabled.facebook_URL ? true : false}
                      class={inputDisabled.facebook_URL ? "text-opacity-50 bg-white rounded-md font-medium text-purple-600" : "bg-white rounded-md font-medium text-purple-600 hover:text-purple-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"}
                      type="submit"
                    >
                      {isEditing && !inputDisabled.facebook_URL && (
                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          strokeWidth="0"
                          viewBox="0 0 1024 1024"
                          className="w-5 h-5 mr-3 animate-spin"
                          xmlns="http://www.w3.org/2000/svg">
                          <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 0 0-94.3-139.9 437.71 437.71 0 0 0-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                        </svg>
                      )}
                      {isEditing && !inputDisabled.facebook_URL ? null : 'Update'}
                    </button>
                  </span>
                </dd>
              </div>

              <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-b sm:border-gray-200">
                <dt class="text-sm font-medium self-center text-gray-500">
                  Twitter URL
                </dt>
                <dd class="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  <input
                    id='twitter_URL'
                    type='text'
                    name='twitter_URL'
                    onChange={handleInputChange}
                    class={inputDisabled.twitter_URL ? "flex-grow rounded-md text-sm opacity-50" : "flex-grow rounded-md text-sm"}
                    disabled={inputDisabled.twitter_URL ? true : false}
                    defaultValue={input.twitter_URL}
                    ref={register}
                  />
                  <span class="ml-4 flex-shrink-0 self-center">
                    <button
                      disabled={inputDisabled.twitter_URL ? true : false}
                      class={inputDisabled.twitter_URL ? "text-opacity-50 bg-white rounded-md font-medium text-purple-600" : "bg-white rounded-md font-medium text-purple-600 hover:text-purple-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"}
                      type="submit"
                    >
                      {isEditing && !inputDisabled.twitter_URL && (
                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          strokeWidth="0"
                          viewBox="0 0 1024 1024"
                          className="w-5 h-5 mr-3 animate-spin"
                          xmlns="http://www.w3.org/2000/svg">
                          <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 0 0-94.3-139.9 437.71 437.71 0 0 0-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                        </svg>
                      )}
                      {isEditing && !inputDisabled.twitter_URL ? null : 'Update'}
                    </button>
                  </span>
                </dd>
              </div>

              <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-b sm:border-gray-200">
                <dt class="text-sm font-medium self-center text-gray-500">
                  YouTube URL
                </dt>
                <dd class="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  <input
                    id='youtube_URL'
                    type='text'
                    name='youtube_URL'
                    onChange={handleInputChange}
                    class={inputDisabled.youtube_URL ? "flex-grow rounded-md text-sm opacity-50" : "flex-grow rounded-md text-sm"}
                    disabled={inputDisabled.youtube_URL ? true : false}
                    defaultValue={input.youtube_URL}
                    ref={register()}
                  />
                  <span class="ml-4 flex-shrink-0 self-center">
                    <button
                      disabled={inputDisabled.youtube_URL ? true : false}
                      class={inputDisabled.youtube_URL ? "text-opacity-50 bg-white rounded-md font-medium text-purple-600" : "bg-white rounded-md font-medium text-purple-600 hover:text-purple-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"}
                      type="submit"
                    >
                      {isEditing && !inputDisabled.youtube_URL && (
                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          strokeWidth="0"
                          viewBox="0 0 1024 1024"
                          className="w-5 h-5 mr-3 animate-spin"
                          xmlns="http://www.w3.org/2000/svg">
                          <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 0 0-94.3-139.9 437.71 437.71 0 0 0-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                        </svg>
                      )}
                      {isEditing && !inputDisabled.youtube_URL ? null : 'Update'}
                    </button>
                  </span>
                </dd>
              </div>

              <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-b sm:border-gray-200">
                <dt class="text-sm font-medium self-center text-gray-500">
                  Instagram URL
                </dt>
                <dd class="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  <input
                    id='instagram_URL'
                    type='text'
                    name='instagram_URL'
                    onChange={handleInputChange}
                    class={inputDisabled.instagram_URL ? "flex-grow rounded-md text-sm opacity-50" : "flex-grow rounded-md text-sm"}
                    disabled={inputDisabled.instagram_URL ? true : false}
                    defaultValue={input.instagram_URL}
                    ref={register}
                  />
                  <span class="ml-4 flex-shrink-0 self-center">
                    <button
                      disabled={inputDisabled.instagram_URL ? true : false}
                      class={inputDisabled.instagram_URL ? "text-opacity-50 bg-white rounded-md font-medium text-purple-600" : "bg-white rounded-md font-medium text-purple-600 hover:text-purple-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"}
                      type="submit"
                    >
                      {isEditing && !inputDisabled.instagram_URL && (
                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          strokeWidth="0"
                          viewBox="0 0 1024 1024"
                          className="w-5 h-5 mr-3 animate-spin"
                          xmlns="http://www.w3.org/2000/svg">
                          <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 0 0-94.3-139.9 437.71 437.71 0 0 0-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                        </svg>
                      )}
                      {isEditing && !inputDisabled.instagram_URL ? null : 'Update'}
                    </button>
                  </span>
                </dd>
              </div>

              <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-b sm:border-gray-200">
                <dt class="text-sm font-medium self-center text-gray-500">
                  Twitch URL
                </dt>
                <dd class="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  <input
                    id='twitch_URL'
                    type='text'
                    name='twitch_URL'
                    onChange={handleInputChange}
                    class={inputDisabled.twitch_URL ? "flex-grow rounded-md text-sm opacity-50" : "flex-grow rounded-md text-sm"}
                    disabled={inputDisabled.twitch_URL ? true : false}
                    defaultValue={input.twitch_URL}
                    ref={register}
                  />
                  <span class="ml-4 flex-shrink-0 self-center">
                    <button
                      disabled={inputDisabled.twitch_URL ? true : false}
                      class={inputDisabled.twitch_URL ? "text-opacity-50 bg-white rounded-md font-medium text-purple-600" : "bg-white rounded-md font-medium text-purple-600 hover:text-purple-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"}
                      type="submit"
                    >
                      {isEditing && !inputDisabled.twitch_URL && (
                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          strokeWidth="0"
                          viewBox="0 0 1024 1024"
                          className="w-5 h-5 mr-3 animate-spin"
                          xmlns="http://www.w3.org/2000/svg">
                          <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 0 0-94.3-139.9 437.71 437.71 0 0 0-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                        </svg>
                      )}
                      {isEditing && !inputDisabled.twitch_URL ? null : 'Update'}
                    </button>
                  </span>
                </dd>
              </div>

              <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                <dt class="text-sm font-medium self-center text-gray-500">
                  TikTok URL
                </dt>
                <dd class="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  <input
                    id='tiktok_URL'
                    type='text'
                    name='tiktok_URL'
                    onChange={handleInputChange}
                    class={inputDisabled.tiktok_URL ? "flex-grow rounded-md text-sm opacity-50" : "flex-grow rounded-md text-sm"}
                    disabled={inputDisabled.tiktok_URL ? true : false}
                    defaultValue={input.tiktok_URL}
                    ref={register}
                  />
                  <span class="ml-4 flex-shrink-0 self-center">
                    <button
                      disabled={inputDisabled.tiktok_URL ? true : false}
                      class={inputDisabled.tiktok_URL ? "text-opacity-50 bg-white rounded-md font-medium text-purple-600" : "bg-white rounded-md font-medium text-purple-600 hover:text-purple-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"}
                      type="submit"
                    >
                      {isEditing && !inputDisabled.tiktok_URL && (
                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          strokeWidth="0"
                          viewBox="0 0 1024 1024"
                          className="w-5 h-5 mr-3 animate-spin"
                          xmlns="http://www.w3.org/2000/svg">
                          <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 0 0-94.3-139.9 437.71 437.71 0 0 0-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                        </svg>
                      )}
                      {isEditing && !inputDisabled.tiktok_URL ? null : 'Update'}
                    </button>
                  </span>
                </dd>
              </div>
            </form>
          </dl>
        </div>
      ) : null}
    </div>
  );
};

export default PublisherAdminData;