import React from 'react';
import { Link } from 'react-router-dom';

// ----------------------------------------------------------------------------------
// ------------------------------------ PRIVACY -------------------------------------
// ----------------------------------------------------------------------------------

export const Privacy = () => {
  const url = window.location.href; // GRABS REFERENCE TO THE CURRENT URL

  return (
    <div className='relative py-8 bg-hero-pattern bg-fixed bg-cover overflow-hidden'>
      {/* Only show the back button if routing through the landing page */}
      {url.includes('dashboard') ? null : (
        <div className='max-w-lg mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl '>
          <Link
            to='/'
            class='inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-mcgreen bg-mcbuttondarkgreen hover:bg-mcbuttonlightgreen focus:outline-none focus:border-mcdarkgreen focus:shadow-outline-teal active:bg-mcdarkgreen transition ease-in-out duration-150'
          >
            {/* <!-- Heroicon name: back --> */}
            <svg
              stroke='currentColor'
              fill='currentColor'
              className='mr-2'
              stroke-width='0'
              viewBox='0 0 512 512'
              height='1em'
              width='1em'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path d='M401.4 224h-214l83-79.4c11.9-12.5 11.9-32.7 0-45.2s-31.2-12.5-43.2 0L89 233.4c-6 5.8-9 13.7-9 22.4v.4c0 8.7 3 16.6 9 22.4l138.1 134c12 12.5 31.3 12.5 43.2 0 11.9-12.5 11.9-32.7 0-45.2l-83-79.4h214c16.9 0 30.6-14.3 30.6-32 .1-18-13.6-32-30.5-32z'></path>
            </svg>
          Go back
          </Link>
        </div>
      )}

      <div className='relative px-4 sm:px-6 lg:px-8'>
        <div className='text-lg max-w-prose mx-auto mb-6'>
          <p className='text-base text-center leading-6 text-mcgreen font-semibold tracking-wide uppercase'>
            Music Chunks
          </p>
          <h1 className='mt-2 mb-8 text-3xl text-center leading-8 font-extrabold tracking-tight text-faqheader sm:text-4xl sm:leading-10'>
            Privacy Policy for Children
          </h1>
          <p className='text-base text-white'>
            <span className='italic'>Effective and Last modified</span>: April 16th, 2021
          </p>
          <br />
          <p className='text-faqquestion font-black'>
            BY CLICKING AND USING THIS WEBSITE OR OUR SERVICES, YOU AGREE TO THIS PRIVACY POLICY.
          </p>
          <br />
          <p className='text-base text-white'>
            Synced Up Products, LLC dba Music Chunks (“<span className='font-black'>Music Chunks</span>”,
            “<span className='font-black'>we</span>”, “<span className='font-black'>us</span>”, or
            “<span className='font-black'>our</span>”) is committed to protecting the personal information
            of our users and providing a safe online experience. This statement of privacy
            (“<span className='font-black'>Privacy Policy</span>”) applies to the Music Chunks website
            https://www.musicchunks.com (the “<span className='font-black'>Site</span>”) and Music Chunks’
            service that provides educators and teachers the ability to learn and teach music and fine arts
            (collectively, the “<span className='font-black'>Services</span>”).
          </p>
          <br />
          <p className='text-base text-white'>
            This Privacy Policy governs the collection, usage, and disclosure of personal information regarding
            children under the age of 13 (“<span className='font-black'>child</span>”
            or “<span className='font-black'>children</span>”), which requires the verifiable parental consent of
            a parent or legal guardian (“<span className='font-black'>parent</span>” or
            “<span className='font-black'>parents</span>”) or authorized school official
            (“<span className='font-black'>authorized educator</span>”) when provided in the education setting to
            comply with the Children's Online Privacy Protection Act of 1998 and its rules
            (collectively, "<span className='font-black'>COPPA</span>"). Personal information, or personal data,
            means any information about an individual from which that person can be identified.
            It does not include data where the identity has been removed (anonymous data).
          </p>
          <br />
          <p className='text-faqquestion font-black'>
            PLEASE READ THIS PRIVACY POLICY CAREFULLY BEFORE USING OR ALLOWING YOUR CHILD TO USE OUR SERVICES.
            BY ALLOWING YOUR CHILD TO USE ANY OF OUR SERVICES (OTHER THAN TO READ THIS PRIVACY POLICY FOR THE
            FIRST TIME), YOU ARE AGREEING THAT YOU AND YOUR CHILD WILL BE BOUND BY THIS PRIVACY POLICY, WHICH
            MAY CHANGE FROM TIME TO TIME. IF YOU DO NOT AGREE WITH THIS PRIVACY POLICY, DO NOT ALLOW YOUR CHILD
            TO USE OUR SERVICES.
          </p>
          <br />
          <p className='text-faqquestion font-black'>
            FOR THE PURPOSE OF THIS PRIVACY POLICY, MUSIC CHUNKS DEFINES “USER” OR “YOU” AS THE CHILD OR PARENT
            WHO USES THE SITE OR SERVICES.
          </p>
        </div>

        <div className='prose prose-lg text-white mx-auto'>
          <p className='text-faqquestion font-black'>
            1. Children’s Privacy
          </p>
          <p className='text-base'>
            Children are not eligible to access or use the Site or Services without the express permission
            and/or supervision of their parent, legal guardian, or authorized educator. Music Chunks does not
            knowingly collect personal information from children without the verifiable consent of their parent,
            legal guardian, or authorized educator and we will not use such information if we discover it has
            been provided without such consent.
          </p>

          <p className='text-faqquestion font-black'>
            2. Users in the United States
          </p>
          <p className='text-base'>
            Children’s online privacy is of the utmost importance to us. We may collect certain information about
            children only for the purpose of providing our educational services through the Music Chunks Services.
            Our Services are only available to users in the United States.
          </p>
          <ol className='text-base'>
            <li>
              <span className='underline'>Verifiable Consent</span>: Music Chunks does not knowingly collect any
              information from children unless appropriate, verifiable consent has been obtained directly from
              the parent or authorized educator.
            </li>
            <li>
              <span className='underline'>Providing or Withdrawing Consent</span>: Parents may review information
              submitted by their children and may request the removal of any personal information to prevent further
              use or online collection of their children’s personal information.
            </li>
            <li>
              <span className='underline'>Parent’s Rights Under COPPA</span>: In compliance with COPPA, as a parent
              of a child you may contact us through any of the methods below to request (a) what information we
              have about your child, (b) that we correct any incorrect information, (c) that we delete any personal
              information of your child, and/or (d) that we cease further collection or use of any personal
              information of your child by:
              <ul>
                <li>
                  Contacting us at:
                </li>
                <div className='ml-40'>
                  <address>
                    Music Chunks <br />
                    PO BOX 1324 <br />
                    Smyrna, Tennessee 37167 <br />
                    <span className='not-italic'>Email Address: <a href={`mailto:privacy@musicchunks.com`} className='white hover:text-mcgreen'>privacy@musicchunks.com</a></span> <br />
                    <span className='not-italic'>Phone: 833-479-6233 </span>
                  </address>
                </div>
              </ul>
            </li>
            <li>
              <span className='underline'>Schools that Act as the Parent’s Agent</span>: United States based
              Schools that contract with Music Chunks to provide the Services for legitimate educational purposes
              are providing consent to the limited collection and use of children’s personal information on the
              parent’s behalf (see “Verifiable Consent” above) as allowed under COPPA. Schools should consult
              their compliance officers on the necessary consent for the child’s use of the Services on behalf
              of the parent.
            </li>
            <li>
              <p>
                <span className='underline'>Notice to Schools and other Entities working with children</span>: If you are a school,
                educator, or other user working with a child through the Music Chunks Site and/or Services, it
                is your responsibility to obtain and document verifiable consent from a parent, or as allowed by
                applicable law, provide consent on the parent’s behalf, before any child may use the Site or
                Services. If you provide a link to create an account on Music Chunks’ Site, or create an account
                for a child, whether manually through the Site or by any other means, you represent and warrant
                that you have either (a) obtained and documented verifiable consent from the child’s parent or
                legal guardian, or (b) have the authority to provide consent on the parent’s behalf under
                applicable law.
              </p>
              <p>
                By using the Music Chunks Site and Services for educational purposes, each educational institution
                and representative thereof promises to comply with any applicable laws related to the collection
                and use of educational and personal information related to its students.
              </p>
            </li>
            <li>
              The Family Educational Rights and Privacy Act of 1974 (“FERPA”) is a federal law that states than
              an educational institution must establish a written institutional policy concerning the
              confidentiality of student education records, and that students must be notified of this Privacy
              Policy and their rights under the legislation. For all students in the United States, their use
              of the Sites is compliant with FERPA and their records are protected by FERPA policy.
            </li>
          </ol>

          <p className='text-faqquestion font-black'>
            PARENTS AND TEACHERS ARE ENCOURAGED TO DISCUSS THESE POLICIES AND ONLINE SAFETY PRACTICES WITH
            THEIR CHILDREN AND STUDENTS.
          </p>

          <p className='text-faqquestion font-black'>
            3. Collection of your Personal Information
          </p>
          <p className='text-base'>
            In order to better provide the products and services offered through our Services, Music Chunks
            may collect personal information, such as your:
          </p>
          <ul className='text-base'>
            <li>
              First and Last Name, E-mail Address
            </li>
          </ul>
          <p className='text-base'>
            We do not collect any personal information about your child unless necessary for us to provide
            the Services. However, certain personal information may be provided to us when your child is
            registered for the services and while participating in lessons, including any support provided
            if the Services are not operating properly. As applicable, we may collect data related to Schools
            and Entities who access or use our Site and/or Services, such as school or district name, or purchase
            order numbers.
          </p>
          <ol className='text-base' type='I'>
            <li>
              <p className='text-faqquestion font-black'>
                Non-Personally Identifiable User Information
              </p>
              <p>
                We may collect and use non-personally identifiable user information for purposes including,
                but not limited to (a) analytics purposes, and (b) to calculate contractual royalty payments
                to third party content providers.
              </p>
            </li>
            <li>
              <p className='text-faqquestion font-black'>
                Automatically Collected Information
              </p>
              <p>
                Information about computer hardware and software may be automatically collected by Music Chunks.
                This information can include: IP address, browser type, domain names, access times and referring
                website addresses. This information is used for the operation of the service, to maintain quality
                of the service, and to provide general statistics regarding use of the Music Chunks website.
              </p>
            </li>
          </ol>

          <p className='text-faqquestion font-black'>
            4. Use of your Child’s Personal Information
          </p>
          <p className='text-base'>
            Music Chunks collects and uses your child’s personal information and data to operate its Site,
            deliver the services requested, connect the child’s account to the educational institution,
            provide customer support, and to fulfill requests for information. We may use contact information
            to contact a User regarding an account issue via email.
          </p>

          <p className='text-faqquestion font-black'>
            5. Sharing Information with Third Parties
          </p>
          <p className='text-base font-black'>
            Music Chunks does not sell, rent or lease its customer lists to third parties.
          </p>
          <p className='text-base'>
            Music Chunks may share data with trusted partners to help perform statistical analysis, send you
            email, or provide customer support. All such third parties are prohibited from using your personal
            information except to provide these services to Music Chunks, and they are required to maintain
            the confidentiality of your information.
          </p>
          <p className='text-base'>
            Music Chunks may disclose personal information, without notice, if required to do so by law or in the
            good faith belief that such action is necessary to: (a) conform to the edicts of the law or comply with
            legal process served on Music Chunks or the Site; (b) protect and defend the rights or property of Music
            Chunks; and/or (c) act under exigent circumstances to protect the personal safety of users of Music
            Chunks, or the public.
          </p>
          <p className='text-base'>
            Children’s personal information may be used in a corporate transaction, such as the sale, merger,
            spin-off or other corporate reorganization of our business, where your information is provided to
            the new controlling entity in the regular course of business. If this scenario were to take place,
            Music Chunks will make every effort to inform you the transfer of your personal information.
          </p>

          <p className='text-faqquestion font-black'>
            6. What Happens If a User Does Not Provide Their Personal Information?
          </p>
          <p className='text-base'>
            If you choose to limit the personal information your child provides, or provide no personal information
            at all, we may be unable to provide the Services or products that we have a contract to provide you.
            However, we will never require more personal information than is necessary to provide the Services.
          </p>

          <p className='text-faqquestion font-black'>
            7. How Long will we keep your Information?
          </p>
          <p className='text-base'>
            We may retain your user information and data for as long as permitted by law and as long as it
            necessary to permit your use of the Services.
          </p>
          <p className='text-base'>
            For United States based users who are students of educational institutions and are subject to the
            Family Educational Rights and Privacy Act (FERPA) and/or where the school has provided consent for
            Music Chunks to collect the student’s PII and user data under the Children’s Online Privacy Protection
            Act (COPPA), your data will be kept for the period during which we are contracted to provide the
            Services, or until an educator, administrator, or parent/legal guardian associated with your account
            requests the removal of your information, unless we have a superseding legal obligation to retain the
            data.
          </p>

          <p className='text-faqquestion font-black'>
            8. Notice to California Residents
          </p>
          <p className='text-base'>
            Except as expressly identified in this Privacy Policy, Music Chunks does not disclose a user’s
            personal data and information to any third-party for such third-party’s direct marketing purposes.
            Thus, under California Civil Code Sections 1798.80-1798.84, Music Chunks has no obligation to
            Californians to provide further information about Music Chunks’ use of Californians’ personal data
            and information.
          </p>

          <p className='text-faqquestion font-black'>
            9. Notice to Utah Residents
          </p>
          <p className='text-base'>
            Except as expressly identified in this Privacy Policy, we will not disclose a user’s personal data to
            any third-party for such third-party’s direct marketing purposes.
          </p>

          <p className='text-faqquestion font-black'>
            10. Notice to New York Residents
          </p>
          <p className='text-base'>
            Consistent with the provisions of New York Education Law §2-D, Music Chunks is committed to
            protecting the security and confidentiality of student, teacher, and staff data. If requested,
            Music Chunks will add a Parents’ Bill of Rights to our agreement with any New York State school
            district. New York State school district administrators should contact their Music Chunks Sales
            Representative to add a Parents’ Bill of Rights to their agreement with Music Chunks.
          </p>

          <p className='text-faqquestion font-black'>
            Parents’ Bill of Rights
          </p>
          <p className='text-base'>
            In accordance with State and Federal law, and in recognition of the risk of identity theft or
            unwarranted invasion of privacy, Synced Up Products, dba Music Chunks affirms its commitment to
            safeguarding student personally identifiable information (PII) in educational records from
            unauthorized access or disclosure. Music Chunks establishes the following Parents’ Bill of Rights:
          </p>
          <ol className='text-base' type='I'>
            <li>
              Student PII will be collected and disclosed only as necessary to achieve educational purposes in
              accordance with State and Federal Law;
            </li>
            <li>
              A student’s PII will not be sold or released for any commercial purposes, including direct
              marketing, by Music Chunks or any third-party;
            </li>
            <li>
              In accordance with State and Federal Laws, when a student’s PII is stored or transferred,
              the confidentiality of that PII will be protected by safeguards associated with industry standards
              and best practices, including but not limited to, encryption, firewalls, and password protection;
            </li>
            <li>
              The complete contents of a child’s education record may be inspected and reviewed by their
              parents or legal guardians;
            </li>
            <li>
              Parents have the right to have complaints about possible breaches of student data addressed.
              Complaints should be sent by email to <a href={`mailto:privacy@musicchunks.com`} className='white hover:text-mcgreen'>privacy@musicchunks.com</a>.
            </li>
            <li>
              In accordance with applicable laws, Parents have the right to be notified if a breach or
              unauthorized release of their student’s PII occurs.
            </li>
          </ol>

          <p className='text-faqquestion font-black'>
            11. Notice to Illinois Residents
          </p>
          <p className='text-base'>
            Available upon your request, SUP will include our SOPPA addendum to the terms of service,
            specifically designed to comply with the requirements of the Illinois Student Online Personal
            Protection Act (105 ILCS 85/). Schools and School Districts located in Illinois should contact
            their Music Chunks Sales Representative to incorporate the Illinois Addendum into your agreement.
          </p>

          <p className='text-faqquestion font-black'>
            12. Notice to Connecticut Residents
          </p>
          <p className='text-base'>
            Available upon your request, Music Chunks will include our Connecticut addendum to the terms of
            service, specifically designed to comply with the requirements of the Connecticut Act Concerning
            Student Data Privacy, Conn. Gen. Stat. Ann. § 10-234aa-dd. Schools and School Districts located in
            Connecticut should contact their Music Chunks Sales Representative to incorporate the Connecticut
            Addendum into your agreement.
          </p>

          <p className='text-faqquestion font-black'>
            13. Use of Cookies
          </p>
          <p className='text-base'>
            The Music Chunks Site may use "cookies" to help you personalize your online experience. A cookie is
            a text file that is placed on your hard disk by a web page server. Cookies cannot be used to run
            programs or deliver viruses to your computer. Cookies are uniquely assigned to you, and can only be
            read by a web server in the domain that issued the cookie to you.
          </p>
          <p className='text-base'>
            One of the primary purposes of cookies is to provide a convenience feature to save you time.
            The purpose of a cookie is to tell the Web server that you have returned to a specific page.
            For example, if you personalize Music Chunks pages, or register with Music Chunks Sites or Services,
            a cookie helps Music Chunks to recall your specific information on subsequent visits. This simplifies
            the process of recording your personal information, such as billing addresses, shipping addresses,
            and so on. When you return to the same Music Chunks Site, the information you previously provided
            can be retrieved, so you can easily use the Music Chunks features that you customized.
          </p>
          <p className='text-base'>
            You have the ability to accept or decline cookies. Most Web browsers automatically accept cookies,
            but you can usually modify your browser setting to decline cookies if you prefer. If you choose to
            decline cookies, you may not be able to fully experience the interactive features of the Music Chunks
            Services or Sites you visit.
          </p>

          <p className='text-faqquestion font-black'>
            14. Links
          </p>
          <p className='text-base'>
            This Site contains links to other sites. Please be aware that we are not responsible for the content
            or privacy practices of such other sites. We encourage our users to be aware when they leave our site
            and to read the privacy statements of any other site that collects personal information.
          </p>

          <p className='text-faqquestion font-black'>
            15. Security of your Personal Information
          </p>
          <p className='text-base'>
            Music Chunks secures your personal information from unauthorized access, use, or disclosure. We use
            physical, electronic, and administrative safeguards designed to protect your child’s personal
            information from loss, misuse and unauthorized access, use, alteration or disclosure.
          </p>
          <p className='text-base'>
            We strive to take appropriate security measures to protect against unauthorized access to or
            alteration of your personal information. Unfortunately, no data transmission over the Internet
            or any wireless network can be guaranteed to be 100% secure. As a result, while we strive to
            protect your personal information, you acknowledge that: (a) there are security and privacy
            limitations inherent to the Internet which are beyond our control; and (b) security, integrity,
            and privacy of any and all information and data exchanged between you and us through this Site
            cannot be guaranteed.
          </p>

          <p className='text-faqquestion font-black'>
            16. Right to Deletion
          </p>
          <p className='text-base'>
            Subject to certain exceptions set out below, on receipt of a verifiable request from you, we will:
          </p>
          <ul className='text-base'>
            <li>
              Delete your child’s personal information from our records.
            </li>
            <li>
              Direct any service providers to delete your child’s personal information from their records.
            </li>
          </ul>
          <p className='text-base'>
            Please note that we may not be able to comply with requests to delete your personal information if it
            is necessary to:
          </p>
          <ul className='text-base'>
            <li>
              Complete the transaction for which the personal information was collected, fulfill the terms of a
              written warranty or product recall conducted in accordance with federal law, provide a good or
              service requested by you, or reasonably anticipated within the context of our ongoing business
              relationship with you, or otherwise perform a contract between you and us;
            </li>
            <li>
              Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity;
              or prosecute those responsible for that activity;
            </li>
            <li>
              Debug to identify and repair errors that impair existing intended functionality;
            </li>
            <li>
              Exercise free speech, ensure the right of another consumer to exercise his or her right of
              free speech, or exercise another right provided for by law;
            </li>
            <li>
              Comply with the California Electronic Communications Privacy Act;
            </li>
            <li>
              Engage in public or peer-reviewed scientific, historical, or statistical research in the public
              interest that adheres to all other applicable ethics and privacy laws, when our deletion of the
              information is likely to render impossible or seriously impair the achievement of such research,
              provided we have obtained your informed consent;
            </li>
            <li>
              Enable solely internal uses that are reasonably aligned with your expectations based on your
              relationship with us;
            </li>
            <li>
              Comply with an existing legal obligation; or
            </li>
            <li>
              Otherwise use your personal information, internally, in a lawful manner that is compatible
              with the context in which you provided the information.
            </li>
          </ul>

          <p className='text-faqquestion font-black'>
            17. Disconnecting your Music Chunks Account from Third-Party Websites
          </p>
          <p className='text-base'>
            You may be able to connect your Music Chunks account to third-party accounts. BY CONNECTING YOUR
            MUSIC CHUNKS ACCOUNT TO YOUR THIRD-PARTY ACCOUNT, YOU ACKNOWLEDGE AND AGREE THAT YOU ARE CONSENTING
            TO THE CONTINUOUS RELEASE OF INFORMATION ABOUT YOU TO OTHERS (IN ACCORDANCE WITH YOUR PRIVACY
            SETTINGS ON THOSE THIRD-PARTY SITES). IF YOU DO NOT WANT INFORMATION ABOUT YOU, INCLUDING PERSONALLY
            IDENTIFYING INFORMATION, TO BE SHARED IN THIS MANNER, DO NOT USE THIS FEATURE. You may disconnect
            your account from a third-party account at any time. Users may learn how to disconnect their accounts
            from third-party websites by visiting their "My Account" page. Users may also contact us via email.
          </p>

          <p className='text-faqquestion font-black'>
            18. Operators that Collect or Maintain Information from Children
          </p>
          <p className='text-base'>
            A list of all operators that may collect or maintain personal information from children through
            the Site is available here.  Please direct your inquiries about any operator’s privacy practices
            and use of children’s information to the operator at its contact information provided.
          </p>
          <div className='text-base'>
            <p className='text-center'>
              Synced Up Products <br />
              Attention: Data Privacy Officer <br />
              P.O. Box 1324 Smyrna, TN 37167 <br />
              US: 1-833-4SYNCED <br />
              <a href={`mailto:privacy@musicchunks.com`} className='white hover:text-mcgreen'>privacy@musicchunks.com</a> <br />
            </p>
            <p className='text-center'>
              Soundslice <br />
              Attention: Adrian Holovaty <br />
              <a href={`mailto:feedback@soundslice.com`} className='white hover:text-mcgreen'>feedback@soundslice.com</a> <br />
            </p>
          </div>

          <p className='text-faqquestion font-black'>
            19. Changes to this Privacy Policy
          </p>
          <p className='text-base'>
            Music Chunks reserves the right to change this Privacy Policy from time to time. We will notify
            you about significant changes in the way we treat personal information by sending a notice to the
            primary email address specified in your account, by placing a prominent notice on our Sites,
            and/or by updating any privacy information on this page. Your continued use of the Site and/or
            Services available through this Site after such modifications will constitute your: (a) acknowledgment
            of the modified Privacy Policy; and (b) agreement to abide and be bound by that Policy.
          </p>

          <p className='text-faqquestion font-black'>
            20. Contact Information
          </p>
          <p className='text-base'>
            Music Chunks welcomes your questions or comments regarding this Privacy Policy. If you believe
            that Music Chunks has not adhered to this Privacy Policy, please contact Music Chunks at:
          </p>

          <div className='text-base'>
            <address>
              Music Chunks <br />
              PO BOX 1324 <br />
              Smyrna, Tennessee 37167 <br />
              <span className='not-italic'>Email Address: <br />
                <a href={`mailto:privacy@musicchunks.com`} className='white hover:text-mcgreen'>privacy@musicchunks.com</a></span> <br />
            </address>
            <p className='not-italic'>Telephone Number: <br />
            833-479-6233
            </p>
          </div>
        </div>
      </div>
    </div >
  );
};
