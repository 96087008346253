import React from 'react';

// ROUTING
import { useParams, Link } from 'react-router-dom';
import { MemoizedChunkCard } from './ChunkCard';

// ----------------------------------------------------------------------------------
// --------------------------------- PLAYLIST PAGE ----------------------------------
// ----------------------------------------------------------------------------------

const PlaylistPage = ({ cognitoUser, group, groupChunks, groupPlaylists, handleClearSearchBar, match }) => {
  // State
  const { playlistId } = useParams();

  // Find current playlist
  const currentPlaylist = groupPlaylists.filter((s) => {
    return s.id === playlistId;
  });

  return (
    <>
      {/* ABOUT THE PLAYLIST */}
      <div className='bg-green-700 px-5 py-3 mt-2 rounded-lg'>
        <h1 className='text-white text-lg font-bold'>
          {currentPlaylist[0]?.type}
        </h1>
        <h2 className='mb-5 text-mcgreen'>
          By {group?.name}
        </h2>
        <p className='whitespace-pre-wrap text-white'>
          {currentPlaylist[0]?.description}
        </p>
      </div>

      {/* COLLECTION CARDS */}
      <div className='grid justify-center gap-10 mt-2 sm:grid-cols-2 md:grid-cols-2 xl:grid-cols-4 2xl:grid-cols-6 2xl:px-15'>
        {groupChunks?.filter(filter => filter.section_ids.includes(playlistId)).map((chunk) => {
          return (
            <Link
              to={`${match.url}/chunks/${chunk?.id}`}
            >
              <MemoizedChunkCard
                data={chunk}
                cognitoUser={cognitoUser}
                handleClearSearchBar={handleClearSearchBar}
                match={match}
              />
            </Link>
          );
        })}
      </div>
    </>
  );
};
export default PlaylistPage;