import React from 'react';

// ROUTING
import { Link } from 'react-router-dom';

// COMPONENTS
import NavBar from './NavBar';
import { Footer } from './Footer';

// IMAGES
import musicSheetBg from '../../img/musicSheetBg.png';

// ----------------------------------------------------------------------------------
// --------------------------------- ENSEMBLES --------------------------------------
// ----------------------------------------------------------------------------------

export const Ensembles = () => {
  return (
    <>
        <NavBar />
        <main>
            <div className='lg:flex lg:h-screen lg:bg-none items-center pb-14 lg:pb-0 overflow-hidden'>
                <img src={musicSheetBg}
                    className='hidden lg:flex opacity-40'
                    alt='sheet music' 
                />
                <div className='lg:absolute mt-6 lg:mt-28 mx-14'>
                    <div className='flex flex-col lg:flex-row justify-center lg:justify-between items-center mb-14'>
                        <div className='text-white lg:w-1/2 mb-12 lg:mb-0 lg:mr-8'>
                            <h1 className='text-5xl font-bold mb-6'>
                                What are Ensemble Accounts?
                            </h1>
                            <p className='text-md mb-10'>
                                Schools, studio lesson teachers, ensembles, and organizations
                                with at least 10 students are able to manage their own private
                                “Ensembles” on Music Chunks, where the content inside is only
                                visible to the students and teachers in that ensemble. Teachers
                                can add/remove students from the ensemble with ease, create
                                custom playlists to organize content around their needs, and even
                                upload their own videos, audio, scores, and lessons for their
                                ensemble’s private viewing and learning. This allows your
                                students to practice along with inspiring examples, at their own
                                speed, whether or not their instructor is present.
                            </p>
                            <Link
                                to='/faq-ensemble'
                                className='mr-3 py-2 px-4 text-lg px-2 border-2 border-mcgreen text-base leading-6 font-bold rounded-md text-mcgreen hover:bg-mcgreen hover:text-black focus:outline-none transition duration-150 ease-in-out'
                                >
                                Learn More
                            </Link>
                        </div>
                        <iframe className='w-full lg:w-1/2' height="360" src="https://www.youtube.com/embed/b5r0tMSkbjg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                </div>
            </div>
        </main>
        <Footer />
    </>
  );
};