import React, { useState, useEffect } from 'react';
import axios from 'axios';
import axiosRetry from 'axios-retry';

// ROUTING
import { Route, Link, useLocation, useHistory } from 'react-router-dom';

// STREAM CHAT
import { StreamChat } from 'stream-chat';

// AUTH
import { Auth } from '@aws-amplify/auth';

// STATE
import { useSetRecoilState, useRecoilValue } from 'recoil';
import {
  chunkState as chunksAtom,
  alphabetizedChunkState as alphabetizedChunksAtom,
  publisherState as publishersAtom,
  collectionState as collectionsAtom,
  sectionState as sectionsAtom,
  showCollectionState as showCollectionsAtom,
  concertCollectionState as concertCollectionsAtom,
  jazzPopCollectionState as jazzPopCollectionsAtom,
  exerciseCollectionState as exerciseCollectionsAtom,
  basicCollectionState as basicCollectionsAtom,
  visualCollectionState as visualCollectionsAtom,
  learnCollectionState as learnCollectionsAtom,
  publicGroupState as publicGroupsAtom,
  searchBarTerm,
  cogUserState,
  ForceReload
} from '../../state/atoms';

// SENTRY
import * as Sentry from "@sentry/react";

// TAILWIND TRANSITION
import { Transition } from '@tailwindui/react';

// COMPONENTS
import MobileDashMenu from './MobileDashMenu';
import Sidebar from './Sidebar';
import { FAQPage } from '../landing/FAQPage';
import { TOS } from '../legal/TOS';
import { Privacy } from '../legal/Privacy';
import ChunkList from './ChunkList';
import { SingleChunk } from './SingleChunk';
import { MyChunks } from './MyChunks';
import { Home } from './Home';
import PublisherList from './PublisherList';
import PublisherPage from './PublisherPage';
import CollectionList from './CollectionList';
import CollectionPage from './CollectionPage';
import CollectionTypePage from './CollectionTypePage';
import GroupPage from './GroupPage';
import EnsembleUserRoute from '../../utils/EnsembleUserRoute'
import PublicEnsembleUserRoute from '../../utils/PublicEnsembleUserRoute'

// LOADING ICON
import { CoffeeLoading } from 'react-loadingg';

// FORM MANAGEMENT
import { useForm } from 'react-hook-form';

const client = StreamChat.getInstance(process.env.REACT_APP_STREAM_API_KEY)

// ----------------------------------------------------------------------------------
// ---------------------------------- Dashboard -------------------------------------
// ----------------------------------------------------------------------------------

export const Dashboard = () => {
  // State
  const set = useSetRecoilState(ForceReload);
  const [offCanvasMenu, setOffCanvasMenu] = useState(false);
  const [profileDropdown, setProfileDropdown] = useState(false);
  const [loadingPage, setLoadingPage] = useState(false);
  const [keepSearchBar, setKeepSearchBar] = useState(false);
  const [streamToken, setStreamToken] = useState(localStorage.getItem('streamToken'));
  const [clientReady, setClientReady] = useState(false);
  const { register, reset } = useForm({
    mode: 'onChange',
  });   // React hook form

  // Recoil State
  const setSearchBarTerm = useSetRecoilState(searchBarTerm);
  const setChunks = useSetRecoilState(chunksAtom);
  const setAlphabetizedChunks = useSetRecoilState(alphabetizedChunksAtom);
  const setPublishers = useSetRecoilState(publishersAtom);
  const setCollections = useSetRecoilState(collectionsAtom);
  const setSections = useSetRecoilState(sectionsAtom);
  const setShowCollections = useSetRecoilState(showCollectionsAtom);
  const setConcertCollections = useSetRecoilState(concertCollectionsAtom);
  const setJazzPopCollections = useSetRecoilState(jazzPopCollectionsAtom);
  const setExerciseCollections = useSetRecoilState(exerciseCollectionsAtom);
  const setBasicCollections = useSetRecoilState(basicCollectionsAtom);
  const setVisualCollections = useSetRecoilState(visualCollectionsAtom);
  const setLearnCollections = useSetRecoilState(learnCollectionsAtom);
  const setPublicGroups = useSetRecoilState(publicGroupsAtom);

  // Grab the cognito user ID from localstorage and link to the user in our backend database 
  const cognitoUser = useRecoilValue(cogUserState(localStorage.getItem(process.env.REACT_APP_USER_ID)))   // Get user from our DB
  Sentry.setUser({ email: cognitoUser?.data.email });

  // React Router
  const location = useLocation();
  const { pathname, search } = useLocation();
  const history = useHistory();

  // Set axios to retry a set number of times on failed request/return
  axiosRetry(axios, { retries: 3 })

  // Force reloads the page
  useEffect(() => {
    set(Math.random());
  }, [set]);

  // If a user does not have a first or last name logged in our DB, update it to match cognito first and last names
  useEffect(() => {
    if (!cognitoUser?.data?.first_name && !cognitoUser?.data?.first_name) {
      axios({
        method: 'put',
        url:
          process.env.REACT_APP_API + `users/${cognitoUser?.data?.id}`,
        headers: {
          Accept: 'application/json',
          Authorization: process.env.REACT_APP_HEROKU_SERVER_KEY,
        }, data: {
          first_name: Auth?.user?.attributes?.given_name,
          last_name: Auth?.user?.attributes?.family_name
        }
      })
    }
  }, [cognitoUser, Auth]);

  // UseEffect that grabs streamToken if the user is already signed in and doesn't have one assigned
  useEffect(() => {
    if (!streamToken) {
      axios({
        method: 'get',
        url:
          process.env.REACT_APP_API + `users/${cognitoUser.data.cognito_id}/streamtoken`,
        headers: {
          Accept: 'application/json',
          Authorization: process.env.REACT_APP_HEROKU_SERVER_KEY,
        },
      })
        .then((res) => {
          setStreamToken(res.data.streamToken)
          localStorage.setItem('streamToken', res.data.streamToken)
        })
        .catch((err) => console.log(err));
    }
  }, [cognitoUser])

  // UseEffect that sets up connection to Stream Chat API with the signed in user
  useEffect(() => {
    const setupClient = async () => {
      try {
        // Connect the user if they aren't already
        if (!client.user) {
          await client.connectUser(
            {
              id: cognitoUser.data.cognito_id,
              name: `${cognitoUser.data.first_name} ${cognitoUser.data.last_name}`,
              fullName: `${cognitoUser.data.first_name} ${cognitoUser.data.last_name}`,
              image: `https://getstream.io/random_svg/?name=${cognitoUser.data.first_name}`
            },
            streamToken,
          );
          setClientReady(true);
        }
      } catch (err) {
        console.log(err);
      }
    };
    setupClient();
  }, [cognitoUser, streamToken]);

  // UseEffect that grabs all chunks, publishers, and collections from the site
  useEffect(() => {
    setLoadingPage(true);
    async function loadContent() {
      let chunkRequest = axios.get(
        process.env.REACT_APP_API + 'chunks/public', {
        headers: {
          Authorization: process.env.REACT_APP_HEROKU_SERVER_KEY,
        },
      });
      let publishersRequest = axios.get(
        process.env.REACT_APP_API + 'publishers/public', {
        headers: {
          Authorization: process.env.REACT_APP_HEROKU_SERVER_KEY,
        },
      }
      );
      let collectionRequest = axios.get(
        process.env.REACT_APP_API + 'collections/public', {
        headers: {
          Authorization: process.env.REACT_APP_HEROKU_SERVER_KEY,
        },
      }
      );
      let sectionRequest = axios.get(
        process.env.REACT_APP_API + 'sections', {
        headers: {
          Authorization: process.env.REACT_APP_HEROKU_SERVER_KEY,
        },
      }
      );
      let showCollectionRequest = axios.get(
        process.env.REACT_APP_API + 'collections/type/shows', {
        headers: {
          Authorization: process.env.REACT_APP_HEROKU_SERVER_KEY,
        },
      }
      );
      let concertCollectionRequest = axios.get(
        process.env.REACT_APP_API + 'collections/type/concerts', {
        headers: {
          Authorization: process.env.REACT_APP_HEROKU_SERVER_KEY,
        },
      }
      );
      let jazzCollectionRequest = axios.get(
        process.env.REACT_APP_API + 'collections/type/jazz', {
        headers: {
          Authorization: process.env.REACT_APP_HEROKU_SERVER_KEY,
        },
      }
      );
      let exerciseCollectionRequest = axios.get(
        process.env.REACT_APP_API + 'collections/type/exercises', {
        headers: {
          Authorization: process.env.REACT_APP_HEROKU_SERVER_KEY,
        },
      }
      );
      let basicCollectionRequest = axios.get(
        process.env.REACT_APP_API + 'collections/type/basics', {
        headers: {
          Authorization: process.env.REACT_APP_HEROKU_SERVER_KEY,
        },
      }
      );
      let visualCollectionRequest = axios.get(
        process.env.REACT_APP_API + 'collections/type/visuals', {
        headers: {
          Authorization: process.env.REACT_APP_HEROKU_SERVER_KEY,
        },
      }
      );
      let learnCollectionRequest = axios.get(
        process.env.REACT_APP_API + 'collections/type/learn', {
        headers: {
          Authorization: process.env.REACT_APP_HEROKU_SERVER_KEY,
        },
      }
      );
      let publicGroupRequest = axios.get(
        process.env.REACT_APP_API + 'groups/public', {
        headers: {
          Authorization: process.env.REACT_APP_HEROKU_SERVER_KEY,
        },
      }
      );
      axios
        .all([chunkRequest, publishersRequest, collectionRequest, sectionRequest, showCollectionRequest, concertCollectionRequest, jazzCollectionRequest, exerciseCollectionRequest, basicCollectionRequest, visualCollectionRequest, learnCollectionRequest, publicGroupRequest])
        .then(
          axios.spread((...res) => {
            let chunks = res[0].data;
            let alphabetizedChunks = res[0].data;
            let publishers = res[1].data;
            let collections = res[2].data;
            let sections = res[3].data;
            let showCollections = res[4].data;
            let concertCollections = res[5].data;
            let jazzCollections = res[6].data;
            let exerciseCollections = res[7].data;
            let basicCollections = res[8].data;
            let visualCollections = res[9].data;
            let learnCollections = res[10].data;
            let publicGroups = res[11].data;
            setChunks(getRandom(chunks, chunks.length));
            setAlphabetizedChunks(alphabetizedChunks)
            setPublishers(publishers);
            setCollections(collections);
            setSections(sections);
            setShowCollections(showCollections);
            setConcertCollections(concertCollections);
            setJazzPopCollections(jazzCollections);
            setExerciseCollections(exerciseCollections);
            setBasicCollections(basicCollections);
            setVisualCollections(visualCollections);
            setLearnCollections(learnCollections);
            setPublicGroups(publicGroups);
            setLoadingPage(false);
          })
        )
        .catch((err) => {
          console.log(err);
        });
    }
    loadContent();
  }, [setChunks, setAlphabetizedChunks, setPublishers, setCollections, setSections, setShowCollections, setConcertCollections, setJazzPopCollections, setExerciseCollections, setBasicCollections, setVisualCollections, setLearnCollections, setPublicGroups]);

  useEffect(() => {
    const getNotifications = async () => {
      try {
        await client.on(event => {
          let ensembleID = event.team

          // Sends desktop notificaiton whenever there is an unread message in the channel
          if (event.type === 'notification.message_new' && event.unread_count > 0 && event.message.type === 'regular') {
            let notification = new Notification(event.message.user.name, {
              body: event.message.text,
            });

            notification.onclick = function (e) {
              e.preventDefault(); // prevent the browser from focusing the Notification's tab
              window.open(`${process.env.REACT_APP_SITE_URL}/ensembles/${ensembleID}/classrooms`, '_blank');
            }

            document.getElementById('favicon').href =
              'https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/240/google/223/bell_1f514.png';
          } else if (event.type === 'message.new' && event.unread_count > 0 && event.message.type === 'regular') {
            let notification = new Notification(event.user.name, {
              body: event.message.text,
            });

            notification.onclick = function (e) {
              e.preventDefault(); // prevent the browser from focusing the Notification's tab
              window.open(`${process.env.REACT_APP_SITE_URL}/ensembles/${ensembleID}/classrooms`, '_blank');
            }

            document.getElementById('favicon').href =
              'https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/240/google/223/bell_1f514.png';
          }
          if (event.type === 'notification.message_read' && !event.total_unread_count) {
            document.getElementById('favicon').href = '/favicon.ico';
          }

          // Sends desktop notification to mentioned users in threads
          if (event.type === 'message.new' && event.message.mentioned_users.length > 0 && event.message.type === 'reply') {
            const currentUser = event.message.mentioned_users.find(user => client.user.id === user.id)

            if (currentUser) {
              let notification = new Notification(event.user.name, {
                body: event.message.text,
              });

              notification.onclick = function (e) {
                e.preventDefault(); // prevent the browser from focusing the Notification's tab
                window.open(`${process.env.REACT_APP_SITE_URL}/ensembles/${ensembleID}/classrooms`, '_blank');
              }

              document.getElementById('favicon').href =
                'https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/240/google/223/bell_1f514.png';
            }
          }
        });
      } catch (err) {
        console.log(err);
      }
    };
    getNotifications();
  }, [client])

  // Function to help randomize the chunks and publishers on each refresh or rerender of the page
  function getRandom(arr, n) {
    var result = new Array(n),
      len = arr.length,
      taken = new Array(len);
    if (n > len)
      throw new RangeError('getRandom: more elements taken than available');
    while (n--) {
      var x = Math.floor(Math.random() * len);
      result[n] = arr[x in taken ? taken[x] : x];
      taken[x] = --len in taken ? taken[len] : len;
    }
    return result;
  }

  // Function that links a user to their payment portal
  const redirectToManage = (e) => {
    setProfileDropdown(false);
    fetch(process.env.REACT_APP_PAYMENT_PORTAL, {
      method: 'POST',
      body: cognitoUser.data.cognito_id
    })
      .then((res) => res.json())
      // .then((data => console.log(data))) // Uncomment when testing to check the response
      .then((link) => (window.location.href = link))
      .catch((err) => console.log(err));
  };

  // Function for handling logout
  let logout = () => {
    Auth.signOut()
    client.disconnectUser();
    localStorage.clear()
    history.push('/')
    window.location.reload();
  };

  // Function for handling search terms when viewing all chunks
  const handleSearchTermChange = (e) => {
    e.preventDefault();
    setSearchBarTerm(e.target.value);
  };

  // Function for clearing search bar terms when viewing all chunks
  const handleClearSearchBar = () => {
    reset();
    setSearchBarTerm('');
  };

  // Scrolls page to top on reroute
  const scrollToTop = () => {
    // Scroll to top
    const element = document.getElementById('main');
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };

  // UseEffect that calls our scrollToTop function
  useEffect(() => {
    scrollToTop();
  }, [pathname, search]);

  return (
    <div className='flex h-screen overflow-hidden bg-fixed bg-cover bg-hero-pattern '>
      {/* <!-- Off-canvas menu for mobile --> */}
      <MobileDashMenu
        offCanvasMenu={offCanvasMenu}
        setOffCanvasMenu={setOffCanvasMenu}
        location={location}
        cognitoUser={cognitoUser}
        handleClearSearchBar={handleClearSearchBar}
      />

      {/* <!-- Static sidebar for desktop --> */}
      <Sidebar
        cognitoUser={cognitoUser}
        handleClearSearchBar={handleClearSearchBar}
      />

      <main className='flex-1 overflow-auto focus:outline-none' tabIndex='0'>
        <div className='relative z-10 flex flex-shrink-0 h-16 bg-white border-b border-gray-200 lg:border-none'>
          {/* MOBILE MENU BUTTON */}
          <button
            onClick={() => setOffCanvasMenu(!offCanvasMenu)}
            className='px-4 border-r text-sidebaricon focus:outline-none hover:bg-coolgrayhighlight lg:hidden'
            aria-label='Open sidebar'
          >
            <svg
              className='w-6 h-6 transition duration-150 ease-in-out'
              fill='none'
              viewBox='0 0 24 24'
              stroke='currentColor'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth='2'
                d='M4 6h16M4 12h8m-8 6h16'
              />
            </svg>
          </button>

          {/* <!-- Search bar --> */}
          <main className='flex justify-between flex-1 px-4 sm:px-6 lg:max-w-6xl lg:mx-auto xl:max-w-8xl lx:mx-auto lg:px-8'>
            <div id='main' className='flex flex-1'>

              {/* <!-- Search All Chunk From Home --> */}
              {/* {location.pathname === '/dashboard' && (
                <form
                  className='flex w-full md:ml-0'
                  onSubmit={(e) => e.preventDefault()}
                >
                  <label htmlFor='search_field' className='sr-only'>
                    Search
                  </label>
                  <div className='relative w-full text-sidebaricon focus-within:text-black'>
                    <div className='absolute inset-y-0 left-0 flex items-center pointer-events-none'>
                      <svg
                        className='w-8 h-8'
                        fill='currentColor'
                        viewBox='0 0 20 20'
                      >
                        <path
                          fillRule='evenodd'
                          clipRule='evenodd'
                          d='M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z'
                        />
                      </svg>
                    </div>
                    <input
                      id='search_field'
                      name='search_field'
                      onChange={handleSearchTermChange}
                      onClick={() => {
                        setKeepSearchBar(true);
                        history.push('/dashboard/chunks?collection=all')
                      }}
                      ref={register}
                      className='block border-none w-full h-full pl-11 pr-3 rounded-md hover:bg-coolgrayhighlight focus:ring-0 text-lg'
                      placeholder='BLERG'
                      type='search'
                    />
                  </div>
                </form>
              )} */}

              {/* <!-- Search Playlists --> */}
              {location.search.includes('collection') || location.pathname === '/dashboard' ? (
                <form
                  className='flex w-full md:ml-0'
                  onSubmit={(e) => e.preventDefault()}
                >
                  <label htmlFor='search_field' className='sr-only'>
                    Search
                  </label>
                  <div className='relative w-full text-sidebaricon focus-within:text-black'>
                    <div className='absolute inset-y-0 left-0 flex items-center pointer-events-none'>
                      <svg
                        className='w-8 h-8'
                        fill='currentColor'
                        viewBox='0 0 20 20'
                      >
                        <path
                          fillRule='evenodd'
                          clipRule='evenodd'
                          d='M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z'
                        />
                      </svg>
                    </div>
                    <input
                      id='search_field'
                      name='search_field'
                      onChange={handleSearchTermChange}
                      ref={register}
                      onClick={() => {
                        if (location.pathname === '/dashboard') {
                          history.push('/dashboard/chunks?collection=all')
                        }
                      }}
                      className='block border-none w-full h-full pl-11 pr-3 rounded-md hover:bg-coolgrayhighlight focus:ring-0 text-lg'
                      placeholder='Chunk, Instrument, Publisher, Playlist, Collection Type, Keyword'
                      type='search'
                    />
                  </div>
                </form>
              ) : null}

              {/* <!-- Search Collection Types --> */}
              {location.pathname.includes('type') && (
                <form
                  className='flex w-full md:ml-0'
                  onSubmit={(e) => e.preventDefault()}
                >
                  <label htmlFor='search_field' className='sr-only'>
                    Search
                  </label>
                  <div className='relative w-full text-sidebaricon focus-within:text-black'>
                    <div className='absolute inset-y-0 left-0 flex items-center pointer-events-none'>
                      <svg
                        className='w-8 h-8'
                        fill='currentColor'
                        viewBox='0 0 20 20'
                      >
                        <path
                          fillRule='evenodd'
                          clipRule='evenodd'
                          d='M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z'
                        />
                      </svg>
                    </div>
                    <input
                      id='search_field'
                      name='search_field'
                      onChange={handleSearchTermChange}
                      ref={register}
                      className='block border-none w-full h-full pl-11 pr-3 rounded-md hover:bg-coolgrayhighlight focus:ring-0 text-lg'
                      placeholder='Collection, Publisher, Keyword'
                      type='search'
                    />
                  </div>
                </form>
              )}

              {/* <!-- Search Publisher--> */}
              {location.search.includes('publisher') && (
                <form
                  className='flex w-full md:ml-0'
                  onSubmit={(e) => e.preventDefault()}
                >
                  <label htmlFor='search_field' className='sr-only'>
                    Search
                  </label>
                  <div className='relative w-full text-sidebaricon focus-within:text-black'>
                    <div className='absolute inset-y-0 left-0 flex items-center pointer-events-none'>
                      <svg
                        className='w-8 h-8'
                        fill='currentColor'
                        viewBox='0 0 20 20'
                      >
                        <path
                          fillRule='evenodd'
                          clipRule='evenodd'
                          d='M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z'
                        />
                      </svg>
                    </div>
                    <input
                      id='search_field'
                      name='search_field'
                      onChange={handleSearchTermChange}
                      ref={register}
                      className='block border-none w-full h-full pl-11 pr-3 rounded-md hover:bg-coolgrayhighlight focus:ring-0 text-lg'
                      placeholder='Publisher Name'
                      type='search'
                    />
                  </div>
                </form>
              )
              }
            </div>
            <div className='flex items-center ml-4 md:ml-6'>
              {/* NOTIFICATION BUTTON */}
              {/* <button
                className='p-1 rounded-full text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:shadow-outline focus:text-gray-500'
                aria-label='Notifications'
              >
                <svg
                  className='w-6 h-6'
                  stroke='currentColor'
                  fill='none'
                  viewBox='0 0 24 24'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth='2'
                    d='M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9'
                  />
                </svg>
              </button> */}

              {/* <!-- Profile dropdown --> */}
              <div className='relative ml-3'>
                <div>
                  <button
                    onClick={() => {
                      setProfileDropdown(!profileDropdown);
                    }}
                    className='flex items-center max-w-xs text-sm rounded-full focus:outline-none focus:bg-coolgrayhighlight p-2 rounded-md hover:bg-coolgrayhighlight'
                    id='user-menu'
                    aria-label='User menu'
                    aria-haspopup='true'
                  >

                    <p className='ml-3 text-sm font-medium leading-5 text-gray-700 lg:block'>
                      {cognitoUser.data.first_name ? `${cognitoUser.data.first_name}` : `${Auth?.user?.attributes?.given_name}`} {cognitoUser.data.last_name ? `${cognitoUser.data.last_name}` : `${Auth?.user?.attributes?.family_name}`}
                    </p>
                    <svg
                      className='flex-shrink-0 w-5 h-5 ml-1 text-gray-400 lg:block'
                      viewBox='0 0 20 20'
                      fill='currentColor'
                    >
                      <path
                        fillRule='evenodd'
                        d='M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z'
                        clipRule='evenodd'
                      />
                    </svg>
                  </button>
                </div>
                {/* <!--
              Profile dropdown panel, show/hide based on dropdown state.
              Entering: "transition ease-out duration-100"
                From: "transform opacity-0 scale-95"
                To: "transform opacity-100 scale-100"
              Leaving: "transition ease-in duration-75"
                From: "transform opacity-100 scale-100"
                To: "transform opacity-0 scale-95"
            --> */}
                <Transition
                  show={profileDropdown}
                  enter='transition ease-out duration-100'
                  enterFrom='transform opacity-0 scale-95'
                  enterTo='transform opacity-100 scale-100'
                  leave='transition ease-in duration-75'
                  leaveFrom='transform opacity-100 scale-100'
                  leaveTo='transform opacity-0 scale-95'
                >
                  <div className='absolute right-0 w-48 mt-2 origin-top-right rounded-md shadow-lg'>
                    <div
                      className='p-1 bg-white divide-y rounded-md shadow-xs divide-grey-50'
                      role='menu'
                      aria-orientation='vertical'
                      aria-labelledby='user-menu'
                    >
                      <Link
                        to={`/my-account/settings`}
                        onClick={() => {
                          setProfileDropdown(!profileDropdown);
                        }}

                        className='block w-full px-4 py-2 text-sm text-center transition duration-150 ease-in-out text-useroptions hover:bg-coolgrayhighlight'
                      >
                        My Account
                      </Link>
                      {cognitoUser?.data?.role.includes('student') ? (
                        null
                      ) : (
                        <button
                          type='button'
                          onClick={redirectToManage}
                          className='block w-full px-4 py-2 text-sm transition duration-150 ease-in-out text-useroptions hover:bg-coolgrayhighlight'
                          role='menuitem'
                        >
                          Payment Settings
                        </button>
                      )}
                      <Link
                        to={`/dashboard/faq`}
                        onClick={() => {
                          setProfileDropdown(!profileDropdown);
                        }}
                        className='block w-full px-4 py-2 text-sm text-center transition duration-150 ease-in-out text-useroptions hover:bg-coolgrayhighlight'
                      >
                        FAQ
                      </Link>
                      <a
                        class='email'
                        href={`mailto:info@musicchunks.com`}
                        onClick={() => {
                          setProfileDropdown(!profileDropdown);
                        }}
                        className='block w-full px-4 py-2 text-sm text-center transition duration-150 ease-in-out text-useroptions hover:bg-coolgrayhighlight'
                      >
                        Contact Us
                      </a>
                      <Link
                        to={`/dashboard/privacy-policy`}
                        onClick={() => {
                          setProfileDropdown(!profileDropdown);
                        }}
                        className='block w-full px-4 py-2 text-sm text-center transition duration-150 ease-in-out text-useroptions hover:bg-coolgrayhighlight'
                      >
                        Your Privacy Rights
                      </Link>
                      <Link
                        to={`/dashboard/terms-of-service`}
                        onClick={() => {
                          setProfileDropdown(!profileDropdown);
                        }}
                        className='block w-full px-4 py-2 text-sm text-center transition duration-150 ease-in-out text-useroptions hover:bg-coolgrayhighlight'
                      >
                        Terms and Conditions
                      </Link>

                      <button
                        type='button'
                        onClick={() => logout()}
                        className='block w-full px-4 py-2 text-sm transition duration-150 ease-in-out text-useroptions hover:bg-coolgrayhighlight'
                        role='menuitem'
                      >
                        Logout
                      </button>
                    </div>
                  </div>
                </Transition>
              </div>
            </div>
          </main>

        </div>
        <div className='relative z-0 flex-1 pb-8 overflow-y-auto'>
          {/* Page content */}
          <div className='mt-8'>
            <div className='px-4 mx-auto max-w-7xl sm:px-6 lg:px-8 lg:max-w-full '>
              {loadingPage ? (
                <div className='flex items-center justify-center w-full h-screen'>
                  <CoffeeLoading />
                </div>
              ) : (
                <>
                  <Route
                    exact
                    path={`/dashboard`}
                    render={(props) => (
                      <Home
                        handleClearSearchBar={handleClearSearchBar}
                        cognitoUser={cognitoUser}
                        {...props}
                      />
                    )}
                  />
                  <Route
                    exact
                    path={`/dashboard/chunks`}
                    render={(props) => (
                      <ChunkList
                        handleClearSearchBar={handleClearSearchBar}
                        cognitoUser={cognitoUser}
                        {...props}
                      />
                    )}
                  />
                  <Route
                    path={`/dashboard/chunks/:id`}
                    render={(props) => (
                      <SingleChunk
                        cognitoUser={cognitoUser}
                        {...props}
                      />
                    )}
                  />
                  <Route
                    path={`/dashboard/collections/:collectionId/chunks/:id`}
                    render={(props) => (
                      <SingleChunk
                        cognitoUser={cognitoUser}
                        {...props}
                      />
                    )}
                  />
                  <Route
                    path={`/dashboard/your-chunks`}
                    render={(props) => (
                      <MyChunks
                        cognitoUser={cognitoUser}
                        {...props}
                      />
                    )}
                  />
                  <Route
                    exact
                    path={`/dashboard/publishers`}
                    render={(props) => (
                      <PublisherList
                        handleClearSearchBar={handleClearSearchBar}
                        cognitoUser={cognitoUser}
                        {...props}
                      />
                    )}
                  />
                  <Route
                    path={`/dashboard/publishers/:id`}
                    render={(props) => (
                      <PublisherPage
                        handleClearSearchBar={handleClearSearchBar}
                        cognitoUser={cognitoUser}
                        {...props}
                      />
                    )}
                  />
                  <Route
                    exact
                    path={`/dashboard/collections`}
                    render={(props) => (
                      <CollectionList
                        handleClearSearchBar={handleClearSearchBar}
                        cognitoUser={cognitoUser}
                        {...props}
                      />
                    )}
                  />
                  <Route
                    exact path={`/dashboard/collections/:collectionId`}
                    render={(props) => (
                      <CollectionPage
                        cognitoUser={cognitoUser}
                        handleClearSearchBar={handleClearSearchBar}
                        {...props}
                      />
                    )}
                  />
                  <Route
                    path={'/dashboard/type/showmusic'}
                    render={(props) => (
                      <CollectionTypePage
                        cognitoUser={cognitoUser}
                        handleClearSearchBar={handleClearSearchBar}
                        {...props}
                      />
                    )}
                  />
                  <Route
                    path={'/dashboard/type/exercises'}
                    render={(props) => (
                      <CollectionTypePage
                        cognitoUser={cognitoUser}
                        handleClearSearchBar={handleClearSearchBar}
                        {...props}
                      />
                    )}
                  />
                  <Route
                    path={'/dashboard/type/basics'}
                    render={(props) => (
                      <CollectionTypePage
                        cognitoUser={cognitoUser}
                        handleClearSearchBar={handleClearSearchBar}
                        {...props}
                      />
                    )}
                  />
                  <Route
                    path={'/dashboard/type/concerts'}
                    render={(props) => (
                      <CollectionTypePage
                        cognitoUser={cognitoUser}
                        handleClearSearchBar={handleClearSearchBar}
                        {...props}
                      />
                    )}
                  />
                  <Route
                    path={'/dashboard/type/jazz'}
                    render={(props) => (
                      <CollectionTypePage
                        cognitoUser={cognitoUser}
                        handleClearSearchBar={handleClearSearchBar}
                        {...props}
                      />
                    )}
                  />
                  <Route
                    path={'/dashboard/type/visuals'}
                    render={(props) => (
                      <CollectionTypePage
                        cognitoUser={cognitoUser}
                        handleClearSearchBar={handleClearSearchBar}
                        {...props}
                      />
                    )}
                  />
                  <Route
                    path={'/dashboard/type/learn'}
                    render={(props) => (
                      <CollectionTypePage
                        cognitoUser={cognitoUser}
                        handleClearSearchBar={handleClearSearchBar}
                        {...props}
                      />
                    )}
                  />

                  {/* PRIVATE AND PUBLIC ENSEMBLE ROUTES */}
                  <EnsembleUserRoute path={'/dashboard/ensembles/:groupID'} component={GroupPage} />
                  <PublicEnsembleUserRoute path={'/dashboard/public/ensembles/:groupID'} component={GroupPage} />

                  {/* INFO ROUTES */}
                  <Route path={`/dashboard/faq`} component={FAQPage} />
                  <Route path={`/dashboard/privacy-policy`} component={Privacy} />
                  <Route path={`/dashboard/terms-of-service`} component={TOS} />
                </>
              )}
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export const MemoizedDashboard = React.memo(Dashboard);