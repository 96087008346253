import React from 'react';

// ROUTING
import { Link } from 'react-router-dom';

// COMPONENTS
import NavBar from './NavBar';
import { Footer } from './Footer';

// ----------------------------------------------------------------------------------
// ------------------------------------ PRICES --------------------------------------
// ----------------------------------------------------------------------------------

export const Prices = () => {
  return (
    <>
    <NavBar />
      <div className='bg-mcdarkgreen'>
        <div className='px-4 pt-12 sm:px-6 lg:px-8 lg:pt-36'>
          <div className='text-center'>
            <p className='text-3xl font-extrabold leading-9 text-white mt- sm:text-4xl sm:leading-10 lg:text-5xl lg:leading-none'>
              Get the Subscription that fits your needs!
            </p>
            <p className='max-w-4xl mx-auto mt-3 text-xl leading-7 text-gray-300 sm:mt-5 sm:text-2xl sm:leading-8'>
              Beginning, intermediate, and advanced musicians. University, school, and studio
              music teachers. School and independent concert band, jazz band, color guard, marching
              band, drumline, steel drum, jazz, and combo ensembles, and many more like those. We
              built this platform to make your journey through the Arts better. Better technology,
              more accessibility, and more fun to use while practicing and growing!
            </p>
            <p className='max-w-4xl mx-auto mt-3 text-xl leading-7 text-gray-300 sm:mt-5 sm:text-2xl sm:leading-8'>
              {`Start your `}
              <span>
                <Link to='/signup' className='text-mcgreen hover:text-opacity-75'>
                  free 7 day trial
                </Link>
              </span> today, giving you access to all of Music Chunks excluding PDF downloads.
            </p>
          </div>
        </div>

        <div className='pb-12 mt-16 bg-gray-50 lg:mt-20 lg:pb-20'>
          <div className='relative z-0'>
            <div className='absolute inset-0 h-5/6 bg-gradient-to-b from-mcdarkgreen lg:h-2/3'></div>
            <div className='max-w-screen-xl px-4 mx-auto sm:px-6 lg:px-8'>
              <div className='relative lg:grid lg:grid-cols-7'>
                <div className='max-w-md mx-auto lg:mx-0 lg:max-w-none lg:col-start-1 lg:col-end-3 lg:row-start-2 lg:row-end-3'>
                  <div className='flex flex-col h-full overflow-hidden rounded-lg shadow-lg lg:rounded-none lg:rounded-l-lg'>
                    <div className='flex flex-col flex-1'>
                      <div className='px-6 py-10 bg-white'>
                        <div>
                          <h3
                            className='text-2xl font-medium leading-8 text-center text-gray-900'
                            id='tier-hobby'
                          >
                            Standard Users
                          </h3>
                          <div className='flex items-center justify-center mt-4'>
                            <span className='flex items-start px-3 text-6xl leading-none tracking-tight text-gray-900'>
                              <span className='mt-2 mr-2 text-4xl font-medium'>
                                $
                              </span>
                              <span className='font-extrabold'>3.99</span>
                            </span>
                            <span className='text-xl font-medium leading-7 text-gray-500'>
                              /month
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className='flex flex-col justify-between flex-1 p-6 border-t-2 border-gray-100 bg-gray-50 sm:p-10 lg:p-6'>
                        <ul>
                          <li className='flex items-start'>
                            <div className='flex-shrink-0'>
                              <svg
                                className='w-6 h-6 text-green-500'
                                stroke='currentColor'
                                fill='none'
                                viewBox='0 0 24 24'
                              >
                                <path
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                  strokeWidth='2'
                                  d='M5 13l4 4L19 7'
                                />
                              </svg>
                            </div>
                            <p className='ml-3 text-base font-medium leading-6 text-gray-500'>
                              THIS IS MEANT FOR THE INDIVIDUAL USER
                            </p>
                          </li>
                          <li className='flex items-start mt-3'>
                            <div className='flex-shrink-0'>
                              <svg
                                className='w-6 h-6 text-green-500'
                                stroke='currentColor'
                                fill='none'
                                viewBox='0 0 24 24'
                              >
                                <path
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                  strokeWidth='2'
                                  d='M5 13l4 4L19 7'
                                />
                              </svg>
                            </div>
                            <p className='ml-3 text-base font-medium leading-6 text-gray-500'>
                              ACCESS MUSIC CHUNKS’ ENTIRE LIBRARY OF MUSIC AND EDUCATIONAL CONTENT
                            </p>
                          </li>
                          <li className='flex items-start mt-3'>
                            <div className='flex-shrink-0'>
                              <svg
                                className='w-6 h-6 text-green-500'
                                stroke='currentColor'
                                fill='none'
                                viewBox='0 0 24 24'
                              >
                                <path
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                  strokeWidth='2'
                                  d='M5 13l4 4L19 7'
                                />
                              </svg>
                            </div>
                            <p className='ml-3 text-base font-medium text-gray-500 leading-'>
                              PRACTICE AND LEARN WITH EASE USING THE INTERACTIVE PLAYER
                            </p>
                          </li>
                          <li className='flex items-start mt-3'>
                            <div className='flex-shrink-0'>
                              <svg
                                className='w-6 h-6 text-green-500'
                                stroke='currentColor'
                                fill='none'
                                viewBox='0 0 24 24'
                              >
                                <path
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                  strokeWidth='2'
                                  d='M5 13l4 4L19 7'
                                />
                              </svg>
                            </div>
                            <p className='ml-3 text-base font-medium leading-6 text-gray-500'>
                              VIEW AND PLAY WHOLE SCORES OR INDIVIDUAL PARTS
                            </p>
                          </li>
                          <li className='flex items-start mt-3'>
                            <div className='flex-shrink-0'>
                              <svg
                                className='w-6 h-6 text-green-500'
                                stroke='currentColor'
                                fill='none'
                                viewBox='0 0 24 24'
                              >
                                <path
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                  strokeWidth='2'
                                  d='M5 13l4 4L19 7'
                                />
                              </svg>
                            </div>
                            <p className='ml-3 text-base font-medium leading-6 text-gray-500'>
                              PRINT FUNDAMENTALS AND ETUDES FROM THE MUSIC CHUNKS "BAND BASICS" COLLECTION
                            </p>
                          </li>
                          <li className='flex items-start mt-3'>
                            <div className='flex-shrink-0'>
                              <svg
                                className='w-6 h-6 text-green-500'
                                stroke='currentColor'
                                fill='none'
                                viewBox='0 0 24 24'
                              >
                                <path
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                  strokeWidth='2'
                                  d='M5 13l4 4L19 7'
                                />
                              </svg>
                            </div>
                            <p className='ml-3 text-base font-medium leading-6 text-gray-500'>
                              SAVE CHUNKS TO YOUR OWN PLAYLIST FOR QUICK ACCESS
                            </p>
                          </li>
                        </ul>
                        <div className='mt-8'>
                          <div className='rounded-lg shadow-md'>
                            <Link
                              to='/signup'
                              className='block w-full px-6 py-3 text-base font-medium leading-6 text-center transition duration-150 ease-in-out bg-mcbuttondarkgreen border border-transparent rounded-lg text-mcgreen hover:bg-mcgreen hover:text-mcbuttondarkgreen focus:outline-none focus:shadow-outline'
                              aria-describedby='tier-free'
                            >
                              Sign up now!
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div className='max-w-md mx-auto mt-10 lg:mt-0 lg:max-w-none lg:mx-0 lg:col-start-3 lg:col-end-6 lg:row-start-1 lg:row-end-4'> */}
                <div className='max-w-md mx-auto mt-10 lg:mt-0 lg:max-w-none lg:mx-0 lg:col-start-3 lg:col-end-6 lg:row-start-1 lg:row-end-4'>
                  <div className='relative z-10 rounded-lg shadow-xl'>
                    <div className='absolute inset-0 border-2 rounded-lg pointer-events-none border-mcgreen'></div>
                    <div className='absolute inset-x-0 top-0 transform translate-y-px'>
                      <div className='flex justify-center transform -translate-y-1/2'>
                        <span className='inline-flex px-4 py-1 text-sm font-semibold leading-5 tracking-wider text-white uppercase rounded-full bg-mcgreen'>
                          Most Popular
                        </span>
                      </div>
                    </div>
                    <div className='px-6 pt-12 pb-10 bg-white rounded-t-lg'>
                      <div>
                        <h3
                          className='text-3xl font-semibold leading-9 text-center text-gray-900 sm:-mx-6'
                          id='tier-growth'
                        >
                          Ensembles
                        </h3>
                        <div className='flex items-center justify-center mt-4'>
                          <span className='flex items-start px-3 text-6xl leading-none tracking-tight text-gray-900 sm:text-6xl'>
                            <span className='mt-2 mr-2 text-4xl font-medium'>
                              $
                            </span>
                            <span className='font-extrabold'>20</span>
                          </span>
                          <span className='text-2xl font-medium leading-8 text-gray-500'>
                            /year/student
                          </span>
                        </div>
                        <p className='text-xs mt-2'>***monthly student options available. Contact us
                          at <span className='text-mcgreen hover:text-opacity-75'>
                            <a
                              class='email'
                              href={`mailto:info@musicchunks.com`}
                            >
                              info@musicchunks.com
                            </a>
                          </span> to schedule a demo and to learn about the billing process.
                        </p>
                      </div>
                    </div>
                    <div className='px-6 pt-10 pb-8 border-t-2 border-gray-100 rounded-b-lg bg-gray-50 sm:px-10 sm:py-10'>
                      <ul>
                        <li className='flex items-start'>
                          <div className='flex-shrink-0'>
                            <svg
                              className='w-6 h-6 text-green-500'
                              fill='none'
                              viewBox='0 0 24 24'
                              stroke='currentColor'
                            >
                              <path
                                strokeLinecap='round'
                                strokeLinejoin='round'
                                strokeWidth='2'
                                d='M5 13l4 4L19 7'
                              />
                            </svg>
                          </div>
                          <p className='ml-3 text-base font-medium leading-6 text-gray-500'>
                            DISCOUNTED RATE WITH 10 OR MORE STUDENTS
                          </p>
                        </li>
                        <li className='flex items-start mt-4'>
                          <div className='flex-shrink-0'>
                            <svg
                              className='w-6 h-6 text-green-500'
                              fill='none'
                              viewBox='0 0 24 24'
                              stroke='currentColor'
                            >
                              <path
                                strokeLinecap='round'
                                strokeLinejoin='round'
                                strokeWidth='2'
                                d='M5 13l4 4L19 7'
                              />
                            </svg>
                          </div>
                          <p className='ml-3 text-base font-medium leading-6 text-gray-500'>
                            EVERYTHING INCLUDED WITH THE STANDARD ACCOUNT
                          </p>
                        </li>
                        <li className='flex items-start mt-4'>
                          <div className='flex-shrink-0'>
                            <svg
                              className='w-6 h-6 text-green-500'
                              fill='none'
                              viewBox='0 0 24 24'
                              stroke='currentColor'
                            >
                              <path
                                strokeLinecap='round'
                                strokeLinejoin='round'
                                strokeWidth='2'
                                d='M5 13l4 4L19 7'
                              />
                            </svg>
                          </div>
                          <p className='ml-3 text-base font-medium leading-6 text-gray-500'>
                            UPLOAD YOUR OWN CONTENT FOR YOUR ENSEMBLE’S PRIVATE USE
                          </p>
                        </li>
                        <li className='flex items-start mt-4'>
                          <div className='flex-shrink-0'>
                            <svg
                              className='w-6 h-6 text-green-500'
                              fill='none'
                              viewBox='0 0 24 24'
                              stroke='currentColor'
                            >
                              <path
                                strokeLinecap='round'
                                strokeLinejoin='round'
                                strokeWidth='2'
                                d='M5 13l4 4L19 7'
                              />
                            </svg>
                          </div>
                          <p className='ml-3 text-base font-medium leading-6 text-gray-500'>
                            CREATE UNLIMITED ENSEMBLES WITH PRIVATE ACCESS
                          </p>
                        </li>
                        <li className='flex items-start mt-4'>
                          <div className='flex-shrink-0'>
                            <svg
                              className='w-6 h-6 text-green-500'
                              stroke='currentColor'
                              fill='none'
                              viewBox='0 0 24 24'
                            >
                              <path
                                strokeLinecap='round'
                                strokeLinejoin='round'
                                strokeWidth='2'
                                d='M5 13l4 4L19 7'
                              />
                            </svg>
                          </div>
                          <p className='ml-3 text-base font-medium leading-6 text-gray-500'>
                            CREATE AND ORGANIZE CUSTOM PLAYLISTS AROUND YOUR NEEDS
                          </p>
                        </li>
                        <li className='flex items-start mt-4'>
                          <div className='flex-shrink-0'>
                            <svg
                              className='w-6 h-6 text-green-500'
                              stroke='currentColor'
                              fill='none'
                              viewBox='0 0 24 24'
                            >
                              <path
                                strokeLinecap='round'
                                strokeLinejoin='round'
                                strokeWidth='2'
                                d='M5 13l4 4L19 7'
                              />
                            </svg>
                          </div>
                          <p className='ml-3 text-base font-medium leading-6 text-gray-500'>
                            ADMIN CONTROL TO ADD OR DELETE STUDENTS
                          </p>
                        </li>
                        <li className='flex items-start mt-4'>
                          <div className='flex-shrink-0'>
                            <svg
                              className='w-6 h-6 text-green-500'
                              stroke='currentColor'
                              fill='none'
                              viewBox='0 0 24 24'
                            >
                              <path
                                strokeLinecap='round'
                                strokeLinejoin='round'
                                strokeWidth='2'
                                d='M5 13l4 4L19 7'
                              />
                            </svg>
                          </div>
                          <p className='ml-3 text-base font-medium leading-6 text-gray-500'>
                            FREE TEACHER/STAFF ACCOUNTS - 5 FOR EACH SCHOOL
                          </p>
                        </li>
                        <li className='flex items-start mt-4'>
                          <div className='flex-shrink-0'>
                            <svg
                              className='w-6 h-6 text-green-500'
                              stroke='currentColor'
                              fill='none'
                              viewBox='0 0 24 24'
                            >
                              <path
                                strokeLinecap='round'
                                strokeLinejoin='round'
                                strokeWidth='2'
                                d='M5 13l4 4L19 7'
                              />
                            </svg>
                          </div>
                          <p className='ml-3 text-base font-medium leading-6 text-gray-500'>
                            ONE FREE MONTH TRIAL FOR THE ENTIRE ENSEMBLE
                          </p>
                        </li>
                      </ul>
                      <div className='mt-8'>
                        <div className='rounded-lg shadow-md'>
                          <a
                            href='https://forms.monday.com/forms/5f11f8fc03d9eadd954db240e52ed0df'
                            className='block w-full px-6 py-3 text-base font-medium leading-6 text-center transition duration-150 ease-in-out bg-mcbuttondarkgreen border border-transparent rounded-lg text-mcgreen hover:bg-mcgreen hover:text-mcbuttondarkgreen focus:outline-none focus:shadow-outline'
                            aria-describedby='tier-free'
                            target='_blank'
                          >
                            Get a quote!
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='max-w-md mx-auto mt-10 lg:m-0 lg:max-w-none lg:col-start-6 lg:col-end-8 lg:row-start-2 lg:row-end-3'>
                  <div className='flex flex-col h-full overflow-hidden rounded-lg shadow-lg lg:rounded-none lg:rounded-r-lg'>
                    <div className='flex flex-col flex-1'>
                      <div className='px-6 py-10 bg-white'>
                        <div>
                          <h3
                            className='text-2xl font-medium leading-8 text-center text-gray-900'
                            id='tier-scale'
                          >
                            Teachers
                          </h3>
                          <div className='flex items-center justify-center mt-4'>
                            <span className='flex items-start px-3 text-6xl leading-none tracking-tight text-gray-900 sm:text-6xl'>
                              <span className='mt-2 mr-2 text-4xl font-medium'>
                                $
                              </span>
                              <span className='font-extrabold'>5.99</span>
                            </span>
                            <span className='text-2xl font-medium leading-8 text-gray-500'>
                              /month
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className='flex flex-col justify-between flex-1 p-6 border-t-2 border-gray-100 bg-gray-50 sm:p-10 lg:p-6'>
                        <ul>
                          <li className='flex items-start'>
                            <div className='flex-shrink-0'>
                              <svg
                                className='w-6 h-6 text-green-500'
                                stroke='currentColor'
                                fill='none'
                                viewBox='0 0 24 24'
                              >
                                <path
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                  strokeWidth='2'
                                  d='M5 13l4 4L19 7'
                                />
                              </svg>
                            </div>
                            <p className='ml-3 text-base font-medium leading-6 text-gray-500'>
                              THIS IS MEANT FOR TEACHERS AND LESSON PROVIDERS
                            </p>
                          </li>
                          <li className='flex items-start mt-3'>
                            <div className='flex-shrink-0'>
                              <svg
                                className='w-6 h-6 text-green-500'
                                stroke='currentColor'
                                fill='none'
                                viewBox='0 0 24 24'
                              >
                                <path
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                  strokeWidth='2'
                                  d='M5 13l4 4L19 7'
                                />
                              </svg>
                            </div>
                            <p className='ml-3 text-base font-medium leading-6 text-gray-500'>
                              EVERYTHING INCLUDED WITH THE STANDARD ACCOUNT
                            </p>
                          </li>
                          <li className='flex items-start mt-3'>
                            <div className='flex-shrink-0'>
                              <svg
                                className='w-6 h-6 text-green-500'
                                stroke='currentColor'
                                fill='none'
                                viewBox='0 0 24 24'
                              >
                                <path
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                  strokeWidth='2'
                                  d='M5 13l4 4L19 7'
                                />
                              </svg>
                            </div>
                            <p className='ml-3 text-base font-medium leading-6 text-gray-500'>
                              ACCESS TO THE PRIVATE ENSEMBLE PORTAL TO ORGANIZE LESSONS
                            </p>
                          </li>
                          <li className='flex items-start mt-3'>
                            <div className='flex-shrink-0'>
                              <svg
                                className='w-6 h-6 text-green-500'
                                stroke='currentColor'
                                fill='none'
                                viewBox='0 0 24 24'
                              >
                                <path
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                  strokeWidth='2'
                                  d='M5 13l4 4L19 7'
                                />
                              </svg>
                            </div>
                            <p className='ml-3 text-base font-medium leading-6 text-gray-500'>
                              UPLOAD YOUR OWN CONTENT FOR YOUR PRIVATE USE
                            </p>
                          </li>
                          <li className='flex items-start mt-3'>
                            <div className='flex-shrink-0'>
                              <svg
                                className='w-6 h-6 text-green-500'
                                stroke='currentColor'
                                fill='none'
                                viewBox='0 0 24 24'
                              >
                                <path
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                  strokeWidth='2'
                                  d='M5 13l4 4L19 7'
                                />
                              </svg>
                            </div>
                            <p className='ml-3 text-base font-medium leading-6 text-gray-500'>
                              CAN'T INVITE STUDENTS WITH THIS ACCOUNT
                            </p>
                          </li>
                        </ul>
                        <div className='mt-8'>
                          <div className='rounded-lg shadow-md'>
                            <Link
                              to='/signup'
                              className='block w-full px-6 py-3 text-base font-medium leading-6 text-center transition duration-150 ease-in-out bg-mcbuttondarkgreen border border-transparent rounded-lg text-mcgreen hover:bg-mcgreen hover:text-mcbuttondarkgreen focus:outline-none focus:shadow-outline'
                              aria-describedby='tier-free'
                            >
                              Sign up now!
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};