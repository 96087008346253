import React, { useState } from 'react';

// ROUTING
import { Route, Link, useHistory } from 'react-router-dom';

// AUTH
import { Auth } from '@aws-amplify/auth';

// STREAM CHAT
import { StreamChat } from 'stream-chat';

// STATE
import { useRecoilValue } from 'recoil';
import { cogUserState } from '../../state/atoms';

// COMPONENTS
import PublishersTab from './PublishersTab';
import UserTab from './UserTab';

// LOGO
import { ReactComponent as MCLOGO } from '../../img/MCLOGONEW.svg';

const client = StreamChat.getInstance(process.env.REACT_APP_STREAM_API_KEY)

// ----------------------------------------------------------------------------------
// --------------------------------- MY ACCOUNT PAGE --------------------------------
// ----------------------------------------------------------------------------------

const MyAccountPage = () => {
  // State
  const history = useHistory();
  const cognitoUser = useRecoilValue(cogUserState(localStorage.getItem(process.env.REACT_APP_USER_ID)))   // Get user from our DB
  const url = window.location.href; // GRABS REFERENCE TO THE CURRENT URL TO CHECK WHICH PLAN DATA TO LOAD
  const [canvasMenu, setCanvasMenu] = useState(false);

  // Function for handling logout
  let logout = () => {
    Auth.signOut()
    client.disconnectUser();
    localStorage.clear()
    history.push('/')
  };

  return (
    <div class="h-screen bg-white overflow-hidden flex">
      {/* <!-- Off-canvas menu for mobile, show/hide based on off-canvas menu state. --> */}
      {!canvasMenu ? null : (
        <div class="fixed inset-0 z-40 flex md:hidden" role="dialog" aria-modal="true">
          <div class="fixed inset-0 bg-gray-600 bg-opacity-75" aria-hidden="true"></div>
          <div class="relative max-w-xs w-full bg-white pt-5 pb-4 flex-1 flex flex-col">
            <div class="absolute top-0 right-0 -mr-14 p-1">
              <button
                class="h-12 w-12 rounded-full flex items-center justify-center focus:outline-none focus:bg-gray-600"
                onClick={() => {
                  setCanvasMenu(false)
                }}
              >
                <svg class="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
                <span class="sr-only">Close sidebar</span>
              </button>
            </div>

            <div class="flex-shrink-0 px-4 flex object-center">
              <MCLOGO className='w-auto h-25' />
            </div>
            <div class="mt-5 flex-1 h-0 overflow-y-auto">
              <nav class="h-full flex flex-col">
                <div class="space-y-1">
                  <Link
                    to="/dashboard"
                    class={url.includes('dashboard') ?
                      "bg-purple-50 border-purple-600 text-purple-600 group border-l-4 py-2 px-3 flex items-center text-base font-medium" :
                      "border-transparent text-gray-600 hover:bg-gray-50 hover:text-gray-900 group border-l-4 py-2 px-3 flex items-center text-base font-medium"}
                    onClick={() => setCanvasMenu(!canvasMenu)}
                  >
                    <svg
                      class={url.includes('dashboard') ?
                        "text-purple-500 mr-4 h-6 w-6" :
                        "text-gray-400 group-hover:text-gray-500 mr-4 h-6 w-6"}
                      xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true"
                    >
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
                    </svg>
                  Dashboard
                </Link>
                  <Link
                    to="/my-account/publishers/general"
                    class={url.includes('my-account/publishers') ?
                      "bg-purple-50 border-purple-600 text-purple-600 group border-l-4 py-2 px-3 flex items-center text-base font-medium" :
                      "border-transparent text-gray-600 hover:bg-gray-50 hover:text-gray-900 group border-l-4 py-2 px-3 flex items-center text-base font-medium"}
                    onClick={() => setCanvasMenu(!canvasMenu)}
                  >
                    <svg
                      class={url.includes('my-account/publishers') ?
                        "text-purple-500 mr-4 h-6 w-6" :
                        "text-gray-400 group-hover:text-gray-500 mr-4 h-6 w-6"}
                      xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true"
                    >
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z" />
                    </svg>
                  Publishers
                </Link>
                  <Link
                    to="/my-account/settings"
                    class={url.includes('my-account/settings') ?
                      "bg-purple-50 border-purple-600 text-purple-600 group border-l-4 py-2 px-3 flex items-center text-base font-medium" :
                      "border-transparent text-gray-600 hover:bg-gray-50 hover:text-gray-900 group border-l-4 py-2 px-3 flex items-center text-base font-medium"}
                    onClick={() => setCanvasMenu(!canvasMenu)}
                  >
                    <svg
                      class={url.includes('my-account/settings') ?
                        "text-purple-500 mr-4 h-6 w-6" :
                        "text-gray-400 group-hover:text-gray-500 mr-4 h-6 w-6"}
                      xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true"
                    >
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z" />
                    </svg>
                  User Settings
                </Link>
                </div>

                <div class="mt-auto pt-10 space-y-1">
                  {/* <a href="#" class="group border-l-4 border-transparent py-2 px-3 flex items-center text-base font-medium text-gray-600 hover:text-gray-900 hover:bg-gray-50"> */}
                  {/* <!-- Heroicon name: outline/question-mark-circle --> */}
                  {/* <svg class="mr-4 h-6 w-6 text-gray-400 group-hover:text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
              Help
            </a> */}
                  <button
                    class="group border-l-4 border-transparent py-2 px-3 flex items-center text-sm font-medium text-gray-600 hover:text-gray-900 hover:bg-gray-50"
                    onClick={() => logout()}
                  >
                    <svg class="mr-4 h-6 w-6 text-gray-400 group-hover:text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                    </svg>
              Logout
            </button>
                </div>
              </nav>
            </div>
          </div>

          <div class="flex-shrink-0 w-14" aria-hidden="true">
            {/* <!-- Dummy element to force sidebar to shrink to fit close icon --> */}
          </div>
        </div>
      )}

      {/* <!-- Static sidebar for desktop --> */}
      <div class="hidden md:flex md:flex-shrink-0">
        <div class="w-64 flex flex-col">
          {/* <!-- Sidebar component, swap this element with another sidebar if you like --> */}
          <nav class="bg-gray-50 border-r border-gray-200 pt-5 pb-4 flex flex-col flex-grow overflow-y-auto">
            <div class="flex-shrink-0 px-4 flex object-center">
              <MCLOGO className='w-auto h-25' />
            </div>
            <div class="flex-grow mt-5 flex flex-col">
              <div class="flex-1 space-y-1">
                <Link
                  to="/dashboard"
                  class={url.includes('dashboard') ?
                    "bg-purple-50 border-purple-600 text-purple-600 group border-l-4 py-2 px-3 flex items-center text-sm font-medium" :
                    "border-transparent text-gray-600 hover:text-gray-900 hover:bg-gray-50 group border-l-4 py-2 px-3 flex items-center text-sm font-medium"}
                >
                  <svg
                    class={url.includes('dashboard') ?
                      "text-purple-500 mr-3 h-6 w-6" :
                      "text-gray-400 group-hover:text-gray-500 mr-3 h-6 w-6"}
                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true"
                  >
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
                  </svg>
                  Dashboard
                </Link>
                <Link
                  to="/my-account/publishers/general"
                  class={url.includes('my-account/publishers') ?
                    "bg-purple-50 border-purple-600 text-purple-600 group border-l-4 py-2 px-3 flex items-center text-sm font-medium" :
                    "border-transparent text-gray-600 hover:text-gray-900 hover:bg-gray-50 group border-l-4 py-2 px-3 flex items-center text-sm font-medium"}
                >
                  <svg
                    class={url.includes('my-account/publishers') ?
                      "text-purple-500 mr-3 h-6 w-6" :
                      "text-gray-400 group-hover:text-gray-500 mr-3 h-6 w-6"}
                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true"
                  >
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z" />
                  </svg>
              Publishers
            </Link>
                <Link
                  to="/my-account/settings"
                  class={url.includes('my-account/settings') ?
                    "bg-purple-50 border-purple-600 text-purple-600 group border-l-4 py-2 px-3 flex items-center text-sm font-medium" :
                    "border-transparent text-gray-600 hover:text-gray-900 hover:bg-gray-50 group border-l-4 py-2 px-3 flex items-center text-sm font-medium"}
                >
                  <svg
                    class={url.includes('my-account/settings') ?
                      "text-purple-500 mr-3 h-6 w-6" :
                      "text-gray-400 group-hover:text-gray-500 mr-3 h-6 w-6"}
                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true"
                  >
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                  </svg>
              User Settings
            </Link>
              </div>
            </div>

            {/* Bottom sidebar */}
            <div class="flex-shrink-0 block w-full">
              {/* <a href="#" class="group border-l-4 border-transparent py-2 px-3 flex items-center text-sm font-medium text-gray-600 hover:text-gray-900 hover:bg-gray-50">
                <svg class="text-gray-400 group-hover:text-gray-500 mr-3 h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
                Help
              </a> */}
              <button
                class="group border-l-4 border-transparent py-2 px-3 flex items-center text-sm font-medium text-gray-600 hover:text-gray-900 hover:bg-gray-50"
                onClick={() => logout()}
              >
                {/* <!-- Heroicon name: outline/cog --> */}
                <svg class="text-gray-400 group-hover:text-gray-500 mr-3 h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                </svg>
                Logout
              </button>
            </div>
          </nav>
        </div>
      </div>

      {/* <!-- Content area --> */}
      <div class="flex-1 flex flex-col">
        {/* MOBILE MENU BUTTON */}
        <button
          onClick={() => setCanvasMenu(!canvasMenu)}
          className='md:hidden w-14 text-sidebaricon hover:rounded-md focus:outline-none hover:bg-coolgrayhighlight'
          aria-label='Open sidebar'
        >
          <svg
            className='w-6 h-6 m-4 transition duration-150 ease-in-out'
            fill='none'
            viewBox='0 0 24 24'
            stroke='currentColor'
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth='2'
              d='M4 6h16M4 12h8m-8 6h16'
            />
          </svg>
        </button>

        {/* <div class="w-full max-w-4xl mx-auto md:px-8 xl:px-0">
          <div class="relative z-10 flex-shrink-0 h-16 bg-white border-b border-gray-200 flex">
            <button class="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-purple-500 md:hidden">
              <span class="sr-only">Open sidebar</span> */}
        {/* <!-- Heroicon name: outline/menu-alt-2 --> */}
        {/* <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h7" />
              </svg>
            </button>
            <div class="flex-1 flex justify-between px-4 md:px-0">
              <div class="flex-1 flex">
                <form class="w-full flex md:ml-0" action="#" method="GET">
                  <label for="search_field" class="sr-only">Search</label>
                  <div class="relative w-full text-gray-400 focus-within:text-gray-600">
                    <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center"> */}
        {/* <!-- Heroicon name: solid/search --> */}
        {/* <svg class="flex-shrink-0 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd" />
                      </svg>
                    </div>
                    <input name="search_field" id="search_field" class="h-full w-full border-transparent py-2 pl-8 pr-3 text-base text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-0 focus:border-transparent focus:placeholder-gray-400 sm:hidden" placeholder="Search" type="search" />
                    <input name="search_field" id="search_field" class="hidden h-full w-full border-transparent py-2 pl-8 pr-3 text-sm text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-0 focus:border-transparent focus:placeholder-gray-400 sm:block" placeholder="Search jobs, applicants, and more" type="search" />
                  </div>
                </form>
              </div>
              <div class="ml-4 flex items-center md:ml-6">
                <button class="bg-white rounded-full p-1 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"> */}
        {/* <!-- Heroicon name: outline/bell --> */}
        {/* <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
                  </svg>
                  <span class="sr-only">View notifications</span>
                </button>
              </div>
            </div>
          </div>
        </div> */}

        {/* ROUTES */}
        <Route
          path={`/my-account/publishers`}
          render={(props) => (
            <PublishersTab
              cognitoUser={cognitoUser}
              {...props}
            />
          )}
        />

        <Route
          exact path={`/my-account/settings`}
          render={(props) => (
            <UserTab
              cognitoUser={cognitoUser}
              {...props}
            />
          )}
        />
      </div>
    </div>
  );
};

export default MyAccountPage;