import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import axiosRetry from 'axios-retry';
import { v4 as uuidv4 } from 'uuid';
import S3 from 'react-aws-s3';

// FORM MANAGEMENT
import { useForm } from 'react-hook-form';

// TOAST
import cogoToast from 'cogo-toast';

// ----------------------------------------------------------------------------------
// ----------------------------- GROUP EDIT GROUP FORM ------------------------------
// ----------------------------------------------------------------------------------

const GroupEditGroupForm = ({ group, refresh, setRefresh }) => {
  const {
    name,
    description,
    profile_img,
    banner_img
  } = group;

  // State
  const [isEditing, setIsEditing] = useState(false);

  // Image State
  const [loadingImg, setLoadingImg] = useState(false);
  const [image, setImage] = useState('');
  const [loadingImg2, setLoadingImg2] = useState(false);
  const [image2, setImage2] = useState('');

  // Form
  const { register, handleSubmit, errors, reset } = useForm({
    mode: 'onChange',
  });

  // Set axios to retry a set number of times on failed request/return
  axiosRetry(axios, { retries: 3 })

  // UseEffect that sets profile and banner pics for ensembles
  useEffect(() => {
    setImage(profile_img);
    setImage2(banner_img);
  }, [profile_img, banner_img]);

  // Function that adds ensemble profile images to our Amazon Web Services S3 bucket
  const fileInput = useRef();

  const uploadImg = async (e) => {
    e.preventDefault();

    let file = fileInput.current.files[0]
    let newFileName = fileInput.current.files[0].name
    let newFileNameWithUUID = 'ensembles/' + newFileName.substring(0, newFileName.lastIndexOf('.')) + '_' + uuidv4();

    const config = {
      bucketName: process.env.REACT_APP_BUCKET_NAME,
      region: process.env.REACT_APP_REGION,
      accessKeyId: process.env.REACT_APP_ACCESS_ID,
      secretAccessKey: process.env.REACT_APP_ACCESS_KEY,
    };

    const ReactS3Client = new S3(config);
    ReactS3Client.uploadFile(file, newFileNameWithUUID).then(data => {
      if (data.status === 204) {
        const imageBucketURL = data.location
        setImage(imageBucketURL);
        setLoadingImg(false);
        console.log('Image successfully uploaded to S3!');
        return data
      } else {
        console.log('Image failed upload to S3');
      }
    })
  };

  // Function that adds ensemble banner images to our Amazon Web Services S3 bucket
  const fileInput2 = useRef();

  const uploadImg2 = async (e) => {
    e.preventDefault();

    let file = fileInput2.current.files[0]
    let newFileName = fileInput2.current.files[0].name
    let newFileNameWithUUID = 'ensembles/' + newFileName.substring(0, newFileName.lastIndexOf('.')) + '_' + uuidv4();

    const config = {
      bucketName: process.env.REACT_APP_BUCKET_NAME,
      region: process.env.REACT_APP_REGION,
      accessKeyId: process.env.REACT_APP_ACCESS_ID,
      secretAccessKey: process.env.REACT_APP_ACCESS_KEY,
    };

    const ReactS3Client = new S3(config);
    ReactS3Client.uploadFile(file, newFileNameWithUUID).then(data => {
      if (data.status === 204) {
        const imageBucketURL = data.location
        setImage2(imageBucketURL);
        setLoadingImg2(false);
        console.log('Image successfully uploaded to S3!');
        return data
      } else {
        console.log('Image failed upload to S3');
      }
    })
  };

  // Function that handles submitting updates/edits for group data
  const onSubmit = (data) => {
    setIsEditing(false);

    axios
      ({
        method: 'put',
        url: process.env.REACT_APP_API + `groups/${group.id}`,
        headers: {
          Accept: 'application/json',
          Authorization: process.env.REACT_APP_HEROKU_SERVER_KEY
        }, data: {
          ...data,
          profile_img: image,
          banner_img: image2
        }
      })
      .then((res) => {
        setIsEditing(false)
        setRefresh(!refresh);
        cogoToast.success(`Ensemble edited successfully!`, {
          hideAfter: 5,
        });
      })
      .catch((err) => {
        console.log(err);
        setIsEditing(false)
        cogoToast.error(`There was an error editing the ensemble`, {
          hideAfter: 5,
        });
      });
  };

  return (
    <div className='mt-3'>
      <div className='bg-green-700 px-5 py-3 mt-2 rounded-lg'>
        <p className='whitespace-pre-wrap text-white'>
          Change the images, name, and description for your Ensemble's Home Page and About sections.
        </p>
      </div>

      <div className='mt-2 border-t-4 border-green-600 border-solid'>
        <h1 className='w-full pt-2 text-2xl font-medium leading-6 text-white'>
          EDIT ENSEMBLE
        </h1>
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='px-4 py-5 sm:px-6'>
          <dl className='grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3'>

            <div className='sm:col-span-1'>
              <dt className='text-md font-medium leading-5 text-white'>
                Name
              </dt>
              {isEditing ? (
                <input
                  id='name'
                  type='text'
                  name='name'
                  defaultValue={name}
                  ref={register({ required: true })}
                  className='block w-full px-3 py-2 mt-1 transition duration-150 ease-in-out border border-gray-300 rounded-md shadow-sm form-input focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5'
                />
              ) : (
                <dd className='mt-1 text-sm leading-5 text-white'>
                  {name}
                </dd>
              )}
              {errors.name && (
                <span className='text-red-500'>This field is required</span>
              )}
            </div>

            <div className='sm:col-span-1'>
              <dt className='text-md font-medium leading-5 text-white'>
                Description
              </dt>
              {isEditing ? (
                <textarea
                  id='description'
                  type='text'
                  name='description'
                  defaultValue={description}
                  rows='6'
                  ref={register}
                  className='block w-full px-3 py-2 mt-1 transition duration-150 ease-in-out border border-gray-300 rounded-md shadow-sm form-input focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5'
                />
              ) : (
                <dd className=' truncate-3-lines mt-1 text-sm leading-5 text-white'>
                  {description}
                </dd>
              )}
            </div>

            <div className='sm:col-span-3'>
              <dt className='text-md font-medium leading-5 text-white'>
                Ensemble Banner Image
                </dt>
              {isEditing ? (
                <div className='mt-2 sm:mt-0 sm:col-span-2'>
                  <div className='flex justify-center max-w-lg px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md'>
                    <div className='text-center'>
                      {image2 && !loadingImg2 ? (
                        <img
                          src={image2}
                          alt='chunk img'
                          className='w-full h-56'
                        />
                      ) : (
                        <svg
                          className='w-12 h-12 mx-auto text-gray-400'
                          stroke='currentColor'
                          fill='none'
                          viewBox='0 0 48 48'
                        >
                          <path
                            d='M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02'
                            strokeWidth='2'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      )}
                      {loadingImg2 ? (
                        <h3> Loading ...</h3>
                      ) : (
                        <h3>
                          <p className='mt-1 text-sm text-gray-600'>
                            <input
                              type='file'
                              name='file'
                              ref={fileInput2}
                              placeholder='Upload a banner pic here.'
                              onChange={uploadImg2}
                            />
                          </p>
                          <p className='mt-1 text-xs text-gray-500'>
                            PNG, JPG, SVG up to 150MB
                            </p>
                        </h3>
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                <dd className='mt-1'>
                  <img
                    src={image2}
                    alt='img for a single chunk'
                    className='inline-block object-cover w-2/3 h-48 rounded-md'
                  />
                </dd>
              )}
            </div>

            <div className='sm:col-span-1'>
              <dt className='text-md font-medium leading-5 text-white'>
                Ensemble Profile Image
                </dt>
              {isEditing ? (
                <div className='mt-2 sm:mt-0 sm:col-span-2'>
                  <div className='flex justify-center max-w-lg px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md'>
                    <div className='text-center'>
                      {image && !loadingImg ? (
                        <img
                          src={image}
                          alt='chunk img '
                          className='w-20 h-20'
                        />
                      ) : (
                        <svg
                          className='w-12 h-12 mx-auto text-gray-400'
                          stroke='currentColor'
                          fill='none'
                          viewBox='0 0 48 48'
                        >
                          <path
                            d='M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02'
                            strokeWidth='2'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      )}
                      {loadingImg ? (
                        <h3> Loading ...</h3>
                      ) : (
                        <h3>
                          <p className='mt-1 text-sm text-gray-600'>
                            <input
                              type='file'
                              name='file'
                              ref={fileInput}
                              placeholder='Upload an image here.'
                              onChange={uploadImg}
                            />
                          </p>
                          <p className='mt-1 text-xs text-gray-500'>
                            PNG, JPG, SVG up to 150MB
                            </p>
                        </h3>
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                <dd className='mt-1'>
                  <img
                    src={image}
                    alt='img for a single chunk'
                    className='inline-block object-cover w-20 h-20 rounded-md'
                  />
                </dd>
              )}
            </div>
          </dl>

          {!isEditing && (
            <div className='flex w-full justify-center sm:justify-end mt-5'>
              <button
                type='button'
                onClick={() => {
                  setIsEditing(!isEditing);
                }}
                className=' mr-4 inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs leading-4 font-medium rounded text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150'
              >
                Edit
                </button>

            </div>
          )}
        </div>

        {/* SUBMIT EDITS OR DELETE */}
        {isEditing && (
          <div className='flex w-full justify-center sm:justify-end'>
            {' '}
            <button
              type='submit'
              className=' mr-4 inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs leading-4 font-medium rounded text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150'
            >
              Save
              </button>
            <button
              onClick={() => {
                setIsEditing(!isEditing);
                reset();
              }}
              type='reset'
              className='inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs leading-4 font-medium rounded text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150'
            >
              Cancel
              </button>
          </div>
        )}
      </form>
    </div>
  );
};

export const MemoizedGroupEditGroupForm = React.memo(GroupEditGroupForm);