import React from 'react';
import { Link } from 'react-router-dom';

// ----------------------------------------------------------------------------------
// -------------------------------------- TOS ---------------------------------------
// ----------------------------------------------------------------------------------

import { ReactComponent as Heroimg } from '../../img/MCLOGONEW.svg';

export const TOS = () => {
  const url = window.location.href; // GRABS REFERENCE TO THE CURRENT URL

  return (
    <div className='relative py-8 bg-hero-pattern bg-fixed bg-cover overflow-hidden'>
      {/* Only show the back button if routing through the landing page */}
      {url.includes('dashboard') ? null : (
        <div className='max-w-lg mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl '>
          <Link
            to='/'
            class='inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-mcgreen bg-mcbuttondarkgreen hover:bg-mcbuttonlightgreen focus:outline-none focus:border-mcdarkgreen focus:shadow-outline-teal active:bg-mcdarkgreen transition ease-in-out duration-150'
          >
            {/* <!-- Heroicon name: back --> */}
            <svg
              stroke='currentColor'
              fill='currentColor'
              className='mr-2'
              stroke-width='0'
              viewBox='0 0 512 512'
              height='1em'
              width='1em'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path d='M401.4 224h-214l83-79.4c11.9-12.5 11.9-32.7 0-45.2s-31.2-12.5-43.2 0L89 233.4c-6 5.8-9 13.7-9 22.4v.4c0 8.7 3 16.6 9 22.4l138.1 134c12 12.5 31.3 12.5 43.2 0 11.9-12.5 11.9-32.7 0-45.2l-83-79.4h214c16.9 0 30.6-14.3 30.6-32 .1-18-13.6-32-30.5-32z'></path>
            </svg>
          Go back
          </Link>
        </div>
      )}

      <div className='relative px-4 sm:px-6 lg:px-8'>
        <div className='text-lg max-w-prose mx-auto mb-6'>
          <p className='text-base text-center leading-6 text-mcgreen font-semibold tracking-wide uppercase'>
            Music Chunks
          </p>
          <h1 className='mt-2 mb-8 text-3xl text-center leading-8 font-extrabold tracking-tight text-faqheader sm:text-4xl sm:leading-10'>
            Terms and Conditions
          </h1>
          <p className='text-xl font-medium text-faqquestion leading-8'>
            Agreement between User and <a href='/'>www.musicchunks.com </a>
          </p>
          <p className='text-base text-gray-50 leading-8'>
            Welcome to www.musicchunks.com. The www.musicchunks.com website (the
            "Site") is comprised of various web pages operated by Synced Up
            Products ("SUP"). www.musicchunks.com is offered to you conditioned
            on your acceptance without modification of the terms, conditions,
            and notices contained herein (the "Terms"). Your use of
            www.musicchunks.com constitutes your agreement to all such Terms.
            Please read these terms carefully, and keep a copy of them for your
            reference.
          </p>
          <p className='text-base py-5 text-gray-50'>
            www.musicchunks.com is a
            <span className='font-medium text-faqquestion ml-1.5'>
              Music Education and Training Site.
            </span>
          </p>
        </div>
        <div className='prose prose-lg text-gray-50 mx-auto'>
          <p className='text-base'>
            Music Chunks is a subscription based, Music E-Learning platform.
            Music Chunk provides users access to an extensive catalog of
            "chunks", small sections of music that you can slow down, speed up,
            even break down into smaller sections to practice.
          </p>

          <p className='text-faqquestion text-lg font-black'>Privacy</p>
          <p className='text-base'>
            Your use of www.musicchunks.com is subject to SUP's Privacy Policy.
            Please review our Privacy Policy, which also governs the Site and
            informs users of our data collection practices.
          </p>

          <p className='text-faqquestion text-lg font-black'>
            Electronic Communications{' '}
          </p>
          <p className='text-base'>
            Visiting www.musicchunks.com or sending emails to SUP constitutes
            electronic communications. You consent to receive electronic
            communications and you agree that all agreements, notices,
            disclosures and other communications that we provide to you
            electronically, via email and on the Site, satisfy any legal
            requirement that such communications be in writing.
          </p>

          <p className='text-faqquestion text-lg font-black'>Your Account </p>
          <p className='text-base'>
            If you use this site, you are responsible for maintaining the
            confidentiality of your account and password and for restricting
            access to your computer, and you agree to accept responsibility for
            all activities that occur under your account or password. You may
            not assign or otherwise transfer your account to any other person or
            entity. You acknowledge that SUP is not responsible for third party
            access to your account that results from theft or misappropriation
            of your account. SUP and its associates reserve the right to refuse
            or cancel service, terminate accounts, or remove or edit content in
            our sole discretion.
          </p>

          <p className='text-faqquestion text-lg font-black'>
            Children Under Thirteen
          </p>
          <p className='text-base'>
            SUP does not knowingly collect, either online or offline, personal
            information from persons under the age of thirteen. If you are under
            18, you may use www.musicchunks.com only with permission of a parent
            or guardian.
          </p>

          <p className='text-faqquestion text-lg font-black'>
            Cancellation/Refund Policy
          </p>
          <p className='text-base'>
            Cancel at any time. Your subscription will end at the end of the
            billing cycle. No refunds available.
          </p>

          <p className='text-faqquestion text-lg font-black'>
            Links to Third Party Sites/Third Party Services
          </p>
          <p className='text-base'>
            www.musicchunks.com may contain links to other websites ("Linked
            Sites"). The Linked Sites are not under the control of SUP and SUP
            is not responsible for the contents of any Linked Site, including
            without limitation any link contained in a Linked Site, or any
            changes or updates to a Linked Site. SUP is providing these links to
            you only as a convenience, and the inclusion of any link does not
            imply endorsement by SUP of the site or any association with its
            operators.
          </p>
          <p className='text-base'>
            Certain services made available via www.musicchunks.com are
            delivered by third party sites and organizations. By using any
            product, service or functionality originating from the
            www.musicchunks.com domain, you hereby acknowledge and consent that
            SUP may share such information and data with any third party with
            whom SUP has a contractual relationship to provide the requested
            product, service or functionality on behalf of www.musicchunks.com
            users and customers.
          </p>

          <p className='text-faqquestion text-lg font-black'>
            No Unlawful or Prohibited Use/Intellectual Property
          </p>
          <p className='text-base'>
            You are granted a non-exclusive, non-transferable, revocable license
            to access and use www.musicchunks.com strictly in accordance with
            these terms of use. As a condition of your use of the Site, you
            warrant to SUP that you will not use the Site for any purpose that
            is unlawful or prohibited by these Terms. You may not use the Site
            in any manner which could damage, disable, overburden, or impair the
            Site or interfere with any other party's use and enjoyment of the
            Site. You may not obtain or attempt to obtain any materials or
            information through any means not intentionally made available or
            provided for through the Site.
          </p>
          <p className='text-base'>
            All content included as part of the Service, such as text, graphics,
            logos, images, as well as the compilation thereof, and any software
            used on the Site, is the property of SUP or its suppliers and
            protected by copyright and other laws that protect intellectual
            property and proprietary rights. You agree to observe and abide by
            all copyright and other proprietary notices, legends or other
            restrictions contained in any such content and will not make any
            changes thereto
          </p>
          <p className='text-base'>
            You will not modify, publish, transmit, reverse engineer,
            participate in the transfer or sale, create derivative works, or in
            any way exploit any of the content, in whole or in part, found on
            the Site. SUP content is not for resale. Your use of the Site does
            not entitle you to make any unauthorized use of any protected
            content, and in particular you will not delete or alter any
            proprietary rights or attribution notices in any content. You will
            use protected content solely for your personal use, and will make no
            other use of the content without the express written permission of
            SUP and the copyright owner. You agree that you do not acquire any
            ownership rights in any protected content. We do not grant you any
            licenses, express or implied, to the intellectual property of SUP or
            our licensors except as expressly authorized by these Terms.
          </p>

          <p className='text-faqquestion text-lg font-black'>
            Third Party Accounts
          </p>
          <p className='text-base'>
            You will be able to connect your SUP account to third party
            accounts. By connecting your SUP account to your third party
            account, you acknowledge and agree that you are consenting to the
            continuous release of information about you to others (in accordance
            with your privacy settings on those third party sites). If you do
            not want information about you to be shared in this manner, do not
            use this feature.
          </p>

          <p className='text-faqquestion text-lg font-black'>
            International Users
          </p>
          <p className='text-base'>
            The Service is controlled, operated and administered by SUP from our
            offices within the USA. If you access the Service from a location
            outside the USA, you are responsible for compliance with all local
            laws. You agree that you will not use the SUP Content accessed
            through www.musicchunks.com in any country or in any manner
            prohibited by any applicable laws, restrictions or regulations
          </p>

          <p className='text-faqquestion text-lg font-black'>Indemnification</p>
          <p className='text-base'>
            You agree to indemnify, defend and hold harmless SUP, its officers,
            directors, employees, agents and third parties, for any losses,
            costs, liabilities and expenses (including reasonable attorney's
            fees) relating to or arising out of your use of or inability to use
            the Site or services, any user postings made by you, your violation
            of any terms of this Agreement or your violation of any rights of a
            third party, or your violation of any applicable laws, rules or
            regulations. SUP reserves the right, at its own cost, to assume the
            exclusive defense and control of any matter otherwise subject to
            indemnification by you, in which event you will fully cooperate with
            SUP in asserting any available defenses.
          </p>

          <p className='text-faqquestion text-lg font-black'>Arbitration</p>
          <p className='text-base'>
            In the event the parties are not able to resolve any dispute between
            them arising out of or concerning these Terms and Conditions, or any
            provisions hereof, whether in contract, tort, or otherwise at law or
            in equity for damages or any other relief, then such dispute shall
            be resolved only by final and binding arbitration pursuant to the
            Federal Arbitration Act, conducted by a single neutral arbitrator
            and administered by the American Arbitration Association, or a
            similar arbitration service selected by the parties, in a location
            mutually agreed upon by the parties. The arbitrator's award shall be
            final, and judgment may be entered upon it in any court having
            jurisdiction. In the event that any legal or equitable action,
            proceeding or arbitration arises out of or concerns these Terms and
            Conditions, the prevailing party shall be entitled to recover its
            costs and reasonable attorney's fees. The parties agree to arbitrate
            all disputes and claims in regards to these Terms and Conditions or
            any disputes arising as a result of these Terms and Conditions,
            whether directly or indirectly, including Tort claims that are a
            result of these Terms and Conditions. The parties agree that the
            Federal Arbitration Act governs the interpretation and enforcement
            of this provision. The entire dispute, including the scope and
            enforceability of this arbitration provision shall be determined by
            the Arbitrator. This arbitration provision shall survive the
            termination of these Terms and Conditions.
          </p>

          <figure>
            <Heroimg />
          </figure>

          <p className='text-faqquestion text-lg font-black'>
            Class Action Waiver
          </p>
          <p className='text-base'>
            Any arbitration under these Terms and Conditions will take place on
            an individual basis; class arbitrations and
            class/representative/collective actions are not permitted. THE
            PARTIES AGREE THAT A PARTY MAY BRING CLAIMS AGAINST THE OTHER ONLY
            IN EACH'S INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS
            MEMBER IN ANY PUTATIVE CLASS, COLLECTIVE AND/ OR REPRESENTATIVE
            PROCEEDING, SUCH AS IN THE FORM OF A PRIVATE ATTORNEY GENERAL ACTION
            AGAINST THE OTHER. Further, unless both you and SUP agree otherwise,
            the arbitrator may not consolidate more than one person's claims,
            and may not otherwise preside over any form of a representative or
            class proceeding.
          </p>

          <p className='text-faqquestion text-lg font-black'>
            Liability Disclaimer
          </p>
          <p className='text-base'>
            THE INFORMATION, SOFTWARE, PRODUCTS, AND SERVICES INCLUDED IN OR
            AVAILABLE THROUGH THE SITE MAY INCLUDE INACCURACIES OR TYPOGRAPHICAL
            ERRORS. CHANGES ARE PERIODICALLY ADDED TO THE INFORMATION HEREIN.
            SYNCED UP PRODUCTS AND/OR ITS SUPPLIERS MAY MAKE IMPROVEMENTS AND/OR
            CHANGES IN THE SITE AT ANY TIME.
          </p>
          <p className='text-base'>
            SYNCED UP PRODUCTS AND/OR ITS SUPPLIERS MAKE NO REPRESENTATIONS
            ABOUT THE SUITABILITY, RELIABILITY, AVAILABILITY, TIMELINESS, AND
            ACCURACY OF THE INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND
            RELATED GRAPHICS CONTAINED ON THE SITE FOR ANY PURPOSE. TO THE
            MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, ALL SUCH INFORMATION,
            SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS ARE PROVIDED "AS
            IS" WITHOUT WARRANTY OR CONDITION OF ANY KIND. SYNCED UP PRODUCTS
            AND/OR ITS SUPPLIERS HEREBY DISCLAIM ALL WARRANTIES AND CONDITIONS
            WITH REGARD TO THIS INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND
            RELATED GRAPHICS, INCLUDING ALL IMPLIED WARRANTIES OR CONDITIONS OF
            MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND
            NON-INFRINGEMENT.
          </p>
          <p className='text-base'>
            TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL
            SYNCED UP PRODUCTS AND/OR ITS SUPPLIERS BE LIABLE FOR ANY DIRECT,
            INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL DAMAGES OR
            ANY DAMAGES WHATSOEVER INCLUDING, WITHOUT LIMITATION, DAMAGES FOR
            LOSS OF USE, DATA OR PROFITS, ARISING OUT OF OR IN ANY WAY CONNECTED
            WITH THE USE OR PERFORMANCE OF THE SITE, WITH THE DELAY OR INABILITY
            TO USE THE SITE OR RELATED SERVICES, THE PROVISION OF OR FAILURE TO
            PROVIDE SERVICES, OR FOR ANY INFORMATION, SOFTWARE, PRODUCTS,
            SERVICES AND RELATED GRAPHICS OBTAINED THROUGH THE SITE, OR
            OTHERWISE ARISING OUT OF THE USE OF THE SITE, WHETHER BASED ON
            CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE, EVEN IF
            SYNCED UP PRODUCTS OR ANY OF ITS SUPPLIERS HAS BEEN ADVISED OF THE
            POSSIBILITY OF DAMAGES. BECAUSE SOME STATES/JURISDICTIONS DO NOT
            ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR
            INCIDENTAL DAMAGES, THE ABOVE LIMITATION MAY NOT APPLY TO YOU. IF
            YOU ARE DISSATISFIED WITH ANY PORTION OF THE SITE, OR WITH ANY OF
            THESE TERMS OF USE, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE
            USING THE SITE.
          </p>

          <p className='text-faqquestion text-lg font-black'>
            Termination/Access Restriction
          </p>
          <p className='text-base'>
            SUP reserves the right, in its sole discretion, to terminate your
            access to the Site and the related services or any portion thereof
            at any time, without notice. To the maximum extent permitted by law,
            this agreement is governed by the laws of the State of Tennessee and
            you hereby consent to the exclusive jurisdiction and venue of courts
            in Tennessee in all disputes arising out of or relating to the use
            of the Site. Use of the Site is unauthorized in any jurisdiction
            that does not give effect to all provisions of these Terms,
            including, without limitation, this section.
          </p>
          <p className='text-base'>
            You agree that no joint venture, partnership, employment, or agency
            relationship exists between you and SUP as a result of this
            agreement or use of the Site. SUP's performance of this agreement is
            subject to existing laws and legal process, and nothing contained in
            this agreement is in derogation of SUP's right to comply with
            governmental, court and law enforcement requests or requirements
            relating to your use of the Site or information provided to or
            gathered by SUP with respect to such use. If any part of this
            agreement is determined to be invalid or unenforceable pursuant to
            applicable law including, but not limited to, the warranty
            disclaimers and liability limitations set forth above, then the
            invalid or unenforceable provision will be deemed superseded by a
            valid, enforceable provision that most closely matches the intent of
            the original provision and the remainder of the agreement shall
            continue in effect.
          </p>
          <p className='text-base'>
            Unless otherwise specified herein, this agreement constitutes the
            entire agreement between the user and SUP with respect to the Site
            and it supersedes all prior or contemporaneous communications and
            proposals, whether electronic, oral or written, between the user and
            SUP with respect to the Site. A printed version of this agreement
            and of any notice given in electronic form shall be admissible in
            judicial or administrative proceedings based upon or relating to
            this agreement to the same extent and subject to the same conditions
            as other business documents and records originally generated and
            maintained in printed form. It is the express wish to the parties
            that this agreement and all related documents be written in English.
          </p>

          <p className='text-faqquestion text-lg font-black'>Changes to Terms</p>
          <p className='text-base'>
            SUP reserves the right, in its sole discretion, to change the Terms
            under which www.musicchunks.com is offered. The most current version
            of the Terms will supersede all previous versions. SUP encourages
            you to periodically review the Terms to stay informed of our
            updates.
          </p>

          <p className='text-faqquestion text-lg font-black'>Contact Us</p>
          <p className='text-base'>
            SUP welcomes your questions or comments regarding the Terms:
          </p>
          <address>
            Synced Up Products <br></br>
            PO BOX 1324 <br></br>
            Smyrna, Tennessee 37167
          </address>
          <p className='text-base'>
            Email Address: <br></br>
            sales@syncedupdesigns.com
          </p>
          <p className='text-base'>
            Telephone number:<br></br>
            833-479-6233
          </p>
          <p className='text-base'>Effective as of September 18, 2020</p>
        </div>
      </div>
    </div>
  );
};
