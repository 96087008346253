import React from 'react';

// STATE
import { useRecoilValue } from 'recoil';
import { cogUserState } from '../state/atoms';
import { publicGroupState } from '../state/atoms';

// ROUTING
import { Route, Redirect } from 'react-router-dom';

// ----------------------------------------------------------------------------------
// -------------------------- PUBLIC ENSEMBLE USER ROUTE ----------------------------
// ----------------------------------------------------------------------------------

const PublicEnsembleUserRoute = ({ component: Component, ...rest }) => {
  const publicGroups = useRecoilValue(publicGroupState)
  const cognitoUser = useRecoilValue(
    cogUserState(
      localStorage.getItem(
        process.env.REACT_APP_USER_ID,
      ),
    ),
  ); // Get user from our DB

  return (
    <Route
      {...rest}
      render={rest => {
        var isAdmin = false
        var isPublic = true

        console.log(rest)

        // Checks to make sure ensemble/group is public and prevent users from typing ids in the URL
        publicGroups.map(publicGroup => {
          if (publicGroup.id === rest.match.params.groupID) {
            isPublic = true
          } else {
            isPublic = false
          }
        })

        // Locks out users and admins who do not belong to groups (redirects if they try to force type in url)
        cognitoUser.data.groups.map(userGroup => {
          if (userGroup.id === rest.match?.params?.groupID && userGroup.isAdmin === false) {
            isAdmin = false;
          } else if (userGroup.id === rest.match?.params?.groupID && userGroup.isAdmin === true) {
            isAdmin = true;
          }
        })

        if (isPublic) {
          return <Component {...rest} cognitoUser={cognitoUser} isAdmin={isAdmin} />;
        } else {
          return <Redirect to="/dashboard" />;
        }
      }}
    />
  );
};

export default PublicEnsembleUserRoute;