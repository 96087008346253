import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

// ROUTING
import { BrowserRouter as Router } from 'react-router-dom';

// SENTRY
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

// STATE
import { RecoilRoot } from 'recoil';
import { LoadingPage } from './components/LoadingPage';

// new browser history
import { createBrowserHistory } from 'history';
const history = createBrowserHistory();

Sentry.init({
  dsn: "https://ad29a580413a4d5b9a865cc10c057a34@o481195.ingest.sentry.io/5548230",
  environment: 'production',
  integrations: [
    new Integrations.BrowserTracing({
      // init react-router sentry
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
    }),
  ],
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <React.StrictMode>
    <RecoilRoot>
      <Sentry.ErrorBoundary fallback={'An error has occurred'} showDialog>
        <React.Suspense fallback={<LoadingPage />}>
          <Router history={history}>
            <App />
          </Router>
        </React.Suspense>
      </Sentry.ErrorBoundary>
    </RecoilRoot>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
