import React, { useState } from 'react';

// STRIPE
import { loadStripe } from '@stripe/stripe-js';
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';

// AUTH
import { Auth } from '@aws-amplify/auth';

// STREAM CHAT
import { StreamChat } from 'stream-chat';

// STATE
import { useRecoilValue } from 'recoil';
import { cogUserState } from '../../state/atoms';

// STYLING
import '../../Stripe.css';

// DATE
import moment from 'moment';

// TOAST
import cogoToast from 'cogo-toast';

// ROUTING
import { useHistory } from 'react-router-dom';

const client = StreamChat.getInstance(process.env.REACT_APP_STREAM_API_KEY)

// ----------------------------------------------------------------------------------
// ------------------------------ PAYMENT ON SIGNUP ---------------------------------
// ----------------------------------------------------------------------------------

const CheckoutForm = () => {
  const history = useHistory();

  // State
  const [submitting, setSubmitting] = useState(false);
  const [showPromo, setShowPromo] = useState(false);
  const [input, setInput] = useState({
    coupon_code: ''
  });
  const cogUserAttributes = Auth.user.attributes;

  const handleInputChange = (event) => {
    setInput({ ...input, [event.target.name]: event.target.value.toUpperCase().replace(/ /g, '') });
  };

  // Get user data
  const cognitoUser = useRecoilValue(
    cogUserState(
      localStorage.getItem(
        process.env.REACT_APP_USER_ID,
      ),
    ),
  ); // Get user from our DB

  const stripe = useStripe();
  const elements = useElements();

  const handleCreditCardSubmit = async (event) => {
    // Block native form submission
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    setSubmitting(true);

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement);

    // Use your card Element with other Stripe.js APIs
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (error) {
      setSubmitting(false);
      cogoToast.warn('Invalid credit card details.', {
        hideAfter: 5,
      });
    } else {

      // Lambda function
      const addToCustomer = await fetch(
        process.env.REACT_APP_PAYMENT_SIGNUP,
        {
          method: 'POST',
          body: JSON.stringify({
            stripe_id: cognitoUser.data.stripe_id,
            payment_method_id: paymentMethod.id,
            user_id: cognitoUser.data.id,
            email: cogUserAttributes.email,
            last_name: cogUserAttributes.family_name,
            first_name: cogUserAttributes.given_name
          }),
        },
      )
        .then((res) => {
          if (window.rewardful) {
            window.rewardful('convert', { email: cogUserAttributes.email });
          }
          return res.json();
        })
        .catch((err) => {
          cogoToast.error('Something happened. Try again.', { hideAfter: 5 });
          setSubmitting(false);
        });

      if (addToCustomer.message === 'succeeded') {
        cogoToast.success('Successfully added your payment method!');
        setSubmitting(false);
        window.location.reload(true);
      } else {
        cogoToast.error(addToCustomer.message, { hideAfter: 5 });
        setSubmitting(false);
      }
    }
  };

  const handleCouponSubmit = async (event) => {
    // Block native form submission.
    event.preventDefault();
    setSubmitting(true);

    if (input.coupon_code) {
      // Lambda function
      const addToCustomer = await fetch(
        process.env.REACT_APP_ADD_CUSTOMER_COUPON,
        {
          method: 'POST',
          body: JSON.stringify({
            stripe_id: cognitoUser.data.stripe_id,
            coupon_name: input.coupon_code,
            user_id: cognitoUser.data.id,
            email: cogUserAttributes.email,
            last_name: cogUserAttributes.family_name,
            first_name: cogUserAttributes.given_name
          }),
        },
      )
        .then((res) => {
          if (window.rewardful) {
            window.rewardful('convert', { email: cogUserAttributes.email });
          }
          return res.json();
        })
        .catch((err) => {
          cogoToast.error('Something happened. Try again.', { hideAfter: 5 });
          setSubmitting(false);
        });

      if (addToCustomer.message === 'succeeded') {
        cogoToast.success('Successfully added your coupon!');
        setSubmitting(false);
        window.location.reload(true);
      } else {
        cogoToast.error(addToCustomer.message, { hideAfter: 5 });
        setSubmitting(false);
      }
    } else {
      cogoToast.error('No coupon code submitted', { hideAfter: 5 });
      setSubmitting(false);
    }
  }

  // Function for handling logout
  let logout = () => {
    Auth.signOut()
    client.disconnectUser();
    localStorage.clear()
    history.push('/')
  };

  // Function for toggling Promo Code Field
  let togglePromo = () => {
    if (showPromo) {
      setShowPromo(false);
    } else {
      setShowPromo(true);
    }
  }

  return (
    <div className="flex flex-col items-center justify-center w-full h-screen space-y-6 overflow-hidden bg-fixed bg-cover bg-hero-pattern">
      <div className="flex flex-col justify-center space-y-3 text-center">
        <h1 className="text-6xl text-mcgreen">Start your free trial!</h1>
        <h3 className="text-3xl font-bold text-white">
          {' '}
          Try MusicChunks with no strings attached
        </h3>
        <p className="max-w-2xl text-xl text-gray-500">
          Trial includes EVERYTHING we have to offer. Note you can cancel this
          trial anytime before {moment().add(7, 'days').calendar()} and will not
          be charged. Exceeding this time frame confirms your monthly
          subscription.
        </p>
      </div>

      {/* FORM */}
      <div className="w-full max-w-3xl mx-auto ">
        <form
          onSubmit={showPromo ? (
            handleCouponSubmit
          ) : (
            handleCreditCardSubmit
          )}
          className="flex flex-col items-center w-full">

          {/* COUPON CODE TOGGLE */}
          {!showPromo ? (
            <CardElement
              className="w-full"
              options={{
                style: {
                  base: {
                    fontSize: '20px',
                    color: '#424770',
                    '::placeholder': {
                      color: '#aab7c4',
                    },
                  },
                  invalid: {
                    color: '#9e2146',
                  },
                },
              }}
            />
          ) : (
            <input
              name='coupon_code'
              type='text'
              value={input.coupon_code}
              onChange={handleInputChange}
              placeholder='Enter Coupon Code'
              className='w-1/3 flex items-center mb-7 mt-3 p-2 rounded-md text-lg'
            />
          )}
          {showPromo ? (
            <div className='text-white text-lg mb-6' >
              <span className='mr-3'>
                No coupon?
              </span>
              <button
                className='text-mcgreen hover:text-mcfadedgreen focus:outline-none'
                onClick={togglePromo}
                type='button'
              >
                Enter Credit Card
              </button>
            </div>
          ) : (
            <div className='text-white text-lg mb-6' >
              <span className='mr-3'>
                Have a coupon?
                </span>
              <button
                className='text-mcgreen hover:text-mcfadedgreen focus:outline-none'
                onClick={togglePromo}
                type='button'
              >
                Enter coupon
                </button>
            </div>
          )}

          {/* SUBMIT BUTTONS */}
          <div className=" flex justify-center w-full max-w-3xl mx-auto">
            {showPromo ? (
              <button
                type="submit"
                disabled={!stripe}
                className={`${submitting && 'opacity-50 pointer-events-none'
                  } flex items-center rounded-lg text-2xl px-5 py-3 mr-15 text-center font-medium text-mcgreen bg-mcbuttondarkgreen hover:bg-mcgreen hover:text-mcbuttondarkgreen focus:ring transition duration-150 ease-in-out`}>
                {submitting && (
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    strokeWidth="0"
                    viewBox="0 0 1024 1024"
                    className="w-5 h-5 mr-3 animate-spin"
                    xmlns="http://www.w3.org/2000/svg">
                    <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 0 0-94.3-139.9 437.71 437.71 0 0 0-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                  </svg>
                )}{' '}
              Submit coupon
              </button>
            ) : (
              <button
                type="submit"
                disabled={!stripe}
                className={`${submitting && 'opacity-50 pointer-events-none'
                  } flex items-center rounded-lg text-2xl px-5 py-3 mr-15 text-center font-medium text-mcgreen bg-mcbuttondarkgreen hover:bg-mcgreen hover:text-mcbuttondarkgreen focus:ring transition duration-150 ease-in-out`}>
                {submitting && (
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    strokeWidth="0"
                    viewBox="0 0 1024 1024"
                    className="w-5 h-5 mr-3 animate-spin"
                    xmlns="http://www.w3.org/2000/svg">
                    <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 0 0-94.3-139.9 437.71 437.71 0 0 0-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                  </svg>
                )}{' '}
            Submit payment
              </button>
            )}
            <button
              type="button"
              onClick={() => logout()}
              className={'flex items-center rounded-lg text-2xl ml-5 px-5 py-3 text-center font-medium text-mcgreen bg-mcbuttondarkgreen hover:bg-mcgreen hover:text-mcbuttondarkgreen focus:ring transition duration-150 ease-in-out'}
            >
              Log out
          </button>
          </div>
        </form>
      </div>
    </div>
  );
};

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUB_KEY);

const PaymentOnSignup = () => {
  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm />
    </Elements>
  );
};

export default PaymentOnSignup;