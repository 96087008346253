import React from 'react';

// ROUTING
import { Route, Link } from 'react-router-dom';

// COMPONENTS
import { MemoizedChunkCard } from './ChunkCard';
import { MemoizedFeaturedChunkCard } from './FeaturedChunkCard';

// ----------------------------------------------------------------------------------
// -------------------------------- GROUP HOME CARD ---------------------------------
// ----------------------------------------------------------------------------------

export const GroupHomeCard = ({ cognitoUser, group, groupChunks, groupFeaturedChunks, match }) => {
  return (
    <div className='mt-3'>
      {/* All GROUP FEATURED CHUNKS SECTION */}
      {groupFeaturedChunks.length > 0 ? (
        <div>
          <div className='mt-2 border-t-4 border-green-600 border-solid'>
            <h1 className='w-full pt-2 text-2xl font-medium leading-6 text-white'>
              FEATURED CHUNKS
          </h1>
          </div>
          {/* RENDERS ALL OF A GROUP'S FEATURED CHUNKS */}
          <div className='grid grid-cols-1 gap-5 mt-2 max-w-screen '>
            {groupFeaturedChunks?.map((data) => (
              <Link
                key={data.id}
                to={`${match.url}/chunks/${data?.id}`}
              >
                <Route
                  render={(props) => (
                    <MemoizedFeaturedChunkCard
                      key={data.id}
                      cognitoUser={cognitoUser}
                      data={data}
                      {...props}
                    />
                  )}
                />
              </Link>
            ))}
          </div>
        </div>
      ) : null}

      {/* All GROUP CHUNKS SECTION */}
      {groupChunks.length === 0 ? (
        <div className='text-white w-full pt-2 text-2xl font-medium leading-6 text-whites'>
          <p>This ensemble has no chunks</p>
        </div>
      ) : (
        <div className='mt-2 border-t-4 border-green-600 border-solid'>
          <h1 className='w-full pt-2 text-2xl font-medium leading-6 text-white'>
            ENSEMBLE CHUNKS
            </h1>
        </div>
      )}

      {/* RENDERS ALL OF A GROUP'S CHUNKS */}
      <div className='grid justify-center gap-10 mt-2 sm:grid-cols-2 md:grid-cols-2 xl:grid-cols-4 2xl:grid-cols-6 2xl:px-15'>
        {groupChunks?.map((data) => (
          <Link
            key={data.id}
            to={`${match.url}/chunks/${data?.id}`}
          >
            <Route
              render={(props) => (
                <MemoizedChunkCard
                  key={data.id}
                  cognitoUser={cognitoUser}
                  data={data}
                  {...props}
                />
              )}
            />
          </Link>
        ))}
      </div>
    </div>
  );
};

export const MemoizedGroupHomeCard = React.memo(GroupHomeCard);
