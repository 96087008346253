import React, { useState } from 'react';
import axios from 'axios';
import axiosRetry from 'axios-retry';

// FORM MANAGEMENT
import { useForm } from 'react-hook-form';

// ROUTING
import { Link, useHistory } from 'react-router-dom';

// STATE
import { useSetRecoilState } from 'recoil';
import { compositionData } from '../../../state/atoms';

// ----------------------------------------------------------------------------------
// ------------------------------ ADD COMPOSITION FORM ------------------------------
// ----------------------------------------------------------------------------------

export const AddCompositionForm = () => {
  // State
  const history = useHistory();
  const compositions = useSetRecoilState(compositionData);
  const [submitting, setSubmitting] = useState(false);

  // Set axios to retry a set number of times on failed request/return
  axiosRetry(axios, { retries: 3 })

  // Form
  const { register, handleSubmit, errors, reset } = useForm({
    mode: 'onChange',
    defaultValues: {
      public: true,
    },
  });

  // Function that handles submission of the form
  const onSubmit = (data) => {
    setSubmitting(true);

    axios
      ({
        method: 'post',
        url: process.env.REACT_APP_API + `compositions`,
        headers: {
          Accept: 'application/json',
          Authorization: process.env.REACT_APP_HEROKU_SERVER_KEY
        }, data: {
          ...data,
          ISWC: data.ISWC ? data.ISWC : null,
          MLC_Song_Code: data.MLC_Song_Code ? data.MLC_Song_Code : null
        }
      })
      .then((res) => {
        setSubmitting(false);
        compositions((oldList) => {
          return [...oldList, res.data];
        });
        history.push('/admin/compositions');
      })
      .catch((err) => {
        console.log(err);
        alert('something went wrong');
        setSubmitting(false);
      });
    // Resets the form on submit (failed or not)
    // reset();
  };

  return (
    <div className='flex justify-center'>
      <div className=''>
        <div className='px-4 py-5 bg-white shadow sm:rounded-lg sm:p-6'>
          <div className='md:grid md:grid-cols-3 md:gap-6'>
            <div className='md:col-span-1'>
              <p className='mt-1 text-sm font-bold leading-5 text-red-700'>
                ONLY FILL OUT THIS FORM IF THE COMPOSITION IS NOT ALREADY IN THE
                LIST OF COMPOSITIONS!!! SUBMITTING A DUPLICATE COMPOSITION WILL
                FAIL!
              </p>
            </div>

            {/* INPUT FIELDS */}
            <div className='mt-5 md:mt-0 md:col-span-2'>
              <form onSubmit={handleSubmit(onSubmit)}>

                <div className='sm:col-span-1 mt-4'>
                  <dt className='text-sm font-medium leading-5 text-teal-500'>
                    Work Title (appears on PRO reports)
                  <span className='ml-1 text-sm font-medium leading-5 text-red-500'>
                      *
                  </span>
                  </dt>
                  <input
                    id='Work_Title'
                    name='Work_Title'
                    type='text'
                    placeholder='Today is a beautiful'
                    ref={register({ required: true })}
                    className='block w-full mt-1 transition duration-150 ease-in-out form-textarea sm:text-sm sm:leading-5'
                  />
                  {errors.Work_Title && (
                    <span className='text-red-500'>This field is required</span>
                  )}
                </div>

                <div className='sm:col-span-1 mt-4'>
                  <dt className='text-sm font-medium leading-5 text-gray-500'>
                    Work Alternative Titles
                  </dt>
                  <textarea
                    id='Work_Alternative_Titles'
                    name='Work_Alternative_Titles'
                    type='text'
                    placeholder='Beautiful day|What a beautiful day'
                    ref={register}
                    className='block w-full mt-1 transition duration-150 ease-in-out form-textarea sm:text-sm sm:leading-5'
                  />
                </div>

                <div className='sm:col-span-1 mt-4'>
                  <dt className='text-sm font-medium leading-5 text-gray-500'>
                    MLC Song Code
                  </dt>
                  <input
                    id='MLC_Song_Code'
                    name='MLC_Song_Code'
                    type='text'
                    placeholder='D00001'
                    ref={register}
                    className='block w-full mt-1 transition duration-150 ease-in-out form-textarea sm:text-sm sm:leading-5'
                  />
                </div>

                <div className='sm:col-span-1 mt-4'>
                  <dt className='text-sm font-medium leading-5 text-teal-500'>
                    ISWC (appears on PRO reports)
                  </dt>
                  <input
                    id='ISWC'
                    name='ISWC'
                    type='text'
                    placeholder='T8572057154'
                    ref={register}
                    className='block w-full mt-1 transition duration-150 ease-in-out form-textarea sm:text-sm sm:leading-5'
                  />
                </div>

                <div className='sm:col-span-1 mt-4'>
                  <dt className='text-sm font-medium leading-5 text-gray-500'>
                    Musical Work Copyright Owner
                  </dt>
                  <input
                    id='Musical_Work_Copyright_Owner'
                    name='Musical_Work_Copyright_Owner'
                    type='text'
                    placeholder='Synced Up Designs'
                    ref={register}
                    className='block w-full mt-1 transition duration-150 ease-in-out form-textarea sm:text-sm sm:leading-5'
                  />
                </div>

                <div className='sm:col-span-1 mt-4'>
                  <dt className='text-sm font-medium leading-5 text-teal-500'>
                    Writers (appears on PRO reports)
                  </dt>
                  <textarea
                    id='Writers'
                    name='Writers'
                    type='text'
                    placeholder='John Smith|Jane Smith'
                    ref={register}
                    className='block w-full mt-1 transition duration-150 ease-in-out form-textarea sm:text-sm sm:leading-5'
                  />
                </div>

                <div className='sm:col-span-1 mt-4'>
                  <dt className='text-sm font-medium leading-5 text-gray-500'>
                    Writer IPI
                  </dt>
                  <textarea
                    id='Writer_IPI'
                    name='Writer_IPI'
                    type='text'
                    placeholder='73519527851|31823461941'
                    ref={register}
                    className='block w-full mt-1 transition duration-150 ease-in-out form-textarea sm:text-sm sm:leading-5'
                  />
                </div>

                <div className='sm:col-span-1 mt-4'>
                  <dt className='text-sm font-medium leading-5 text-gray-500'>
                    Writer ISNI
                  </dt>
                  <textarea
                    id='Writer_ISNI'
                    name='Writer_ISNI'
                    type='text'
                    placeholder='1234234534564567|9876876576546543'
                    ref={register}
                    className='block w-full mt-1 transition duration-150 ease-in-out form-textarea sm:text-sm sm:leading-5'
                  />
                </div>

                <div className='sm:col-span-1 mt-4'>
                  <dt className='text-sm font-medium leading-5 text-gray-500'>
                    Believed Public Domain
                </dt>
                  <input
                    id='Believed_Public_Domain'
                    name='Believed_Public_Domain'
                    type='checkbox'
                    ref={register}
                    className='w-4 h-4 text-indigo-600 transition duration-150 ease-in-out form-checkbox'
                  />
                </div>

                {/* SUBMIT AND GO BACK BUTTONS */}
                <div className='flex justify-end w-full p-5 px-10 '>
                  <span className='inline-flex space-x-8 rounded-md shadow-sm'>
                    <Link
                      to='/admin/compositions'
                      className={`${submitting && 'opacity-50 cursor-not-allowed'
                        }inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs leading-4 font-medium rounded text-white bg-red-500 hover:bg-red-800 focus:outline-none focus:border-indigo-300 focus:shadow-outline-indigo active:bg-indigo-200 transition ease-in-out duration-150`}
                    >
                      Go Back
                    </Link>
                    <button
                      type='submit'
                      className={`${submitting && 'opacity-50 cursor-not-allowed'
                        }  inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150`}
                    >
                      {submitting ? '' : 'Submit'}
                      <svg
                        className={`animate-spin ${!submitting && 'hidden'}`}
                        stroke='currentColor'
                        fill='currentColor'
                        strokeWidth='0'
                        viewBox='0 0 16 16'
                        height='1em'
                        width='1em'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path d='M8 .75c.172 0 .333.034.484.102a1.214 1.214 0 0 1 .664.664c.068.15.102.312.102.484s-.034.333-.102.484a1.214 1.214 0 0 1-.265.399 1.324 1.324 0 0 1-.399.273A1.254 1.254 0 0 1 8 3.25c-.172 0-.333-.031-.484-.094a1.324 1.324 0 0 1-.672-.672A1.254 1.254 0 0 1 6.75 2c0-.172.031-.333.094-.484.067-.151.159-.284.273-.399.115-.114.248-.203.399-.265A1.17 1.17 0 0 1 8 .75zM2.633 3.758a1.111 1.111 0 0 1 .68-1.031 1.084 1.084 0 0 1 .882 0c.136.057.253.138.352.242.104.099.185.216.242.351a1.084 1.084 0 0 1 0 .883 1.122 1.122 0 0 1-.594.594 1.169 1.169 0 0 1-.883 0 1.19 1.19 0 0 1-.359-.234 1.19 1.19 0 0 1-.234-.36 1.169 1.169 0 0 1-.086-.445zM2 7a.941.941 0 0 1 .703.297A.941.941 0 0 1 3 8a.97.97 0 0 1-.078.39 1.03 1.03 0 0 1-.531.532A.97.97 0 0 1 2 9a.97.97 0 0 1-.39-.078 1.104 1.104 0 0 1-.32-.211 1.104 1.104 0 0 1-.212-.32A.97.97 0 0 1 1 8a.97.97 0 0 1 .29-.703A.97.97 0 0 1 2 7zm.883 5.242a.887.887 0 0 1 .531-.805.863.863 0 0 1 .68 0c.11.047.203.11.281.188a.887.887 0 0 1 .188.96.887.887 0 0 1-1.148.461.913.913 0 0 1-.462-.46.863.863 0 0 1-.07-.344zM8 13.25c.208 0 .385.073.531.219A.723.723 0 0 1 8.75 14a.723.723 0 0 1-.219.531.723.723 0 0 1-.531.219.723.723 0 0 1-.531-.219A.723.723 0 0 1 7.25 14c0-.208.073-.385.219-.531A.723.723 0 0 1 8 13.25zm3.617-1.008c0-.177.06-.325.18-.445s.268-.18.445-.18.326.06.445.18c.12.12.18.268.18.445s-.06.326-.18.445a.605.605 0 0 1-.445.18.605.605 0 0 1-.445-.18.605.605 0 0 1-.18-.445zM14 7.5a.48.48 0 0 1 .352.148A.48.48 0 0 1 14.5 8a.48.48 0 0 1-.148.352A.48.48 0 0 1 14 8.5a.48.48 0 0 1-.352-.148A.48.48 0 0 1 13.5 8a.48.48 0 0 1 .148-.352A.48.48 0 0 1 14 7.5zm-1.758-5.117c.188 0 .365.036.531.11a1.413 1.413 0 0 1 .735.734c.073.166.11.343.11.53 0 .188-.037.365-.11.532a1.413 1.413 0 0 1-.735.734 1.31 1.31 0 0 1-.53.11c-.188 0-.365-.037-.532-.11a1.415 1.415 0 0 1-.734-.734 1.31 1.31 0 0 1-.11-.531c0-.188.037-.365.11-.531a1.413 1.413 0 0 1 .734-.735c.167-.073.344-.11.531-.11z'></path>
                      </svg>
                    </button>
                  </span>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};