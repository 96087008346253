import React, { useEffect } from 'react';

// ROUTING
import { Link } from 'react-router-dom';

// STATE
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  chunkFilterState,
  searchedChunks,
  searchBarTerm,
} from '../../state/atoms';

// COMPONENTS
import { MemoizedChunkCard } from './ChunkCard';

// ----------------------------------------------------------------------------------
// ---------------------------------- Chunk List ------------------------------------
// ----------------------------------------------------------------------------------

const ChunkList = ({ match, location, handleClearSearchBar, cognitoUser }) => {
  // STATE
  const chunks = useRecoilValue(searchedChunks);
  const searchTerm = useRecoilValue(searchBarTerm);
  const chunkFilter = useSetRecoilState(chunkFilterState);

  // UseEffect to help the search bar filter chunk search terms
  useEffect(() => {
    chunkFilter(location.search.split('=')[1]);
  }, [chunkFilter, location.search]);

  return (
    <>
      {chunks.length === 0 && searchTerm !== '' ? (
        <div className='flex flex-col items-center justify-center p-16 '>
          <p className='text-lg font-bold leading-6 text-emptySearchResults'>
            Couldn't find
          </p>
          <p className='text-lg font-bold text-emptySearchResults'>"{searchTerm}"</p>
          <p className='text-sm text-center text-white'>
            Try searching again using a different spelling or keyword.
          </p>
        </div>
      ) : (
          <div className='grid justify-center gap-10 mt-2 grig-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 2xl:grid-cols-6 2xl:px-15'>
            {chunks?.map((i) => (
              <Link
                key={i.id}
                to={`${match.url}/${i.id}`}
                onClick={() => {
                  handleClearSearchBar();
                }}
              >
                <MemoizedChunkCard
                  key={i.id}
                  data={i}
                  cognitoUser={cognitoUser}
                  handleClearSearchBar={handleClearSearchBar}
                  match={match}
                />
              </Link>
            ))}
          </div>
        )}
    </>
  );
};

export default ChunkList;
