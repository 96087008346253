import React, { useState, useEffect } from 'react';
import axios from 'axios';
import axiosRetry from 'axios-retry';

// TAILWIND TRANSITION
import { Transition } from '@tailwindui/react';

// FORM MANAGEMENT
import { useForm, Controller } from 'react-hook-form';
import Select from 'react-select';

// DURATION PICKER
import DurationPicker from 'react-duration-picker';

// TOAST
import cogoToast from 'cogo-toast';

// IMAGES
import { ReactComponent as Checkmark } from '../../../img/icon/checkmark.svg';

// ROUTING
import { useHistory, useRouteMatch } from 'react-router-dom';

// STATE
import { useSetRecoilState } from 'recoil';
import { soundRecordingData } from '../../../state/atoms';

// ----------------------------------------------------------------------------------
// ------------------------------ SOUND RECORDING DATA ------------------------------
// ----------------------------------------------------------------------------------

const SoundRecordingData = ({ data }) => {
  const {
    DSP_Recording_Identifier,
    Sound_Slice_Embed_Name,
    ISRC,
    Recording_Title,
    Recording_Version_Title,
    Recording_Alternative_Titles,
    Recording_Artist,
    Recording_Playing_Time,
    Recording_Date_First_Utilized,
    Recording_Studio_Producer,
    ICPN,
    Catalog_Number,
    Product_Distributor_Identifier,
    Product_Title,
    Release_Date,
    PLine,
    Label_Name,
    Distributor,
    Service_URL,
    Usage_Type,
    Performance_Type,
    Performance_Date,
    chunk_id
  } = data;

  // State
  const history = useHistory();
  const { url } = useRouteMatch();
  const soundRecordings = useSetRecoilState(soundRecordingData);
  const [openOptions, setOpenOptions] = useState(false);
  const [openDetails, setOpenDetails] = useState(false);
  const [openWarning, setOpenWarning] = useState(false);
  const [openWarning2, setOpenWarning2] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [allChunks, setAllChunks] = useState([]);  // SAVES LIST OF ALL CHUNKS
  const [recordingDuration, setRecordingDuration] = useState()

  // Set axios to retry a set number of times on failed request/return
  axiosRetry(axios, { retries: 3 })

  // Form
  const { register, handleSubmit, errors, reset, control } = useForm({
    mode: 'onChange',
  });

  // Useffect to grab all chunks for editing
  useEffect(() => {
    axios({
      method: 'get',
      url: process.env.REACT_APP_API + `chunks`,
      headers: {
        Accept: 'application/json',
        Authorization: process.env.REACT_APP_HEROKU_SERVER_KEY,
      }
    })
      .then((res) => {
        setAllChunks(res.data)
      })
  }, [])

  // Function to handle deleting an sound recording
  const handleDelete = (id) => {
    axios
      ({
        method: 'delete',
        url: process.env.REACT_APP_API + `soundrecordings/${id}`,
        headers: {
          Accept: 'application/json',
          Authorization: process.env.REACT_APP_HEROKU_SERVER_KEY
        }
      })
      .then((res) => {
        soundRecordings((oldList) => {
          const newList = oldList.filter((i) => {
            return i.DSP_Recording_Identifier !== id;
          });
          return newList;
        });
      })
      .catch((err) => {
        console.log(err);
        alert('something happened, item not deleted');
      });
  };

  // Function to handle unlinking sound recording
  const handleUnlink = (id) => {
    axios
      ({
        method: 'put',
        url: process.env.REACT_APP_API + `soundrecordings/${id}`,
        headers: {
          Accept: 'application/json',
          Authorization: process.env.REACT_APP_HEROKU_SERVER_KEY
        }, data: {
          chunk_id: null
        }
      })
      .then((res) => {
        soundRecordings((oldList) => {
          const newList = oldList.filter((i) => {
            return i.DSP_Recording_Identifier !== id;
          });
          return newList;
        });
      })
      .catch((err) => {
        console.log(err);
        alert('something happened, item not unlinked');
      });
  };

  // Function that handles submitting updates/edits for sound recording data
  const onSubmit = (data) => {
    setIsEditing(true);

    axios
      ({
        method: 'put',
        url: process.env.REACT_APP_API + `soundrecordings/${DSP_Recording_Identifier}`,
        headers: {
          Accept: 'application/json',
          Authorization: process.env.REACT_APP_HEROKU_SERVER_KEY
        }, data: {
          ...data,
          ISRC: data.ISRC ? data.ISRC : null,
          Recording_Playing_Time: `${recordingDuration.hours}:${recordingDuration.minutes}:${recordingDuration.seconds}`,
          Recording_Date_First_Utilized: data.Recording_Date_First_Utilized ? data.Recording_Date_First_Utilized : null,
          Release_Date: data.Release_Date ? data.Release_Date : null,
          Performance_Date: data.Performance_Date ? data.Performance_Date : null,
          chunk_id: data.chunk_id.value
        }
      })
      .then((res) => {
        setIsEditing(false);
        soundRecordings((oldList) => {
          const newList = oldList.map((i) =>
            i.DSP_Recording_Identifier === DSP_Recording_Identifier ? res.data.updatedSoundRecording : i
          );
          return newList;
        });
        history.push(url);
      })
      .catch((err) => {
        console.log(err);
        alert('something went wrong');
        setIsEditing(false);
      });
  };

  // Sets recording duration based on the duration picker settings
  const onChange = (duration) => {
    setRecordingDuration(duration);
  };

  return (
    <div className='overflow-hidden shadow bg-gray-50'>
      <div className='flex justify-between px-4 py-5 border-b border-gray-200 sm:px-6'>
        {isEditing ? (
          <input
            id='Recording_Title'
            type='text'
            name='Recording_Title'
            placeholder='It is a beautiful day'
            defaultValue={Recording_Title}
            ref={register({ required: true })}
            className='block w-1/3 px-3 py-2 mt-1 transition duration-150 ease-in-out border border-gray-300 rounded-md shadow-sm form-input focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5'
          />
        ) : (
            <div className='flex'>
              <h3 className='text-lg font-medium leading-6 text-gray-900'>
                {Recording_Title}
              </h3>
              {chunk_id ? (
                <Checkmark className='ml-5 inline-block object-fill w-7 h-7 rounded-md' />
              ) : null}
              <h3 className='ml-5 text-lg font-light leading-6 text-gray-900'>
                {Sound_Slice_Embed_Name}
              </h3>
            </div>
          )}
        {errors.Recording_Title && (
          <span className='text-red-500'>This field is required</span>
        )}

        <div className='flex-shrink-0 pr-2'>
          {openDetails ? (
            <button
              onClick={() => setOpenDetails(!openDetails)}
              id='menu dropdown '
              aria-haspopup='true'
              className='inline-flex items-center justify-center w-8 h-8 text-gray-400 transition duration-150 ease-in-out bg-transparent rounded-full hover:text-gray-500 focus:outline-none focus:text-gray-500 focus:bg-gray-100'
            >
              X
            </button>
          ) : (
              <button
                onClick={() => setOpenOptions(!openOptions)}
                id='menu dropdown '
                aria-haspopup='true'
                className='inline-flex items-center justify-center w-8 h-8 text-gray-400 transition duration-150 ease-in-out bg-transparent rounded-full hover:text-gray-500 focus:outline-none focus:text-gray-500 focus:bg-gray-100'
              >
                {/* <!-- Heroicon name: dots-vertical --> */}
                <svg
                  className='w-5 h-5'
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 20 20'
                  fill='currentColor'
                >
                  <path d='M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z' />
                </svg>
              </button>
            )}
          {/* <!--
                  Dropdown panel, show/hide based on dropdown state.

                  Entering: "transition ease-out duration-100"
                    From: "transform opacity-0 scale-95"
                    To: "transform opacity-100 scale-100"
                  Leaving: "transition ease-in duration-75"
                    From: "transform opacity-100 scale-100"
                    To: "transform opacity-0 scale-95"
                --> */}
          <Transition show={openOptions}>
            <div className='absolute z-10 w-48 mx-3 mt-1 origin-top-right rounded-md shadow-lg right-2'>
              <div
                className='bg-white rounded-md shadow-xs'
                role='menu'
                aria-orientation='vertical'
                aria-labelledby='pinned-project-options-menu-0'
              >
                <div className='relative z-40 py-1'>
                  <button
                    onClick={() => {
                      setOpenDetails(!openDetails);
                      setOpenOptions(!openOptions);
                    }}
                    id='pinned-project-options-menu-0'
                    aria-haspopup='true'
                    className='block w-full px-4 py-2 text-sm leading-5 text-left text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900'
                    role='menuitem'
                  >
                    View
                  </button>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>

      <Transition show={openDetails}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='px-4 py-5 sm:px-6'>
            <dl className='grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3'>

              <div className='sm:col-span-1'>
                <dt className='text-sm font-medium leading-5 text-gray-500'>
                  Linked Chunk
                </dt>
                {isEditing ? (
                  <Controller
                    as={Select}
                    options={allChunks.map(ch => ({ label: ch.name, value: ch.id }))}
                    id='chunk_id'
                    name='chunk_id'
                    defaultValue={allChunks.map(ch => ch.id === chunk_id ? ({ label: ch.name, value: ch.id }) : null)}
                    control={control}
                  />
                ) : (
                    <dd className='mt-1 text-sm leading-5 text-gray-900'>
                      {chunk_id}
                    </dd>
                  )}
              </div>

              <div className='sm:col-span-1'>
                <dt className='text-sm font-medium leading-5 text-gray-500'>
                  Embedded Sound Slice Recording Name
                </dt>
                {isEditing ? (
                  <input
                    id='Sound_Slice_Embed_Name'
                    name='Sound_Slice_Embed_Name'
                    type='text'
                    placeholder='Synthetic'
                    ref={register({ required: true })}
                    defaultValue={Sound_Slice_Embed_Name}
                    className='block w-full mt-1 transition duration-150 ease-in-out form-textarea sm:text-sm sm:leading-5'
                  />
                ) : (
                    <dd className='w-1/2 mt-1 text-sm leading-5 text-gray-900'>
                      {Sound_Slice_Embed_Name}
                    </dd>
                  )}
              </div>

              <div className='sm:col-span-1'>
                <dt className='text-sm font-medium leading-5 text-gray-500'>
                  ISRC
                </dt>
                {isEditing ? (
                  <input
                    id='ISRC'
                    name='ISRC'
                    type='text'
                    placeholder='USSM19804756'
                    ref={register}
                    defaultValue={ISRC}
                    className='block w-full mt-1 transition duration-150 ease-in-out form-textarea sm:text-sm sm:leading-5'
                  />
                ) : (
                    <dd className='w-1/2 mt-1 text-sm leading-5 text-gray-900'>
                      {ISRC}
                    </dd>
                  )}
              </div>

              <div className='sm:col-span-1'>
                <dt className='text-sm font-medium leading-5 text-gray-500'>
                  Recording Version Title
                </dt>
                {isEditing ? (
                  <input
                    id='Recording_Version_Title'
                    name='Recording_Version_Title'
                    type='text'
                    placeholder='Radio Edit Explicit'
                    ref={register}
                    defaultValue={Recording_Version_Title}
                    className='block w-full mt-1 transition duration-150 ease-in-out form-textarea sm:text-sm sm:leading-5'
                  />
                ) : (
                    <dd className='w-1/2 mt-1 text-sm leading-5 text-gray-900'>
                      {Recording_Version_Title}
                    </dd>
                  )}
              </div>

              <div className='sm:col-span-1'>
                <dt className='text-sm font-medium leading-5 text-gray-500'>
                  Recording Alternative Titles
                </dt>
                {isEditing ? (
                  <textarea
                    id='Recording_Alternative_Titles'
                    name='Recording_Alternative_Titles'
                    type='text'
                    placeholder='Beautiful day|What a beautiful day'
                    ref={register}
                    defaultValue={Recording_Alternative_Titles}
                    className='block w-full mt-1 transition duration-150 ease-in-out form-textarea sm:text-sm sm:leading-5'
                  />
                ) : (
                    <dd className='w-1/2 mt-1 text-sm leading-5 text-gray-900'>
                      {Recording_Alternative_Titles}
                    </dd>
                  )}
              </div>

              <div className='sm:col-span-1'>
                <dt className='text-sm font-medium leading-5 text-gray-500'>
                  Recording Artist
                </dt>
                {isEditing ? (
                  <input
                    id='Recording_Artist'
                    name='Recording_Artist'
                    type='text'
                    placeholder='John Smith feat. Jane'
                    ref={register({ required: true })}
                    defaultValue={Recording_Artist}
                    className='block w-full mt-1 transition duration-150 ease-in-out form-textarea sm:text-sm sm:leading-5'
                  />
                ) : (
                    <dd className='w-1/2 mt-1 text-sm leading-5 text-gray-900'>
                      {Recording_Artist}
                    </dd>
                  )}
                {errors.Recording_Artist && (
                  <span className='text-red-500'>This field is required</span>
                )}
              </div>

              <div className='sm:col-span-1'>
                <dt className='text-sm font-medium leading-5 text-gray-500'>
                  Recording Playing Time
                </dt>
                {isEditing ? (
                  <DurationPicker
                    id='Recording_Playing_Time'
                    name='Recording_Playing_Time'
                    onChange={onChange}
                    initialDuration={{ hours: Recording_Playing_Time.slice(0, 2), minutes: Recording_Playing_Time.slice(3, 5), seconds: Recording_Playing_Time.slice(6, 8) }}
                    ref={register}
                  />
                ) : (
                    <dd className='w-1/2 mt-1 text-sm leading-5 text-gray-900'>
                      {Recording_Playing_Time}
                    </dd>
                  )}
              </div>

              <div className='sm:col-span-1'>
                <dt className='text-sm font-medium leading-5 text-gray-500'>
                  Recording Date First Utilized
                </dt>
                {isEditing ? (
                  <input
                    id='Recording_Date_First_Utilized'
                    name='Recording_Date_First_Utilized'
                    type='date'
                    ref={register}
                    defaultValue={Recording_Date_First_Utilized ? (
                      Recording_Date_First_Utilized.slice(0, 10)
                    ) : null}
                    className='block w-full mt-1 transition duration-150 ease-in-out form-textarea sm:text-sm sm:leading-5'
                  />
                ) : (
                    <dd className='w-1/2 mt-1 text-sm leading-5 text-gray-900'>
                      {Recording_Date_First_Utilized ? (
                        Recording_Date_First_Utilized.slice(0, 10)
                      ) : null}
                    </dd>
                  )}
              </div>

              <div className='sm:col-span-1'>
                <dt className='text-sm font-medium leading-5 text-gray-500'>
                  Recording Studio Producer
                </dt>
                {isEditing ? (
                  <input
                    id='Recording_Studio_Producer'
                    name='Recording_Studio_Producer'
                    type='text'
                    placeholder='Jane Smith'
                    ref={register}
                    defaultValue={Recording_Studio_Producer}
                    className='block w-full mt-1 transition duration-150 ease-in-out form-textarea sm:text-sm sm:leading-5'
                  />
                ) : (
                    <dd className='w-1/2 mt-1 text-sm leading-5 text-gray-900'>
                      {Recording_Studio_Producer}
                    </dd>
                  )}
              </div>

              <div className='sm:col-span-1'>
                <dt className='text-sm font-medium leading-5 text-gray-500'>
                  ICPN
                </dt>
                {isEditing ? (
                  <input
                    id='ICPN'
                    name='ICPN'
                    type='text'
                    placeholder='8742620672'
                    ref={register}
                    defaultValue={ICPN}
                    className='block w-full mt-1 transition duration-150 ease-in-out form-textarea sm:text-sm sm:leading-5'
                  />
                ) : (
                    <dd className='w-1/2 mt-1 text-sm leading-5 text-gray-900'>
                      {ICPN}
                    </dd>
                  )}
              </div>

              <div className='sm:col-span-1'>
                <dt className='text-sm font-medium leading-5 text-gray-500'>
                  Catalog Number
                </dt>
                {isEditing ? (
                  <input
                    id='Catalog_Number'
                    name='Catalog_Number'
                    type='text'
                    placeholder='dww1R3'
                    ref={register}
                    defaultValue={Catalog_Number}
                    className='block w-full mt-1 transition duration-150 ease-in-out form-textarea sm:text-sm sm:leading-5'
                  />
                ) : (
                    <dd className='w-1/2 mt-1 text-sm leading-5 text-gray-900'>
                      {Catalog_Number}
                    </dd>
                  )}
              </div>

              <div className='sm:col-span-1'>
                <dt className='text-sm font-medium leading-5 text-gray-500'>
                  Product Distributor Identifier
                </dt>
                {isEditing ? (
                  <input
                    id='Product_Distributor_Identifier'
                    name='Product_Distributor_Identifier'
                    type='text'
                    placeholder='312d231'
                    ref={register}
                    defaultValue={Product_Distributor_Identifier}
                    className='block w-full mt-1 transition duration-150 ease-in-out form-textarea sm:text-sm sm:leading-5'
                  />
                ) : (
                    <dd className='w-1/2 mt-1 text-sm leading-5 text-gray-900'>
                      {Product_Distributor_Identifier}
                    </dd>
                  )}
              </div>

              <div className='sm:col-span-1'>
                <dt className='text-sm font-medium leading-5 text-gray-500'>
                  Product Title
                </dt>
                {isEditing ? (
                  <input
                    id='Product_Title'
                    name='Product_Title'
                    type='text'
                    placeholder='Early Songs'
                    ref={register}
                    defaultValue={Product_Title}
                    className='block w-full mt-1 transition duration-150 ease-in-out form-textarea sm:text-sm sm:leading-5'
                  />
                ) : (
                    <dd className='w-1/2 mt-1 text-sm leading-5 text-gray-900'>
                      {Product_Title}
                    </dd>
                  )}
              </div>

              <div className='sm:col-span-1'>
                <dt className='text-sm font-medium leading-5 text-gray-500'>
                  Release Date
                </dt>
                {isEditing ? (
                  <input
                    id='Release_Date'
                    name='Release_Date'
                    type='date'
                    ref={register}
                    defaultValue={Release_Date ? (
                      Release_Date.slice(0, 10)
                    ) : null}
                    className='block w-full mt-1 transition duration-150 ease-in-out form-textarea sm:text-sm sm:leading-5'
                  />
                ) : (
                    <dd className='w-1/2 mt-1 text-sm leading-5 text-gray-900'>
                      {Release_Date ? (
                        Release_Date.slice(0, 10)
                      ) : null}
                    </dd>
                  )}
              </div>

              <div className='sm:col-span-1'>
                <dt className='text-sm font-medium leading-5 text-gray-500'>
                  PLine
                </dt>
                {isEditing ? (
                  <input
                    id='PLine'
                    name='PLine'
                    type='text'
                    placeholder='2020 Awesome Records'
                    ref={register}
                    defaultValue={PLine}
                    className='block w-full mt-1 transition duration-150 ease-in-out form-textarea sm:text-sm sm:leading-5'
                  />
                ) : (
                    <dd className='w-1/2 mt-1 text-sm leading-5 text-gray-900'>
                      {PLine}
                    </dd>
                  )}
              </div>

              <div className='sm:col-span-1'>
                <dt className='text-sm font-medium leading-5 text-gray-500'>
                  Label Name
                </dt>
                {isEditing ? (
                  <input
                    id='Label_Name'
                    name='Label_Name'
                    type='text'
                    placeholder='Awesome Records'
                    ref={register}
                    defaultValue={Label_Name}
                    className='block w-full mt-1 transition duration-150 ease-in-out form-textarea sm:text-sm sm:leading-5'
                  />
                ) : (
                    <dd className='w-1/2 mt-1 text-sm leading-5 text-gray-900'>
                      {Label_Name}
                    </dd>
                  )}
              </div>

              <div className='sm:col-span-1'>
                <dt className='text-sm font-medium leading-5 text-gray-500'>
                  Distributor
                </dt>
                {isEditing ? (
                  <input
                    id='Distributor'
                    name='Distributor'
                    type='text'
                    placeholder='Awesome Distributions'
                    ref={register}
                    defaultValue={Distributor}
                    className='block w-full mt-1 transition duration-150 ease-in-out form-textarea sm:text-sm sm:leading-5'
                  />
                ) : (
                    <dd className='w-1/2 mt-1 text-sm leading-5 text-gray-900'>
                      {Distributor}
                    </dd>
                  )}
              </div>

              <div className='sm:col-span-1'>
                <dt className='text-sm font-medium leading-5 text-gray-500'>
                  Service URL
                </dt>
                {isEditing ? (
                  <input
                    id='Service_URL'
                    name='Service_URL'
                    type='text'
                    placeholder='www.musicchunks.com'
                    ref={register({ required: true })}
                    defaultValue={Service_URL}
                    className='block w-full mt-1 transition duration-150 ease-in-out form-textarea sm:text-sm sm:leading-5'
                  />
                ) : (
                    <dd className='w-1/2 mt-1 text-sm leading-5 text-gray-900'>
                      {Service_URL}
                    </dd>
                  )}
                {errors.Service_URL && (
                  <span className='text-red-500'>This field is required</span>
                )}
              </div>

              <div className='sm:col-span-1'>
                <dt className='text-sm font-medium leading-5 text-gray-500'>
                  Usage Type
                </dt>
                <dt className='text-sm font-medium leading-5 text-gray-500'>
                  (Feature, Jingle, Background, Theme, Sample)
                </dt>
                {isEditing ? (
                  ['F', 'J', 'BG', 'T', 'S'].map((value) => (
                    <div key={value} className='flex items-center my-2'>
                      <input
                        id='Usage_Type'
                        name='Usage_Type'
                        value={value}
                        type='radio'
                        ref={register}
                        defaultChecked={value === Usage_Type}
                        className='w-4 h-4 text-indigo-600 transition duration-150 ease-in-out form-radio'
                      />
                      <label htmlFor='difficulty-medium' className='ml-3'>
                        <span className='block text-sm font-medium leading-5 text-gray-700'>
                          {value}
                        </span>
                      </label>
                    </div>
                  ))
                ) : (
                    <dd class='mt-1 text-sm leading-5 text-gray-900'>
                      {Usage_Type}
                    </dd>
                  )}
              </div>

              <div className='sm:col-span-1'>
                <dt className='text-sm font-medium leading-5 text-gray-500'>
                  Performance Type
                </dt>
                <dt className='text-sm font-medium leading-5 text-gray-500'>
                  (Interactive, Non Interactive)
                </dt>
                {isEditing ? (
                  ['IT', 'NI'].map((value) => (
                    <div key={value} className='flex items-center my-2'>
                      <input
                        id='Performance_Type'
                        name='Performance_Type'
                        value={value}
                        type='radio'
                        ref={register}
                        defaultChecked={value === Performance_Type}
                        className='w-4 h-4 text-indigo-600 transition duration-150 ease-in-out form-radio'
                      />
                      <label htmlFor='difficulty-medium' className='ml-3'>
                        <span className='block text-sm font-medium leading-5 text-gray-700'>
                          {value}
                        </span>
                      </label>
                    </div>
                  ))
                ) : (
                    <dd class='mt-1 text-sm leading-5 text-gray-900'>
                      {Performance_Type}
                    </dd>
                  )}
              </div>

              <div className='sm:col-span-1'>
                <dt className='text-sm font-medium leading-5 text-gray-500'>
                  Performance Date
                </dt>
                {isEditing ? (
                  <input
                    id='Performance_Date'
                    name='Performance_Date'
                    type='date'
                    ref={register}
                    defaultValue={Performance_Date ? (
                      Performance_Date.slice(0, 10)
                    ) : null}
                    className='block w-full mt-1 transition duration-150 ease-in-out form-textarea sm:text-sm sm:leading-5'
                  />
                ) : (
                    <dd className='w-1/2 mt-1 text-sm leading-5 text-gray-900'>
                      {Performance_Date ? (
                        Performance_Date.slice(0, 10)
                      ) : null}
                    </dd>
                  )}
              </div>
            </dl>
          </div>

          {/* SUBMIT EDITS OR DELETE */}
          {isEditing && (
            <div className='flex justify-end pb-4 pr-12'>
              {' '}
              <button
                type='submit'
                className=' mr-4 inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs leading-4 font-medium rounded text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150'
              >
                Save
              </button>
              <button
                onClick={() => {
                  setIsEditing(!isEditing);
                  reset();
                }}
                type='reset'
                className='inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs leading-4 font-medium rounded text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150'
              >
                Cancel
              </button>
            </div>
          )}
        </form>

        {!isEditing && (
          <div className='flex justify-end pb-4 pr-12'>
            <button
              type='button'
              onClick={() => {
                setIsEditing(!isEditing);
              }}
              className=' mr-4 inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs leading-4 font-medium rounded text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150'
            >
              Edit
            </button>

            <button
              onClick={() => {
                setOpenWarning(!openWarning)
              }}
              type='reset'
              className='mr-4 inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs leading-4 font-medium rounded text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150'
            >
              DELETE
            </button>

            <button
              onClick={() => {
                setOpenWarning2(!openWarning2)
              }}
              type='button'
              className='inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs leading-4 font-medium rounded text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150'
            >
              UNLINK CHUNK
            </button>

            {/* WARNING MESSAGE FOR DELETING A CHUNK */}
            <Transition show={openWarning}>
              <div class="fixed z-10 inset-0 overflow-y-auto">
                <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                  {/* <!--
                    Background overlay, show/hide based on modal state.
                    Entering: "ease-out duration-300"
                      From: "opacity-0"
                      To: "opacity-100"
                    Leaving: "ease-in duration-200"
                      From: "opacity-100"
                      To: "opacity-0"
                  --> */}
                  <div class="fixed inset-0 transition-opacity">
                    <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
                  </div>
                  {/* <!-- This element is to trick the browser into centering the modal contents. --> */}
                  <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;
                    {/* <!--
                      Modal panel, show/hide based on modal state.
                      Entering: "ease-out duration-300"
                        From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        To: "opacity-100 translate-y-0 sm:scale-100"
                      Leaving: "ease-in duration-200"
                        From: "opacity-100 translate-y-0 sm:scale-100"
                        To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    --> */}
                  <div class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                    <div class="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                      <button onClick={() => {
                        setOpenWarning(!openWarning)
                      }}
                        type="button" class="text-gray-400 hover:text-gray-500 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150" aria-label="Close">
                        {/* <!-- Heroicon name: x --> */}
                        <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                      </button>
                    </div>
                    <div class="sm:flex sm:items-start">
                      <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                        {/* <!-- Heroicon name: exclamation --> */}
                        <svg class="h-6 w-6 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                        </svg>
                      </div>
                      <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                        <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
                          Delete Sound Recording
                        </h3>
                        <div class="mt-2">
                          <p class="text-sm leading-5 text-gray-500">
                            Are you sure you want to delete this sound recording? This sound recording and all its data will be removed from our servers forever. This action cannot be undone.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                      <span class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
                        <button onClick={() => {
                          handleDelete(DSP_Recording_Identifier);
                        }}
                          type="button"
                          class="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-red-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                        >
                          Delete
                        </button>
                      </span>
                      <span class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
                        <button onClick={() => {
                          setOpenWarning(!openWarning)
                        }} type="button" class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                          Cancel
                        </button>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </Transition>

            {/* WARNING MESSAGE FOR UNLINKING A CHUNK FROM THE SOUND RECORDING */}
            <Transition show={openWarning2}>
              <div class="fixed z-10 inset-0 overflow-y-auto">
                <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                  {/* <!--
                    Background overlay, show/hide based on modal state.
                    Entering: "ease-out duration-300"
                      From: "opacity-0"
                      To: "opacity-100"
                    Leaving: "ease-in duration-200"
                      From: "opacity-100"
                      To: "opacity-0"
                  --> */}
                  <div class="fixed inset-0 transition-opacity">
                    <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
                  </div>
                  {/* <!-- This element is to trick the browser into centering the modal contents. --> */}
                  <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;
                    {/* <!--
                      Modal panel, show/hide based on modal state.
                      Entering: "ease-out duration-300"
                        From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        To: "opacity-100 translate-y-0 sm:scale-100"
                      Leaving: "ease-in duration-200"
                        From: "opacity-100 translate-y-0 sm:scale-100"
                        To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    --> */}
                  <div class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                    <div class="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                      <button onClick={() => {
                        setOpenWarning2(!openWarning2)
                      }}
                        type="button" class="text-gray-400 hover:text-gray-500 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150" aria-label="Close">
                        {/* <!-- Heroicon name: x --> */}
                        <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                      </button>
                    </div>
                    <div class="sm:flex sm:items-start">
                      <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                        {/* <!-- Heroicon name: exclamation --> */}
                        <svg class="h-6 w-6 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                        </svg>
                      </div>
                      <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                        <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
                          Unlink Chunk
                        </h3>
                        <div class="mt-2">
                          <p class="text-sm leading-5 text-gray-500">
                            Are you sure you want to unlink the chunk from this sound recording?
                            Once unlinked, this sound recording will not be deleted from the server
                            and will become searchable again to link to other chunks.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                      <span class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
                        <button onClick={() => {
                          handleUnlink(DSP_Recording_Identifier);
                        }}
                          type="button"
                          class="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-red-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                        >
                          Unlink
                        </button>
                      </span>
                      <span class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
                        <button onClick={() => {
                          setOpenWarning2(!openWarning2)
                        }} type="button" class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                          Cancel
                        </button>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </Transition>

          </div>
        )}
      </Transition>
    </div>
  );
};

export default SoundRecordingData;
