import React, { useState, useEffect } from 'react';
import axios from 'axios';
import axiosRetry from 'axios-retry';

// COMPONENTS
import { MemoizedPublisherAdminFeaturedChunkTable } from './PublisherAdminFeaturedChunkTable'

// ----------------------------------------------------------------------------------
// ---------------------- PUBLISHER ADMIN FEATURED CHUNK DATA -----------------------
// ----------------------------------------------------------------------------------

const PublisherAdminFeaturedChunkData = ({ data, refresh, setRefresh, open, setOpen, closed, setClosed }) => {
  const {
    id,
    name
  } = data;

  // State
  const [publisherChunks, setPublisherChunks] = useState([]);
  const [featuredChunkTotal, setFeaturedChunkTotal] = useState();
  const featuredLimit = 5;

  // Set axios to retry a set number of times on failed request/return
  axiosRetry(axios, { retries: 3 })

  // Get all of a publisher's chunks
  useEffect(() => {
    axios
      ({
        method: 'get',
        url: process.env.REACT_APP_API + `publishers/${id}/chunks`,
        headers: {
          Accept: 'application/json',
          Authorization: process.env.REACT_APP_HEROKU_SERVER_KEY
        }
      })
      .then((res) => {
        setPublisherChunks(res.data)
      })
      .catch((err) => {
        console.log(err);
      });
  }, [refresh])

  // UseEffect that finds the total number of featured chunks currently in our db for the publisher
  useEffect(() => {
    var featuredChunks = publisherChunks.filter(fc => fc.featured === true)
    setFeaturedChunkTotal(featuredChunks.length)
  }, [refresh, publisherChunks])

  const toggleOpen = (id) => {
    let openObject = {}
    let closedObject = {}

    Object.keys(open).map(function (key, index) {
      if (key === id && closed[key] === true) {
        openObject[key] = true
        closedObject[key] = false
      } else {
        openObject[key] = false
        closedObject[key] = true
      }
    })
    setOpen(openObject)
    setClosed(closedObject)
  };

  return (
    <div class="mt-10 divide-y divide-gray-200">
      <div class="space-y-1 flex justify-between">
        <div>
          <h3 class="text-lg self-center leading-6 font-medium text-gray-900">
            {name}
          </h3>
          <p class="max-w-2xl text-sm text-gray-500">
            Featured Chunks Available: {featuredLimit - featuredChunkTotal}
          </p>
        </div>
        <div className={'self-center'}>
          {name ?
            <button
              onClick={() => toggleOpen(id)}
              id='menu dropdown '
              aria-haspopup='true'
              className='inline-flex items-center justify-center w-8 h-8 text-gray-400 transition duration-150 ease-in-out bg-transparent rounded-full hover:text-gray-500 focus:outline-none'
            >
              <svg
                className={`${open[id] ? '-rotate-180' : 'rotate-0'
                  } h-6 w-6 transform`}
                fill='none'
                viewBox='0 0 24 24'
                stroke='currentColor'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth='2'
                  d='M19 9l-7 7-7-7'
                />
              </svg>
            </button> : null}
        </div>
      </div>

      {/* PUBLISHER DATA WHEN DROPDOWN SELECTED */}
      {open[id] ? (
        <div class="mt-6">
          <MemoizedPublisherAdminFeaturedChunkTable
            publisherChunks={publisherChunks}
            featuredChunkTotal={featuredChunkTotal}
            featuredLimit={featuredLimit}
            refresh={refresh}
            setRefresh={setRefresh}
          />
        </div>
      ) : null}
    </div>
  );
};

export default PublisherAdminFeaturedChunkData;