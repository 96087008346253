import React, { useState, useEffect } from 'react';
import axios from 'axios';
import axiosRetry from 'axios-retry';

// ROUTING
import { Link } from 'react-router-dom';

// STATE
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  collectionFilterState,
  searchedCollections,
  searchBarTerm
} from '../../state/atoms';

// COMPONENTS
import { MemoizedCollectionCard } from './CollectionCard';
import { MemoizedChunkCard } from './ChunkCard';

// IMAGES
import BasicsBanner from '../../img/collections/mc_basics_banner.png'
import ConcertsBanner from '../../img/collections/mc_concerts_banner.png'
import ExercisesBanner from '../../img/collections/mc_exercises_banner.png'
import JazzBanner from '../../img/collections/mc_jazz_banner.png'
import MarchingBanner from '../../img/collections/mc_marching_banner.png'
import VisualsBanner from '../../img/collections/mc_visuals_banner.png'
import LearnBanner from '../../img/collections/mc_learn_about_banner.png'

// ----------------------------------------------------------------------------------
// ---------------------------- COLLECTION TYPE PAGE --------------------------------
// ----------------------------------------------------------------------------------

const CollectionTypePage = ({ cognitoUser, match, location, handleClearSearchBar }) => {
  // State
  const collectionMusic = useRecoilValue(searchedCollections);
  const searchTerm = useRecoilValue(searchBarTerm);
  const collectionFilter = useSetRecoilState(collectionFilterState);
  const [collectionType, setCollectionType] = useState({})

  const collectionTypes = [
    {
      id: 0,
      name: 'Marching Arts',
      filter: 'showmusic',
      banner_pic: MarchingBanner,
      description: `Marching Arts Collections containing chunks of show music, solos, and etudes ranging from Marching Band, to Indoor Percussion and Winds, and Drum and Bugle Corps. Discover and Learn their music with audio and video examples using powerful practice tools such as metronome, looping with count-in, and playback speed control.`,
      collections: 'show_collections',
      chunks: 'show_chunks'
    },
    {
      id: 1,
      name: 'Concert',
      filter: 'concerts',
      banner_pic: ConcertsBanner,
      description: `Concert Collections containing chunks of concert, percussion, and wind ensemble music as well as solos and etudes. Discover and Learn their music with audio and video examples using powerful practice tools such as metronome, looping with count-in, and playback speed control.`,
      collections: 'concert_collections',
      chunks: 'concert_chunks'
    },
    {
      id: 2,
      name: 'Jazz Pop Combo',
      filter: 'jazz',
      banner_pic: JazzBanner,
      description: `Collections containing chunks of music for Jazz ensemble, Steel drum ensemble, and Combo ensemble instruments. Discover and Learn their music with audio and video examples using powerful practice tools such as metronome, looping with count-in, and playback speed control.`,
      collections: 'jazz_collections',
      chunks: 'jazz_chunks'
    },
    {
      id: 3,
      name: 'Exercises & Etudes',
      filter: 'exercises',
      banner_pic: ExercisesBanner,
      description: `Collections of Fundamental studies and Exercises by today’s top educators. Take your skills to the next level. Discover and Learn their music with audio and video examples using powerful practice tools such as metronome, looping with count-in, and playback speed control.`,
      collections: 'exercise_collections',
      chunks: 'exercise_chunks'
    },
    {
      id: 4,
      name: 'Music Chunks Band Basics',
      filter: 'basics',
      banner_pic: BasicsBanner,
      description: `A One-Stop shop for warm-ups, scales, rudiments, technique-builders, and basics your band program needs. Discover and Learn these basics with audio and video examples using powerful practice tools such as metronome, looping with count-in, and playback speed control.`,
      collections: 'basic_collections',
      chunks: 'basic_chunks'
    },
    {
      id: 5,
      name: 'Visual & Choreography',
      filter: 'visuals',
      banner_pic: VisualsBanner,
      description: `Collections of Visuals & Choreography by today’s top educators. Take your skills to the next level. Discover and Learn their music with audio and video examples using powerful practice tools such as metronome, looping with count-in, and playback speed control.`,
      collections: 'visual_collections',
      chunks: 'visual_chunks'
    },
    {
      id: 6,
      name: 'Learn About Music Chunks',
      filter: 'learn',
      banner_pic: LearnBanner,
      description: `This is a collection of all instructional videos, tips, tricks, and how-to’s to help navigate and use Music Chunks!`,
      collections: 'learn_collections',
      chunks: 'learn_chunks'
    },
  ]

  // Set axios to retry a set number of times on failed request/return
  axiosRetry(axios, { retries: 3 })

  // UseEffect for the search bar to help filtering collections
  useEffect(() => {
    collectionFilter(location.search.split('=')[1]);
  }, [collectionFilter, location.search]);

  // UseEffect to determine which collection type to filter by
  useEffect(() => {
    collectionTypes.map(collectionType => {
      if (location.pathname.includes(collectionType?.filter)) {
        setCollectionType(collectionTypes[collectionType.id])
      }
    })
  }, []);

  return (
    <>
      {/* ABOUT THE COLLECTION */}
      <img
        src={collectionType.banner_pic}
        alt='img for a collection type'
        className='inline-block object-cover w-full h-full rounded-t-md'
      />
      <div className='bg-green-700 px-5 py-3 rounded-b-md'>
        <h1 className='text-white text-lg mb-5 font-bold'>
          {collectionType.name} Collections
        </h1>
        <p className='whitespace-pre-wrap text-white mb-5'>
          {collectionType.description}
        </p>
      </div>

      {/* LIST OF COLLECTIONS */}
      {collectionMusic?.[collectionType?.collections]?.length === 0 && collectionMusic?.[collectionType?.chunks]?.length === 0 && searchTerm !== '' ? (
        <div className='flex flex-col items-center justify-center p-16 '>
          <p className='text-lg font-bold leading-6 text-emptySearchResults'>
            Couldn't find
          </p>
          <p className='text-lg font-bold text-emptySearchResults'>"{searchTerm}"</p>
          <p className='text-sm text-center text-white'>
            Try searching again using a different spelling or keyword.
          </p>
        </div>
      ) : (
        < div className='grid gap-10 mt-2 grig-cols-1 sm:grid-cols-2 md:grid-cols-2 xl:grid-cols-4 2xl:px-15 '>
          {collectionMusic?.[collectionType?.collections]?.map((collection) => {
            return (
              <Link
                to={`/dashboard/collections/${collection.id}`}
                onClick={() => {
                  handleClearSearchBar();
                }}
              >
                <MemoizedCollectionCard
                  key={collection.id}
                  data={collection}
                  cognitoUser={cognitoUser}
                />
              </Link>
            );
          })}

          {/* LIST OF SINGLE CHUNKS BY COLLECTION TYPE */}
          {collectionMusic?.[collectionType?.chunks]?.map((chunk) => {
            return (
              <Link
                to={`/dashboard/chunks/${chunk.id}`}
                onClick={() => {
                  handleClearSearchBar();
                }}
              >
                <MemoizedChunkCard
                  data={chunk}
                  cognitoUser={cognitoUser}
                  handleClearSearchBar={handleClearSearchBar}
                  key={chunk.id}
                  match={match}
                />
              </Link>
            );
          })}
        </div>
      )}
    </>
  );
};

export default CollectionTypePage;
