import React from 'react';

// ROUTING
import { Link } from 'react-router-dom';

// COMPONENTS
import { MemoizedGroupUserTable } from './GroupUserTable';
import { MemoizedGroupPublicChunkTable } from './GroupPublicChunkTable';
import { MemoizedGroupFeaturedChunkTable } from './GroupFeaturedChunkTable';
import { MemoizedGroupCollectionTable } from './GroupCollectionTable';
import { MemoizedGroupPlaylistTable } from './GroupPlaylistTable';

// ICONS
import { ReactComponent as PlusIcon } from '../../img/icon/Plus.svg';

// ----------------------------------------------------------------------------------
// -------------------------------- GROUP ADMIN CARD --------------------------------
// ----------------------------------------------------------------------------------

const GroupAdminCard = ({ group, groupPlaylists, groupUsers, groupChunks, groupFeaturedChunks, groupCollections, refresh, setRefresh, match }) => {
  return (
    <div className='mt-3'>
      {/* EDIT ENSEMBLE CARD*/}
      <div className='flex justify-between mt-2 border-t-4 border-green-600 border-solid'>
        <h1 className='pt-2 text-2xl font-medium leading-6 text-white'>
          EDIT ENSEMBLE INFO
          </h1>
      </div>
      <div className='bg-green-700 px-5 py-3 mt-2 rounded-lg'>
        <p className='whitespace-pre-wrap text-white'>
          Change the images, name, and description of this Ensemble.
        </p>
      </div>
      {/* EDIT ENSEMBLE CARD */}
      <div className='grid justify-center gap-10 mt-2 sm:grid-cols-2 2xl:px-15'>
        <Link to={`${match.url}/edit-ensemble`}>
          <div className={`hover:bg-mcgreen max-w-359 hover:bg-opacity-25 p-2 rounded-md`}>
            <div className={`opacity-50 overflow-hidden`}>
              {/* TOP IMG */}
              <img
                src={group?.banner_img}
                alt='img for a single user'
                className='object-fill w-full h-full min-h-full max-h-40 rounded-t-md'
              />
              {/* color bar */}
              <div className={`relative  w-full  rounded-b-lg bg-collcards`}>
                <div className='flex items-center justify-center px-4 py-2 text-sm text-white'>
                  <span>++ EDIT ENSEMBLE INFO ++</span>
                </div>
              </div>
            </div>
          </div>
        </Link>
      </div>

      {/* LIST OF PUBLIC CHUNKS SECTION */}
      <div className='flex justify-between mt-2 border-t-4 border-green-600 border-solid'>
        <div className='flex'>
          <h1 className='text-center pt-2 text-2xl font-medium leading-6 text-white mr-3'>
            MANAGE ENSEMBLE CHUNKS
          </h1>
          <Link to={`${match.url}/add-chunk`}>
            <PlusIcon className='object-center w-auto h-10 p-1' />
          </Link>
        </div>
      </div>
      <div className='bg-green-700 px-5 py-3 mt-2 rounded-t-lg'>
        <p className='whitespace-pre-wrap text-white'>
          Add/Remove content for your Ensemble below. You can search and add chunks from the entire Music Chunks
          library, or you can upload your own ensemble private content. Click the ‘Add Chunks to Ensemble’ button
          below to search and add chunks for your Ensemble. To remove chunks, use the boxes on the far left to
          select one or more chunks, then click the ‘Remove Chunks’ button. You can upload your own ensemble
          private content by clicking the ‘Upload Ensemble Private Content’ button. **Chunks must be added here
          first, then you can add them to playlists or feature them**
        </p>
      </div>

      {/* RENDERS TABLE FOR ALL OF A GROUP'S PUBLIC CHUNKS */}
      <MemoizedGroupPublicChunkTable group={group} groupChunks={groupChunks} refresh={refresh} setRefresh={setRefresh} match={match} />

      {/* LIST OF FEATURED CHUNKS SECTION */}
      <div className='flex justify-between mt-2 border-t-4 border-green-600 border-solid'>
        <div className='flex'>
          <h1 className='text-center pt-2 text-2xl font-medium leading-6 text-white mr-3'>
            MANAGE FEATURED CHUNKS
          </h1>
          <Link to={`${match.url}/add-featuredchunk`}>
            <PlusIcon className='object-center w-auto h-10 p-1' />
          </Link>
        </div>
      </div>
      <div className='bg-green-700 px-5 py-3 mt-2 rounded-t-lg'>
        <p className='whitespace-pre-wrap text-white'>
          Select Featured Chunks to appear at the top of your Ensemble Home Page. Click the “Add Featured Chunks”
          button below to search and add featured Chunks. To “un-feature” a Chunk, use the boxes on the far left
          to select one or more chunks, then click the ‘Remove Featured Chunks’ button.
        </p>
      </div>

      {/* RENDERS TABLE FOR ALL OF A GROUP'S FEATURED CHUNKS */}
      <MemoizedGroupFeaturedChunkTable group={group} groupFeaturedChunks={groupFeaturedChunks} refresh={refresh} setRefresh={setRefresh} match={match} />

      {/* LIST OF PUBLIC COLLECTIONS SECTION */}
      <div className='flex justify-between mt-2 border-t-4 border-green-600 border-solid'>
        <div className='flex'>
          <h1 className='text-center pt-2 text-2xl font-medium leading-6 text-white mr-3'>
            MANAGE PUBLISHER COLLECTIONS
          </h1>
          <Link to={`${match.url}/add-collection`}>
            <PlusIcon className='object-center w-auto h-10 p-1' />
          </Link>
        </div>
      </div>
      <div className='bg-green-700 px-5 py-3 mt-2 rounded-t-lg'>
        <p className='whitespace-pre-wrap text-white'>
          Instead of chunk-by-chunk, you can add entire collections from any Publisher on Music Chunks for your
          Ensemble to use. Click the “Add Collections” button below to search by Publisher Name or Collection
          Name and add collections for your ensemble. To Remove a collection, use the boxes on the far left to
          select one or more collections, then click the ‘Remove Collections’ button.
        </p>
      </div>

      {/* RENDERS TABLE FOR ALL OF A GROUP'S PUBLISHER COLLECTIONS */}
      <MemoizedGroupCollectionTable group={group} groupCollections={groupCollections} refresh={refresh} setRefresh={setRefresh} match={match} />

      {/* LIST OF PRIVATE PLAYLISTS SECTION */}
      <div className='flex justify-between mt-2 border-t-4 border-green-600 border-solid'>
        <div className='flex'>
          <h1 className='text-center pt-2 text-2xl font-medium leading-6 text-white mr-3'>
            MANAGE PLAYLISTS
          </h1>
          <Link to={`${match.url}/add-playlist`}>
            <PlusIcon className='object-center w-auto h-10 p-1' />
          </Link>
        </div>
      </div>
      <div className='bg-green-700 px-5 py-3 mt-2 rounded-t-lg'>
        <p className='whitespace-pre-wrap text-white'>
          Create and organize custom playlists. Click the “Add New Playlist” button below to create a new playlist.
          Click on any of your playlists on this list to open that playlist and edit the contents. To Remove
          playlists, use the boxes on the far left to select one or more playlists, then click the ‘Remove
          Playlists’ button.
        </p>
      </div>

      {/* RENDERS TABLE FOR ALL OF A GROUP'S PRIVATE PLAYLISTS */}
      <MemoizedGroupPlaylistTable group={group} groupPlaylists={groupPlaylists} refresh={refresh} setRefresh={setRefresh} match={match} />

      {/* LIST OF STUDENTS SECTION */}
      {group.public === false ? (
        <div>
          <div className=' sm:flex justify-between mt-2 border-t-4 border-green-600 border-solid'>
            <div className='flex'>
              <h1 className='text-center pt-2 text-2xl font-medium leading-6 text-white mr-3'>
                MANAGE STUDENTS
              </h1>
              {(group?.user_limit - groupUsers?.length) > 0 ? (
                <Link to={`${match.url}/add-student`}>
                  <PlusIcon className='object-center w-auto h-10 p-1' />
                </Link>
              ) : null}
            </div>
            <h2 className='text-center sm:justify-self-end pt-2 text-2xl font-medium leading-6 text-white'>
              Seats Available - {group?.user_limit - groupUsers?.length}
            </h2>
          </div>
          <div className='bg-green-700 px-5 py-3 mt-2 rounded-t-lg'>
            <p className='whitespace-pre-wrap text-white'>
              Add/Remove students to give or take away access to your private Ensemble classroom. This affects
              the seats available. As students come and go within your Ensemble, just add or remove them here.
              To invite new students, click the 'Invite Students' button below. To remove them, select as many students
              from the table as you wish and click the 'Remove Students' button.
            </p>
          </div>

          {/* RENDERS TABLE FOR ALL OF A GROUP'S USERS */}
          <MemoizedGroupUserTable group={group} groupUsers={groupUsers} refresh={refresh} setRefresh={setRefresh} match={match} />
        </div>
      ) : null}
    </div>
  );
};

export const MemoizedGroupAdminCard = React.memo(GroupAdminCard);