import React from 'react';

// ROUTING
import { useRouteMatch } from 'react-router-dom';

// STATE
import { useRecoilValue } from 'recoil';
import { adminSoundRecordingFilter } from '../../../state/atoms';

// COMPONENTS
import SoundRecordingData from './SoundRecordingData';

// ----------------------------------------------------------------------------------
// ------------------------------ SOUND RECORDING TABLE -----------------------------
// ----------------------------------------------------------------------------------

const SoundRecordingTable = () => {
  // State
  const route = useRouteMatch();
  const soundRecordings = useRecoilValue(
    adminSoundRecordingFilter(route.params?.compID)
  );

  return (
    <div className='py-4 '>
      {soundRecordings.map((data) => (
        <SoundRecordingData key={data.id} data={data} />
      ))}
    </div>
  );
};

export default SoundRecordingTable;