import React, { useState, useEffect } from 'react';
import { Avatar, useChatContext } from 'stream-chat-react';

// ROUTING
import { useParams } from 'react-router-dom';

// STYLING
import './UserList.css';

// ICONS
import { InviteIcon } from '../../assets';

// ----------------------------------------------------------------------------------
// ------------------------------------ USER LIST -----------------------------------
// ----------------------------------------------------------------------------------

const ListContainer = ({ children, userListType }) => {
    return (
        <div className='user-list__container'>
            <div className='user-list__header'>
                <p>User</p>
                {/* <p>Last Active</p> */}
                <p>{userListType.includes('add-members') ? 'Invite' : 'Remove'}</p>
            </div>
            {children}
        </div>
    );
}

const UserItem = ({ index, user, setSelectedUsers }) => {
    const [selected, setSelected] = useState(false)

    // const getLastActive = (i) => {
    //     switch (i) {
    //         case 0:
    //             return '12 min ago';
    //         case 1:
    //             return '27 min ago';
    //         case 2:
    //             return '6 hours ago';
    //         case 3:
    //             return '14 hours ago';
    //         case 4:
    //             return 'Yesterday';
    //         default:
    //             return 'Yesterday';
    //     }
    // };

    const handleSelect = () => {
        if (selected) {
            setSelectedUsers((prevUsers) => prevUsers.filter((prevUser) => prevUser !== user.id))
        } else {
            setSelectedUsers((prevUsers) => [...prevUsers, user.id])
        }

        setSelected((prevSelected) => !prevSelected)
    }

    return (
        <div className='user-item__wrapper' onClick={handleSelect}>
            <div className='user-item__name-wrapper'>
                <Avatar image={user.image} name={user.fullName || user.id} size={32} />
                <p className='user-item__name'>{user.fullName || user.id}</p>
            </div>
            {/* <p className='user-item__last-active'>{getLastActive(index)}</p> */}
            {selected ? <InviteIcon /> : <div className='user-item__invite-empty' />}
        </div>
    )
}

const UserList = ({ setSelectedUsers, userListType }) => {
    const { client, channel } = useChatContext();
    const groupID = useParams();
    const [users, setUsers] = useState([]);
    const [joinedUsers, setJoinedUsers] = useState([]);
    const [unjoinedUsers, setUnjoinedUsers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [usersListEmpty, setUsersListEmpty] = useState(false);
    const [joinedUsersListEmpty, setJoinedUsersListEmpty] = useState(false);
    const [unjoinedUsersListEmpty, setUnjoinedUsersListEmpty] = useState(false);
    const [error, setError] = useState(false);

    useEffect(() => {
        const getUsers = async () => {
            if (loading) return;

            setLoading(true);

            try {
                let makeUserQuery = true;
                let userOffset = 0
                const userLimit = 100
                let allUsers = []

                // Keep hitting the user endpoint up to the user Limit to get all users (Stream Chat only allows 100 users per request)
                while (makeUserQuery) {
                    const usersResponse = await client.queryUsers({
                        $and: [
                            { id: { $ne: client.userID } },
                            { teams: { $contains: groupID.id } }
                        ],
                    }, { fullName: 1 }, { limit: userLimit, offset: userOffset });

                    if (usersResponse.users.length < userLimit) {
                        allUsers.push(usersResponse.users)
                        makeUserQuery = false
                        break;
                    } else {
                        userOffset += userLimit;
                        allUsers.push(usersResponse.users)
                    }
                }

                // Merge the users arrays into one array
                let mergedUsers = [].concat.apply([], allUsers)

                let makeMemberQuery = true;
                let memberOffset = 0
                const memberLimit = 100
                let allMembers = []

                // Keep hitting the member endpoint up to the user Limit to get all channel members (Stream Chat only allows 100 users per request)
                if (channel) {
                    while (makeMemberQuery) {
                        const joinedUsersResponse = await channel.queryMembers({
                            $and: [
                                { joined: true },
                                { id: { $ne: client.userID } }
                            ],
                        }, { name: 1 }, { limit: memberLimit, offset: memberOffset });

                        if (joinedUsersResponse.members.length < memberLimit) {
                            allMembers.push(joinedUsersResponse.members)
                            makeMemberQuery = false
                            break;
                        } else {
                            memberOffset += memberLimit;
                            allMembers.push(joinedUsersResponse.members)
                        }
                    }
                }

                // Merge the users arrays into one array
                let mergedMembers = [].concat.apply([], allMembers)

                // Filters out users who are already joined to the channel
                let filteredUserResponse = [];

                if (channel) {
                    filteredUserResponse = mergedUsers.filter(el => {
                        return !mergedMembers.find(element => {
                            return element.user.id === el.id;
                        });
                    });
                }
                if (filteredUserResponse.length) {
                    setUnjoinedUsers(filteredUserResponse);
                } else {
                    setUnjoinedUsersListEmpty(true);
                }

                if (mergedUsers.length) {
                    setUsers(mergedUsers);
                } else {
                    setUsersListEmpty(true);
                }

                if (mergedMembers.length) {
                    setJoinedUsers(mergedMembers);
                } else {
                    setJoinedUsersListEmpty(true);
                }

            } catch (error) {
                setError(true);
                console.log(error)
            }
            setLoading(false);
        }

        if (client) getUsers()
    }, []);

    // if (error) {
    //     return (
    //         <ListContainer userListType={userListType}>
    //             <div className='user-list__message'>
    //                 Error loading, please refresh and try again.
    //             </div>
    //         </ListContainer>
    //     )
    // }

    if (joinedUsersListEmpty && userListType === 'remove-members') {
        return (
            <ListContainer userListType={userListType}>
                <div className='user-list__message'>
                    No users found.
                </div>
            </ListContainer>
        )
    }

    if (unjoinedUsersListEmpty && userListType === 'add-members') {
        return (
            <ListContainer userListType={userListType}>
                <div className='user-list__message'>
                    No users found.
                </div>
            </ListContainer>
        )
    }

    if (usersListEmpty && userListType === 'add-members-create') {
        return (
            <ListContainer userListType={userListType}>
                <div className='user-list__message'>
                    No users found.
                </div>
            </ListContainer>
        )
    }

    return (
        <ListContainer userListType={userListType}>
            {loading ? (
                <div className='user-list__message'>Loading users...</div>
            ) : userListType === 'add-members' ? (
                unjoinedUsers?.length &&
                unjoinedUsers.map((user, i) => (
                    <UserItem index={i} key={user.id} setSelectedUsers={setSelectedUsers} user={user} />
                ))
            ) : userListType === 'remove-members' ? (
                joinedUsers?.length &&
                joinedUsers.map((user, i) => (
                    <UserItem index={i} key={user.user.id} setSelectedUsers={setSelectedUsers} user={user.user} />
                ))
            ) : (
                users?.length &&
                users.map((user, i) => (
                    <UserItem index={i} key={user.id} setSelectedUsers={setSelectedUsers} user={user} />
                ))
            )}
        </ListContainer>
    );
};

export default UserList;