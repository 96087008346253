import React from 'react';

// IMAGES
import { ReactComponent as BlankPublisher } from '../../img/icon/BlankPublisher.svg';
import PlaceholderBanner from '../../img/placeholder-banner.jpg';

// ----------------------------------------------------------------------------------
// --------------------------------- PUBLISHER CARD ---------------------------------
// ----------------------------------------------------------------------------------

const PublisherCard = ({ data }) => {
  const {
    name,
    bio,
    profile_pic,
    banner_pic
  } = data;

  return (
    <div
      key={name}
      className={`hover:bg-mcgreen hover:bg-opacity-25 p-2 rounded-lg`}
    >
      <div className={`overflow-hidden`}>
        {/* TOP IMG */}
        {banner_pic ? (
          <img
            src={banner_pic}
            alt='img for a single publisher'
            className='inline-block object-cover w-full h-full rounded-t-md'
          />
        ) : (
          <img
            src={PlaceholderBanner}
            alt='placeholder img for a single publisher'
            className='inline-block object-cover w-full h-full rounded-t-md'
          />
        )}

        {/* PUBLISHER INFO BAR */}
        <div className='flex items-start p-2 bg-green-700 rounded-b-lg'>
          <div className='flex-shrink-0'>
            {profile_pic ? (
              <img
                className='inline-block object-cover w-24 h-24 rounded-md'
                src={profile_pic}
                alt='profile for a single publisher'
              />
            ) : (
              <BlankPublisher className='inline-block object-fill w-12 h-12 rounded-md' />
            )}
          </div>

          <div className='flex-1 w-0 ml-5'>
            <dl className='w-full md:w-1/2'>
              <dt className='pb-2 text-lg font-medium leading-5 text-white truncate-2-lines'>
                {name}
              </dt>
              <dd>
                <div className='text-sm font-medium text-publisherbio truncate-3-lines'>
                  <span className='mr-2'>{bio}</span>
                </div>
              </dd>
            </dl>
          </div>
        </div>
      </div>
    </div>
  );
};

export const MemoizedPublisherCard = React.memo(PublisherCard);